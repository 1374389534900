angular.module('CaresApp').component('ocrUpcomingActivities', {
  templateUrl: 'components/ocr-upcoming-activities/ocr-upcoming-activities.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    '$document',
    '$mdDialog',
    'OcrUtils',
    'UpcomingActivityService',
    function($scope, $document, $mdDialog, OcrUtils, UpcomingActivityService) {
      const self = this;
      OcrUtils.setPageTitle('Upcoming Activities');
      self.today = new Date();

      self.events = [];

      self.promise = null;
      $scope.$watch(() => self.promise, () => {
        self.setPromise()(self.promise);
      });

      self.updateData = function() {
        console.log("Updating");
        self.promise = UpcomingActivityService.query({
          limit: 1000,
        }).$promise.then(
          function(resp) {
            angular.element('#calendar').fullCalendar('prev');
            angular.element('#calendar').fullCalendar('next');
            angular.element('#calendar').fullCalendar('removeEvents');
            for (const event of resp.list) {
              self.events.push({
                id: event.id,
                title: event.title,
                start: new Date(event.start),
                appointmentId: event.appointmentId,
                userId: event.userId,
                groupId: event.groupId,
                typeId: event.typeId,
                billable: event.billable,
                created: event.created,
                user: event.user,
                stick: true,
              });
            }
          },
        ).finally(() => self.promise = null)
      }

      self.eventSources = [self.events];

      self.onEventClick = function(activity) {
        $mdDialog.show({
          controller: 'AddUpcomingActivityDialogController',
          templateUrl:
            'components/ocr-upcoming-activities/add-upcoming-activity-dialog.template.html',
          parent: angular.element($document[0].body),
          locals: {
            add: false,
            activity: activity,
            date: undefined,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          multiple: true,
          clickOutsideToClose: false,
        }).then(function() {
          self.updateData();
        }, function() {});
      };
      /* alert on Drop */
      self.onDrop = function(_, delta) {
      };
      /* alert on Resize */
      self.onResize = function(_, delta) {
      };

      self.onDayClick = function(day) {
        // TODO date is being displayed incorrectly when passed in
        if (day._d < self.today) {
          const message
            = "Upcoming Activities must be scheduled for the future. To add an Upcoming Activity, "
            + "click OK and either select a date in the future or click the Add Activity button.";
          $mdDialog.show(
            $mdDialog.alert()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Activity Must Be Upcoming')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK')
              .multiple(true),
          );
        } else {
          self.addActivity(new Date(day._d));
        }
      }

      self.uiConfig = {
        calendar: {
          height: 600,
          editable: true,
          header: {
            left: 'month basicWeek basicDay',
            center: 'title',
            right: 'today prev,next',
          },
          displayEventTime: false,
          timezone: 'UTC',
          eventClick: self.onEventClick,
          eventDrop: self.onDrop,
          eventResize: self.onResize,
          dayClick: self.onDayClick,
        },
      };

      self.addActivity = function(date) {
        $mdDialog.show({
          controller: 'AddUpcomingActivityDialogController',
          templateUrl:
            'components/ocr-upcoming-activities/add-upcoming-activity-dialog.template.html',
          parent: angular.element($document[0].body),
          locals: {
            add: true,
            activity: undefined,
            date: date,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          multiple: true,
          clickOutsideToClose: false,
          //fullscreen: self.customFullscreen // Only for -xs, -sm breakpoints.
        }).then(function(newActivity) {
          self.events.push({
            id: newActivity.id,
            title: newActivity.title,
            start: new Date(newActivity.start),
            appointmentId: newActivity.appointmentId,
            userId: newActivity.userId,
            groupId: newActivity.groupId,
            typeId: newActivity.typeId,
            billable: newActivity.billable,
            created: newActivity.created,
            user: newActivity.user,
            stick: true,
          });
        }, function() {});
      }

      self.onLoad = function() {
        self.updateData();
      };
    },
  ],
});
