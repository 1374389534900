angular.module('CaresApp').component('ocrMyOutOfOfficeAppointments', {
  templateUrl: 'components/ocr-my-out-of-office-appointments/ocr-my-out-of-office-appointments.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    '$location',
    'AppointmentService',
    'OcrAuth',
    'OcrUtils',
    'OcrViews',
    'SessionService',
    'AppointmentOfficeAccessRequestService',
    'AppointmentBookmarkService',
    'OcrLookups',
    function($scope, $location, AppointmentService, OcrAuth,
             OcrUtils, OcrViews, SessionService, AppointmentOfficeAccessRequestService, AppointmentBookmarkService, OcrLookups) {
      const self = this;
      self.selected = [];
      self.today = OcrUtils.today;
      self.currentView = OcrViews.currentView;
      self.getUserFullName = OcrUtils.getUserFullName;
      self.loaded = false;
      self.appointments = [];
      self.appointments.count = 0;
      self.appointments.total = 0;
      self.search = {
        showActiveOut: true,
        inOffice: false,
        bookmarkOut: false
      };
      self.hasPermission = OcrAuth.checkPermission;
      OcrLookups.get('HelpText').then(
        (helpText) => self.requestHelpText = helpText.lookup(3).text,
      );
      const promises = [];

      self.promise = null;
      self.saveSearch = function() {
        if (self.search.showInactiveOut == undefined) {
          self.search.showInactiveOut = false;
        }
        if (self.search.nearMaxOut == undefined) {
          self.search.nearMaxOut = false;
        }
        if(self.search.showActiveOut == undefined) {
          self.search.showActiveOut = false;
        }
        if(self.search.mineOnlyOut == undefined) {
          self.search.mineOnlyOut = false;
        }
        if(self.search.bookmarkOut == undefined) {
          self.search.bookmarkOut = false;
        }
        localStorage.setItem('bookmarkOut', self.search.bookmarkOut);
        localStorage.setItem('mineOnlyOut', self.search.mineOnlyOut);
        localStorage.setItem('showInactiveOut', self.search.showInactiveOut);
        localStorage.setItem('nearMaxOut', self.search.nearMaxOut);
        localStorage.setItem('showActiveOut', self.search.showActiveOut);
        localStorage.setItem('dateFromOut', self.search.dateFromOut);
        localStorage.setItem('dateToOut', self.search.dateToOut);
        localStorage.setItem('abbreviatedCaseNumberOut', self.search.abbreviatedCaseNumberOut);
        localStorage.setItem('childPartyFirstNameOut', self.search.childPartyFirstNameOut);
        localStorage.setItem('childPartyLastNameOut', self.search.childPartyLastNameOut);
      }

      self.resetFilter = function() {
        self.search.bookmarkOut = false;
        self.search.showActiveOut = true;
        self.search.showInactiveOut = false;
        self.search.mineOnlyOut = false;
        self.search.nearMaxOut = false;
        self.search.dateFromOut = undefined;
        self.search.dateToOut = undefined;
        self.search.abbreviatedCaseNumberOut = '';
        self.search.childPartyLastNameOut = '';
        self.search.childPartyFirstNameOut = '';
        localStorage.setItem('showInactiveOut', '');
        localStorage.setItem('nearMaxOut', '');
        localStorage.setItem('showActiveOut', self.search.showActiveOut);
        localStorage.setItem('mineOnlyOut', '');
        localStorage.setItem('dateFromOut', undefined);
        localStorage.setItem('dateToOut', undefined);
        localStorage.setItem('abbreviatedCaseNumberOut', '');
        localStorage.setItem('childPartyFirstNameOut', '');
        localStorage.setItem('childPartyLastNameOut', '');
        localStorage.setItem('bookmarkOut', '');
        self.updateData();
      }

      self.updateData = function() {
        self.search.skip = (self.query.page - 1) * self.query.limit;
        self.search.limit = self.query.limit;
        self.search.inOffice = false;
        promises.push(AppointmentService.get(self.search)
          .$promise.then(function(resp) {
            self.appointments = resp.list;
            self.appointments.count = resp.count;
            self.appointments.total = resp.total;
            for (const appointment_ of self.appointments) {
              const childPartyNames = [];
              promises.push(AppointmentOfficeAccessRequestService.get({
                appointmentId: appointment_.id,
                requestStatusId: 4,
                officeId: OcrAuth.session.user.officeId,
                revoked: 0
              }).$promise.then(function (response) {
                for (const request of response.list) {
                  if (request.dateInactive == undefined) {
                    appointment_.isActive = true;
                  } else if (request.dateInactive != null) {
                    appointment_.isActive = false;
                  }
                  if (request.requesterId == OcrAuth.session.user.id) {
                    appointment_.myRequest = true;
                  } else {
                    appointment_.myRequest = false;
                    break;
                  }
                }
              }));
              promises.push(AppointmentBookmarkService.get({
                appointmentId: appointment_.id,
                userId: OcrAuth.session.user.id
              }).$promise.then(function (response) {
                if (response.list.length == 0) {
                  appointment_.isBookmark = false;
                } else {
                  appointment_.isBookmark = true;
                }
              }));
              if (appointment_.childParties) {
                for (const childParty of appointment_.childParties) {
                  if (childParty.endDate) {
                    childParty.endDate = new Date(childParty.endDate);
                  }
                  if (!childParty.endDate || childParty.endDate > self.today) {
                    childPartyNames.push(OcrUtils.getChildPartyFullName(
                      childParty));
                  }
                }
              }
              appointment_.childPartyNames = childPartyNames.join("; ");
            }
            self.loaded = true;
          }));
      };

      self.buildSearch = function(object) {
        let search;
        angular.forEach(object, function(value, key) {
          if (angular.isUndefined(search)) {
            if (typeof value === "boolean" && value === true) {
              search = key;
            } else {
              search = `${key}=${value}`;
            }
          } else if (typeof value === "boolean" && value === true) {
            search = `${search}&${key}`;
          } else {
            search = `${search}&${key}=${value}`;
          }
        });
        return search;
      }

      self.$onInit = function() {
        self.search = $location.search();
        if (Object.keys(self.search).length !== 0) {
          self.query.limit = self.search.limit;
          self.query.page = (self.search.skip / self.query.limit) + 1;
          self.search.inOffice = false;
          self.search.showInactiveOut = (localStorage.getItem('showInactiveOut') == 'true');
          self.search.nearMaxOut = (localStorage.getItem('nearMaxOut') == 'true');
          self.search.mineOnlyOut = (localStorage.getItem('mineOnlyOut') == 'true');
          self.search.bookmarkOut = (localStorage.getItem('bookmarkOut') == 'true');
          if (localStorage.getItem('dateFromOut') != null && localStorage.getItem('dateFromOut') != 'undefined') {
            self.search.dateFromOut = localStorage.getItem('dateFromOut');
          }
          if (localStorage.getItem('dateToOut') != null && localStorage.getItem('dateToOut') != 'undefined') {
            self.search.dateToOut = localStorage.getItem('dateToOut');
          }
          if (localStorage.getItem('abbreviatedCaseNumberOut') != null && localStorage.getItem('abbreviatedCaseNumberOut') != 'undefined') {
            self.search.abbreviatedCaseNumberOut = localStorage.getItem('abbreviatedCaseNumberOut');
          }
          if (localStorage.getItem('childPartyFirstNameOut') != null && localStorage.getItem('childPartyFirstNameOut') != 'undefined') {
            self.search.childPartyFirstNameOut = localStorage.getItem('childPartyFirstNameOut');
          }
          if (localStorage.getItem('childPartyLastNameOut') != null && localStorage.getItem('childPartyLastNameOut') != 'undefined') {
            self.search.childPartyLastNameOut = localStorage.getItem('childPartyLastNameOut');
          }
          if (localStorage.getItem('showActiveOut') == null) {
            self.search.showActiveOut = true;
          } else {
            self.search.showActiveOut = (localStorage.getItem('showActiveOut') == 'true');
          }
        } else {
          self.query.limit = 50;
          self.search.inOffice = false;
          self.search.showInactiveOut = (localStorage.getItem('showInactiveOut') == 'true');
          self.search.nearMaxOut = (localStorage.getItem('nearMaxOut') == 'true');
          self.search.mineOnlyOut = (localStorage.getItem('mineOnlyOut') == 'true');
          self.search.bookmarkOut = (localStorage.getItem('bookmarkOut'));
          if (localStorage.getItem('dateFromOut') != null && localStorage.getItem('dateFromOut') != 'undefined') {
            self.search.dateFromOut = localStorage.getItem('dateFromOut');
          }
          if (localStorage.getItem('dateToOut') != null && localStorage.getItem('dateToOut') != 'undefined') {
            self.search.dateToOut = localStorage.getItem('dateToOut');
          }
          if (localStorage.getItem('abbreviatedCaseNumberOut') != null && localStorage.getItem('abbreviatedCaseNumberOut') != 'undefined') {
            self.search.abbreviatedCaseNumberOut = localStorage.getItem('abbreviatedCaseNumberOut');
          }
          if (localStorage.getItem('childPartyFirstNameOut') != null && localStorage.getItem('childPartyFirstNameOut') != 'undefined') {
            self.search.childPartyFirstNameOut = localStorage.getItem('childPartyFirstNameOut');
          }
          if (localStorage.getItem('childPartyLastNameOut') != null && localStorage.getItem('childPartyLastNameOut') != 'undefined') {
            self.search.childPartyLastNameOut = localStorage.getItem('childPartyLastNameOut');
          }
          if (localStorage.getItem('showActiveOut') == null) {
            self.search.showActiveOut = true;
          } else {
            self.search.showActiveOut = (localStorage.getItem('showActiveOut') == 'true');
          }
        }
        self.updateData();
      };

      self.requestAccess = function() {
        var url = '' + self.currentView.key + '/' + self.appointments.appointment.id;
        $location.path(url);
      }

      self.makeActive = function() {
        var today = new Date();
        for (const appointment of self.selected) {
          promises.push(AppointmentOfficeAccessRequestService.get({
            appointmentId: appointment.id,
            requestStatusId: 4,
            officeId: OcrAuth.session.user.officeId,
            revoked: 0
          }).$promise.then(function (response) {
            for (const request of response.list) {
              if (request.dateInactive != null) {
                if (request.revoked != true && request.requesterId == OcrAuth.session.user.id) {
                  request.dateInactive = null;
                  promises.push(
                    AppointmentOfficeAccessRequestService.update({
                      id: request.id,
                    }, request).$promise,);
                  appointment.isActive = true;
                }
              }
            }
          }));
        }
        self.selected = [];
      }

      self.makeInactive = function() {
        var today = new Date();
        for (const appointment of self.selected) {
          promises.push(AppointmentOfficeAccessRequestService.get({
            appointmentId: appointment.id,
            requestStatusId: 4,
            officeId: OcrAuth.session.user.officeId,
            revoked: 0
          }).$promise.then(function (response) {
            for (const request of response.list) {
              if (request.dateInactive == null) {
                if (request.revoked != true && request.requesterId == OcrAuth.session.user.id) {
                  request.dateInactive = today;
                  promises.push(
                    AppointmentOfficeAccessRequestService.update({
                      id: request.id,
                    }, request).$promise,);
                  appointment.isActive = false;
                }
              }
            }
          }));
        }
        self.selected = [];
      }

      $scope.$on('resetFilters', () => {
          self.resetFilter();
        },
      );

      self.addBookmark = function() {
        for (const appointment of self.selected) {
          if (appointment.isBookmark == false) {
            promises.push(AppointmentBookmarkService.save({
              appointmentId: appointment.id,
              userId: OcrAuth.session.user.id
            }).$promise,);
            appointment.isBookmark = true;
          }
        }
        self.selected = [];
      }

      self.deleteBookmark = function() {
        for (const appointment of self.selected) {
          if (appointment.isBookmark == true) {
            promises.push(AppointmentBookmarkService.get({
                appointmentId: appointment.id,
                userId: OcrAuth.session.user.id
              }).$promise.then(function (response) {
                for (const bookmark of response.list) {
                  promises.push(AppointmentBookmarkService.delete({
                    id: bookmark.id
                  }).$promise,);
                }
              })
            )
            appointment.isBookmark = false;
          }
        }
        self.selected = [];
      }

      self.disableMakeActive = function() {
        return !(self.selected[0].myRequest && !self.selected[0].isActive);
      }

      self.disableMakeInactive = function() {
        return !(self.selected[0].myRequest && self.selected[0].isActive);
      }

      self.disableAddBookmark = function() {
        return self.selected[0].isBookmark;
      }

      self.disableRemoveBookmark = function() {
        return !self.selected[0].isBookmark;
      }

      $scope.$on("LoggingOut",
        () => {
          SessionService.signout();
        },
      );

      $scope.$on('$locationChangeStart',
        function(_, newUrl, oldUrl) {
          const newArray = newUrl.split("?");
          const oldArray = oldUrl.split("?");
          if (newArray[0] !== oldArray[0]) {
            $location.search("");
          }
        });
    },
  ],
});
