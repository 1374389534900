angular.module('CaresApp').component('ocrAttorneyRoleEdit', {
    templateUrl: 'components/ocr-attorney-role-edit/ocr-attorney-role-edit.template.html',
    controller: [
      '$scope',
      '$document',
      '$location',
      '$routeParams',
      '$mdToast',
      '$mdDialog',
      'AttorneyRoleService',
      'OcrLookups',
      'OcrUtils',
      'OcrAuth',
      function($scope, $document, $location, $routeParams, $mdToast, $mdDialog, AttorneyRoleService,
        OcrLookups, OcrUtils, OcrAuth,
      ) {
        $scope.$emit("SetSearchBox", false);
        const self = this;
  
        self.roleTypes = OcrLookups.dummyList;
        self.hasPermission = OcrAuth.checkPermission;
  
        OcrLookups.get('RoleType').then(
          (roleTypes) => self.roleTypes = roleTypes,
        )
  
        if ($routeParams.attorneyRoleId !== 'create') {
          self.id = $routeParams.attorneyRoleId;
        } else {
          OcrUtils.setPageTitle('New Attorney Role');
        }
        self.loading = true;
        if (self.id) {
          self.promise = AttorneyRoleService.get({ id: self.id }).$promise
            .then(function(resp) {
              self.entity = resp;
              OcrUtils.setPageTitle(`Attorney Role: ${self.entity.attorneyRole}`);
            })
            .finally(() => self.loading = false);
        } else {
          self.entity = {}
          self.loading = false
        }
  
        self.save = function(andExit) {
          const message
            = "This is a reminder that this Role cannot be deleted after creation, and its Role Type cannot be edited later. </br>"
            + "Edits to the role name/order will display to anyone already using this role. </br>"
            + "Click Cancel to go back and check your entries or click Confirm.";
  
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Please Confirm')
              .htmlContent(message)
              .ariaLabel(message)
              .ok('Confirm')
              .cancel('Cancel'),
          ).then(function() {
            // ok
            OcrLookups.remove('AttorneyRole')
            OcrLookups.remove('AttorneyRole', true);
            $mdToast.show(
              $mdToast.simple()
                .textContent('Saving...')
                .position("bottom right"),
            );
            let saveFunction = null;
            let saveArgs = null;
            if (self.id) {
              saveFunction = AttorneyRoleService.update
              saveArgs = [{ id: self.id }, self.entity]
            } else {
              saveFunction = AttorneyRoleService.save
              saveArgs = [self.entity]
            }
            saveFunction.apply(this, saveArgs).$promise.then(
              function() {
                self.form.$setPristine();
                $mdToast.show(
                  $mdToast.simple()
                    .textContent('Attorney role saved.')
                    .position("bottom right"),
                )
                if (andExit) {
                  self.close(true)
                }
              },
            ).catch(function(error) {
              if (error && error.data && error.data.status === 401) {
                self.close(true);
                return;
              }
              if (error.data.reason.includes("Duplicate")) {
                console.log("contains duplicate")
                if (error.data.reason.includes("attorney_role_order")) {
                  console.log("contains order")
                  $mdToast.show(
                    $mdToast.simple()
                      .textContent("This Order is already in use, please enter a different value.")
                      .position("bottom right"),
                  );
                } else if (error.data.reason.includes("attorney_role")) {
                  console.log("contains role")
                  $mdToast.show(
                    $mdToast.simple()
                      .textContent("This Attorney Role is already in use, please enter a different value.")
                      .position("bottom right"),
                  );
                } else {
                  $mdToast.show(
                    $mdToast.simple()
                      .textContent(error.data.reason)
                      .position("bottom right"),
                  );
                }
              } else {
                $mdToast.show(
                  $mdToast.simple()
                    .textContent(error.data.reason)
                    .position("bottom right"),
                );
              }
            })
          }, () => {});
        }
  
        self.close = function(noConfirm) {
          
            $location.path(`/views/${$routeParams.view}`);
          
        }
  
        self.deleteForm = function() {
          const message
            = "Are you sure that you want to delete this attorney role?";
  
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Attorney Role Delete')
              .textContent(message)
              .ariaLabel(message)
              .ok('Delete Attorney Role')
              .cancel('Cancel'),
          ).then(function() {
            // ok
            OcrLookups.remove('AttorneyRole')
            OcrLookups.remove('AttorneyRole', true);
            $mdToast.show(
              $mdToast.simple()
                .textContent('Deleting...')
                .position("bottom right"),
            );
  
            AttorneyRoleService.delete({
              id: self.id,
            }, self.entity).$promise.then(function() {
              $mdToast.show(
                $mdToast.simple()
                  .textContent('Attorney role deleted.')
                  .position("bottom right"),
              );
              $location.path(`/views/${$routeParams.view}`);
            })
              .catch(function(error) {
                console.error("Error: %O", error);
                $mdToast.show(
                  $mdToast.simple()
                    .textContent(error.data.reason)
                    .position("bottom right"),
                );
              });
          }, () => {});
        };
  
        $scope.$on('$locationChangeStart',
          function(event, newUrl, oldUrl) {
            const removeQueryParams = (url) => {
              if (url) {
                const index = url.indexOf("?");
                if (index >= 0) {
                  return url.substring(0, index);
                }
              }
              return url;
            };
  
            const newPath = $location.path();
  
            if (
              newPath === "/"
              || self.form.$pristine
              || removeQueryParams(newUrl) === removeQueryParams(oldUrl)
            ) {
              return;
            }
  
            event.preventDefault();
  
            const message
              = "Are you sure that you want to close this form without saving your changes?";
  
            $mdDialog.show(
              $mdDialog.confirm()
                .parent(angular.element($document[0].querySelector(
                  '#popupContainer')))
                .clickOutsideToClose(false)
                .title('Confirm Page Exit')
                .textContent(message)
                .ariaLabel(message)
                .ok('OK')
                .cancel('Cancel'),
            ).then(() => {
              self.form.$setPristine();
              $location.path(newPath);
            });
          });
  
        self.$onInit =
          () => $scope.$watch(
            () => angular.isDefined(self.form),
            () => {
              if (self.form && self.form.$invalid) {
                angular.forEach(self.form.$error, function(field) {
                  angular.forEach(field, function(errorField) {
                    errorField.$setTouched();
                  });
                });
              }
            }, true);
      },
    ],
  });
  