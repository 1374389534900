angular.module('CaresApp').controller('AlertDialogController', [
  '$mdDialog',
  'OcrUtils',
  'OcrLookups',
  'RoleChangeAlertService',
  function($mdDialog, OcrUtils, OcrLookups, RoleChangeAlertService) {
    const self = this;

    self.close = function() {
      if(self.type == 'ar') {
        RoleChangeAlertService.update({
          appointmentChildPartyId: self.appointmentChildPartyId,
          field: "additional",
        })
      } else if(self.type == 'cw') {
        RoleChangeAlertService.update({
          appointmentChildPartyId: self.appointmentChildPartyId,
          field: "warning",
        })
      }
      $mdDialog.hide();
    }
  }
]);
