angular.module('CaresApp').controller('RoleChangeDialogController', [
  '$scope',
  '$mdDialog',
  'OcrUtils',
  'OcrLookups',
  function($scope, $mdDialog, OcrUtils, OcrLookups) {
    const self = this;

    $scope.$watch(
      () => angular.isDefined(self.childParty),
      function() {
        if (self.childParty) {
          self.changeDate = new Date(self.childParty.roleChangeDate).toUTCString();
          self.changeDate = self.changeDate.substring(0, self.changeDate.length - 13);
          self.ageRole = Math.floor((new Date(self.childParty.roleChangeDate) - new Date(self.childParty.dateOfBirth)) / 31557600000);
          self.age = Math.floor((new Date(self.childParty.roleChangeDate) - new Date(self.childParty.dateOfBirth)) / 31557600000)
        }
      }, true);

    OcrLookups.get('BestInterestsContinuationReason').then(
      (bestInterestsContinuationReasons) => self.bestInterestsContinuationReasons = bestInterestsContinuationReasons,
    );

    self.remind = function() {
      $mdDialog.hide({remind: true});
    }

    self.submit = function() {
      response = {
        remind: false,
        action: self.action,
        bicr: self.continuationReason,
        roleChangeDate: self.childParty.roleChangeDate
      }
      $mdDialog.hide(response);
    }
  }
]);
