angular.module('CaresApp').component('ocrPlacementEdit', {
  templateUrl: 'components/ocr-placement-edit/ocr-placement-edit.template.html',
  controller: [
    '$scope',
    '$document',
    '$location',
    '$routeParams',
    '$mdToast',
    '$mdDialog',
    'PlacementService',
    'OcrLookups',
    'OcrAuth',
    'OcrUtils',
    function($scope, $document, $location, $routeParams, $mdToast, $mdDialog, PlacementService,
      OcrLookups, OcrAuth, OcrUtils,
    ) {
      $scope.$emit("SetSearchBox", false);
      const self = this;
      self.hasPermission = OcrAuth.checkPermission;
      self.states = self.types = OcrLookups.dummyList;
      OcrLookups.get('PlacementType').then(
        (types) => self.types = types,
      )

      OcrLookups.get('State').then(
        (states) => self.states = states,
      );

      if ($routeParams.placementId !== 'create') {
        self.id = $routeParams.placementId;
      } else {
        OcrUtils.setPageTitle('New Placement');
      }
      self.loading = true;
      if (self.id) {
        self.promise = PlacementService.get({ id: self.id }).$promise
          .then(function(resp) {
            self.entity = resp;
            OcrUtils.setPageTitle(`Placement: ${self.entity.placement}`);
            if (self.entity.startDate) {
              self.entity.startDate = new Date(self.entity.startDate)
            }
            if (self.entity.endDate) {
              self.entity.endDate = new Date(self.entity.endDate)
            }
          })
          .finally(() => self.loading = false);
      } else {
        self.entity = {
          outOfHome: true,
          state: "CO",
        }
        self.loading = false
      }

      self.save = function(andExit) {
        $mdToast.show(
          $mdToast.simple()
            .textContent('Saving...')
            .position("bottom right"),
        );
        let saveFunction = null;
        let saveArgs = null;
        if (self.id) {
          saveFunction = PlacementService.update
          saveArgs = [{ id: self.id }, self.entity]
        } else {
          saveFunction = PlacementService.save
          saveArgs = [self.entity]
        }
        saveFunction.apply(this, saveArgs).$promise.then(
          function() {
            self.form.$setPristine();
            $mdToast.show(
              $mdToast.simple()
                .textContent('Placement saved.')
                .position("bottom right"),
            )
            if (andExit) {
              self.close(true)
            }
          },
        ).catch(function(error) {
          if (error && error.data && error.data.status === 401) {
            self.close(true);
            return;
          }
          if (error && error.data) {
            $mdToast.show(
              $mdToast.simple()
                .textContent(error.data.reason)
                .position("bottom right"),
            );
          }
        })
      }

      self.close = function(noConfirm) {
        if (noConfirm || self.form.$pristine) {
          $location.path(`/views/${$routeParams.view}`);
        } else {
          const message
              = "Are you sure that you want to close this form without saving your changes?";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Form Close')
              .textContent(message)
              .ariaLabel(message)
              .ok('Close Form')
              .cancel('Cancel'),
          ).then(
            () => $location.path(`/views/${$routeParams.view}`),
            () => {},
          );
        }
      }

      self.deleteForm = function() {
        const message
          = "Are you sure that you want to delete this placement?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Placement Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete Placement')
            .cancel('Cancel'),
        ).then(function() {
          // ok
          $mdToast.show(
            $mdToast.simple()
              .textContent('Deleting...')
              .position("bottom right"),
          );

          PlacementService.delete({
            id: self.id,
          }, self.entity).$promise.then(function() {
            $mdToast.show(
              $mdToast.simple()
                .textContent('Placement deleted.')
                .position("bottom right"),
            );
            $location.path(`/views/${$routeParams.view}`);
          })
            .catch(function(error) {
              if (error && error.data) {
                $mdToast.show(
                  $mdToast.simple()
                    .textContent(error.data.reason)
                    .position("bottom right"),
                );
              }
            });
        }, () => {});
      };

      self.validateLength = function() {
        if (self.entity.phone) {
          if (self.entity.phone.toString().length !== 10) {
            self.form.phoneNumber.$setValidity("length", false);
          } else {
            self.form.phoneNumber.$setValidity("length", true);
          }
        } else {
          self.form.phoneNumber.$setValidity("required", false);
        }
      };

      $scope.$on('$locationChangeStart',
        function(event, newUrl, oldUrl) {
          const removeQueryParams = (url) => {
            if (url) {
              const index = url.indexOf("?");
              if (index >= 0) {
                return url.substring(0, index);
              }
            }
            return url;
          };

          const newPath = $location.path();

          if (
            newPath === "/"
            || self.form.$pristine
            || removeQueryParams(newUrl) === removeQueryParams(oldUrl)
          ) {
            return;
          }

          event.preventDefault();

          const message
            = "Are you sure that you want to close this form without saving your changes?";

          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Page Exit')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK')
              .cancel('Cancel'),
          ).then(() => {
            self.form.$setPristine();
            $location.path(newPath);
          });
        });

      self.$onInit =
        () => $scope.$watch(
          () => angular.isDefined(self.form),
          function() {
            if (self.form && self.form.$invalid) {
              angular.forEach(self.form.$error, function(field) {
                angular.forEach(field, function(errorField) {
                  errorField.$setTouched();
                });
              });
            }
          }, true);
    },
  ],
});
