angular.module('CaresApp').component('ocrOfficeEdit', {
  templateUrl: 'components/ocr-office-edit/ocr-office-edit.template.html',
  controller: [
    '$scope',
    '$document',
    '$location',
    '$routeParams',
    '$q',
    '$mdToast',
    '$mdDialog',
    '$http',
    'OfficeService',
    'OfficeHistoryService',
    'VendorService',
    'OcrLookups',
    'OcrUtils',
    'OcrAuth',
    function($scope, $document, $location, $routeParams, $q, $mdToast, $mdDialog, $http,
      OfficeService, OfficeHistoryService, VendorService, OcrLookups, OcrUtils, OcrAuth) {
      $scope.$emit("SetSearchBox", false);
      const self = this;

      self.statuses = self.states = OcrLookups.dummyList;
      self.statusHistory = null;
      self.vendors = {
        data: [],
        count: 0,
        total: 0,
        deleted: [],
      };
      self.latestInvoiceDates = new Map();

      OcrLookups.get('OfficeStatus').then(
        (statuses) => self.statuses = statuses,
      );

      OcrLookups.get('State').then(
        (states) => self.states = states,
      );

      if ($routeParams.officeId !== 'create') {
        self.id = $routeParams.officeId;
      } else {
        OcrUtils.setPageTitle('New Office');
      }
      self.loading = true;
      if (self.id) {
        self.promise = OfficeService.get({ id: self.id }).$promise
          .then(function(resp) {
            self.entity = resp;
            self.entity.currentStatusId = self.entity.statusId;
            OcrUtils.setPageTitle(`Office: ${self.entity.name}`);
          })
          .finally(() => self.loading = false);

        OfficeHistoryService.get({
          limit: 1000,
          officeId: self.id,
        }).$promise
          .then((response) => self.statusHistory = response.list);

        VendorService.get({
          officeId: self.id,
        }).$promise
          .then(function(response) {
            self.vendors.data = response.list;
            self.vendors.count = response.count;
            self.vendors.total = response.total;
            self.vendors.deleted = [];
          });

        $http.get(`/api/latestInvoice/${self.id}`).then(function(response) {
          for (const invoice of response.data.list) {
            self.latestInvoiceDates.set(invoice.vendorId, invoice.rangeTo);
          }
        });
      } else {
        self.entity = {
          state: "CO",
        }
        self.loading = false
      }

      self.getUserFullName = OcrUtils.getUserFullName;
      self.hasPermission = OcrAuth.checkPermission;

      self.save = function(andExit) {
        if (self.validateVendors()) {
          self.submitting = true;
          OcrLookups.remove('Office');
          OcrLookups.remove('Office', true);
          OcrLookups.remove('Vendor');
          OcrLookups.remove('Vendor', true);
          $mdToast.show(
            $mdToast.simple()
              .textContent('Saving...')
              .position("bottom right"),
          );
          let saveFunction = null;
          let saveArgs = null;
          if (self.id) {
            saveFunction = OfficeService.update
            saveArgs = [{ id: self.id }, self.entity]
          } else {
            saveFunction = OfficeService.save
            saveArgs = [self.entity]
          }
          saveFunction.apply(this, saveArgs).$promise.then(
            (response) => {
              self.entity.currentStatusId = self.entity.statusId;
              if (!self.id) {
                self.id = response.id;
                self.entity.id = response.id;
              }
              for (const vendor of self.vendors.data) {
                // In case this was a new office
                vendor.officeId = response.id;
              }
              VendorService.save({
                vendors: self.vendors.data,
              }).$promise.then(
                () => {
                  $mdToast.show(
                    $mdToast.simple()
                      .textContent('Office saved.')
                      .position("bottom right"),
                  );
                  self.form.$setPristine();
                  if (andExit) {
                    self.close(true);
                  } else {
                    VendorService.get({
                      officeId: self.id,
                    }).$promise
                      .then(function(response) {
                        self.vendors.data = response.list;
                        self.vendors.count = response.count;
                        self.vendors.total = response.total;
                        self.vendors.deleted = [];
                        self.submitting = false;
                      });
                    OfficeHistoryService.get({
                      limit: 1000,
                      officeId: self.id,
                    }).$promise
                      .then((response) => self.statusHistory = response.list);
                  }
                },
              );
              const deletePromises = [];
              for (const vendor of self.vendors.deleted) {
                deletePromises.add(VendorService.delete({
                  id: vendor.id,
                }));
              }
              $q.all(deletePromises).then(() => self.vendors.deleted = []);
            },
          ).catch(function(error) {
            if (!error) {
              self.close(true);
              return;
            }
            self.submitting = false;
            let msg = null;
            if (error.data) {
              msg = error.data.reason;
            } else {
              msg = error.reason;
            }
            if (!msg) {
              msg = "An unknown error occurred";
            }
            $mdToast.show(
              $mdToast.simple()
                .textContent(msg)
                .position("bottom right"),
            );
          })
        } else {
          $mdDialog.show(
            $mdDialog.alert()
              .title('Vendors Not Valid')
              .textContent("There is an issue with the dates of the Vendors. This could be "
                + "because the dates for vendors overlap or there is more than one Vendor without "
                + "an end date.  Please correct the dates accordingly and try again.")
              .ok('Close')
              .targetEvent(event),
          );
        }
      }

      self.close = function(noConfirm) {
        if (noConfirm || self.form.$pristine) {
          $location.path(`/views/${$routeParams.view}`);
        } else {
          const message =
              "Are you sure that you want to close this form without saving your changes?";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Form Close')
              .textContent(message)
              .ariaLabel(message)
              .ok('Close Form')
              .cancel('Cancel'),
          ).then(
            () => $location.path(`/views/${$routeParams.view}`),
            () => {});
        }
      }

      self.deleteForm = function() {
        const message =
          "Are you sure that you want to delete this office?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Office Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete Office')
            .cancel('Cancel'),
        ).then(function() {
          // ok
          OcrLookups.remove('Office')
          OcrLookups.remove('Office', true);
          OcrLookups.remove('Vendor')
          OcrLookups.remove('Vendor', true);
          $mdToast.show(
            $mdToast.simple()
              .textContent('Deleting...')
              .position("bottom right"),
          );

          OfficeService.delete({
            id: self.id,
          }, self.entity).$promise.then(function() {
            for (const vendor of self.vendors.data) {
              //TODO do we need to wait for these promises?
              VendorService.delete({
                id: vendor.id,
              });
            }

            $mdToast.show(
              $mdToast.simple()
                .textContent('Office deleted.')
                .position("bottom right"),
            );
            $location.path(`/views/${$routeParams.view}`);
          })
            .catch(function(error) {
              console.error("Error: %O", error);
              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            });
        }, () => {});
      };

      self.addVendor = function() {
        let tempVendor = {};
        if (self.vendors.count == 0) {
          tempVendor = {
            officeId: self.id,
            address1: self.entity.address1,
            address2: self.entity.address2,
            city: self.entity.city,
            state: self.entity.state,
            zip: self.entity.zip,
            phone: self.entity.phone,
            extension: self.entity.extension,
          };
        } else {
          tempVendor = {
            officeId: self.id,
            address1: self.vendors.data[self.vendors.count - 1].address1,
            address2: self.vendors.data[self.vendors.count - 1].address2,
            city: self.vendors.data[self.vendors.count - 1].city,
            state: self.vendors.data[self.vendors.count - 1].state,
            zip: self.vendors.data[self.vendors.count - 1].zip,
            phone: self.vendors.data[self.vendors.count - 1].phone,
            extension: self.vendors.data[self.vendors.count - 1].extension,
          };
        }
        $mdDialog.show({
          controller: 'AddVendorDialogController',
          templateUrl: 'tmpl/add-vendor-dialog.html',
          parent: angular.element($document.body),
          locals: {
            vendor: tempVendor,
            latestInvoiceDate: undefined,
          },
          clickOutsideToClose: false,
          fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
        })
          .then(function(response) {
            self.vendors.data.push(response);
            self.vendors.count++;
            self.vendors.total++;
            self.sortVendors();
            self.form.$setDirty();
          }, () => {});
      };

      self.editVendor = function(entry) {
        $mdDialog.show({
          controller: 'AddVendorDialogController',
          templateUrl: 'tmpl/add-vendor-dialog.html',
          parent: angular.element($document.body),
          locals: {
            vendor: entry,
            latestInvoiceDate: self.latestInvoiceDates.get(entry.id),
          },
          clickOutsideToClose: false,
          fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
        })
          .then(function(response) {
            for (let i = 0; i < self.vendors.data.length; i++) {
              if (self.vendors.data[i].$$hashKey === entry.$$hashKey) {
                if (response) {
                  self.vendors.data[i] = response;
                  break;
                } else {
                  self.vendors.deleted.push(self.vendors.data[i]);
                  self.vendors.data.splice(i, 1);
                  break;
                }
              }
            }
            self.sortVendors();
            self.form.$setDirty();
          }, () => {});
      };

      self.sortVendors = function() {
        self.vendors.data.sort((a, b) => {
          const da = new Date(a.startDate),
            db = new Date(b.startDate);
          return da - db;
        });
      }

      self.validateVendors = function() {
        let overlap = false;
        let missing = false;

        for (let i = 0; i < self.vendors.data.length; i++) {
          if (i < self.vendors.data.length - 1 && !self.vendors.data[i].endDate) {
            console.log("Missing");
            missing = true;
          }
          if (i > 0) {
            const start = parseValidationDates(self.vendors.data[i].startDate);
            const end = parseValidationDates(self.vendors.data[i - 1].endDate);
            if (start.getTime() <= end.getTime()) {
              overlap = true;
            }
          }
        }

        return !overlap && !missing;
      }

      function parseValidationDates(date) {
        if (typeof date === 'string') {
          const splitDate = date.split('-');
          return new Date(splitDate[0],
            splitDate[1] - 1,
            splitDate[2]);
        } else {
          const newDate = new Date(date);
          return new Date(newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate());
        }
      }

      self.validateLength = function() {
        if (angular.isDefined(self.entity.phone) && self.entity.phone !== null) {
          if (self.entity.phone.toString().length !== 10) {
            self.form.phoneNumber.$setValidity("length", false);
          } else {
            self.form.phoneNumber.$setValidity("length", true);
          }
        } else {
          self.form.phoneNumber.$setValidity("required", false);
        }
      };

      $scope.$on('$locationChangeStart',
        function(event, newUrl, oldUrl) {
          const removeQueryParams = (url) => {
            if (url) {
              const index = url.indexOf("?");
              if (index >= 0) {
                return url.substring(0, index);
              }
            }
            return url;
          };

          const newPath = $location.path();

          if (
            newPath === "/"
            || self.form.$pristine
            || removeQueryParams(newUrl) === removeQueryParams(oldUrl)
          ) {
            return;
          }

          event.preventDefault();

          const message
            = "Are you sure that you want to close this form without saving your changes?";

          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Page Exit')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK')
              .cancel('Cancel'),
          ).then(() => {
            self.form.$setPristine();
            $location.path(newPath);
          });
        });

      self.$onInit = function() {
        $scope.$watch(
          () => angular.isDefined(self.form) && self.form !== null ,
          function() {
            if (self.form && self.form.$invalid) {
              angular.forEach(self.form.$error, function(field) {
                angular.forEach(field, function(errorField) {
                  errorField.$setTouched();
                });
              });
              return null;
            }
            return false;
          }, true);
      }
    },
  ],
});
