angular.module('CaresApp').controller('ViewPlacementDialogController', [
  '$mdDialog',
  'OcrLookups',
  function($mdDialog, OcrLookups) {
    const self = this;
    self.placementTypes = OcrLookups.dummyList;
    OcrLookups.get('PlacementType').then(
      (placementTypes) => self.placementTypes = placementTypes,
    );

    self.formatAddress = function() {
      self.placement.address = "";
      if (self.placement.address1) {
        self.placement.address += self.placement.address1;
      }

      if (self.placement.address1 && self.placement.address2) {
        self.placement.address += ", ";
      }

      if (self.placement.address2) {
        self.placement.address += self.placement.address2;
      }

      if (self.placement.city || self.placement.state || self.placement.zip) {
        if (self.placement.address1 || self.placement.address2) {
          self.placement.address += "\n";
        }
        let city = false;
        let state = false;
        if (self.placement.city) {
          city = true;
          self.placement.address += self.placement.city;
        }
        if (self.placement.state) {
          state = true;
          if (city) {
            self.placement.address += ", ";
          }
          self.placement.address += self.placement.state;
        }
        if (self.placement.zip) {
          if (state) {
            self.placement.address += " ";
          }
          self.placement.address += self.placement.zip;
        }
      }
    }

    self.$onInit = () => self.formatAddress();

    self.getPlacementTypeById = (id) => self.placementTypes.lookup(id);

    self.cancel = $mdDialog.cancel;
    self.hide = $mdDialog.hide;
  },
]);
