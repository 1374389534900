angular.module('CaresApp').controller('TFARemindDialogController', [
  '$location',
  '$mdDialog',
  '$mdToast',
  'UserService',
  'user',
  function($location, $mdDialog, $mdToast, UserService, user) {
    const self = this;
    self.user = user;
    self.today = new Date();

    self.submitting = false;

    self.remindLater = function() {
      self.submitting = true;
      user.tfaRemind = new Date();
      // Add 10 days before reminding again
      //TODO reenable for Production
      user.tfaRemind.setDate(user.tfaRemind.getDate() + 10);
      // user.tfaRemind = new Date(user.tfaRemind.getTime() + (600000));
      UserService.update(user).$promise.then(
        () => {
          self.submitting = false;
          $mdDialog.hide();
        },
      ).catch((error) => {
        if (error && error.data) {
          $mdToast.show(
            $mdToast.simple()
              .textContent(error.data.reason)
              .position("bottom right"),
          );
        }
      })
    };

    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;
    self.submit = function() {
      self.submitting = true;
      $mdDialog.hide();
      $location.path('views/general_settings').search({quickSetup : 1});
      self.submitting = false;
    };
  },
]);
