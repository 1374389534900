angular.module('CaresApp').controller('AddSavedChildPartyPlacementDialogController', [
  '$scope',
  '$mdDialog',
  'OcrLookups',
  'OcrUtils',
  'SavedPlacementService',
  function($scope, $mdDialog, OcrLookups, OcrUtils, SavedPlacementService) {
    const self = this;

    self.placementTypes = self.placementOptions = self.states = OcrLookups.dummyList;
    OcrLookups.get('PlacementType').then(
      (placementTypes) => self.placementTypes = placementTypes,
    );
    OcrLookups.get('Placement').then(
      (placementOptions) => self.placementOptions = placementOptions,
    );
    OcrLookups.get('State').then((states) => self.states = states);

    self.today = OcrUtils.today;
    self.placements = [];
    self.placements.total = 0;
    self.selected = [];
    self.query = {
      order: undefined,
      limit: 20,
      page: 1,
    };
    self.childPartyPlacement = {};

    self.$onInit = function() {
      $scope.$watch(
        () => angular.isDefined(self.addSavedPlacementForm),
        function() {
          $scope.$watch(
            () => self.addSavedPlacementForm.$invalid === true,
            function() {
              angular.forEach(self.addSavedPlacementForm.$error, function(field) {
                angular.forEach(field, function(errorField) {
                  errorField.$setTouched();
                });
              });
            }, true);
        }, true);
      self.updateData();
    }

    self.updateData = function() {
      self.promise = SavedPlacementService.get({
        skip: (self.query.page - 1) * self.query.limit,
        limit: self.query.limit,
        query: self.searchText ? self.searchText : undefined,
      }).$promise.then(
        function(response) {
          self.placements = response.list;
          self.placements.total = response.total;
        }).finally(() => self.promise = undefined);
    };

    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;

    self.submit = function() {
      const placement = self.placementOptions.lookup(
        self.placementTypes.lookup(self.selected[0].placementTypeId).type, 'placement');
      self.childPartyPlacement.placementId = placement.id;
      self.childPartyPlacement.placement = placement.placement;
      self.childPartyPlacement.placementName = self.selected[0].placementName;
      self.childPartyPlacement.placementType = self.placementTypes.lookup(
        self.selected[0].placementTypeId).type;
      self.childPartyPlacement.placementTypeId = self.selected[0].placementTypeId;
      self.childPartyPlacement.address1 = self.selected[0].address1;
      self.childPartyPlacement.address2 = self.selected[0].address2;
      self.childPartyPlacement.city = self.selected[0].city;
      self.childPartyPlacement.state = self.selected[0].state;
      self.childPartyPlacement.zip = self.selected[0].zip;
      self.childPartyPlacement.phone = self.selected[0].phone;
      self.childPartyPlacement.extension = self.selected[0].extension;
      self.childPartyPlacement.email = self.selected[0].email;
      self.childPartyPlacement.outOfHome = self.selected[0].outOfHome;
      self.childPartyPlacement.fullAddress = undefined;
      $mdDialog.hide(self.childPartyPlacement);
    };

    self.doSearch = OcrUtils.debounce(() => self.updateData(), 500);
  },
]);
