angular.module('CaresApp').component('ocrSideNav', {
  templateUrl: 'components/ocr-side-nav/ocr-side-nav.template.html',
  bindings: {
    toggle: '&ocrToggle',
  },
  controller: [
    '$scope',
    '$location',
    '$window',
    '$timeout',
    '$mdSidenav',
    '$mdMedia',
    'OcrViews',
    'OcrAuth',
    function($scope, $location, $timeout, $window, $mdSidenav, $mdMedia, OcrViews,
      OcrAuth) {
      const self = this;

      self.checkPermission = OcrAuth.checkPermission
      Object.defineProperty(self, 'navItems', { get: () => OcrViews.views })
      self.currentYear = (new Date()).getFullYear()

      self.toggleView = function(item) {
        item.visibleInSideNav = !item.visibleInSideNav
        if (item.children) {
          for (const child of item.children) {
            child.visibleInSideNav = item.visibleInSideNav
          }
        }
      };

      self.mdMedia = $mdMedia

      self.currentView = null
      if (OcrViews.currentView) {
        self.currentView = OcrViews.currentView.key
      }
      self.toggleSidenav = function() {
        if (!$mdSidenav('side-nav').isLockedOpen()) {
          $mdSidenav('side-nav').toggle();
        }
      };
      if (!OcrViews.currentView.visibleInSideNav) {
        self.toggleView(OcrViews.currentView.parent || OcrViews.currentView)
      }

      $scope.$on('ViewChanged',
        function(_, __, newView) {
          self.currentView = newView.key
          if (!self.checkPermissions(newView)) {
            return;
          }
          if (newView.parent) {
            self.toggleView(newView.parent);
          }
        },
      )

      self.checkPermissions = function(view) {
        if (OcrAuth.session
          && angular.isDefined(OcrAuth.session.user)
          && angular.isDefined(view.permissions)
        ) {
          if (angular.isDefined(OcrAuth.session.user.permissions)) {
            if (view.hasChildren) {
              for (const permission of view.permissions) {
                if (OcrAuth.checkPermission(permission)) {
                  return true
                }
              }
              return false;
            }
            for (const permission of view.permissions) {
              if (!OcrAuth.checkPermission(permission)) {
                return false
              }
            }
            return true;
          }
        }
        return false;
      };


      self.clickNavItem = function(item) {
        if (item.hasChildren) {
          self.toggleView(item);
        } else if (item.key.localeCompare("help") === 0) {
          $window.open("https://ocr.zendesk.com/hc/en-us", '_blank');
        } else {
          $location.path(`/views/${item.key}`);
        }
      };
      // fix size of scroll area

      if (!$mdMedia("xs")) { // xs when fonts are very large for vision impaired
        self.listStyle = {
          maxHeight: `${$window.innerHeight - 385}px`,
        };
      } else {
        self.listStyle = {};
      }

      function onResize() {
        $timeout(function() {
          if (!$mdMedia("xs")) {
            self.listStyle.maxHeight = `${$window.innerHeight - 250
            }px`;
          } else {
            self.listStyle = {};
          }
        });
      }

      $window.addEventListener('resize', onResize, true);
    },
  ],
});
