angular.module('CaresApp').component('ocrOtherApprovalsEdit', {
  templateUrl: 'components/ocr-other-approvals-edit/ocr-other-approvals-edit.template.html',
  controller: [
    '$scope',
    '$document',
    '$location',
    '$routeParams',
    '$mdToast',
    '$mdDialog',
    'OtherExpenseRequestService',
    'OcrLookups',
    'OcrUtils',
    'OcrAuth',
    function($scope, $document, $location, $routeParams, $mdToast, $mdDialog,
      OtherExpenseRequestService, OcrLookups, OcrUtils, OcrAuth,
    ) {
      $scope.$emit("SetSearchBox", false);
      const self = this;
      self.today = new Date();
      self.submitting = false;

      self.id = $routeParams.otherApprovalsId;

      self.requestStatuses = self.requestTypes = OcrLookups.dummyList
      OcrLookups.get('ExcessFeesRequestStatus').then(
        (excessFeesRequestStatuses) => self.requestStatuses = excessFeesRequestStatuses,
      );
      OcrLookups.get('RequestType').then(
        (requestTypes) => self.requestTypes = requestTypes,
      );

      self.getUserFullName = OcrUtils.getUserFullName;

      self.loading = true;
      self.promise = OtherExpenseRequestService.get({ id: self.id }).$promise
        .then(function(resp) {
          self.entity = resp;
          OcrUtils.setPageTitle('Other Approvals');
          if (self.entity.timestamp) {
            self.entity.timestamp = new Date(self.entity.timestamp);
          }
          self.entity.amountCents = parseInt(self.entity.amountCents) / 100.00;
          if (self.entity.approvedAmountCents === 0) {
            self.entity.approvedAmountCents = undefined;
          } else {
            self.entity.approvedAmountCents = parseInt(self.entity.approvedAmountCents) / 100.00;
          }
        }).finally(() => self.loading = false);

      self.$onInit = function() {
        $scope.$watch(
          () => angular.isDefined(self.form),
          function() {
            if (self.form && self.form.$invalid) {
              angular.forEach(self.form.$error, function(field) {
                angular.forEach(field, function(errorField) {
                  errorField.$setTouched();
                });
              });
            }
          }, true);
      }

      self.exit = function(noConfirm) {
        if (noConfirm || self.form.$pristine) {
          $location.path(`/views/${$routeParams.view}`);
        } else {
          const message
              = "Are you sure that you want to close this form without submitting your changes?";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Form Close')
              .textContent(message)
              .ariaLabel(message)
              .ok('Close Form')
              .cancel('Cancel'),
          ).then(
            () => $location.path(`/views/${$routeParams.view}`),
            () => {},
          );
        }
      }

      self.review = function() {
        const message
          = "Are you sure that you want to have this request sent for additional review?";
        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title("Confirm Flag")
            .textContent(message)
            .ariaLabel(message)
            .ok("Flag Request")
            .cancel('Cancel'),
        ).then(function() {
          self.entity.requestStatusId = 2;
          self.save();
        }, () => {});
      }

      self.approve = function() {
        const message
          = "Are you sure that you want to approve this request?";
        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title("Confirm Approval")
            .textContent(message)
            .ariaLabel(message)
            .ok("Approve Request")
            .cancel('Cancel'),
        ).then(function() {
          self.entity.requestStatusId = 3;
          self.entity.userApprovedId = OcrAuth.session.userId;
          self.entity.userApproved = OcrAuth.session.user;
          self.save();
          //self.updateABA();
        }, () => {});
      };

      self.reject = function() {
        const message
          = "Are you sure that you want to reject this request?";
        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title("Confirm Rejection")
            .textContent(message)
            .ariaLabel(message)
            .ok("Reject Request")
            .cancel('Cancel'),
        ).then(function() {
          self.entity.requestStatusId = 4;
          self.save();
        }, () => {});
      };

      self.delete = function() {
        const message
          = "Are you sure that you want to delete this request?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Request Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete Request')
            .cancel('Cancel'),
        ).then(function() {
          // ok
          $mdToast.show(
            $mdToast.simple()
              .textContent('Deleting...')
              .position("bottom right"),
          );

          OtherExpenseRequestService.delete({
            id: self.id,
          }, self.entity).$promise.then(function() {
            $mdToast.show(
              $mdToast.simple()
                .textContent('Request deleted.')
                .position("bottom right"),
            );
            self.exit(true);
          })
            .catch(function(error) {
              if (error && error.data) {
                $mdToast.show(
                  $mdToast.simple()
                    .textContent(error.data.reason)
                    .position("bottom right"),
                );
              }
            });
        }, () => {});
      };

      self.close = function() {
        const message
          = "Are you sure that you want to dismiss the alert for this request? This is to remove "
          + "this request from your notifications. You can still find a record of the request in "
          + "the future by going to Other Approvals.";
        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title("Dismiss Alert?")
            .textContent(message)
            .ariaLabel(message)
            .ok("Dismiss Alert")
            .cancel('Cancel'),
        ).then(function() {
          if (self.entity.requestStatusId === 3) {
            self.entity.requestStatusId = 5;
          } else if (self.entity.requestStatusId === 4) {
            self.entity.requestStatusId = 6;
          }
          self.save();
        }, () => {});
      };

      self.save = function() {
        self.submitting = true;
        $mdToast.show(
          $mdToast.simple()
            .textContent('Submitting...')
            .position("bottom right"),
        );

        const params = angular.copy(self.entity);

        params.amountCents = parseInt(params.amountCents) * 100;
        params.approvedAmountCents = parseInt(params.approvedAmountCents) * 100;

        OtherExpenseRequestService.update(params).$promise.then(
          function() {
            self.submitting = false;
            $mdToast.show(
              $mdToast.simple()
                .textContent('Submitted.')
                .position("bottom right"),
            );
            self.exit(true);
          },
        ).catch(function(error) {
          self.submitting = false;
          if (error && error.data && error.data.status === 401) {
            self.exit(true);
            return;
          }
          if (error && error.data) {
            $mdToast.show(
              $mdToast.simple()
                .textContent(error.data.reason)
                .position("bottom right"),
            );
          }
        })
      }

      self.allowReview = function() {
        if (self.entity.requestStatusId === 1) {
          return OcrAuth.checkPermission("EXPENSE_REQUEST_REVIEW");
        }
        return false;
      }

      self.allowDecision = function() {
        if (self.entity.requestStatusId === 1 || self.entity.requestStatusId === 2) {
          return OcrAuth.checkPermission("EXPENSE_REQUEST_REVIEW");
        }
        return false;
      }

      self.allowDelete = function() {
        if (self.entity.requestStatusId === 1
          && OcrAuth.session.userId === self.entity.userSubmittedId) {
          return true;
        }
        return false;
      }

      self.allowClose = function() {
        if ((self.entity.requestStatusId === 3 || self.entity.requestStatusId === 4)
          && OcrAuth.session.userId === self.entity.userSubmittedId) {
          return true;
        }
        return false;
      }

      self.readOnlyCheck = function() {
        if (self.entity.requestStatusId === 1 || self.entity.requestStatusId === 2) {
          if (OcrAuth.checkPermission("EXPENSE_REQUEST_REVIEW")) {
            return false;
          }
          return true;
        }
        return true;
      }

      self.setPromise = function() {
        return self.promise;
      };
    },
  ],
});
