angular.module('CaresApp').component('ocrAdditionalFundsEdit', {
  templateUrl: 'components/ocr-additional-funds-edit/ocr-additional-funds-edit.template.html',
  controller: [
    '$scope',
    '$document',
    '$location',
    '$timeout',
    '$routeParams',
    '$mdToast',
    '$mdDialog',
    'ExcessFeesRequestService',
    'AppointmentService',
    'OcrLookups',
    'OcrUtils',
    'OcrAuth',
    function($scope, $document, $location, $timeout, $routeParams, $mdToast, $mdDialog,
      ExcessFeesRequestService, AppointmentService, OcrLookups, OcrUtils, OcrAuth) {
      $scope.$emit("SetSearchBox", false);
      const self = this;
      self.today = new Date();

      self.id = $routeParams.additionalFundsId;

      self.excessFeesRequestStatuses = OcrLookups.dummyList;
      OcrLookups.get('ExcessFeesRequestStatus').then(
        (excessFeesRequestStatuses) => self.excessFeesRequestStatuses = excessFeesRequestStatuses,
      );

      self.getUserFullName = OcrUtils.getUserFullName;
      self.getHistoryUserFullName = function(first, middle, last, suffix) {
        const user = {
          firstName: first,
          middleName: middle,
          lastName: last,
          suffix: suffix,
        }
        return OcrUtils.getUserFullName(user);
      }

      self.loading = true;
      self.promise = ExcessFeesRequestService.get({ id: self.id }).$promise
        .then(function(resp) {
          self.entity = resp;
          OcrUtils.setPageTitle('Additional Funds');
          if (self.entity.timestamp) {
            self.entity.timestamp = new Date(self.entity.timestamp);
          }
          self.entity.totalPaidCents = parseInt(self.entity.totalPaidCents) / 100.00;
          self.entity.remainingAmountCents = parseInt(self.entity.remainingAmountCents) / 100.00;
          self.entity.currentAllocationCents =
            parseInt(self.entity.currentAllocationCents) / 100.00;
          self.entity.additionalFundsCents = parseInt(self.entity.additionalFundsCents) / 100.00;
          self.entity.requestedAllocationCents =
            parseInt(self.entity.requestedAllocationCents) / 100.00;
          self.entity.initialAdditionalFundsCents =
            parseInt(self.entity.initialAdditionalFundsCents) / 100.00;

          self.entity.currentStatusId = self.entity.excessFeesRequestStatusId;

          return ExcessFeesRequestService.query({ appointment: self.entity.appointmentId }).$promise
        }, function(error){
          console.log("error: %O", error)
        if(error && error.data && error.data.status === 403){
          self.unAuthorized = true;
        }
      }).finally(() => self.promise = undefined)
        .then(function(response) {
          self.promise = undefined;
          self.historyEntries = response;
        }).finally(() => self.loading = false);

      self.updateTotal = function() {
        self.entity.requestedAllocationCents = parseInt(self.entity.currentAllocationCents)
          + parseInt(self.entity.additionalFundsCents);
      };

      self.$onInit =
        () => $scope.$watch(
          () => angular.isDefined(self.form),
          function() {
            if (self.form && self.form.$invalid) {
              angular.forEach(self.form.$error, function(field) {
                angular.forEach(field, function(errorField) {
                  errorField.$setTouched();
                });
              });
            }
          }, true);

      self.exit = function(noConfirm) {
        if (noConfirm || self.form.$pristine) {
          $location.path(`/views/${$routeParams.view}`);
        } else {
          const message
              = "Are you sure that you want to close this form without submitting your changes?";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Form Close')
              .textContent(message)
              .ariaLabel(message)
              .ok('Close Form')
              .cancel('Cancel'),
          ).then(
            () => $location.path(`/views/${$routeParams.view}`),
            () => {},
          );
        }
      };

      self.review = function() {
        if (self.entity.additionalFundsCents === null || self.entity.additionalFundsCents === 0) {
          const message = "Additional Funds Requests must be for more than $0. "
            + "Please reject this request instead.";
          $mdDialog.show(
            $mdDialog.alert()
              .parent(angular.element($document[0].querySelector('#popupContainer')))
              .clickOutsideToClose(true)
              .title('$0 Request')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK'),
          );
        } else if (self.entity.ocrComments === null
            || angular.isUndefined(self.entity.ocrComments)
            || self.entity.ocrComments === "") {
          const message =
          "Please enter OCR Comments first. Flagging a request for additional review will send "
          + "an email to the requester via CARES that includes any OCR Comments you type on the "
          + "request.";
          $mdDialog.show(
            $mdDialog.alert()
              .parent(angular.element($document[0].querySelector('#popupContainer')))
              .clickOutsideToClose(true)
              .title('OCR Comments Required')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK'),
          );
        } else {
          const message =
            "Flagging a request for additional review will send an email to the requester via "
            + "CARES that includes any OCR Comments you typed on the request. To adjust your "
            + "comments before sending, click Cancel. To send the email immediately, click Flag "
            + "Request.";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title("Confirm Flag")
              .textContent(message)
              .ariaLabel(message)
              .ok("Flag Request")
              .cancel('Cancel'),
          ).then(function() {
            self.entity.excessFeesRequestStatusId = 2;
            self.save(true);
          },
          () => {},
          );
        }
      };

      self.ocrReview = function() {
        if (self.entity.additionalFundsCents === null
          || angular.isUndefined(self.entity.additionalFundsCents)
          || self.entity.additionalFundsCents === 0) {
          const message =
          "Additional Funds Requests must be for more than $0. Please reject this request instead.";
          $mdDialog.show(
            $mdDialog.alert()
              .parent(angular.element($document[0].querySelector('#popupContainer')))
              .clickOutsideToClose(true)
              .title('$0 Request')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK'),
          );
        } else {
          const message =
            "Are you sure you want to flag this request for review by other OCR staff?";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector('#popupContainer')))
              .clickOutsideToClose(false)
              .title("Confirm Flag")
              .textContent(message)
              .ariaLabel(message)
              .ok("Flag Request")
              .cancel('Cancel'),
          ).then(function() {
            self.entity.excessFeesRequestStatusId = 7;
            self.save(true);
          }, () => {});
        }
      };

      self.approve = function() {
        if (self.entity.additionalFundsCents === null || self.entity.additionalFundsCents === 0) {
          const message = "Additional Funds Requests cannot be approved for $0. Please reject this "
            + "request instead.";
          $mdDialog.show(
            $mdDialog.alert()
              .parent(angular.element($document[0].querySelector('#popupContainer')))
              .clickOutsideToClose(true)
              .title('$0 Request')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK'),
          );
        } else {
          const message
          = "Are you sure that you want to approve this request for additional funds?";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title("Confirm Approval")
              .textContent(message)
              .ariaLabel(message)
              .ok("Approve Request")
              .cancel('Cancel'),
          ).then(function() {
            self.entity.excessFeesRequestStatusId = 3;
            self.save(true);
            //self.updateABA();
          },
          () => {},
          );
        }
      };

      self.reject = function() {
        const message
          = "Are you sure that you want to reject this request for additional funds?";
        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title("Confirm Rejection")
            .textContent(message)
            .ariaLabel(message)
            .ok("Reject Request")
            .cancel('Cancel'),
        ).then(function() {
          self.entity.excessFeesRequestStatusId = 4;
          self.save(true);
        },
        () => {},
        );
      };

      self.delete = function() {
        const message
          = "Are you sure that you want to delete this request?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Request Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete Request')
            .cancel('Cancel'),
        ).then(function() {
          // ok
          $mdToast.show(
            $mdToast.simple()
              .textContent('Deleting...')
              .position("bottom right"),
          );

          ExcessFeesRequestService.delete({
            id: self.id,
          }, self.entity).$promise.then(function() {
            $mdToast.show(
              $mdToast.simple()
                .textContent('Request deleted.')
                .position("bottom right"),
            );
            self.exit(true);
          })
            .catch(function(error) {
              if (error && error.data) {
                $mdToast.show(
                  $mdToast.simple()
                    .textContent(error.data.reason)
                    .position("bottom right"),
                );
              }
            });
        },
        () => {},
        );
      };

      self.close = function() {
        const message
          = "Are you sure that you want to dismiss the alert for this request for additional "
          + "funds? This is to remove this request from your notifications. You can still find a "
          + "record of the request in the future by going to Additional Funds.";
        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title("Dismiss Alert?")
            .textContent(message)
            .ariaLabel(message)
            .ok("Dismiss Alert")
            .cancel('Cancel'),
        ).then(function() {
          if (self.entity.excessFeesRequestStatusId === 3) {
            self.entity.excessFeesRequestStatusId = 5;
          } else if (self.entity.excessFeesRequestStatusId === 4) {
            self.entity.excessFeesRequestStatusId = 6;
          }
          self.save(true);
        },
        () => {},
        );
      };

      self.update = function(exit) {
        self.entity.excessFeesRequestStatusId = 8;
        self.save(exit);
      }

      self.save = function(exit) {
        self.submitting = true;
        $mdToast.show(
          $mdToast.simple()
            .textContent('Submitting...')
            .position("bottom right"),
        );

        const params = angular.copy(self.entity);

        params.totalPaidCents = parseInt(params.totalPaidCents) * 100;
        params.remainingAmountCents = parseInt(params.remainingAmountCents) * 100;
        params.currentAllocationCents = parseInt(params.currentAllocationCents) * 100;
        params.additionalFundsCents = parseInt(params.additionalFundsCents) * 100;
        params.requestedAllocationCents = parseInt(params.requestedAllocationCents) * 100;
        params.initialAdditionalFundsCents = parseInt(params.initialAdditionalFundsCents) * 100;

        ExcessFeesRequestService.update(params).$promise.then(
          function() {
            $mdToast.show(
              $mdToast.simple()
                .textContent('Submitted.')
                .position("bottom right"),
            );
            self.submitting = false;
            if (exit) {
              $timeout(() => {
                self.exit(true)
              }, 2000);
            }
          },
        ).catch(function(error) {
          if (error && error.data && error.data.status === 401) {
            self.exit(true);
            return;
          }
          if (error && error.data) {
            $mdToast.show(
              $mdToast.simple()
                .textContent(error.data.reason)
                .position("bottom right"),
            );
          }
        }).finally(() => self.form.$setPristine());
      };

      self.updateABA = function() {
        const { appointment } = self.entity;
        appointment.appointmentBillingAllocationCents =
          parseInt(self.entity.requestedAllocationCents) * 100;
        AppointmentService.update(appointment).$promise.then(
          // do nothing
        ).catch(function(error) {
          if (error && error.data && error.data.status === 401) {
            self.exit(true);
            return;
          }
          if (error && error.data) {
          }
        });
      };

      self.allowSave = () => self.entity.excessFeesRequestStatusId === 2
          && OcrAuth.session.userId === self.entity.userSubmittedId;

      self.allowReview = function() {
        if (self.entity.excessFeesRequestStatusId === 1
          || self.entity.excessFeesRequestStatusId === 7
          || self.entity.excessFeesRequestStatusId === 8) {
          return OcrAuth.checkPermission("BILLING_CHANGE");
        }
        return false;
      };

      self.allowOcrReview = function() {
        if (self.entity.excessFeesRequestStatusId === 1
          || self.entity.excessFeesRequestStatusId === 8) {
          return OcrAuth.checkPermission("BILLING_CHANGE");
        }
        return false;
      };

      self.allowDecision = function() {
        if (self.entity.excessFeesRequestStatusId === 1) {
          return OcrAuth.checkPermission("BILLING_CHANGE");
        } else if (self.entity.excessFeesRequestStatusId === 2
          || self.entity.excessFeesRequestStatusId === 7
          || self.entity.excessFeesRequestStatusId === 8) {
          return OcrAuth.checkPermission("BILLING_REQUEST_REVIEW");
        }
        return false;
      };

      self.allowDelete =
        () => self.entity.excessFeesRequestStatusId === 1
          && OcrAuth.session.userId === self.entity.userSubmittedId;

      self.allowClose =
        () => (self.entity.excessFeesRequestStatusId === 3
                || self.entity.excessFeesRequestStatusId === 4)
          && OcrAuth.session.userId === self.entity.userSubmittedId;

      self.readOnlyCheck = function() {
        if (self.entity.excessFeesRequestStatusId === 1
          || self.entity.excessFeesRequestStatusId === 2
          || self.entity.excessFeesRequestStatusId === 7
          || self.entity.excessFeesRequestStatusId === 8) {
          return !(OcrAuth.checkPermission("BILLING_CHANGE")
            || OcrAuth.checkPermission("BILLING_REQUEST_REVIEW"));
        }
        return true;
      };

      self.requesterReadOnlyCheck = function() {
        if (self.entity.excessFeesRequestStatusId === 2) {
          return !(OcrAuth.session.userId === self.entity.userSubmittedId);
        }
        return true;
      };

      self.disableSubmit = () => self.form.$invalid || self.submitting;
    },
  ],
});
