angular.module('CaresApp').component('ocrAppointmentProfile', {
  templateUrl: 'components/ocr-appointment-profile/ocr-appointment-profile.template.html',
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    isNew: '<',
    hideEditing: '&hideEditing',
  },
  controller: [
    '$scope',
    '$document',
    '$mdDialog',
    'OcrAuth',
    'UserService',
    'OcrLookups',
    'OcrUtils',
    function($scope, $document, $mdDialog, OcrAuth, UserService, OcrLookups, OcrUtils) {
      const self = this;

      self.appointmentCapacities = self.personaPermissions = self.permissions = OcrLookups.dummyList
      const appointmentCapacitiesPromise = OcrLookups.get('AppointmentCapacity').then(
        (appointmentCapacities) => self.appointmentCapacities = appointmentCapacities,
      );

      OcrLookups.get('PersonaPermission').then(
        (personaPermissions) => self.personaPermissions = personaPermissions,
      );

      OcrLookups.get('Permission').then((permissions) => self.permissions = permissions);

      //self.model = {};

      self.now = new Date();

      self.getUserFullName = OcrUtils.getUserFullName;
      self.hasPermission = OcrAuth.checkPermission;

      self.updateCaseBillingPercentEffectiveDate = () => self.model.effectiveDate = new Date();

      self.canChange =
        () => !(OcrAuth.session
          && OcrAuth.session.user
          && OcrAuth.session.user.permissions.includes("BILLING_CHANGE"));

      self.showAbaHistory = function() {
        $mdDialog.show({
          controller: 'AbaHistoryDialogController',
          templateUrl: 'tmpl/aba-history-dialog.html',
          parent: angular.element($document[0].body),
          clickOutsideToClose: false,
          locals: {
            appointment: self.model,
            officeUsers: self.model.attorneysInOffice,
          },
          fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
        }).then(() => {}, () => {});
      };

      const addAppointmentAttorneyToList = function(list) {
        if (!self.model.attorneyUserId) {
          return
        }
        list.push({
          id: self.model.attorneyUserId,
          firstName: self.model.attorneyFirstName,
          middleName: self.model.attorneyMiddleName,
          lastName: self.model.attorneyLastName,
          suffix: self.model.attorney,
          email: self.model.attorney,
          attorneyRegistrationNumber: self.model.attorneyRegistrationNumber,
        })
      }

      self.$onInit =
        () => $scope.$watch(
          () => self.ngModel.$modelValue,
          () => {
            self.model = self.ngModel.$modelValue;
            $scope.$watch(
              () => self.model.appointmentBillingAllocation,
              () => {
                if (self.model.appointmentBillingAllocation === 0) {
                  self.model.appointmentBillingAllocation = "0.00";
                }
              },
            );
            if (!self.ngModel.$modelValue) {
              return;
            }
            if (self.model.attorneyUserId) {
              self.currentUserId = self.model.attorneyUserId;
            }
            if (self.model && self.model.officeId && !self.model.attorneysInOffice) {
              self.promise = UserService.get({
                limit: 1000,
                office_id: self.model.officeId,
              }).$promise.then(
                function(resp) {
                  self.model.attorneysInOffice = resp.list;
                  self.model.availableAttorneys = [];
                  if (!self.isNew && self.disableUserField()) {
                    addAppointmentAttorneyToList(self.model.availableAttorneys);
                  } else {
                    for (const attorney of resp.list) {
                      if(attorney.statusId != 2) {
                        self.model.availableAttorneys.push(attorney);
                      }
                    }
                    if (self.model.attorneyUserId) {
                      let found = false;
                      for (const attorney of self.model.availableAttorneys) {
                        if (attorney.id === self.model.attorneyUserId) {
                          found = true;
                          break;
                        }
                      }
                      if (!found) {
                        addAppointmentAttorneyToList(self.model.availableAttorneys);
                      }
                    }
                  }
                },
              ).finally(() => self.promise = null);
            }
            appointmentCapacitiesPromise.then(
              function() {
                $scope.$watch(
                  () => self.model.capacityId,
                  function() {
                    if (self.model.capacityId) {
                      if (self.appointmentCapacities.lookup
                        && !self.appointmentCapacities.lookup(self.model.capacityId)
                      ) {
                        self.appointmentCapacities = angular.copy(self.appointmentCapacities)
                        self.appointmentCapacities.unshift({
                          id: self.model.capacityId,
                          capacity: self.model.capacity,
                        })
                      }
                    }
                  }, true);
              },
            )
          },
        );

      self.checkPermission = function(attorney) {
        if (attorney.id === self.model.attorneyUserId) {
          return true;
        }
        if (
          OcrAuth.session
          && OcrAuth.session.user
          && OcrAuth.session.user.permissions.includes("OFFICE_CASE_APPOINT")
          && self.permissions.length > 0
        ) {
          if (self.personaPermissions && self.permissions) {
            const permission = self.permissions.lookup('CASE_APPOINT', 'name');

            if (angular.isUndefined(permission)) {
              console.error("CASE_APPOINT permission does not exist. Please create this " +
                "permission and assign it to a persona");
              return false;
            }

            const personaPermissions = self.personaPermissions.lookup(
              permission.id, 'permissionId', true);

            for (const perm of personaPermissions) {
              if (perm.personaId === attorney.personaId) {
                return true;
              }
            }
          }
          return false;
        }
        return OcrAuth.session
            && OcrAuth.session.user
            && OcrAuth.session.user.id === attorney.id;
      }

      self.disableUserField = function() {
        if (!(self.model.availableAttorneys && self.model.availableAttorneys.length > 0)) {
          return true;
        } else if (self.isNew) {
          return false;
        } else if (!self.currentUserId) {
          return true;
        }
        return !(OcrAuth.session
            && OcrAuth.session.user
            && OcrAuth.session.user.permissions.includes("OFFICE_CASE_APPOINT")
            && (
              OcrAuth.session.user.officeId === self.model.officeId
              || OcrAuth.session.user.permissions.includes("ADMIN_WRITE")
            ));
      };

      self.disableEditableFields = function() {
        if (self.isNew) {
          return false;
        }
        return !(OcrAuth.session
            && OcrAuth.session.user
            && OcrAuth.session.user.permissions.includes("APPOINTMENT_WRITE")
            && OcrAuth.session.user.officeId === self.model.officeId
        );
      }

      self.disableAllocation = () => !self.hasPermission('BILLING_CHANGE');

      self.showWarningPercent = function() {
        if (self.isNew) {
          return true;
        }
        return OcrAuth.session
            && OcrAuth.session.user
            && (OcrAuth.session.user.id === self.currentUserId
              || OcrAuth.session.user.permissions.includes("BILLING_REQUEST_CREATE")
              || OcrAuth.session.user.permissions.includes("BILLING_REQUEST_CREATE_OFFICEWIDE"));
      }

      self.getMinDate = function() {
        if (angular.isUndefined(self.model)) {
          return null;
        }
        if (self.model.currentPercentEffectiveDate < self.now) {
          return self.now;
        }
        return self.model.currentPercentEffectiveDate;
      }
    },
  ],
});
