angular.module('CaresApp').component('ocrOfficeActivitiesDetailView', {
  templateUrl: 'components/ocr-office-activities/ocr-office-activities-detail-view.template.html',
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    selected: '=',
    updateData: '=',
    query: '=',
  },
  controller: [
    '$scope',
    '$document',
    '$mdDialog',
    'OcrAuth',
    'OcrUtils',
    'OcrLookups',
    function($scope, $document, $mdDialog, OcrAuth, OcrUtils) {
      const self = this;

      self.results = {};

      self.getUserFullName = OcrUtils.getUserFullName;
      self.hasAnyPermission = OcrAuth.hasAnyPermission;
      self.showAttachmentsRead = () => OcrAuth.hasAnyPermission([
        'APPOINTMENT_READ',
        'APPOINTMENT_ATTACHMENT_READ',
        'ADMIN_READ',
      ]);

      self.$onInit = function() {
        $scope.$watch(
          () => self.ngModel.$modelValue,
          () => self.results = self.ngModel.$modelValue,
        );
      };

      self.viewNote = function(note) {
        $mdDialog.show({
          controller: 'AddNoteDialogController',
          templateUrl: 'tmpl/add-note-dialog.html',
          parent: angular.element($document[0].body),
          locals: {
            user: OcrAuth.session.user,
            appointmentActivityId: undefined,
            note: note,
            readOnly: true,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          clickOutsideToClose: false,
          multiple: true,
          fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
        })
      };

      self.openNotes = function(notes) {
        $mdDialog.show({
          controller: 'NotesViewController',
          templateUrl: 'components/ocr-appointment-activities-detail-view/notes-view.template.html',
          parent: angular.element($document[0].body),
          locals: {
            notes: notes,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          clickOutsideToClose: false,
          multiple: true,
          fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
        })
      };

      self.openAttachments = function(attachments) {
        $mdDialog.show({
          controller: 'AttachmentsViewController',
          templateUrl:
            'components/ocr-appointment-activities-detail-view/attachments-view.template.html',
          parent: angular.element($document[0].body),
          locals: {
            attachments: attachments,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          clickOutsideToClose: false,
          multiple: true,
          fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
        })
      };
    },
  ],
});
