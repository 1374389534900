angular.module('CaresApp').component('ocrAttorneyRoleList', {
    templateUrl: 'components/ocr-attorney-role-list/ocr-attorney-role-list.template.html',
    bindings: {
      setPromise: '&onPromiseChanged',
      paginate: '<onPaginate',
      query: '<',
    },
    controller: [
      '$scope',
      'AttorneyRoleService',
      'OcrLookups',
      'OcrUtils',
      'OcrViews',
      'OcrAuth',
      function($scope, AttorneyRoleService, OcrLookups, OcrUtils, OcrViews, OcrAuth) {
        const self = this;
        OcrUtils.setPageTitle('Attorney Roles');
        self.currentView = OcrViews.currentView;
        self.hasPermission = OcrAuth.checkPermission;
  
        self.types = OcrLookups.dummyList;
  
        let currentSearch = undefined;
  
        OcrLookups.get('RoleType').then(
          (types) => self.types = types,
        )
  
        self.results = {
          data: [],
          count: 0,
          total: 0,
        };
        self.promise = null;
        $scope.$watch(() => self.promise, () => self.setPromise()(self.promise));
  
        self.refresh = () => self.updateData();
  
        self.updateData = function() {
          self.promise = AttorneyRoleService.query({
            query: currentSearch,
            skip: (self.query.page - 1) * self.query.limit,
            limit: self.query.limit,
          }).$promise.then(
            function(resp) {
              self.results.data = resp.list;
              self.results.count = resp.count;
              self.results.total = resp.total;
            },
          ).finally(() => self.promise = null)
        }
  
        self.$onInit = () => self.updateData();
  
        $scope.$on("Searching", function(_, data) {
          const call = {
            skip: (self.query.page - 1) * self.query.limit,
            limit: self.query.limit,
          }
  
          if (data) {
            currentSearch = data;
            call.query = data;
          } else {
            currentSearch = undefined;
          }
  
          self.promise = AttorneyRoleService.query(call).$promise.then(
            function(resp) {
              self.results.data = resp.list;
              self.results.count = resp.count;
              self.results.total = resp.total;
            },
          ).finally(() => self.promise = null)
        });
      },
    ],
  });
  