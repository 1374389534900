angular.module('CaresApp').component('ocrMyAppointmentGroupsList', {
  templateUrl: 'components/ocr-my-appointment-groups-list/ocr-my-appointment-groups-list.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    '$document',
    '$location',
    '$mdDialog',
    '$mdToast',
    'AppointmentGroupService',
    'OcrLookups',
    'OcrAuth',
    'OcrUtils',
    'OcrViews',
    'UserService',
    'AppointmentService',
    function($scope, $document, $location, $mdDialog, $mdToast,
      AppointmentGroupService, OcrLookups, OcrAuth, OcrUtils, OcrViews, UserService, AppointmentService) {
      const self = this;
      self.today = OcrUtils.today;
      OcrUtils.setPageTitle('Appointment Groups');
      self.currentView = OcrViews.currentView
      self.hasPermission = OcrAuth.checkPermission;
      self.appointmentGroups = [];
      self.attorneysInOffice = [];
      self.getUserFullName = OcrUtils.getUserFullName;
      self.selected = [];
      OcrLookups.get('HelpText').then(
        (helpText) => self.groupHelpText = helpText.lookup(6).text,
      );
      self.search = {
        officeId: OcrAuth.session.user.officeId,
        closed: false,
      };
      self.appointmentGroupNames = [];

      const promises = [];

      self.$onInit = function() {
        self.updateData();
        promises.push(UserService.get({
          hasPermission: 'CASE_APPOINT',
        }).$promise.then(function(response) {
          self.attorneysInOffice = response.list;
        }))
        promises.push(AppointmentGroupService.get({
          officeId: OcrAuth.session.user.officeId,
        }).$promise.then(function(response) {
          self.appointmentGroupNames = response.list;
        }))
      };

      self.updateData = function() {
        if (self.search.abbreviatedCaseNumber == "") {
          self.search.abbreviatedCaseNumber = null;
        }
        promises.push(AppointmentGroupService.get(self.search)
          .$promise.then(function(response) {
            self.appointmentGroups = response.list;
            for (const group of self.appointmentGroups) {
              AppointmentService.get({
                appointmentGroupId: group.id,
              }).$promise.then(function(resp) {
                group.appointments = resp.list;
                if (resp.list.length == 0) {
                  group.hasAppointments = false;
                } else {
                  group.hasAppointments = true;
                }
                for (const appointment of group.appointments) {
                  const childPartyNames = [];
                  if (appointment.childParties) {
                    for (const childParty of appointment.childParties) {
                      if (childParty.endDate) {
                        childParty.endDate = new Date(childParty.endDate);
                      }
                      if (!childParty.endDate || childParty.endDate > self.today) {
                        childPartyNames.push(OcrUtils.getChildPartyFullName(
                          childParty));
                      }
                    }
                  }
                  appointment.childPartyNames = childPartyNames.join("; ");
                }
              })
            }
        }))
      };

      self.toggleAppointment = function(group) {
        group.isExpanded = !group.isExpanded;
      }



      self.addGroup = function() {
        $mdDialog.show({
          controller: 'AddAppointmentGroupWithAppointmentDialogController',
          templateUrl:
            'components/ocr-my-appointment-groups-list/add-appointment-group-with-appointment-dialog.html',
          parent: angular.element($document[0].body),
          clickOutsideToClose: false,
          locals: {
            officeId: OcrAuth.session.user.officeId,
            attorneyUserId: OcrAuth.session.user.id,
            query: self.query,
          },
          bindToController: true,
          controllerAs: '$ctrl',
        })
          .then(function(selectedAppointmentGroup) {
            self.updateData()
          }, () => {});
      };

      self.editGroup = function(group) {
        $mdDialog.show({
          controller: 'EditAppointmentGroupDialogController',
          templateUrl:
            'components/ocr-my-appointment-groups-list/edit-appointment-group-dialog.html',
          parent: angular.element($document[0].body),
          clickOutsideToClose: false,
          locals: {
            group: group,
            attorneyId: group.attorneyUserId,
            query: self.query,
          },
          multiple: true,
          bindToController: true,
          controllerAs: '$ctrl',
        })
          .then(function(newAppointmentGroupName){
            self.updateData();
            group.name = newAppointmentGroupName;
          })
      };


      $scope.$on("LoggingOut",
        () => {
          SessionService.signout();
        },
      );

      $scope.$on('$locationChangeStart',
        function(_, newUrl, oldUrl) {
          const newArray = newUrl.split("?");
          const oldArray = oldUrl.split("?");
          if (newArray[0] !== oldArray[0]) {
            $location.search("");
          }
        });
    },
  ],
});
