angular.module('CaresApp').component('ocrAppointmentOfficeAccessRequests', {
  templateUrl: 'components/ocr-appointment-office-access-requests/'
    + 'ocr-appointment-office-access-requests.template.html',
  require: {
    ngModel: 'ngModel',
  },
  controller: [
    '$scope',
    '$document',
    '$mdDialog',
    'AppointmentOfficeAccessRequestService',
    'OcrAuth',
    'OcrUtils',
    'OcrLookups',
    function($scope, $document, $mdDialog, AppointmentOfficeAccessRequestService,
      OcrAuth, OcrUtils, OcrLookups) {
      const self = this;

      self.currentUserId = OcrAuth.session.userId
      self.model = {};
      self.selected = [];
      self.loaded = false;
      self.hasAnyPermission = OcrAuth.hasAnyPermission

      self.getUserFullName = OcrUtils.getUserFullName;

      self.officeAccessRequestStatuses =
        self.officeAccessRequestReasons =
        self.offices =
        self.personas =
        OcrLookups.dummyList;

      OcrLookups.get('AppointmentOfficeAccessRequestStatus').then(
        (officeAccessRequestStatuses) => self.officeAccessRequestStatuses
          = officeAccessRequestStatuses,
      );
      OcrLookups.get('AppointmentOfficeAccessRequestReason').then(
        (officeAccessRequestReasons) => self.officeAccessRequestReasons
          = officeAccessRequestReasons,
      );
      OcrLookups.get('Office', true).then((offices) => self.offices = offices);
      OcrLookups.get('Persona').then((personas) => self.personas = personas);
      OcrLookups.get('HelpText').then(
        (helpText) => self.requestHelpText = helpText.lookup(1).text,
      );

      self.query = {
        order: undefined,
        page: 1,
        limit: 20,
      }

      self.search = {};

      self.model.requests = []
      self.model.requests.total = 0

      self.$onInit = function() {
        const unregister = $scope.$watch(
          () => self.ngModel.$modelValue,
          function() {
            self.model = self.ngModel.$modelValue;
            if (self.model && self.model.id) {
              self.updateData()
              unregister()
            }
          },
        );
      }

      self.updateData = function() {
        self.promise = AppointmentOfficeAccessRequestService.get({
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
          appointmentId: self.model.id,
          dateFrom: self.search.dateFrom,
          dateTo: self.search.dateTo,
          requestReasonId: undefinedIfBlank(self.search.requestReasonId),
          requestStatusId: undefinedIfBlank(self.search.requestStatusId),
          appointment: self.model,
        }).$promise.then(function(response) {
          self.model.requests = response.list;
          self.model.requests.total = response.total;
          self.loaded = true;
        }).finally(() => self.promise = null);
        $scope.$emit("updatePendingRequests");
      };

      self.resetSearch = function() {
        self.search = {};
        self.query.page = 1;
        self.updateData();
      };

      self.requestInfo = function(request){
        $mdDialog.show({
          controller: 'AppointmentOfficeAccessRequestDialogController',
          templateUrl:
            'dialogs/ocr-appointment-office-access-request-dialog/'
            + 'ocr-appointment-office-access-request-dialog.template.html',
          parent: angular.element($document[0].body),
          locals: {
            request: request,
            appointmentId: self.model.id,
            dateFrom: self.search.dateFrom,
            dateTo: self.search.dateTo,
            requestReasonId: undefinedIfBlank(self.search.requestReasonId),
            requestStatusId: undefinedIfBlank(self.search.requestStatusId),
            model: self.model,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          multiple: true,
          clickOutsideToClose: false,
          onRemoving: self.resetSearch(),
        }).then(function() {
          self.resetSearch();
        });
      }

      self.getCurrentPermission = function(request) {
        if (!request.newPersonaId || request.newPersonaId === "" || request.newPersonaId === null) {
          return "None";
        }
        if (request.revoked) {
          return "None (Revoked)";
        }
        if (self.personas.length == 0) {
          return undefined;
        }
        return self.personas.lookup(request.newPersonaId).name;
      }

      self.needsAction = function(request) {
        if (self.officeAccessRequestStatuses.length == 0) {
          return false;
        }
        const status = self.officeAccessRequestStatuses.lookup(request.statusId).status;
        if (status === 'Pending') {
          return true;
        }
        return false;
      }
      self.getStatus = function(request){
        if (request.revoked) {
          return "Revoked";
        }
        return self.officeAccessRequestStatuses.lookup(request.statusId).status;
      }

      function undefinedIfBlank(value) {
        if (value === "" || value === null) {
          return undefined;
        }
        return value;
      }
    },
  ],
});
