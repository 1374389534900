angular.module('CaresApp').component('ocrAppointmentNotes', {
  templateUrl: 'components/ocr-appointment-notes/ocr-appointment-notes.template.html',
  require: {
    ngModel: 'ngModel',
  },
  controller: [
    '$scope',
    '$document',
    '$mdDialog',
    '$mdToast',
    'AppointmentNoteService',
    'OcrAuth',
    'OcrUtils',
    function($scope, $document, $mdDialog, $mdToast, AppointmentNoteService, OcrAuth, OcrUtils) {
      const self = this;

      self.currentUserId = OcrAuth.session.userId
      self.model = {};
      self.selected = [];
      self.loaded = false;
      self.hasAnyPermission = OcrAuth.hasAnyPermission

      self.getUserFullName = OcrUtils.getUserFullName;

      self.query = {
        order: undefined,
        page: 1,
        limit: 20,
      }

      self.model.notes = []
      self.model.notes.total = 0

      self.$onInit = function() {
        const unregister = $scope.$watch(
          () => self.ngModel.$modelValue,
          () => {
            self.model = self.ngModel.$modelValue;
            if (self.model && self.model.id) {
              self.updateData()
              unregister()
            }
          },
        );
      }

      self.updateData = function() {
        self.promise = AppointmentNoteService.get({
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
          appointmentId: self.model.id,
        }).$promise.then(function(response) {
          self.model.notes = response.list
          self.model.notes.total = response.total
          self.loaded = true
        }).finally(() => self.promise = null);
      }

      self.addNote = function() {
        $mdDialog.show({
          controller: 'AddNoteDialogController',
          templateUrl: 'tmpl/add-note-dialog.html',
          parent: angular.element($document[0].body),
          locals: {
            user: OcrAuth.session.user,
            appointmentActivityId: undefined,
            note: undefined,
            readOnly: false,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          clickOutsideToClose: false,
          fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
        })
          .then(function(newNote) {
            newNote.appointmentId = self.model.id;
            self.promise = AppointmentNoteService.save(newNote)
              .$promise.then(
                () => self.updateData(),
              ).catch(function(error) {
                if (error && error.data) {
                  $mdToast.show(
                    $mdToast.simple()
                      .textContent(error.data.reason)
                      .position("bottom right"),
                  );
                }
              }).finally(function() {
                self.promise = null;
              });
          }, function() {
          });
      };

      self.editNote = function() {
        $mdDialog.show({
          controller: 'AddNoteDialogController',
          templateUrl: 'tmpl/add-note-dialog.html',
          parent: angular.element($document[0].body),
          locals: {
            user: OcrAuth.session.user,
            appointmentActivityId: undefined,
            note: self.selected[0],
            readOnly: false,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          clickOutsideToClose: false,
          fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
        })
          .then(function(newNote) {
            newNote.id = self.selected[0].id;
            newNote.appointmentId = self.selected[0].appointmentId;
            newNote.userId = self.selected[0].userId;
            self.promise = AppointmentNoteService.update(newNote)
              .$promise.then(
                () => self.updateData(),
              ).catch(function(error) {
                if (error && error.data) {
                  $mdToast.show(
                    $mdToast.simple()
                      .textContent(error.data.reason)
                      .position("bottom right"),
                  );
                }
              }).finally(function() {
                self.promise = null;
              });
          }, () => {});
      };

      self.viewNote = () => $mdDialog.show({
        controller: 'AddNoteDialogController',
        templateUrl: 'tmpl/add-note-dialog.html',
        parent: angular.element($document[0].body),
        locals: {
          user: OcrAuth.session.user,
          appointmentActivityId: undefined,
          note: self.selected[0],
          readOnly: true,
        },
        bindToController: true,
        controllerAs: '$ctrl',
        clickOutsideToClose: false,
        fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
      })

      self.deleteNote = function() {
        const message = "Are you sure that you want to delete this note?";
        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Note Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete the note')
            .cancel('Cancel'),
        ).then(function() {
          $mdToast.show(
            $mdToast.simple()
              .textContent('Deleting...')
              .position("bottom right"),
          );
          AppointmentNoteService.delete({
            id: self.selected[0].id,
          }).$promise
            .then(function() {
              $mdToast.show(
                $mdToast.simple()
                  .textContent('Note deleted.')
                  .position("bottom right"),
              )
              self.selected.length = 0;
              self.updateData()
            })
        }, () => {});
      };
    },
  ],
});
