angular.module('CaresApp').component('ocrInvoiceDateView', {
  templateUrl: 'components/ocr-invoice-date-view/ocr-invoice-date-view.template.html',
  require: {
    ngModel: 'ngModel',
  },
  controller: [
    '$scope',
    '$q',
    'InvoiceDataService',
    'OcrInvoiceFlagsService',
    'OcrLookups',
    'OcrUtils',
    'OcrViews',
    function($scope, $q, InvoiceDataService, OcrInvoiceFlagsService,
      OcrLookups, OcrUtils, OcrViews,
    ) {
      const self = this;
      self.currentView = OcrViews.currentView;
      self.flags = OcrInvoiceFlagsService.flags;
      self.showInsufficientFunds = false;
      self.getNegativeAmountStyle = OcrUtils.getNegativeAmountStyle

      self.getUserFullName = OcrUtils.getUserFullName;

      const setActivityDetails = function(day) {
        for (const user of day.userSummaries) {
          user.fullName = OcrUtils.getUserFullName(user);
          for (const court of user.courtSummaries) {
            for (const caseType of court.caseTypeSummaries) {
              for (const case_ of caseType.caseSummaries) {
                for (const activity of case_.activitySummaries) {
                  const typeInfo = self.activityTypes.lookup(activity.typeId);
                  activity.details = [];
                  if (typeInfo.activityDurationTimePrompt && activity.activityDurationMinutes > 0) {
                    activity.details.push({
                      title: "Duration",
                      duration: activity.activityDurationMinutes,
                      durationCents: activity.activityDurationAmountCents,
                      total: activity.activityDurationAmountCents,
                    });
                  }
                  if (typeInfo.travelTimePrompt && activity.travelDurationMinutes > 0) {
                    activity.details.push({
                      title: "Travel Time",
                      duration: activity.travelDurationMinutes,
                      durationCents: activity.travelDurationAmountCents,
                      total: activity.travelDurationAmountCents,
                    });
                  }
                  if (typeInfo.waitTimePrompt && activity.waitTimeMinutes > 0) {
                    activity.details.push({
                      title: "Wait Time",
                      duration: activity.waitTimeMinutes,
                      durationCents: activity.waitTimeAmountCents,
                      total: activity.waitTimeAmountCents,
                    });
                  }
                  if (typeInfo.discussionTimePrompt && activity.discussionTimeMinutes > 0) {
                    activity.details.push({
                      title: "Confer with Involved Persons",
                      duration: activity.discussionTimeMinutes,
                      durationCents: activity.discussionTimeAmountCents,
                      total: activity.discussionTimeAmountCents,
                    });
                  }
                  if (typeInfo.mileageAmountPrompt && activity.mileageAmount > 0) {
                    activity.details.push({
                      title: "Mileage",
                      quantity: activity.mileageAmount,
                      expense: activity.mileageExpenseCents,
                      total: activity.mileageExpenseCents,
                    });
                  }
                  if (typeInfo.copyQuantityPrompt && activity.copiesQuantity > 0) {
                    activity.details.push({
                      title: "Copies",
                      quantity: activity.copiesQuantity,
                      expense: activity.copiesExpenseCents,
                      total: activity.copiesExpenseCents,
                    });
                  }
                  if (typeInfo.mealAmountPrompt && activity.mealExpenseCents > 0) {
                    activity.details.push({
                      title: "Child Meal",
                      expense: activity.mealExpenseCents,
                      total: activity.mealExpenseCents,
                    });
                  }
                  if (typeInfo.expenseAmountPrompt && activity.expenseAmountCents > 0) {
                    activity.details.push({
                      title: "Expenses",
                      expense: activity.expenseAmountCents,
                      total: activity.expenseAmountCents,
                    });
                  }
                  if (typeInfo.postageAmountPrompt && activity.postageAmountCents > 0) {
                    activity.details.push({
                      title: "Postage",
                      expense: activity.postageAmountCents,
                      total: activity.postageAmountCents,
                    });
                  }
                  if (typeInfo.discoveryAmountPrompt && activity.discoveryAmountCents > 0) {
                    activity.details.push({
                      title: "Discovery",
                      expense: activity.discoveryAmountCents,
                      total: activity.discoveryAmountCents,
                    });
                  }
                  if (typeInfo.filingFeeAmountPrompt && activity.filingFeeAmountCents > 0) {
                    activity.details.push({
                      title: "Filing Fee",
                      expense: activity.filingFeeAmountCents,
                      total: activity.filingFeeAmountCents,
                    });
                  }
                  if (typeInfo.transcriptAmountPrompt && activity.transcriptAmountCents > 0) {
                    activity.details.push({
                      title: "Transcript",
                      expense: activity.transcriptAmountCents,
                      total: activity.transcriptAmountCents,
                    });
                  }
                }
              }
            }
          }
        }
      };

      self.getChildPartiesString = function(activity) {
        const names = [];
        for (const childParty of activity.childParties) {
          if (names.indexOf(OcrUtils.getChildPartyFullName(childParty)) === -1) {
            names.push(OcrUtils.getChildPartyFullName(childParty));
          }
        }
        return names.join(', ');
      };

      const activityTypesPromise = OcrLookups.get('ActivityType').then(
        (types) => self.activityTypes = types,
      );

      const getData = function(noCache) {
        const insufficientFundsPromise
          = InvoiceDataService.getDaysData(self.invoice.invoiceNumber, true, noCache)
            .then(
              function(data) {
                self.insufficientFunds = data;
                if (data.length) {
                  self.showInsufficientFunds = true;
                }
              },
            );
        const daysPromise = InvoiceDataService.getDaysData(self.invoice.invoiceNumber, false, noCache)
          .then(
            (data) => {
              self.days = data;
            },
          );
        $q.all([insufficientFundsPromise, activityTypesPromise]).then(
          function() {
            for (const day of self.insufficientFunds) {
              setActivityDetails(day);
            }
          },
        );
        $q.all([daysPromise, activityTypesPromise]).then(
          function() {
            for (const day of self.days) {
              setActivityDetails(day);
            }
          },
        );
        self.promise = $q.all(
          [insufficientFundsPromise, activityTypesPromise, daysPromise],
        ).finally(() => self.promise = null);
      };

      $scope.$watch(
        () => self.ngModel.$modelValue,
        function() {
          self.invoice = self.ngModel.$modelValue;
          if (self.invoice) {
            getData();
          }
        },
      );

      self.getChildPartyNames = function(childParties) {
        if (!childParties || !childParties.length) {
          return null;
        }
        const names = [];
        for (const childParty of childParties) {
          names.push(OcrUtils.getChildPartyFullName(childParty));
        }
        return names.join('; ');
      };

      self.collapseAll = function(days) {
        for (const day of days) {
          for (const user of day.userSummaries) {
            user.isExpanded = false;
            for (const court of user.courtSummaries) {
              court.isExpanded = false;
              for (const caseType of court.caseTypeSummaries) {
                caseType.isExpanded = false;
                for (const case_ of caseType.caseSummaries) {
                  case_.isExpanded = false;
                  for (const activity of case_.activitySummaries) {
                    activity.isExpanded = false;
                  }
                }
              }
            }
          }
        }
      };

      self.expandAll = function(days) {
        for (const day of days) {
          for (const user of day.userSummaries) {
            user.isExpanded = true;
            for (const court of user.courtSummaries) {
              court.isExpanded = true;
              for (const caseType of court.caseTypeSummaries) {
                caseType.isExpanded = true;
                for (const case_ of caseType.caseSummaries) {
                  case_.isExpanded = true;
                  for (const activity of case_.activitySummaries) {
                    activity.isExpanded = true;
                  }
                }
              }
            }
          }
        }
      };

      self.toggleUser = function(user) {
        user.isExpanded = !user.isExpanded;
        if (user.isExpanded === false) {
          for (const court of user.courtSummaries) {
            court.isExpanded = false;
            for (const caseType of court.caseTypeSummaries) {
              caseType.isExpanded = false;
              for (const case_ of caseType.caseSummaries) {
                case_.isExpanded = false;
                for (const activity of case_.activitySummaries) {
                  activity.isExpanded = false;
                }
              }
            }
          }
        }
      };

      self.toggleCourt = function(court) {
        court.isExpanded = !court.isExpanded;
        if (court.isExpanded === false) {
          for (const caseType of court.caseTypeSummaries) {
            caseType.isExpanded = false;
            for (const case_ of caseType.caseSummaries) {
              case_.isExpanded = false;
              for (const activity of case_.activitySummaries) {
                activity.isExpanded = false;
              }
            }
          }
        }
      };

      self.toggleCaseType = function(caseType) {
        caseType.isExpanded = !caseType.isExpanded;
        if (caseType.isExpanded === false) {
          for (const case_ of caseType.caseSummaries) {
            case_.isExpanded = false;
            for (const activity of case_.activitySummaries) {
              activity.isExpanded = false;
            }
          }
        }
      };

      self.toggleCase = function(case_) {
        case_.isExpanded = !case_.isExpanded;
        if (case_.isExpanded === false) {
          for (const activity of case_.activitySummaries) {
            activity.isExpanded = false;
          }
        }
      };

      self.toggleActivity = function(activity) {
        activity.isExpanded = !activity.isExpanded;
      };

      $scope.$on('InvoiceViewCollapseAll',
        function() {
          if (self.insufficientFunds) {
            self.collapseAll(self.insufficientFunds);
          } else {
            const unregister = $scope.$watch(
              !self.insufficientFunds,
              function() {
                if (self.insufficientFunds) {
                  self.collapseAll();
                  unregister();
                }
              },
            );
          }
          if (self.days) {
            self.collapseAll(self.days);
          } else {
            const unregister = $scope.$watch(
              !self.days,
              function() {
                if (self.days) {
                  self.collapseAll();
                  unregister();
                }
              },
            );
          }
        },
      );

      $scope.$on('InvoiceViewExpandAll',
        function() {
          if (self.insufficientFunds) {
            self.expandAll(self.insufficientFunds);
          } else {
            const unregister = $scope.$watch(
              !self.insufficientFunds,
              function() {
                if (self.insufficientFunds) {
                  self.expandAll();
                  unregister();
                }
              },
            );
          }
          if (self.days) {
            self.expandAll(self.days);
          } else {
            const unregister = $scope.$watch(
              !self.days,
              function() {
                if (self.days) {
                  self.expandAll();
                  unregister();
                }
              },
            );
          }
        },
      );

      $scope.$on('ClearingCacheAndReloading', function() {
        getData(true);
      });

    },
  ],
});
