angular.module('CaresApp').controller('NotesViewController', [
  '$document',
  '$mdDialog',
  'OcrAuth',
  'OcrUtils',
  function($document, $mdDialog, OcrAuth, OcrUtils) {
    const self = this;
    self.today = new Date();

    self.getUserFullName = OcrUtils.getUserFullName;

    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;

    self.viewNote = function(note) {
      $mdDialog.show({
        controller: 'AddNoteDialogController',
        templateUrl: 'tmpl/add-note-dialog.html',
        parent: angular.element($document[0].body),
        locals: {
          user: OcrAuth.session.user,
          appointmentActivityId: undefined,
          note: note,
          readOnly: true,
        },
        bindToController: true,
        controllerAs: '$ctrl',
        clickOutsideToClose: false,
        multiple: true,
        fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
      })
    };
  },
]);
