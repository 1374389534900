angular.module('CaresApp').component('ocrRuleEdit', {
  templateUrl: 'components/ocr-rule-edit/ocr-rule-edit.template.html',
  controller: [
    '$scope',
    '$document',
    '$location',
    '$routeParams',
    '$mdToast',
    '$mdDialog',
    '$q',
    'RuleSetService',
    'OcrLookups',
    'OcrUtils',
    'OcrAuth',
    function($scope, $document, $location, $routeParams, $mdToast, $mdDialog, $q, RuleSetService,
      OcrLookups, OcrUtils, OcrAuth,
    ) {
      $scope.$emit("SetSearchBox", false);
      const self = this;
      const fieldToValueMappings = {
        "User": "user.id",
        "Persona": "user.personaId",
        "Activity Type": "activity.typeId",
        "Billable Item Type": "billableItem.billableItemTypeId",
        "Appointment": "appointment.id",
        "Case": "case.id",
        "Case Type": "case.caseTypeId",
        "Appointment Capacity": "appointment.appointmentCapacityId",
        "Access Request Reason": "user.accessReason",
        "Office": "user.officeId",
      };
      const valueToFieldMappings = {
        "user.id": "User",
        "user.personaId": "Persona",
        "activity.typeId": "Activity Type",
        "billableItem.billableItemTypeId": "Billable Item Type",
        "appointment.id": "Appointment",
        "case.id": "Case",
        "case.caseTypeId": "Case Type",
        "appointment.appointmentCapacityId": "Appointment Capacity",
        "user.accessReason": "Access Request Reason",
        "user.officeId": "Office",
      };
      self.hasPermission = OcrAuth.checkPermission;
      self.rules = [];

      self.personas = self.caseTypes = self.appointmentCapacities = self.activityTypes
                    = self.billableItemTypes = self.officeAccessRequestReasons
                    = self.offices = self.users = OcrLookups.dummyList;

      self.promises = [];

      self.promises.push(OcrLookups.get('Persona'));
      self.promises.push(OcrLookups.get('CaseType'));
      self.promises.push(OcrLookups.get('AppointmentCapacity'));
      self.promises.push(OcrLookups.get('ActivityType', true));
      self.promises.push(OcrLookups.get('BillableItemType'));
      self.promises.push(OcrLookups.get('AppointmentOfficeAccessRequestReason'));
      self.promises.push(OcrLookups.get('Office'));
      self.promises.push(OcrLookups.get('User'));

      if ($routeParams.ruleSetId !== 'create') {
        self.id = $routeParams.ruleSetId;
      } else {
        OcrUtils.setPageTitle('New Rule');
      }
      self.loading = true;
      if (self.id) {
        self.promises.push(RuleSetService.get({ id: self.id }).$promise);
      } else {
        self.entity = {};
        self.loading = false;
        self.rules.push(
          {
            "field": undefined,
            "operator": undefined,
            "endsExecution": true,
          },
        )
      }

      self.promise = $q.all(self.promises).then(
        function(response) {
          self.personas = response[0];
          self.caseTypes = response[1];
          self.appointmentCapacities = response[2];
          self.activityTypes = response[3];
          self.billableItemTypes = response[4];
          self.officeAccessRequestReasons = response[5];
          self.offices = response[6];
          self.users = response[7];
          if (response.length === 9) {
            self.entity = response[8];
            self.entity.startDate = new Date(self.entity.startDate);
            if (self.entity.endDate) {
              self.entity.endDate = new Date(self.entity.endDate);
            }
            OcrUtils.setPageTitle(`Rule: ${self.entity.name}`);
            for (let i = 0; i < self.entity.rules.length; i++) {
              self.rules[i] = self.ingestRule(self.entity.rules[i]);
            }
          }
        }).finally(() => {
        self.loading = false;
        self.promises = [];
        self.promise = null;
      });


      self.addRule = function() {
        self.rules.push(
          {
            "field": undefined,
            "operator": undefined,
            "endsExecution": true,
          },
        )
      }

      self.deleteRule = function(rule) {
        for (let i = 0; i < self.rules.length; i++) {
          if (self.rules[i].$$hashKey === rule.$$hashKey) {
            self.rules.splice(i, 1);
          }
        }
      }

      self.formatRule = function(rule, parent) {
        const result = {};
        if (parent) {
          result.endsExecution = rule.endsExecution;
          result.assignments = `appropriationUnit="${self.assignment}"`;
          result.position = self.rules.indexOf(rule);
        }
        const operator = rule.operator === "EQUALS" ? "==" : "!=";
        const field = fieldToValueMappings[rule.field];
        if (angular.isUndefined(field)) {
          if (rule.rules.length > 1) {
            const subRules = [];
            for (const r of rule.rules) {
              subRules.push(self.formatRule(r, false).predicate);
            }
            const joinWord = rule.field === "All of the Following" ? " and " : " or ";
            result.predicate = `(${subRules.join(joinWord)})`;
          } else {
            result.predicate = self.formatRule(rule.rules[0], false).predicate;
          }
        } else {
          let { value } = rule;
          switch (rule.field) {
          case "User":
          case "Persona":
          case "Office":
          case "Activity Type":
          case "Case Type":
            value = rule.selectedItem.id;
          }
          result.predicate = [field, operator, value].join("");
        }
        return result;
      }

      self.parsePredicate = function(predicate, rule) {
        if (predicate.indexOf("and") !== -1) {
          rule.field = "All of the Following";
          rule.rules = [];
          for (let i = 0; i < predicate.length; i += 2) {
            const andRule = {};
            self.parsePredicate(predicate[i], andRule);
            rule.rules.push(andRule);
          }
        } else if (predicate.indexOf("or") !== -1) {
          rule.field = "Any of the Following";
          rule.rules = [];
          for (let i = 0; i < predicate.length; i += 2) {
            const orRule = {};
            self.parsePredicate(predicate[i], orRule);
            rule.rules.push(orRule);
          }
        } else {
          let splitPred = [];
          if (predicate.indexOf("==") !== -1) {
            rule.operator = "EQUALS";
            splitPred = predicate.split('==');
          } else {
            rule.operator = "DOES NOT EQUAL";
            splitPred = predicate.split('!=');
          }

          rule.field = valueToFieldMappings[splitPred[0]];
          switch (rule.field) {
          case "User":
            rule.selectedItem = self.users.lookup(parseInt(splitPred[1]));
            break;
          case "Persona":
            rule.selectedItem = self.personas.lookup(parseInt(splitPred[1]));
            break;
          case "Activity Type":
            rule.selectedItem = self.activityTypes.lookup(parseInt(splitPred[1]));
            break;
          case "Case Type":
            rule.selectedItem = self.caseTypes.lookup(parseInt(splitPred[1]));
            break;
          case "Office":
            rule.selectedItem = self.offices.lookup(parseInt(splitPred[1]));
            break;
          default:
            rule.value = splitPred[1];
          }
        }
      }

      self.ingestRule = function(rule) {
        const result = {};
        result.endsExecution = rule.endsExecution;
        self.assignment = rule.assignments.replace("appropriationUnit=", "");
        if ((self.assignment.startsWith('"') || self.assignment.startsWith("'"))
          && (self.assignment.endsWith('"') || self.assignment.endsWith("'"))) {
          self.assignment = self.assignment.substring(1, self.assignment.length - 1);
        }

        let { predicate } = rule;
        predicate = predicate.replace(/\(/g, "[");
        predicate = predicate.replace(/\)\s/g, "], ");
        predicate = predicate.replace(/\)/g, "]");
        predicate = predicate.replace(/\s+/, ", ");
        predicate = `[${predicate}]`;
        predicate = predicate.replace(/[^\[\]\,\s]+/g, '"$&"');
        predicate = predicate.replace(/" /g, "\", ");

        predicate = JSON.parse(predicate);

        self.parsePredicate(predicate[0], result);

        return result;
      }

      self.save = function(andExit) {
        self.entity.rules = [];
        for (const r of self.rules) {
          self.entity.rules.push(self.formatRule(r, true));
        }

        $mdToast.show(
          $mdToast.simple()
            .textContent('Saving...')
            .position("bottom right"),
        );
        let saveFunction = null;
        let saveArgs = null;
        if (self.id) {
          saveFunction = RuleSetService.update
          saveArgs = [{ id: self.id }, self.entity]
        } else {
          saveFunction = RuleSetService.save
          saveArgs = [self.entity]
        }
        saveFunction.apply(this, saveArgs).$promise.then(
          function() {
            self.form.$setPristine();
            $mdToast.show(
              $mdToast.simple()
                .textContent('Rule saved.')
                .position("bottom right"),
            )
            if (andExit) {
              self.close(true)
            }
          },
        ).catch(function(error) {
          if (error && error.data && error.data.status === 401) {
            self.close(true);
            return;
          }
          if (error && error.data) {
            let message = '';
            if (error.data.predicateErrors) {
              message = error.data.predicateErrors[0].message;
            } else if (error.data.assignmentsErrors) {
              message = error.data.assignmentsErrors[0].message;
            }
            $mdToast.show(
              $mdToast.simple()
                .textContent(message)
                .position("bottom right"),
            );
          }
        })
      }

      self.close = function(noConfirm) {
        if (noConfirm || self.form.$pristine) {
          $location.path(`/views/${$routeParams.view}`);
        } else {
          const message
              = "Are you sure that you want to close this form without saving your changes?";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Form Close')
              .textContent(message)
              .ariaLabel(message)
              .ok('Close Form')
              .cancel('Cancel'),
          ).then(
            () => $location.path(`/views/${$routeParams.view}`),
            () => {},
          );
        }
      }

      self.deleteForm = function() {
        const message
          = "Are you sure that you want to delete this rule?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Rule Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete Rule')
            .cancel('Cancel'),
        ).then(function() {
          // ok
          $mdToast.show(
            $mdToast.simple()
              .textContent('Deleting...')
              .position("bottom right"),
          );

          RuleSetService.delete({
            code: self.id,
          }, self.entity).$promise.then(function() {
            $mdToast.show(
              $mdToast.simple()
                .textContent('Rule deleted.')
                .position("bottom right"),
            );
            $location.path(`/views/${$routeParams.view}`);
          })
            .catch(function(error) {
              if (error && error.data) {
                $mdToast.show(
                  $mdToast.simple()
                    .textContent(error.data.reason)
                    .position("bottom right"),
                );
              }
            });
        }, () => {});
      };

      self.$onInit = function() {
        const unregister = $scope.$watch(
          () => angular.isDefined(self.form),
          function() {
            if (self.form) {
              unregister();
            }
            if (self.form && self.form.$invalid) {
              angular.forEach(self.form.$error, function(field) {
                angular.forEach(field, function(errorField) {
                  errorField.$setTouched();
                });
              });
            }
          }, true);
      }

      $scope.$on('$locationChangeStart',
        function(event, newUrl, oldUrl) {
          const removeQueryParams = (url) => {
            if (url) {
              const index = url.indexOf("?");
              if (index >= 0) {
                return url.substring(0, index);
              }
            }
            return url;
          };

          const newPath = $location.path();

          if (
            newPath === "/"
            || self.form.$pristine
            || removeQueryParams(newUrl) === removeQueryParams(oldUrl)
          ) {
            return;
          }

          event.preventDefault();

          const message
            = "Are you sure that you want to close this form without saving your changes?";

          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Page Exit')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK')
              .cancel('Cancel'),
          ).then(() => {
            self.form.$setPristine();
            $location.path(newPath);
          });
        });

    },
  ],
});
