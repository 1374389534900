angular.module('CaresApp').component('ocrOtherApprovalsList', {
  templateUrl: 'components/ocr-other-approvals-list/ocr-other-approvals-list.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    'OtherExpenseRequestService',
    'OcrLookups',
    'OcrUtils',
    'OcrViews',
    'OcrAuth',
    function($scope, OtherExpenseRequestService, OcrLookups, OcrUtils, OcrViews,
      OcrAuth,
    ) {
      $scope.$emit("SetSearchBox", false);
      const self = this;
      OcrUtils.setPageTitle('Other Approvals');
      self.currentView = OcrViews.currentView

      self.requestStatuses = self.judicialDistricts = self.offices = OcrLookups.dummyList

      OcrLookups.get('ExcessFeesRequestStatus').then(
        (excessFeesRequestStatuses) => self.requestStatuses = excessFeesRequestStatuses,
      );
      OcrLookups.get('JudicialDistrict').then(
        (judicialDistricts) => self.judicialDistricts = judicialDistricts,
      );
      OcrLookups.get('Office', true).then(
        (offices) => self.offices = offices,
      );

      self.results = {
        data: [],
        count: 0,
        total: 0,
      };
      self.search = {};
      self.promise = null;
      $scope.$watch(() => self.promise, () => {
        self.setPromise()(self.promise)
      })

      if (OcrAuth.session && OcrAuth.session.user && OcrAuth.session.user.permissions
          && OcrAuth.session.user.permissions.indexOf("EXPENSE_REQUEST_REVIEW") !== -1) {
        //FIXME excessFeesRequestStatus is not a search parameter.
        self.search.excessFeesRequestStatus = 1;
      }


      self.getUserFullName = function(first, middle, last, suffix) {
        const user = {
          firstName: first,
          middleName: middle,
          lastName: last,
          suffix: suffix,
        }
        return OcrUtils.getUserFullName(user);
      }

      self.refresh = () => self.updateData();

      self.updateData = function() {
        self.search.skip = (self.query.page - 1) * self.query.limit;
        self.search.limit = self.query.limit;
        if (self.fundsFrom) {
          self.search.fundsFrom = self.fundsFrom * 100;
        }
        if (self.fundsTo) {
          self.search.fundsTo = self.fundsTo * 100;
        }

        self.promise = OtherExpenseRequestService.query(self.search).$promise.then(
          function(resp) {
            self.results.data = resp.list;
            self.results.count = resp.count;
            self.results.total = resp.total;
          },
        ).finally(() => self.promise = null)
      }

      self.$onInit = () => self.updateData();
    },
  ],
});
