angular.module('CaresApp').component('ocrRaceEthnicityEdit', {
  templateUrl: 'components/ocr-race-ethnicity-edit/ocr-race-ethnicity-edit.template.html',
  controller: [
    '$scope',
    '$document',
    '$location',
    '$routeParams',
    '$mdToast',
    '$mdDialog',
    'RaceEthnicityService',
    'OcrLookups',
    'OcrUtils',
    'OcrAuth',
    function($scope, $document, $location, $routeParams, $mdToast, $mdDialog,
      RaceEthnicityService, OcrLookups, OcrUtils, OcrAuth,
    ) {
      $scope.$emit("SetSearchBox", false);
      const self = this;
      self.hasPermission = OcrAuth.checkPermission;

      if ($routeParams.raceEthnicityId !== 'create') {
        self.id = $routeParams.raceEthnicityId;
      } else {
        OcrUtils.setPageTitle('New Race/Ethnicity');
      }
      self.loading = true;
      if (self.id) {
        self.promise = RaceEthnicityService.get({ id: self.id }).$promise
          .then(function(resp) {
            self.entity = resp;
            OcrUtils.setPageTitle(`Race/Ethnicity: ${self.entity.raceEthnicity}`);
          })
          .finally(() => self.loading = false);
      } else {
        self.entity = {}
        self.loading = false
      }

      self.save = function(andExit) {
        OcrLookups.remove('RaceEthnicity')
        OcrLookups.remove('RaceEthnicity', true);
        $mdToast.show(
          $mdToast.simple()
            .textContent('Saving...')
            .position("bottom right"),
        );
        let saveFunction = null;
        let saveArgs = null;
        if (self.id) {
          saveFunction = RaceEthnicityService.update
          saveArgs = [{ id: self.id }, self.entity]
        } else {
          saveFunction = RaceEthnicityService.save
          saveArgs = [self.entity]
        }
        saveFunction.apply(this, saveArgs).$promise.then(
          function() {
            self.form.$setPristine();
            $mdToast.show(
              $mdToast.simple()
                .textContent('Race ethnicity saved.')
                .position("bottom right"),
            )
            if (andExit) {
              self.close(true)
            }
          },
        ).catch(function(error) {
          if (error && error.data && error.data.status === 401) {
            self.close(true);
            return;
          }
          if (error && error.data) {
            $mdToast.show(
              $mdToast.simple()
                .textContent(error.data.reason)
                .position("bottom right"),
            );
          }
        })
      }

      self.close = function(noConfirm) {
        if (noConfirm || self.form.$pristine) {
          $location.path(`/views/${$routeParams.view}`);
        } else {
          const message
              = "Are you sure that you want to close this form without saving your changes?";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Form Close')
              .textContent(message)
              .ariaLabel(message)
              .ok('Close Form')
              .cancel('Cancel'),
          ).then(
            () => $location.path(`/views/${$routeParams.view}`),
            () => {},
          );
        }
      }

      self.deleteForm = function() {
        const message
          = "Are you sure that you want to delete this race/ethnicity?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Race/Ethnicity Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete Race/Ethnicity')
            .cancel('Cancel'),
        ).then(function() {
          // ok
          OcrLookups.remove('RaceEthnicity')
          OcrLookups.remove('RaceEthnicity', true);
          $mdToast.show(
            $mdToast.simple()
              .textContent('Deleting...')
              .position("bottom right"),
          );

          RaceEthnicityService.delete({
            id: self.id,
          }, self.entity).$promise.then(function() {
            $mdToast.show(
              $mdToast.simple()
                .textContent('Race ethnicity deleted.')
                .position("bottom right"),
            );
            $location.path(`/views/${$routeParams.view}`);
          })
            .catch(function(error) {
              if (error && error.data) {
                $mdToast.show(
                  $mdToast.simple()
                    .textContent(error.data.reason)
                    .position("bottom right"),
                );
              }
            });
        }, () => {});
      };

      self.$onInit = function() {
        const unregister = $scope.$watch(
          () => angular.isDefined(self.form),
          function() {
            if (self.form) {
              unregister();
            }
            if (self.form && self.form.$invalid) {
              angular.forEach(self.form.$error, function(field) {
                angular.forEach(field, function(errorField) {
                  errorField.$setTouched();
                });
              });
            }
          }, true);
      }

      $scope.$on('$locationChangeStart',
        function(event, newUrl, oldUrl) {
          const removeQueryParams = (url) => {
            if (url) {
              const index = url.indexOf("?");
              if (index >= 0) {
                return url.substring(0, index);
              }
            }
            return url;
          };

          const newPath = $location.path();

          if (
            newPath === "/"
            || self.form.$pristine
            || removeQueryParams(newUrl) === removeQueryParams(oldUrl)
          ) {
            return;
          }

          event.preventDefault();

          const message
            = "Are you sure that you want to close this form without saving your changes?";

          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Page Exit')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK')
              .cancel('Cancel'),
          ).then(() => {
            self.form.$setPristine();
            $location.path(newPath);
          });
        });

    },
  ],
});
