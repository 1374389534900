angular.module('CaresApp').controller('AddUpcomingActivityDialogController', [
  '$scope',
  '$document',
  '$mdDialog',
  '$mdToast',
  '$location',
  'UpcomingActivityService',
  'AppointmentService',
  'OcrAuth',
  'OcrLookups',
  'OcrUtils',
  function($scope, $document, $mdDialog, $mdToast, $location, UpcomingActivityService,
    AppointmentService, OcrAuth, OcrLookups, OcrUtils) {
    const self = this;
    self.today = new Date();
    self.now = OcrUtils.today;
    self.earliest = new Date();

    self.checkPermission = OcrAuth.checkPermission;
    self.userAppointments = [];
    self.activityTypes = self.activityGroups = OcrLookups.dummyList;
    self.getUserFullName = OcrUtils.getUserFullName;
    self.age = OcrUtils.age;

    self.$onInit = function() {
      self.earliest.setDate(self.earliest.getDate() + 1);

      OcrLookups.get('ActivityType', true).then(
        function(activityTypes) {
          self.activityTypes = activityTypes;
          if (self.activity && self.activity.typeId) {
            self.selectedActivityType = self.activityTypes.lookup(self.activity
              .typeId);
            if (self.selectedActivityType.statusId !== 1) {
              //TODO where are these values coming from?
              /* eslint-disable no-undef */
              originalActivityType = self.selectedActivityType;
              changeActivityTypeBackToInactive = true;
              originalActivityType.statusId = 1;
              /* eslint-enable no-undef */
            }
            self.activityTypeChanged();
          }
        },
      );

      OcrLookups.get('ActivityGroup').then(
        function(activityGroups) {
          self.activityGroups = activityGroups;
          if (self.activity && self.activity.groupId) {
            const currentActivityGroup = self.activityGroups.lookup(self.activity
              .groupId);
            if (!currentActivityGroup) {
              self.activityGroups = angular.copy(activityGroups);
              self.activityGroups.unshift({
                id: self.activity.groupId,
                group: self.activity.groupName,
              });
            }
          }
        },
      );

      self.promise = AppointmentService.query({
        inOffice: true,
        showActive: true,
        mineOnly: true,
        limit: 1000,
        order: '?.appointment_id',
      }).$promise.then(function(response) {
        self.userAppointments = response
      }).finally(() => self.promise = undefined)
      if (self.activity) {
        self.isNew = false;
        self.activity = angular.copy(self.activity);
        self.activity.start = new Date(self.activity.start);
      } else {
        self.isNew = true;
        self.activity = {
          userId: OcrAuth.session.user.id,
          user: OcrAuth.session.user,
        };

        if (self.date) {
          self.activity.start = new Date(self.date);
        }
        if (self.appointmentId) {
          self.activity.appointmentId = self.appointmentId;
        }
      }
      self.selectedActivityType = self.getActivityType(self.activity.typeId);
      $scope.$watch(
        () => angular.isDefined(self.addUpcomingActivityForm),
        function() {
          if (self.add) {
            $scope.$watch(
              () => self.addUpcomingActivityForm.$invalid === true,
              function() {
                $scope.$watch(
                  () => angular.isDefined(self.userAppointments.list),
                  function() {
                    angular.forEach(self.addUpcomingActivityForm.$error, function(field) {
                      angular.forEach(field, function(errorField) {
                        errorField.$setTouched();
                      });
                    });
                  }, true);
              }, true);
          }
        }, true);
    }

    //TODO this gets the promise, not set it
    self.setPromise = () => self.promise;


    self.getActivityType = (id) => self.activityTypes.lookup(id);

    self.activityTypeChanged = function() {
      // activity's billable attribute is, by default, the activity type's billable attribute.
      // user can make a billable activity non-billable voluntarily. not visa-versa.
      self.selectedActivityType = self.getActivityType(self.activity
        .typeId);
      if (!self.selectedActivityType) {
        return;
      }

      if (self.isNew) {
        self.activity.billable = self.selectedActivityType.billable;
      }
    };

    self.isActivityTypeBillable = function() {
      if (!self.selectedActivityType) {
        return false;
      }

      return self.selectedActivityType.billable;
    };

    self.shouldDisableSave = function() {
      if (self.submitting || self.addUpcomingActivityForm.$invalid || self.activity.created) {
        return true;
      }
      return false;
    };

    self.showCreate = function() {
      if (!self.activity.id) {
        return false;
      } else if (self.activity.start.getTime() > self.today.getTime()) {
        return false;
      } else if (self.activity.created) {
        return false;
      }
      return true;
    }

    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;
    self.submit = (close) => self.doSave(close);

    self.doSave = function(close) {
      self.submitting = true;
      $mdToast.show(
        $mdToast.simple()
          .textContent('Saving...')
          .position("bottom right"),
      );
      let saveFunction = null;
      let saveArgs = null;
      if (self.activity.id) {
        saveFunction = UpcomingActivityService.update
        saveArgs = [
          { id: self.activity.id },
          {
            activity: {
              id: self.activity.id,
              title: self.activity.title,
              start: new Date(self.activity.start),
              appointmentId: self.activity.appointmentId,
              userId: self.activity.userId,
              groupId: self.activity.groupId,
              typeId: self.activity.typeId,
              billable: self.activity.billable,
            },
          },
        ]
      } else {
        saveFunction = UpcomingActivityService.save
        saveArgs = [self.activity]
      }
      saveFunction.apply(this, saveArgs).$promise.then(
        function(resp) {
          $mdToast.show(
            $mdToast.simple()
              .textContent('Upcoming activity saved in Cases > Upcoming Activities.')
              .position("bottom right"),
          )
          self.submitting = false;
          if (close) {
            $mdDialog.hide(resp);
          }
        },
      ).catch(function(error) {
        if (error && error.data) {
          $mdToast.show(
            $mdToast.simple()
              .textContent(error.data.reason)
              .position("bottom right"),
          );
        }
      })
    };

    self.delete = function() {
      const message =
        "Are you sure that you want to delete this activity from the Upcoming Activities calendar?";
      $mdDialog.show(
        $mdDialog.confirm()
          .parent(angular.element($document[0].querySelector(
            '#popupContainer')))
          .clickOutsideToClose(false)
          .title('Confirm Activity Delete')
          .multiple(true)
          .textContent(message)
          .ariaLabel(message)
          .ok('Delete the activity')
          .cancel('Cancel'),
      ).then(function() {
        $mdToast.show(
          $mdToast.simple()
            .textContent('Deleting...')
            .position("bottom right"),
        );
        UpcomingActivityService.delete({
          id: self.activity.id,
        }).$promise
          .then(function() {
            $mdToast.show(
              $mdToast.simple()
                .textContent('Upcoming Activity deleted.')
                .position("bottom right"),
            );
            $mdDialog.hide();
          });
      },
      () => {});
    }

    self.create = function() {
      $location.search({
        uaid: self.activity.id,
        start: self.activity.start,
        groupId: self.activity.groupId,
        typeId: self.activity.typeId,
        billable: self.activity.billable,
      });
      $location.hash("activities");
      $location.path(`/appointment/my_appointments/${self.activity.appointmentId}`);
      self.cancel()
    }
  },
]);
