angular.module('CaresApp').controller('AddEducationalSettingDialogController', [
  '$scope',
  '$mdDialog',
  'OcrUtils',
  'EducationalSettingService',
  function($scope, $mdDialog, OcrUtils, EducationalSettingService) {
    const self = this;

    self.today = OcrUtils.today
    self.educationalSettings = [];
    self.educationalSettings.total = 0;
    self.selected = [];
    self.query = {
      order: undefined,
      limit: 20,
      page: 1,
    };

    self.$onInit = function() {
      if (self.childPartyEducationalSetting) {
        self.childPartyEducationalSetting = angular.copy(self.childPartyEducationalSetting);
        if (self.childPartyEducationalSetting.startDate) {
          self.childPartyEducationalSetting.startDate = new Date(
            self.childPartyEducationalSetting.startDate);
        }
        const currentSetting = {
          id: self.childPartyEducationalSetting.educationalSettingId,
          name: self.childPartyEducationalSetting.name,
          phone: self.childPartyEducationalSetting.phone,
          email: self.childPartyEducationalSetting.email,
          startDate: self.childPartyEducationalSetting.startDate,
          address1: self.childPartyEducationalSetting.address1,
          address2: self.childPartyEducationalSetting.address2,
          city: self.childPartyEducationalSetting.city,
          state: self.childPartyEducationalSetting.state,
          zip: self.childPartyEducationalSetting.zip,
        }
        self.selected.push(currentSetting);
      }

      self.updateData();
      $scope.$watch(
        () => angular.isDefined(self.addEducationalSettingForm),
        function() {
          $scope.$watch(
            () => self.addEducationalSettingForm.$invalid === true,
            function() {
              angular.forEach(self.addEducationalSettingForm.$error, function(field) {
                angular.forEach(field, function(errorField) {
                  errorField.$setTouched();
                });
              });
            }, true);
        }, true);
    }

    self.updateData = function() {
      self.promise = EducationalSettingService.get({
        skip: (self.query.page - 1) * self.query.limit,
        limit: self.query.limit,
        query: self.searchText ? self.searchText : undefined,
        activeOnly: true,
      }).$promise.then(
        function(response) {
          self.educationalSettings = response.list;
          self.educationalSettings.total = response.total;
        }).finally(() => self.promise = undefined);
    };

    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;

    self.submit = function() {
      self.childPartyEducationalSetting.educationalSettingId = self.selected[0].id
      self.childPartyEducationalSetting.name = self.selected[0].name;
      self.childPartyEducationalSetting.phone = self.selected[0].phone;
      self.childPartyEducationalSetting.email = self.selected[0].email;
      self.childPartyEducationalSetting.address1 = self.selected[0].address1;
      self.childPartyEducationalSetting.address2 = self.selected[0].address2;
      self.childPartyEducationalSetting.city = self.selected[0].city;
      self.childPartyEducationalSetting.state = self.selected[0].state;
      self.childPartyEducationalSetting.zip = self.selected[0].zip;
      self.childPartyEducationalSetting.fullAddress = undefined;
      $mdDialog.hide(self.childPartyEducationalSetting);
    };

    self.doSearch = OcrUtils.debounce(() => self.updateData(), 500);
  },
]);
