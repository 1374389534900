angular.module('CaresApp').component('ocrAppointmentAssociatedAppointments', {
  templateUrl:
    'components/ocr-appointment-associated-appointments/' +
    'ocr-appointment-associated-appointments.template.html',
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    onItemAdded: '&onUserAdded',
    hideEditing: '&hideEditing',
  },
  controller: [
    '$scope',
    '$document',
    '$mdDialog',
    'OcrAuth',
    'AppointmentService',
    'OcrLookups',
    'AppointmentGroupService',
    '$mdToast',
    'AppointmentOfficeAccessRequestService',
    'OcrAppointmentOfficeAccessRequestDialogService',
    'OcrUtils',
    function($scope, $document, $mdDialog, OcrAuth, AppointmentService, OcrLookups, AppointmentGroupService, $mdToast,
      AppointmentOfficeAccessRequestService, OcrAppointmentOfficeAccessRequestDialogService, OcrUtils) {
      const self = this;

      self.hasPermission = OcrAuth.checkPermission;
      self.hasAnyPermission = OcrAuth.hasAnyPermission;
      self.associatedAppointments = [];
      self.model = null;
      self.loaded = OcrAuth.session && OcrAuth.session.user;
      OcrLookups.get('HelpText').then(
        (helpText) => self.groupHelpText = helpText.lookup(5).text,
      );
      OcrLookups.get('AppointmentOfficeAccessRequestStatus').then(
        (statuses) => self.accessRequestStatuses = statuses);
      let getAssociatedAppointments = function(added) {
        if (!self.model.appointmentGroupId || self.model.appointmentGroupId === 0) {
          self.model.associatedAppointments = [];
          self.paginate();
          return;
        }

        AppointmentService.get({
          appointmentGroupId: self.model.appointmentGroupId,
        }).$promise.then(
          function(resp) {
            self.model.associatedAppointments = resp.list;
            if (added) {
              self.model.associatedAppointments.push({
                id: self.model.id,
                abbreviatedCaseNumber: self.model.abbreviatedCaseNumber,
                nickname: self.model.nickname,
                caseTypeDescription: self.model.caseTypeDescription,
                judicialDistrictId: self.model.judicialDistrictId,
                county: self.model.county,
              });
            }
            for (const appointment of self.model.associatedAppointments) {
              const attorney = {
                firstName: appointment.firstName,
                middleName: appointment.middleName,
                lastName: appointment.lastName,
                suffix: appointment.suffix,
              };
              appointment.attorneyName = OcrUtils.getUserFullName(
                attorney);
            }
            self.paginate();
          },
        );
      };

      self.checkOffice = function() {
        if(self.model.officeId != OcrAuth.session.user.officeId) {
          return true;
        }
        return false;
      }

      if (self.model) {
        getAssociatedAppointments();
        getAssociatedAppointments = undefined;
      } else {
        const unregister = $scope.$watch(
          () => self.model,
          () => {
            if (self.model && self.model.abbreviatedCaseNumber) {
              getAssociatedAppointments();
              // getAssociatedAppointments = undefined;
              unregister();
            }
          },
        );
      }

      self.query = {
        page: 1,
        limit: 20,
      };

      function isOcrStaffMember() {
        const ocrStaffMemberPersonas = [
          "OCR Staff Attorney",
          "OCR Accounting",
          "OCR Billing Manager",
          "OCR Administrative",
          "System Admin",
        ];
        return (ocrStaffMemberPersonas.indexOf(OcrAuth.session.user.persona.name) !== -1);
      }

      self.checkAppointment = function(appointment) {
        if (appointment.id == self.model.id) {
          return true;
        }
        return false;
      }

      self.removeFromAppointmentGroup = function() {
        const message
          = "Are you sure that you want to remove this appointment from its appointment group?";
        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .multiple(true)
            .title('Confirm Remove from Appointment Group')
            .textContent(message)
            .ariaLabel(message)
            .ok('Remove from Appointment Group')
            .cancel('Cancel'),
        ).then(function() {
          // ok
          self.model.associatedAppointments = [];
          self.model.appointmentGroupId = undefined;
          self.model.appointmentGroupName = undefined;
          self.model.form.appointmentGroupName.$setDirty();
          self.query.page = 1;
          self.query.limit = 20;
          self.paginate();
          $scope.$emit("updateAppointmentGroup");
        }, () => {});
      };

      self.addToAppointmentGroup = function() {
        AppointmentGroupService.get({
          limit: 1000,
          officeId: self.model.officeId,
        }).$promise.then(
          function(resp) {
            if (resp.list.length == 0) {
              $mdDialog.show({
                controller: 'AddAppointmentGroupDialogController',
                templateUrl:
                  'components/ocr-appointment-associated-appointments/add-appointment-group-dialog.html',
                parent: angular.element($document[0].body),
                clickOutsideToClose: false,
                locals: {
                  officeId: self.model.officeId,
                  attorneyUserId: self.model.attorneyUserId,
                },
                bindToController: true,
                controllerAs: '$ctrl',
              })
                .then(function(selectedAppointmentGroup) {
                  self.model.form.appointmentGroupName.$setDirty();
                  self.model.associatedAppointments = [];
                  self.model.appointmentGroupId = selectedAppointmentGroup.id;
                  self.model.appointmentGroupName = selectedAppointmentGroup.name;
                  self.query.page = 1;
                  self.query.limit = 20;
                  getAssociatedAppointments(true);
                  $scope.$emit('updateAppointmentGroup');
                }, () => {});
            } else {
              $mdDialog.show({
                controller: 'AppointmentGroupDialogController',
                templateUrl:
                  'components/ocr-appointment-associated-appointments/appointment-group-dialog.html',
                parent: angular.element($document[0].body),
                clickOutsideToClose: false,
                locals: {
                  officeId: self.model.officeId,
                  attorneyUserId: self.model.attorneyUserId,
                  model: self.model,
                },
                multiple: true,
                bindToController: true,
                controllerAs: '$ctrl',
              })
                .then(function(selectedAppointmentGroup) {
                  self.model.form.appointmentGroupName.$setDirty();
                  self.model.associatedAppointments = [];
                  self.model.appointmentGroupId = selectedAppointmentGroup.id;
                  self.model.appointmentGroupName = selectedAppointmentGroup.name;
                  self.query.page = 1;
                  self.query.limit = 20;
                  getAssociatedAppointments(true);
                  $scope.$emit('updateAppointmentGroup');
                }, () => {});
            }
          },
        );
      };

      self.getLatestDate = function() {
        let d = self.today;
        if (self.entity && self.entity.appointments) {
          for (const appointment of self.entity.appointments) {
            if (new Date(appointment.startDate) < new Date(d)) {
              d = appointment.startDate;
            }
          }
        }
        return new Date(d);
      };

      self.showRequestAccessDialog = function(appointment) {
        $mdDialog.show({
          controller: 'AppointmentOfficeAccessRequestDialogController',
          templateUrl: 'dialogs/ocr-appointment-office-access-request-dialog/'
            + 'ocr-appointment-office-access-request-dialog.template.html',
          parent: angular.element($document[0].body),
          locals: {
            appointment: appointment,
            request: undefined,
            id: appointment.abbreviatedCaseNumber,
          },
          clickOutsideToClose: false,
          bindToController: true,
          controllerAs: '$ctrl',
        }).then(function(newRequest) {
          getAssociatedAppointments();
        })
      };

      self.cancelAccessRequest = function(appointment) {
        const message
          = "Are you sure that you want to cancel the request for access to this appointment?";
        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Cancel Access Request')
            .textContent(message)
            .ariaLabel(message)
            .ok('Yes, cancel request')
            .cancel('Never mind'),
        ).then(() => {
          $mdToast.show(
            $mdToast.simple()
              .textContent('Saving...')
              .position("bottom right"),
          );
          let request = appointment.accessRequests[0];
          request.statusId = 2;
          request.statusChangeDate = new Date();
          let saveFunction = AppointmentOfficeAccessRequestService.update;
          let saveArgs = [{ id: request.id }, request];
          saveFunction.apply(this, saveArgs).$promise.then(
            function() {
              $mdToast.show(
                $mdToast.simple()
                  .textContent(`Access request cancelled.`)
                  .position("bottom right"),
              )
              appointment.accessRequests = [];
            },
          ).catch(function(error) {
            if (error && error.data && error.data.status === 401) {
              return;
            }
            if (error && error.data) {
              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            }
          })
        }, () => {});
      };

      self.paginate = function() {
        if (!self.model || !self.model.associatedAppointments) {
          return;
        }
        const startIdx = (self.query.page - 1) * self.query.limit;
        let endIdx = startIdx + self.query.limit;
        if (endIdx > self.model.associatedAppointments.length) {
          endIdx = self.model.associatedAppointments.length;
        }
        self.associatedAppointments = self.model.associatedAppointments.slice(
          startIdx, endIdx);
        self.associatedAppointments.total = self.associatedAppointments.length;
        self.associatedAppointments.count = self.model.associatedAppointments.length;
      };

      self.enableAppointmentButton = function(appointment) {
        if (!(OcrAuth.session && OcrAuth.session.user)) {
          return false
        }
        if (OcrAuth.session.user.officeId === appointment.officeId
          || isOcrStaffMember()) {
          return true;
        }
        return self.hasPermission('REQUEST_APPOINTMENT_ACCESS')
          && self.hasActiveAccessRequest(appointment)
          && self.accessRequestApproved(appointment);
      }

      self.accessApproved = function(appointment) {
        if (appointment.accessRequests && appointment.accessRequests.length > 0) {
          return appointment.accessRequests[0].statusId;
        }
        return false;
      }

      self.accessRequested = function(appointment) {
        if (appointment.accessRequests && appointment.accessRequests.length > 0) {
          return appointment.accessRequests[0].statusId;
        }
        return false;
      }

      self.existingRequest = function(appointment) {
        if (appointment.accessRequests && appointment.accessRequests.length > 0) {
          return true;
        }
        return false;
      }

      self.canCancelRequest = function(appointment) {
        const request = getActiveAccessRequest(appointment);
        if (request  != null) {
          const status = self.getAccessRequestStatus(request);
          if (status === 'Pending') {
            return true;
          }
        }
        return false;
      }


      self.getAccessRequestStatus = function(appointment) {
        if (appointment.accessRequests && appointment.accessRequests.length > 0) {
          const status = self.accessRequestStatuses.lookup(appointment.accessRequests[0].statusId);
          return status.status;
        }
        return null;
      }

      function getActiveAccessRequest(appointment) {
        for (const request of appointment.accessRequests) {
          if (request.revoked) {
            continue;
          }
          const status = self.getAccessRequestStatus(request);
          if (status === 'Pending' || status === 'Approved') {
            return request;
          }
        }
        return null;
      }

      self.getAccessRequestStatus =
        (request) => {
          if (self.accessRequestStatuses) {
            return self.accessRequestStatuses.lookup(request.statusId).status;
          }
          return null;
        }

      self.hasActiveAccessRequest = (appointment) => getActiveAccessRequest(appointment) !== null;

      self.hasAccessRequestPending = function(appointment) {
        const request = getActiveAccessRequest(appointment)
        return self.getAccessRequestStatus(request) === 'Pending';
      }

      self.accessRequestApproved = function(appointment) {
        const request = getActiveAccessRequest(appointment);
        return self.getAccessRequestStatus(request) === 'Approved';
      }

      self.hasAccess = function(appointment) {
        const request = getActiveAccessRequest(appointment);
        return self.getAccessRequestStatus(request) === 'Approved';
      }

      self.disableRequestButton = function(appointment) {
        if (appointment.officeId == OcrAuth.session.user.officeId || appointment.endDate || isOcrStaffMember()) {
          return true;
        }
        return false;
      }

      self.$onInit = function() {
        $scope.$watch(
          () => self.ngModel.$modelValue,
          function() {
            self.model = self.ngModel.$modelValue;
            if (self.model) {
              if (self.model.id) {
                self.isNew = false;
                self.query.page = 1;
                self.associatedAppointments = [];
                self.associatedAppointments.total = 0;
                getAssociatedAppointments();
                self.paginate();
              } else {
                self.associatedAppointments = [];
                self.associatedAppointments.count = 0;
                self.associatedAppointments.total = 0;
                self.isNew = true;
              }
            }
          },
        );
      };
    },
  ],
});
