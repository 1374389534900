angular.module('CaresApp').controller('AddCreditDialogController', [
  '$scope',
  '$document',
  '$q',
  '$mdDialog',
  '$mdToast',
  'AppointmentActivityService',
  'OcrAuth',
  'PersonaBillingRateService',
  'UserService',
  'OcrLookups',
  'OcrUtils',
  'AppointmentBillingPercentService',
  'AppointmentOfficeAccessRequestService',
  function($scope, $document, $q, $mdDialog, $mdToast, AppointmentActivityService,
    OcrAuth, PersonaBillingRateService,
    UserService, OcrLookups, OcrUtils, AppointmentBillingPercentService, AppointmentOfficeAccessRequestService) {
    const self = this;
    // Lookups
    self.activityTypes = self.billableItemTypes = self.activityGroups = self.billableItemTypes =
      self.hearingTypes = OcrLookups.dummyList;
    OcrLookups.get('BillableItemType').then((types) => self.billableItemTypes = types);
    //Locals
    let shouldRefreshData = false;
    let changeActivityTypeBackToInactive = false;
    let originalActivityType = null;
    let personaBillingRates = null;
    // Attributes
    self.today = new Date();
    self.now = OcrUtils.today;
    self.selectedNote = [];
    self.selectedHearingTypes = [];
    self.selectedActivityType = {};
    self.selectedAttachments = [];
    self.billingPercents = undefined;
    const promises = [];

    const addedNames = [];
    const addedOffices = [];

    self.query = {
      order: undefined,
      page: 1,
      limit: 20,
    };

    self.$onInit = function() {
      OcrLookups.get('ActivityType', true).then(
        function(activityTypes) {
          self.activityTypes = activityTypes;
          if (self.credit && self.credit.typeId) {
            self.selectedActivityType = self.activityTypes.lookup(self.credit
              .typeId);
            if (self.selectedActivityType
              && self.selectedActivityType.statusId
              && self.selectedActivityType.statusId !== 1) {
              originalActivityType = self.selectedActivityType;
              changeActivityTypeBackToInactive = true;
              originalActivityType.statusId = 1;
            }
            self.activityTypeChanged();
          }
        },
      );

      OcrLookups.get('ActivityGroup').then(
        function(activityGroups) {
          self.activityGroups = activityGroups;
          if (self.credit && self.credit.groupId) {
            const currentActivityGroup = activityGroups.lookup(self.credit
              .groupId, 'id');
            if (!currentActivityGroup) {
              self.activityGroups = angular.copy(activityGroups);
              self.activityGroups.unshift({
                id: self.credit.groupId,
                group: self.credit.groupName,
              });
            }
          }
        },
      );
      OcrLookups.get('HearingType').then(
        function(hearingTypes) {
          if (self.credit && self.credit.hearingTypeId && !hearingTypes
            .lookup(self.credit.hearingTypeId)) {
            self.hearingTypes = angular.copy(hearingTypes);
            self.hearingTypes.push({
              id: self.credit.hearingTypeId,
              type: self.credit.hearingTypeName,
            });
          } else {
            self.hearingTypes = hearingTypes;
          }
        },
      );
      self.promise = AppointmentBillingPercentService.get({
        limit: 1000,
        appointment_id: self.appointment.id,
      }).$promise.then(
        function(response) {
          self.billingPercents = response.list;
        },
      );


      self.promise = undefined;
      if (self.credit) {
        self.isNew = false;
        self.credit = angular.copy(self.credit);
        self.credit.date = new Date(self.credit.date);
        self.credit.startDate = new Date(self.credit.startDate);
        self.credit.endDate = new Date(self.credit.endDate);
        if (!self.credit.notes) {
          self.credit.notes = [];
        }
        self.credit.attendance = [];
        self.credit.childParties = OcrLookups.createLookupList([]);

        if (self.credit.hearingTypes) {
          for (const activityHearingType of self.credit.hearingTypes) {
            if (self.selectedHearingTypes.includes(activityHearingType.hearingTypeId) === false) {
              self.selectedHearingTypes.push(activityHearingType.hearingTypeId);
            }
          }
        }

        self.credit.attachments = [];
        if (!self.credit.converted) {
          self.credit.converted = true;
          self.credit.childMealAmount = Math.abs(self.credit.childMealAmount / 100.0);
          self.credit.copiesQuantity = Math.abs(self.credit.copiesQuantity);
          self.credit.discoveryAmount = Math.abs(self.credit.discoveryAmount / 100.0);
          self.credit.discussionTime = Math.abs(self.credit.discussionTime / 60.0);
          self.credit.duration = Math.abs(self.credit.duration / 60.0);
          self.credit.expenseAmount = Math.abs(self.credit.expenseAmount / 100.0);
          self.credit.filingFeeAmount = Math.abs(self.credit.filingFeeAmount / 100.0);
          self.credit.mileageQuantity = Math.abs(self.credit.mileageQuantity);
          self.credit.postageAmount = Math.abs(self.credit.postageAmount / 100.0);
          self.credit.transcriptAmount = Math.abs(self.credit.transcriptAmount / 100.0);
          self.credit.travelTime = Math.abs(self.credit.travelTime / 60.0);
          self.credit.waitTime = Math.abs(self.credit.waitTime / 60.0);
        }
        if (!self.credit.billableItems) {
          self.credit.billableItems = [];
        }
      } else {
        self.isNew = true;
        self.credit = {
          appointmentId: self.appointment.id,
          billable: true,
          notes: [],
          attachments: [],
          billableItems: [],
          hearingTypes: [],
          statusId: 1,
          childParties: [],
          attendance: [],
          childMealAmount: 0,
          copiesQuantity: 0,
          discoveryAmount: 0,
          discussionTime: 0,
          duration: 0,
          expenseAmount: 0,
          filingFeeAmount: 0,
          mileageQuantity: 0,
          postageAmount: 0,
          transcriptAmount: 0,
          travelTime: 0,
          waitTime: 0,
        };
      }
      OcrLookups.get('BillableItemTypeStandardRate').then(
        (billableItemTypeStandardRates) => {
          self.credit.standardRates = billableItemTypeStandardRates;
        });
      self.credit.attendance = OcrLookups.createLookupList(self.credit.attendance || []);
      self.selectedActivityType = self.getActivityType(self.credit.typeId);
      self.promise = UserService.get({
        limit: 1000,
        office_id: self.appointment.officeId,
      }).$promise.then(
        function(resp) {
          self.credit.attorneysInOffice = resp.list;
        },
      ).finally(() => self.promise = null);

      if (self.credit.userId) {
        if (!self.credit.user) {
          UserService.get({
            id: self.credit.userId,
          }).$promise
            .then(function(response) {
              if (response) {
                self.credit.user = response;
              }
              self.loadPersonaBillingRates();
            })
            .catch(function(error) {
              /*eslint-disable no-undef */
            //FIXME this function does not seem to exist anywhere
              disableActivityEditerror("Unable to get user persona: %O", error);
              /*eslint-enable no-undef */
            });
        } else if (!self.credit.personaBillingRate) {
          self.loadPersonaBillingRates();
        }
      }
      $scope.$watch(
        () => angular.isDefined(self.addCreditForm),
        function() {
          $scope.$watch(
            () => self.addCreditForm.$invalid === true,
            function() {
              angular.forEach(self.addCreditForm.$error, function(field) {
                angular.forEach(field, function(errorField) {
                  errorField.$setTouched();
                });
              });
            }, true);
        }, true);
      self.updateList();
    };

    function getEffectivePersonaBillingRate(billingRates) {
      for (let c = 0; c < billingRates.length; c++) {
        if (
          new Date(billingRates[c].startDate).getTime() <= self.credit.date
            .getTime()
          && (!billingRates[c].endDate || new Date(billingRates[c].endDate).getTime()
            > self.credit.date.getTime())
        ) {
          return billingRates[c].billingRateCents;
        }
      }
      return undefined;
    }

    function getHearingTypes() {
      const hearingTypes = [];
      for (const hearingTypeId of self.selectedHearingTypes) {
        hearingTypes.push({
          appointmentActivityId: self.credit.id,
          hearingTypeId: hearingTypeId,
        });
      }
      return hearingTypes;
    }

    function copyOverIds(request, response) {
      self.credit.id = response.activity.id;
      for (let i = 0; i < response.billableItems.length; i++) {
        request.billableItems[i].id = response.billableItems[i].id;
      }
      for (let i = 0; i < response.notes.length; i++) {
        request.notes[i].isNew = undefined;
        request.notes[i].id = response.notes[i].id;
      }
    }

    //TODO this gets the promise, not set it
    self.setPromise = () => self.promise;

    self.checkPermission = OcrAuth.checkPermission;
    self.getUserFullName = OcrUtils.getUserFullName;
    self.age = OcrUtils.age;

    self.loadPersonaBillingRates = function() {
      if (self.credit.personaBillingRate && self.credit.personaBillingRate
        .billingRateCents) {
        return $q.when();
      } else if (personaBillingRates) {
        self.credit.personaBillingRate.billingRateCents
          = getEffectivePersonaBillingRate(personaBillingRates);
        return $q.when();
      } else if (!self.credit.date || !self.credit.userId) {
        return $q.when();
      }

      return PersonaBillingRateService.get({
        persona_id: self.credit.user.personaId,
        limit: 1000,
        order: "-start_date",
      }).$promise
        .then(function(resp) {
          personaBillingRates = resp.list;
          if (!self.credit.personaBillingRate) {
            self.credit.personaBillingRate = {};
          }

          self.credit.personaBillingRate.billingRateCents
            = getEffectivePersonaBillingRate(personaBillingRates);
        });
    };

    self.getBillableItemByBillableItemTypeId =
      (id) => OcrUtils.getArrayElementById(self.credit.billableItems, "billableItemTypeId", id);
    self.getBillableItemTypeById = (id) => self.billableItemTypes.lookup(id);
    self.getActivityType = (id) => self.activityTypes.lookup(id);

    self.userChanged =
      () => UserService.get({
        id: self.credit.userId,
      }).$promise
        .then(function(response) {
          if (response) {
            self.credit.user = response;
            if (self.credit.personaBillingRate && self.credit.personaBillingRate
              .billingRateCents) {
              self.credit.personaBillingRate.billingRateCents = undefined;
            }
          }
          self.loadPersonaBillingRates();
        })
        .catch(function(error) {
          console.error("Unable to get user persona: %O", error);
        });

    self.activityTypeChanged = function() {
      // activity's billable attribute is, by default, the activity type's billable attribute.
      // user can make a billable activity non-billable voluntarily. not visa-versa.
      self.selectedActivityType = self.getActivityType(self.credit
        .typeId);
      if (!self.selectedActivityType) {
        return;
      }
      self.resetValues();
      $scope.$watch(
        () => self.addCreditForm.$invalid,
        function() {
          if (self.addCreditForm.$invalid === true && self.addCreditForm.$error.length > 0) {
            $scope.$watch(
              () => self.addCreditForm.$error.required.length,
              function() {
                angular.forEach(self.addCreditForm.$error, function(field) {
                  angular.forEach(field, function(errorField) {
                    errorField.$setTouched();
                  });
                });
              }, true);
          }
        }, true);
    };

    self.promptFor =
      (fieldName) => (self.selectedActivityType ?
        self.selectedActivityType[`${fieldName}Prompt`] : false);

    self.promptForDuration = () => self.promptFor('activityDurationTime');
    self.promptForTravelTime = () => self.promptFor('travelTime');
    self.promptForWaitTime = () => self.promptFor('waitTime');
    self.promptForDiscussionTime = () => self.promptFor('discussionTime');
    self.promptForMileageQuantity = () => self.promptFor('mileageAmount');
    self.promptForCopiesQuantity = () => self.promptFor('copyQuantity');
    self.promptForChildMealAmount = () => self.promptFor('mealAmount');
    self.promptForExpenseAmount = () => self.promptFor('expenseAmount');
    self.promptForHearingType = () => self.promptFor('hearingType');
    self.promptForPostageAmount = () => self.promptFor('postageAmount');
    self.promptForDiscoveryAmount = () => self.promptFor('discoveryAmount');
    self.promptForFilingFeeAmount = () => self.promptFor('filingFeeAmount');
    self.promptForTranscriptAmount = () => self.promptFor('transcriptAmount');

    self.timePrompts =
      () => self.promptForDuration()
        || self.promptForTravelTime()
        || self.promptForWaitTime();

    self.quantityPrompts = () => self.promptForMileageQuantity() || self.promptForCopiesQuantity();

    self.amountPrompts =
      () => self.promptForChildMealAmount()
        || self.promptForExpenseAmount()
        || self.promptForPostageAmount()
        || self.promptForDiscoveryAmount()
        || self.promptForFilingFeeAmount()
        || self.promptForTranscriptAmount();

    self.resetValues = function() {
      if (!self.promptForChildMealAmount()) {
        self.credit.childMealAmount = 0;
      }
      if (!self.promptForCopiesQuantity()) {
        self.credit.copiesQuantity = 0;
      }
      if (!self.promptForDuration()) {
        self.credit.duration = 0;
      }
      if (!self.promptForExpenseAmount()) {
        self.credit.expenseAmount = 0;
      }
      if (!self.promptForMileageQuantity()) {
        self.credit.mileageQuantity = 0;
      }
      if (!self.promptForTravelTime()) {
        self.credit.travelTime = 0;
      }
      if (!self.promptForWaitTime()) {
        self.credit.waitTime = 0;
      }
      if (!self.promptForDiscussionTime()) {
        self.credit.discussionTime = 0;
      }
      if (!self.promptForPostageAmount()) {
        self.credit.postageAmount = 0;
      }
      if (!self.promptForDiscoveryAmount()) {
        self.credit.discoveryAmount = 0;
      }
      if (!self.promptForFilingFeeAmount()) {
        self.credit.filingFeeAmount = 0;
      }
      if (!self.promptForTranscriptAmount()) {
        self.credit.transcriptAmount = 0;
      }
    };

    self.addNote = function() {
      $mdDialog.show({
        controller: 'AddNoteDialogController',
        templateUrl: 'tmpl/add-note-dialog.html',
        parent: angular.element($document[0].body),
        locals: {
          user: OcrAuth.session.user,
          appointmentActivityId: self.credit.id,
          activityStartDate: self.credit.date,
          note: undefined,
        },
        bindToController: true,
        controllerAs: '$ctrl',
        clickOutsideToClose: false,
        fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
        multiple: true, // to allow nested dialog boxes!
      })
        .then(function(newNote) {
          newNote.isNew = true;
          self.credit.notes.push(newNote);
        }, function() {});
    };

    self.editNote = function() {
      $mdDialog.show({
        controller: 'AddNoteDialogController',
        templateUrl: 'tmpl/add-note-dialog.html',
        parent: angular.element($document[0].body),
        locals: {
          user: OcrAuth.session.user,
          appointmentActivityId: self.credit.id,
          note: self.selectedNote[0],
          readOnly: false,
        },
        bindToController: true,
        controllerAs: '$ctrl',
        clickOutsideToClose: false,
        fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
        multiple: true, // to allow nested dialog boxes!
      })
        .then(function(newNote) {
          newNote.id = self.selectedNote[0].id;
          newNote.userId = self.selectedNote[0].userId;
          for (let i = 0; i < self.credit.notes.length; i++) {
            const listNote = self.credit.notes[i]
            if (newNote.id === listNote.id) {
              self.credit.notes.splice(i, 1, newNote);
              break;
            }
          }
          newNote.isNew = true;
          self.selectedNote.splice(0, self.selectedNote.length);
        }, () => {});
    };

    self.deleteAttachment = function() {
      const message
        = "Are you sure that you want to delete the selected credit attachment?";
      $mdDialog.show(
        $mdDialog.confirm()
          .parent(angular.element($document[0].querySelector(
            '#popupContainer')))
          .clickOutsideToClose(false)
          .title('Confirm Attachment Delete')
          .textContent(message)
          .ariaLabel(message)
          .ok('Delete Attachment')
          .cancel('Cancel')
          .multiple(true), // to allow nested dialog boxes!
      ).then(function() {
        // ok
        const index = self.credit.attachments.indexOf(self.selectedAttachments[
          0]);
        self.credit.attachments.splice(index, 1);
        self.selectedAttachments.length = 0;
      }, function() {
        // cancel
        // do nothing
      });
    };

    self.updateBillableItem = function(billableItemTypeId) {
      let billableItem = self.getBillableItemByBillableItemTypeId(
        billableItemTypeId);

      if (!billableItem) {
        billableItem = {
          billableItemTypeId: billableItemTypeId,
        };
        self.credit.billableItems.push(billableItem);
      }

      const billingPercent = self.credit.billingPercent / 100;

      const standardRateMillis = self.getEffectiveBillableItemTypeStandardRate(
        billableItemTypeId);

      switch (billableItemTypeId) {
      case 1: // duration (hours)
        const tempDuration = -Math.abs(self.credit.duration);
        billableItem.amountCents = Math.round(self.credit.effectiveBillingRateCents
            * tempDuration);
        billableItem.units = Math.round(tempDuration * 60); // convert to minutes
        break;
      case 2: // travel time
        const tempTravelTime = -Math.abs(self.credit.travelTime);
        billableItem.amountCents = Math.round(self.credit.effectiveBillingRateCents
            * tempTravelTime);
        billableItem.units = Math.round(tempTravelTime * 60); // convert to minutes
        break;
      case 3: // wait time
        const tempWaitTime = -Math.abs(self.credit.waitTime);
        billableItem.amountCents = Math.round(self.credit.effectiveBillingRateCents
            * tempWaitTime);
        billableItem.units = Math.round(tempWaitTime * 60); // convert to minutes
        break;
      case 4: // mileage quantity
        const tempMileageQuantity = -Math.abs(self.credit.mileageQuantity);
        billableItem.amountCents = Math.round((standardRateMillis
            / 10.0) * tempMileageQuantity * billingPercent);
        billableItem.units = tempMileageQuantity;
        break;
      case 5: // copies quantity
        const tempCopiesQuantity = -Math.abs(self.credit.copiesQuantity);
        billableItem.amountCents = Math.round((standardRateMillis
            / 10.0) * tempCopiesQuantity * billingPercent);
        billableItem.units = tempCopiesQuantity;
        break;
      case 6: // child meal amount
        const tempChildMealAmount = -Math.abs(self.credit.childMealAmount);
        billableItem.amountCents = Math.round(tempChildMealAmount
            * 100 * billingPercent);
        billableItem.units = 1;
        billableItem.unadjustedAmountCents = Math.round(tempChildMealAmount
            * 100);
        break;
      case 7: // expense amount
        const tempExpenseAmount = -Math.abs(self.credit.expenseAmount);
        billableItem.amountCents = Math.round(tempExpenseAmount
            * 100 * billingPercent);
        billableItem.unadjustedAmountCents = Math.round(tempExpenseAmount
            * 100);
        billableItem.units = 1;
        break;
      case 8: // postage amount
        const tempPostageAmount = -Math.abs(self.credit.postageAmount);
        billableItem.amountCents = Math.round(tempPostageAmount
            * 100 * billingPercent);
        billableItem.unadjustedAmountCents = Math.round(tempPostageAmount
            * 100);
        billableItem.units = 1;
        break;
      case 9: // discovery amount
        const tempDiscoveryAmount = -Math.abs(self.credit.discoveryAmount);
        billableItem.amountCents = Math.round(tempDiscoveryAmount
            * 100 * billingPercent);
        billableItem.unadjustedAmountCents = Math.round(tempDiscoveryAmount
            * 100);
        billableItem.units = 1;
        break;
      case 10: // filing fee amount
        const tempFilingFeeAmount = -Math.abs(self.credit.filingFeeAmount);
        billableItem.amountCents = Math.round(tempFilingFeeAmount
            * 100 * billingPercent);
        billableItem.unadjustedAmountCents = Math.round(tempFilingFeeAmount
            * 100);
        billableItem.units = 1;
        break;
      case 11: // transcript amount
        const tempTranscriptAmount = -Math.abs(self.credit.transcriptAmount);
        billableItem.amountCents = Math.round(tempTranscriptAmount
            * 100 * billingPercent);
        billableItem.unadjustedAmountCents = Math.round(tempTranscriptAmount
            * 100);
        billableItem.units = 1;
        break;
      case 12: // discussion time
        const tempDiscussionTime = -Math.abs(self.credit.discussionTime);
        billableItem.amountCents = Math.round(self.credit.effectiveBillingRateCents
            * tempDiscussionTime);
        billableItem.units = Math.round(tempDiscussionTime * 60); // convert to minutes
        break;
      }
    };

    self.shouldDisableSave =
      () => self.submitting
        || self.addCreditForm.$invalid
        || (!self.credit.billable && !self.confirmNonBillable)
        || !self.credit.personaBillingRate;

    self.hide = function(response) {
      if (changeActivityTypeBackToInactive) {
        originalActivityType.statusId = 2;
      }
      $mdDialog.hide(response);
    };

    self.cancel = function() {
      if (changeActivityTypeBackToInactive) {
        originalActivityType.statusId = 2;
      }
      $mdDialog.cancel(shouldRefreshData);
    };

    self.getEffectiveBillableItemTypeStandardRate = function(billableItemTypeId) {
      if (!self.credit.standardRates || !self.credit.date) {
        return null;
      }
      for (let c = 0; c < self.credit.standardRates.length; c++) {
        if (
          self.credit.standardRates[c].billableItemTypeId
          === billableItemTypeId
          && new Date(self.credit.standardRates[c].startDate).getTime()
          <= self.credit.date
            .getTime()
          && (!self.credit.standardRates[c].endDate || new Date(self.credit
            .standardRates[c].endDate).getTime()
            > self.credit.date.getTime())
        ) {
          return self.credit.standardRates[c].standardRateMillis;
        }
      }
      return null;
    };

    self.submit = function(close) {
      if (!self.checkValues()) {
        const message
          = "This credit does not have a billable amount. " +
          "Click OK to go back and add at least one amount.";
        $mdDialog.show(
          $mdDialog.alert()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Credit Save')
            .textContent(message)
            .ariaLabel(message)
            .ok('OK')
            .multiple(true),
        );
      } else {
        self.doSave(close);
      }
    };

    self.doSave = function(close) {

      self.submitting = true;
      shouldRefreshData = true;

      self.credit.billingPercent = 100;

      if (self.billingPercents && self.billingPercents.length > 0) {
        self.credit.billingPercent = self.billingPercents[self.billingPercents.length - 1].percent;
      }

      self.credit.effectiveBillingRateCents = (self.credit.personaBillingRate
        .billingRateCents
        * self.credit.billingPercent) / 100;

      for (let i = 1; i <= 12; i++) {
        self.updateBillableItem(i);
      }

      let totalAmountCents = 0;
      let totalUnits = 0;

      for (let i = 0; i < 12; i++) {
        const item = self.credit.billableItems[i];
        if (item.billableItemTypeId <= 3) { // only hours-based billable items
          totalAmountCents += isNaN(item.amountCents) ? 0 : item.amountCents;
          totalUnits += isNaN(item.units) ? 0 : item.units;
        }
      }

      self.credit.totalAmountCents = totalAmountCents;
      self.credit.totalUnits = totalUnits;

      const request = {
        activity: OcrUtils.makeVeryShallowCopy(self.credit),
        billableItems: self.credit.billableItems,
        childParties: [],
        hearingTypes: getHearingTypes(),
      };
      if (self.isNew) {
        request.notes = self.credit.notes;
        self.promise = AppointmentActivityService.save(request)
          .$promise;
        self.promise.then(function(response) {
          copyOverIds(request, response);
          self.isNew = false;

          $mdToast.show(
            $mdToast.simple()
              .textContent("Appointment credit created.")
              .position("bottom right"),
          );
          if (close) {
            self.hide(response);
          }
        })
          .catch(function(error) {
            if (error && error.data) {
              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            }
          }).finally(() => self.submitting = false);
      } else {
        const newNotes = [];
        for (const note of self.credit.notes) {
          if (note.isNew) {
            newNotes.push(note);
          }
        }
        request.notes = newNotes;
        self.promise = AppointmentActivityService.update({
          id: self.credit.id,
        }, request).$promise;

        self.promise.then(function(response) {
          $mdToast.show(
            $mdToast.simple()
              .textContent("Appointment credit updated.")
              .position("bottom right"),
          );
          copyOverIds(request, response);
          if (close) {
            self.hide(response);
          }
        })
          .catch(function(error) {
            if (error && error.data) {
              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            }
          }).finally(() => self.submitting = false);
      }
    };

    self.checkValues =
      () => self.credit.duration > 0
        || self.credit.travelTime > 0
        || self.credit.waitTime > 0
        || self.credit.mileageQuantity > 0
        || self.credit.copiesQuantity > 0
        || self.credit.childMealAmount > 0
        || self.credit.expenseAmount > 0
        || self.credit.postageAmount > 0
        || self.credit.discoveryAmount > 0
        || self.credit.filingFeeAmount > 0
        || self.credit.transcriptAmount > 0
        || self.credit.discussionTime > 0;

    self.toggleHearingType = function(typeId) {
      const idx = self.selectedHearingTypes.indexOf(typeId);

      if (idx >= 0) {
        self.selectedHearingTypes.splice(idx, 1);
      } else {
        self.selectedHearingTypes.push(typeId);
      }
    };

    self.updateList = function() {
      promises.push(AppointmentOfficeAccessRequestService.get({
        skip: (self.query.page - 1) * self.query.limit,
        limit: self.query.limit,
        appointmentId: self.credit.appointmentId,
        requestStatusId: 4,
      }).$promise.then(function(response) {
        for (const appointment of response.list) {
          if (response && response.list) {
            if (!self.credit.attorneysInOffice.includes(appointment.requesterId) && !addedNames.includes(appointment.requesterId)){
              appointment.id = appointment.requesterId;
              appointment.firstName = appointment.requester.firstName;
              appointment.lastName = appointment.requester.lastName;
              self.credit.attorneysInOffice.push(appointment);
              addedNames.push(appointment.requesterId);
              if (!addedOffices.includes(appointment.requestedOfficeId)) {
                addedOffices.push(appointment.requestedOfficeId);
                promises.push(UserService.get({
                  limit: 1000,
                  office_id: appointment.requestedOfficeId,
                }).$promise
                  .then(function(response) {
                    for (const user of response.list) {
                      if (!addedNames.includes(user.id)) {
                        self.credit.attorneysInOffice.push(user);
                      }
                    }
                  }));
              }
            }
          }
        }
      }));
    }
  },
]);
