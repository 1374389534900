angular.module('CaresApp').component('ocrAppointmentActivities', {
  templateUrl: 'components/ocr-appointment-activities/ocr-appointment-activities.template.html',
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    updateMaxBillingAllocation: '&',
  },
  controller: [
    '$scope',
    '$document',
    '$mdDialog',
    '$mdToast',
    '$location',
    '$timeout',
    'AppointmentDataService',
    'AppointmentActivityService',
    'AppointmentActivityTotalsService',
    'AppointmentChildPartyService',
    'OcrAuth',
    'OcrUtils',
    'OcrLookups',
    'UpcomingActivityService',
    'AppointmentOfficeAccessRequestService',
    'UserService',
    function($scope, $document, $mdDialog, $mdToast, $location,
      $timeout, AppointmentDataService, AppointmentActivityService,
      AppointmentActivityTotalsService, AppointmentChildPartyService,
      OcrAuth, OcrUtils, OcrLookups, UpcomingActivityService, AppointmentOfficeAccessRequestService, UserService,) {
      const self = this;
      const promises = [];

      self.model = {};
      self.selected = [];
      self.loaded = false;
      self.hasAnyPermission = OcrAuth.hasAnyPermission;
      self.detailView = false;
      self.search = {
        billable: true,
        notBillable: true,
      };

      self.getUserFullName = OcrUtils.getUserFullName;

      self.activityTypes = self.activityGroups = OcrLookups.dummyList;
      OcrLookups.get('ActivityType').then(
        (activityTypes) => self.activityTypes = activityTypes,
      );

      OcrLookups.get('ActivityGroup').then(
        (activityGroups) => self.activityGroups = activityGroups,
      );

      self.query = {
        order: undefined,
        page: 1,
        limit: 20,
      };

      const addedNames = [];
      const addedOffices = [];

      self.showViewActivityButton =
        () => OcrAuth.checkPermission('ACTIVITY_READ')
          && !OcrAuth.hasAnyPermission(['ACTIVITY_WRITE', 'ACTIVITY_ALL', 'ADMIN_WRITE']);

      self.model.activities = [];
      self.model.activities.total = 0;

      self.$onInit = function() {
        $scope.$watchGroup(
          [
            () => self.ngModel.$modelValue,
            () => (self.ngModel.$modelValue ? self.ngModel.$modelValue.updated : null),
          ],
          function(newValues, oldValues) {
            if (newValues[1] !== oldValues[1]) {
              $timeout(self.updateData, 5000);
              return;
            }

            self.model = self.ngModel.$modelValue;
            if (self.model && self.model.id) {
              self.updateData();
              if (self.model.childParties.length > 0
                && !angular.equals($location.search(), {})
                && $location.search().uaid) {
                self.addActivity($location.search());
              }
            }
            self.updateList();
          },
        );
      };

      function updateFinancialData() {
        if (!self.model.id) {
          return;
        }
        AppointmentDataService.get({
          financialProfileOnly: true,
          id: self.model.id,
        })
          .$promise.then(
            function(resp) {
              angular.copy(resp, self.model.financialProfile);
              self.updateMaxBillingAllocation();
            }
          );
      }

      function refreshAllData() {
        self.selected.length = 0;
        updateFinancialData();
        self.updateLatestVisit();
        self.updateData();
        $scope.$emit("ReloadAttachmentsUp", false);
      }

      function undefinedIfBlank(value) {
        if (value === "" || value === null) {
          return undefined;
        }
        return value;
      }

      function fixBillable() {
        if (self.search.billable && self.search.notBillable) {
          return undefined;
        }
        if (self.search.billable) {
          return true;
        }
        return false;
      }

      self.toggleView = function() {
        self.detailView = !self.detailView;
        if (self.detailView === false) {
          self.search.note = undefined;
        }
      };

      self.updateData = function() {
        AppointmentActivityTotalsService.get({
          appointmentId: self.model.id,
          dateFrom: self.search.dateFrom,
          dateTo: self.search.dateTo,
          userId: self.search.userId,
          activityTypeId: undefinedIfBlank(self.search.activityTypeId),
          activityGroupId: undefinedIfBlank(self.search.activityGroupId),
          note: undefinedIfBlank(self.search.note),
          billable: fixBillable(),
        }).$promise
          .then(
            (resp) => self.activityTotals = resp,
          );
        self.promise = AppointmentActivityService.get({
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
          order: self.query.order,
          appointmentId: self.model.id,
          dateFrom: self.search.dateFrom,
          dateTo: self.search.dateTo,
          userId: self.search.userId,
          activityTypeId: undefinedIfBlank(self.search.activityTypeId),
          activityGroupId: undefinedIfBlank(self.search.activityGroupId),
          note: undefinedIfBlank(self.search.note),
          billable: fixBillable(),
        }).$promise.then(function(response) {
          self.model.activities = response.list;
          // FIXME This should be total, not count. Results are flipped.
          self.model.activities.total = response.count;
          self.model.activities.durationTotal = response.durationTotal;
          self.model.activities.invoicedTotal = response.invoicedTotal;
          self.model.activities.paidTotal = response.paidTotal;
          for (const activity_ of self.model.activities) {
            const childPartyNames = [];
            if (activity_.childParties) {
              for (const childParty of activity_.childParties) {
                childPartyNames.push(OcrUtils.getChildPartyFullName(
                  childParty));
              }
            }
            activity_.childPartyNames = childPartyNames.join("; ");
          }
          self.loaded = true;
        }).finally(() => self.promise = null);
      };

      self.updateLatestVisit = function() {
        self.promise = AppointmentChildPartyService.get({
          latestVisit: true,
          appointmentId: self.model.id,
        }).$promise.then(
          function(response) {
            for (const childParty of self.model.childParties) {
              for (const activity of response.list) {
                if (childParty.childPartyId == activity.childPartyId) {
                  childParty.lastVisit = activity.lastVisit;
                }
              }
            }
          }).finally(() => self.promise = undefined);
      }

      self.resetSearch = function() {
        self.search = {
          billable: true,
          notBillable: true,
        };
        self.query.page = 1;
        self.updateData();
      };

      self.addActivity = function(upcoming) {
        $mdDialog.show({
          controller: 'AddActivityDialogController',
          templateUrl: 'components/ocr-appointment-activities/add-activity-dialog.template.html',
          parent: angular.element($document[0].body),
          locals: {
            add: true,
            appointment: self.model,
            activity: undefined,
            upcoming: upcoming,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          multiple: true,
          clickOutsideToClose: false,
          //fullscreen: self.customFullscreen // Only for -xs, -sm breakpoints.
        }).then(
          function() {
            refreshAllData();
            if (upcoming) {
              UpcomingActivityService.update({
                id: upcoming.uaid,
              }, {
                create: true,
              });
              $location.search("");
              $location.path('/views/upcoming_activities');
            }
          },
          function(shouldRefreshData) {
            if (shouldRefreshData) {
              refreshAllData();
            }
          },
        );
      };

      self.viewActivity = () => $mdDialog.show({
        controller: 'AddActivityDialogController',
        templateUrl: 'components/ocr-appointment-activities/add-activity-dialog.template.html',
        parent: angular.element($document[0].body),
        locals: {
          add: false,
          appointment: self.model,
          activity: self.selected[0],
          readOnly: true,
          disableAdd: false,
        },
        bindToController: true,
        controllerAs: '$ctrl',
        multiple: true,
        clickOutsideToClose: false,
        //fullscreen: self.customFullscreen // Only for -xs, -sm breakpoints.
      });

      self.editActivity = function() {
        $mdDialog.show({
          controller: 'AddActivityDialogController',
          templateUrl: 'components/ocr-appointment-activities/add-activity-dialog.template.html',
          parent: angular.element($document[0].body),
          locals: {
            add: false,
            appointment: self.model,
            activity: self.selected[0],
            disableAdd: false,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          multiple: true,
          clickOutsideToClose: false,
          //fullscreen: self.customFullscreen // Only for -xs, -sm breakpoints.
        }).then(
          () => refreshAllData(),
          (shouldRefreshData) => {
            if (shouldRefreshData) {
              refreshAllData();
            }
          },
        );
      };

      self.deleteActivity = function() {
        const message
          = "Are you sure that you want to delete this activity?";
        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Activity Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete the activity')
            .cancel('Cancel'),
        ).then(function() {
          $mdToast.show(
            $mdToast.simple()
              .textContent('Deleting...')
              .position("bottom right"),
          );
          AppointmentActivityService.delete({
            id: self.selected[0].id,
          }).$promise
            .then(function() {
              $mdToast.show(
                $mdToast.simple()
                  .textContent('Activity deleted.')
                  .position("bottom right"),
              );
              refreshAllData();
            }).catch(function(error) {
              if (error.data.reason.includes("activity_attachment")) {
                $mdDialog.show(
                  $mdDialog.alert()
                    .title('Unable to Delete')
                    .htmlContent(
                      'The activity could not be deleted yet because it '
                      + 'has attachments. Please “Edit Activity” first '
                      + 'to download any attachments that need to be '
                      + 'retained and delete all attachments from this '
                      + 'activity before deleting the activity itself.')
                    .ok('Close')
                    .targetEvent(event),
                );
              } else {
                $mdToast.show(
                  $mdToast.simple()
                    .textContent(error.data.reason)
                    .position("bottom right"),
                );
              }
            });
        }, function() {
          // cancel
          // do nothing
        });
      };

      self.addUpcomingActivity = function() {
        $mdDialog.show({
          controller: 'AddUpcomingActivityDialogController',
          templateUrl:
            'components/ocr-upcoming-activities/add-upcoming-activity-dialog.template.html',
          parent: angular.element($document[0].body),
          locals: {
            add: true,
            activity: undefined,
            date: undefined,
            appointmentId: self.model.id,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          multiple: true,
          clickOutsideToClose: false,
          //fullscreen: self.customFullscreen // Only for -xs, -sm breakpoints.
        }).then(() => {}, () => {});
      }

      self.addCredit = function(upcoming) {
        $mdDialog.show({
          controller: 'AddCreditDialogController',
          templateUrl: 'components/ocr-appointment-activities/add-credit-dialog.template.html',
          parent: angular.element($document[0].body),
          locals: {
            add: true,
            appointment: self.model,
            credit: undefined,
            upcoming: upcoming,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          multiple: true,
          clickOutsideToClose: false,
          //fullscreen: self.customFullscreen // Only for -xs, -sm breakpoints.
        }).then(
          (shouldRefreshData) => {
            if (shouldRefreshData) {
              refreshAllData();
            }
          },
        );
      };

      self.viewCredit = () => $mdDialog.show({
        controller: 'AddCreditDialogController',
        templateUrl: 'components/ocr-appointment-activities/add-credit-dialog.template.html',
        parent: angular.element($document[0].body),
        locals: {
          add: false,
          appointment: self.model,
          credit: self.selected[0],
          readOnly: true,
          disableAdd: false,
        },
        bindToController: true,
        controllerAs: '$ctrl',
        multiple: true,
        clickOutsideToClose: false,
        //fullscreen: self.customFullscreen // Only for -xs, -sm breakpoints.
      });

      self.editCredit = function() {
        $mdDialog.show({
          controller: 'AddCreditDialogController',
          templateUrl: 'components/ocr-appointment-activities/add-credit-dialog.template.html',
          parent: angular.element($document[0].body),
          locals: {
            add: false,
            appointment: self.model,
            credit: self.selected[0],
            disableAdd: false,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          multiple: true,
          clickOutsideToClose: false,
          //fullscreen: self.customFullscreen // Only for -xs, -sm breakpoints.
        }).then(
          () => refreshAllData(),
          (shouldRefreshData) => {
            if (shouldRefreshData) {
              refreshAllData();
            }
          },
        );
      };

      self.deleteCredit = function() {
        const message
          = "Are you sure that you want to delete this credit?";
        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Credit Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete the credit')
            .cancel('Cancel'),
        ).then(function() {
          $mdToast.show(
            $mdToast.simple()
              .textContent('Deleting...')
              .position("bottom right"),
          );
          AppointmentActivityService.delete({
            id: self.selected[0].id,
          }).$promise
            .then(function() {
              $mdToast.show(
                $mdToast.simple()
                  .textContent('Credit deleted.')
                  .position("bottom right"),
              );
              refreshAllData();
            });
        }, () => {});
      };

      self.childPartiesMissing = function() {
        return self.model.childParties.length < 1;
      }

      self.disableActivityEdit = () => !(OcrAuth.session
          && OcrAuth.session.user
          && OcrAuth.session.user.id === self.selected[0].userId
          && self.selected[0].statusId <= 2);

      self.disableCreditEdit = () => self.selected[0].statusId > 2;

      self.showActivityActions =
        () => !(self.selected.length !== 0 && self.selected[0].totalAmountCents < 0)

      self.showCreditActions =
        () => self.selected.length !== 0 && self.selected[0].totalAmountCents < 0;

      self.updateList = function () {
        promises.push(AppointmentOfficeAccessRequestService.get({
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
          appointmentId: self.model.id,
          requestStatusId: 4,
        }).$promise.then(function(response) {
          for (const appointment of response.list) {
            if(response && response.list) {
              if (angular.isDefined(self.model.attorneysInOffice) && angular.isDefined(addedNames)) {
                if (!self.model.attorneysInOffice.includes(appointment.requesterId) && !addedNames.includes(appointment.requesterId)){
                  appointment.id = appointment.requesterId;
                  appointment.firstName = appointment.requester.firstName;
                  appointment.lastName = appointment.requester.lastName;
                  self.model.attorneysInOffice.push(appointment);
                  addedNames.push(appointment.requesterId);
                  if(!addedOffices.includes(appointment.requestedOfficeId)) {
                    addedOffices.push(appointment.requestedOfficeId);
                    promises.push(UserService.get({
                      limit: 1000,
                      office_id: appointment.requestedOfficeId,
                    }).$promise
                      .then(function(response) {
                        for (const user of response.list) {
                          if (!addedNames.includes(user.id)) {
                            self.model.attorneysInOffice.push(user);
                          }
                        }
                      }));
                  }
                }
              }
            }
          }
        }));
      };
    },
  ],
});
