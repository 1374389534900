angular.module('CaresApp').component('ocrBillableItemTypeEdit', {
  templateUrl: 'components/ocr-billable-item-type-edit/ocr-billable-item-type-edit.template.html',
  controller: [
    '$scope',
    '$document',
    '$location',
    '$routeParams',
    '$mdToast',
    '$mdDialog',
    'OcrUtils',
    'BillableItemTypeService',
    'OcrLookups',
    'BillableItemTypeStandardRateService',
    'OcrAuth',
    function($scope, $document, $location, $routeParams, $mdToast, $mdDialog, OcrUtils,
      BillableItemTypeService, OcrLookups,
      BillableItemTypeStandardRateService, OcrAuth) {
      $scope.$emit("SetSearchBox", false);
      const self = this;

      self.id = $routeParams.billableItemTypeId;
      self.loading = true;
      self.standardRates = [];
      self.hasPermission = OcrAuth.checkPermission;

      self.promise = BillableItemTypeService.get({
        id: self.id,
      }).$promise
        .then(function(resp) {
          self.entity = resp;
          OcrUtils.setPageTitle(`Billable Item Type: ${self.entity.name}`);
          self.rateValue = self.entity.standardRateMillis;

          return BillableItemTypeStandardRateService.get({
            billable_item_type_id: self.id,
          }).$promise;
        })
        .then((resp) => self.standardRates = resp.list)
        .catch(() => self.standardRates = [])
        .finally(() => self.loading = false);

      self.clearCode = function() {
        if (!self.entity.deptObjCodeExists) {
          self.entity.deptObjCode = null;
        }
      }

      self.save = function(andExit) {
        self.clearCode();
        OcrLookups.remove('BillableItemType');
        OcrLookups.remove('BillableItemType', true);
        $mdToast.show(
          $mdToast.simple()
            .textContent('Saving...')
            .position("bottom right"),
        );
        let saveFunction = null;
        let saveArgs = null;
        saveFunction = BillableItemTypeService.update;
        saveArgs = [
          {
            id: self.id,
          },
          self.entity,
        ];
        saveFunction.apply(this, saveArgs).$promise.then(
          function() {
            self.form.$setPristine();
            $mdToast.show(
              $mdToast.simple()
                .textContent('Billable item type saved.')
                .position("bottom right"),
            )
            if (andExit) {
              self.close(true)
            }
          },
        ).catch(function(error) {
          if (error && error.data && error.data.status === 401) {
            self.close(true);
          } else if (error && error.data) {
            $mdToast.show(
              $mdToast.simple()
                .textContent(error.data.reason)
                .position("bottom right"),
            );
          }
        })
      }

      self.close = function(noConfirm) {
        if (noConfirm || self.form.$pristine) {
          $location.path(`/views/${$routeParams.view}`);
        } else {
          const message
            = "Are you sure that you want to close this form without saving your changes?";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Form Close')
              .textContent(message)
              .ariaLabel(message)
              .ok('Close Form')
              .cancel('Cancel'),
          ).then(
            () => $location.path(`/views/${$routeParams.view}`),
            () => {},
          );
        }
      };

      self.addStandardRate = function() {
        OcrLookups.remove('BillableItemTypeStandardRate');
        OcrLookups.remove('BillableItemTypeStandardRate', true);
        $mdDialog.show({
          controller: 'AddBillableItemTypeStandardRateDialogController',
          templateUrl: 'tmpl/add-billable-item-standard-rate-dialog.html',
          parent: angular.element($document[0].body),
          locals: {
            billableItemType: self.entity,
            standardRates: self.standardRates,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          clickOutsideToClose: false,
          fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
        }).then(() => {}, () => {});
      };

      self.updateRate = function() {
        if (self.rateValue === null) {
          self.entity.standardRateMillis = null;
        } else {
          self.entity.standardRateMillis = parseInt(self.rateValue.replace(
            /\D/g, ""));
        }
      };

      $scope.$on('$locationChangeStart',
        function(event, newUrl, oldUrl) {
          const removeQueryParams = (url) => {
            if (url) {
              const index = url.indexOf("?");
              if (index >= 0) {
                return url.substring(0, index);
              }
            }
            return url;
          };

          const newPath = $location.path();

          if (
            newPath === "/"
            || self.form.$pristine
            || removeQueryParams(newUrl) === removeQueryParams(oldUrl)
          ) {
            return;
          }

          event.preventDefault();

          const message
            = "Are you sure that you want to close this form without saving your changes?";

          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Page Exit')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK')
              .cancel('Cancel'),
          ).then(() => {
            self.form.$setPristine();
            $location.path(newPath);
          });
        });

      self.$onInit =
        () => $scope.$watch(
          () => angular.isDefined(self.form),
          () => {
            if (self.form && self.form.$invalid) {
              angular.forEach(self.form.$error, function(field) {
                angular.forEach(field, function(errorField) {
                  errorField.$setTouched();
                });
              });
            }
          }, true);
    },
  ],
});
