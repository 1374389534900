//angular.module('CaresApp').factory('$exceptionHandler',
  //[
    //() => () => {
        //debugger;
    //},
  //]);

angular.module('CaresApp').component('ocrSingleRule', {
  templateUrl:
    'components/ocr-single-rule/ocr-single-rule.template.html',
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    'delete': '=',
    'parent': '=',
  },
  controller: [
    '$scope',
    '$q',
    '$timeout',
    'OcrUtils',
    'OcrLookups',
    function($scope, $q, $timeout, OcrUtils, OcrLookups,) {
      const self = this;

      self.model = {};
      self.fields = [
        "User",
        "Persona",
        "Activity Type",
        "Billable Item Type",
        "Appointment",
        "Case",
        "Case Type",
        "Appointment Capacity",
        "Access Request Reason",
        "Office",
        "All of the Following",
        "Any of the Following",
      ];
      self.operators = [
        "EQUALS",
        "DOES NOT EQUAL",
      ];

      self.getUserFullName = OcrUtils.getUserFullName;

      self.personas = self.caseTypes = self.appointmentCapacities = self.activityTypes
                    = self.billableItemTypes = self.officeAccessRequestReasons
                    = self.offices = self.users = OcrLookups.dummyList;

      OcrLookups.get('Persona').then((personas) => self.personas = personas);
      OcrLookups.get('CaseType').then((caseTypes) => self.caseTypes = caseTypes);
      OcrLookups.get('AppointmentCapacity').then(
        (appointmentCapacities) => self.appointmentCapacities = appointmentCapacities);
      OcrLookups.get('ActivityType', true).then((activityTypes) => self.activityTypes = activityTypes);
      OcrLookups.get('BillableItemType').then(
        (billableItemTypes) => self.billableItemTypes = billableItemTypes);
      OcrLookups.get('AppointmentOfficeAccessRequestReason').then(
        (officeAccessRequestReasons) => self.officeAccessRequestReasons
          = officeAccessRequestReasons);
      OcrLookups.get('Office').then((offices) => self.offices = offices);
      OcrLookups.get('User').then((users) => self.users = users);

      self.fieldChanged = function() {
        self.model.operator = undefined;
        self.model.value = undefined;
        self.model.searchText = undefined;
        self.model.selectedItem = undefined;
        self.model.rules = [];
        if (self.model.field === "All of the Following" || self.model.field === "Any of the Following") {
          self.addRule();
        }
      };

      self.addRule = function() {
        self.model.rules.push(
          {
            "field": undefined,
            "operator": undefined,
          },
        )
      }

      function createSearchFunction(filter, listGetter) {
        return (query) => {
          const list = listGetter();
          const results = query ? list.filter(filter(query)) : list;
          const deferred = $q.defer();
          $timeout(() => deferred.resolve(results), Math.random() * 1000, false);
          return deferred.promise;
        }
      }

      function lowerCaseFilterCreator(attribute) {
        return (query) => {
          const lowercaseQuery = query.toLowerCase();
          return function filterFn(value) {
            const lowerCaseValue = value[attribute].toLowerCase();
            return (lowerCaseValue.indexOf(lowercaseQuery) >= 0);
          };
        }
      }

      self.deleteRule = function(rule) {
        for (let i = 0; i < self.model.rules.length; i++) {
          if (self.model.rules[i].$$hashKey === rule.$$hashKey) {
            self.model.rules.splice(i, 1);
          }
        }
      }

      function createUserFilterFor(query) {
        const lowercaseQuery = query.toLowerCase();
        return function filterFn(user) {
          const userDisplay = self.displayUser(user).toLowerCase();
          return (userDisplay.indexOf(lowercaseQuery) >= 0);
        };
      }

      self.userSearch = createSearchFunction(createUserFilterFor, () => self.users);

      self.displayUser = function(user) {
        if (user.middleName !== null && user.middleName !== undefined && user.middleName !== "") {
          return user.firstName + " " + user.middleName + " " + user.lastName + " - " + user.login;
        }
        return user.firstName + " " + user.lastName + " - " + user.login;
      }

      self.personaSearch = createSearchFunction(
        lowerCaseFilterCreator('name'), () => self.personas);
      self.activitySearch = createSearchFunction(
        lowerCaseFilterCreator('type'), () => self.activityTypes);
      self.caseTypeSearch = createSearchFunction(
        lowerCaseFilterCreator('description'), () => self.caseTypes);
      self.officeSearch = createSearchFunction(
        lowerCaseFilterCreator('name'), () => self.offices);

      self.$onInit =
        () => $scope.$watch(
          () => self.ngModel.$modelValue,
          () => self.model = self.ngModel.$modelValue,
        );
    },
  ],
});
