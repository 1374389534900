angular.module('CaresApp').controller('SubmitInvoiceController', [
  '$mdDialog',
  '$mdToast',
  '$location',
  'InvoiceService',
  function($mdDialog, $mdToast, $location, InvoiceService) {
    const self = this;

    self.$onInit = function() {
      self.submitAnyway = !self.hasChanged;
    }

    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;

    self.submit = function() {
      self.submitting = true;
      $mdToast.show(
        $mdToast.simple()
          .textContent('Submitting...')
          .position("bottom right"),
      );
      InvoiceService.update({ id: self.invoiceNumber }, { newStatus: "Submitted" }).$promise
        .then(function() {
          self.hide();
          $mdToast.show(
            $mdToast.simple()
              .textContent('Invoice submitted.')
              .position("bottom right"),
          );
          $location.path(`/views/${self.view}`)
        },
        ).catch(function(error) {
          if (error && error.data) {
            $mdToast.show(
              $mdToast.simple()
                .textContent(error.data.reason)
                .position("bottom right"),
            );
          }
        });
    }
  },
]);
