angular.module('CaresApp').component('ocrAnnouncementList', {
  templateUrl: 'components/ocr-announcement-list/ocr-announcement-list.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    '$location',
    'AnnouncementService',
    'OcrUtils',
    'OcrViews',
    'OcrAuth',
    function($scope, $location, AnnouncementService, OcrUtils, OcrViews, OcrAuth) {
      const self = this;
      OcrUtils.setPageTitle('Announcements');
      self.currentView = OcrViews.currentView

      self.results = {
        data: [],
        count: 0,
        total: 0,
      };
      self.promise = null;
      $scope.$watch(() => self.promise, () => self.setPromise()(self.promise))

      self.refresh = () => self.updateData();

      self.updateData = function() {
        self.promise = AnnouncementService.query({
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
        }).$promise.then(
          function(resp) {
            self.results.data = resp.list;
            self.results.count = resp.count;
            self.results.total = resp.total;
          },
        ).finally(() => self.promise = null)
      }

      self.$onInit = () => self.updateData();

      self.checkPermission = OcrAuth.checkPermission;

      self.search = function() {
        if (self.query.query === "") {
          self.query.query = undefined;
        }

        const input = self.query.query;

        self.promise = AnnouncementService.get({
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
          query: input,
        }).$promise;

        self.promise.then(function(response) {
          self.promise = null;
          // improve error handling here.
          self.results.data = response.list;
          self.results.total = response.total;
          self.results.count = self.results.data.length;
        })
          .catch((error) => {
            if (error && error.data && error.data.status === 401) {
              $location.path("/");
            }
          });
      };
    },
  ],
});
