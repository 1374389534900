angular.module('CaresApp').component('ocrBestInterestsContinuationReasonEdit', {
  templateUrl: 'components/ocr-best-interests-continuation-reason-edit/'
    + 'ocr-best-interests-continuation-reason-edit.template.html',
  controller: [
    '$scope',
    '$document',
    '$location',
    '$routeParams',
    '$mdToast',
    '$mdDialog',
    'BestInterestsContinuationReasonService',
    'OcrLookups',
    'OcrUtils',
    'OcrAuth',
    function($scope, $document, $location, $routeParams, $mdToast, $mdDialog,
      BestInterestsContinuationReasonService, OcrLookups, OcrUtils,
      OcrAuth) {
      $scope.$emit("SetSearchBox", false);
      const self = this;
      self.today = new Date();
      self.hasPermission = OcrAuth.checkPermission;
      const promises = [];

      if ($routeParams.bestInterestsContinuationReasonId !== 'create') {
        self.id = $routeParams.bestInterestsContinuationReasonId;
      } else {
        OcrUtils.setPageTitle('New Best Interests Continuation Reason');
      }

      self.loading = true;
      if (self.id) {
        self.promise = BestInterestsContinuationReasonService.get({ id: self.id }).$promise
          .then(function(resp) {
            self.entity = resp;
            OcrUtils.setPageTitle(`Best Interests Continuation Reason: ${self.entity.reason}`);
            if (self.entity.startDate) {
              self.entity.startDate = new Date(self.entity.startDate);
              self.entity.loadedStart = new Date(self.entity.startDate);
            }
            if (self.entity.endDate) {
              self.entity.endDate = new Date(self.entity.endDate);
            }
          }).finally(() => self.loading = false);
      } else {
        self.entity = {}
        self.loading = false
      }

      self.save = function(andExit) {
        OcrLookups.remove('BestInterestsContinuationReason')
        OcrLookups.remove('BestInterestsContinuationReason', true);
        $mdToast.show(
          $mdToast.simple()
            .textContent('Saving...')
            .position("bottom right"),
        );
        let saveFunction = null;
        let saveArgs = null;
        if (self.id) {
          saveFunction = BestInterestsContinuationReasonService.update
          saveArgs = [{ id: self.id }, self.entity]
        } else {
          saveFunction = BestInterestsContinuationReasonService.save
          saveArgs = [self.entity]
        }
        saveFunction.apply(this, saveArgs).$promise.then(
          function() {
            self.form.$setPristine();
            $mdToast.show(
              $mdToast.simple()
                .textContent('Best interests continuation reason saved.')
                .position("bottom right"),
            )
            if (andExit) {
              self.close(true)
            }
          },
        ).catch(function(error) {
          if (error && error.data && error.data.status === 401) {
            self.close(true);
          } else if (error && error.data) {
            $mdToast.show(
              $mdToast.simple()
                .textContent(error.data.reason)
                .position("bottom right"),
            );
          }
        })
      }

      self.close = function(noConfirm) {
        if (noConfirm || self.form.$pristine) {
          $location.path(`/views/${$routeParams.view}`);
        } else {
          const message
            = "Are you sure that you want to close this form without saving your changes?";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Form Close')
              .textContent(message)
              .ariaLabel(message)
              .ok('Close Form')
              .cancel('Cancel'),
          ).then(() => $location.path(`/views/${$routeParams.view}`), () => {});
        }
      }

      self.deleteForm = function() {
        const message
          = "Are you sure that you want to delete this Best Interests Continuation Reason?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Best Interests Continuation Reason Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete Best Interests Continuation Reason')
            .cancel('Cancel'),
        ).then(function() {
          // ok
          OcrLookups.remove('BestInterestsContinuationReason')
          OcrLookups.remove('BestInterestsContinuationReason', true);
          $mdToast.show(
            $mdToast.simple()
              .textContent('Deleting...')
              .position("bottom right"),
          );
          BestInterestsContinuationReasonService.delete({
            id: self.id,
          }, self.entity).$promise.then(function() {
            $mdToast.show(
              $mdToast.simple()
                .textContent('Best Interests Continuation Reason deleted.')
                .position("bottom right"),
            );
            $location.path(`/views/${$routeParams.view}`);
          })
            .catch(function(error) {
              if (error && error.data) {
                $mdToast.show(
                  $mdToast.simple()
                    .textContent(error.data.reason)
                    .position("bottom right"),
                );
              }
            });
        }, () => {});
      };

      self.disableStart = function() {
        if (self.loading) {
          return true;
        } else if (self.id) {
          if (self.today > self.entity.loadedStart) {
            return true;
          }
          return false;
        }
        return false;
      }

      $scope.$on('$locationChangeStart',
        function(event, newUrl, oldUrl) {
          const removeQueryParams = (url) => {
            if (url) {
              const index = url.indexOf("?");
              if (index >= 0) {
                return url.substring(0, index);
              }
            }
            return url;
          };

          const newPath = $location.path();

          if (
            newPath === "/"
            || self.form.$pristine
            || removeQueryParams(newUrl) === removeQueryParams(oldUrl)
          ) {
            return;
          }

          event.preventDefault();

          const message
            = "Are you sure that you want to close this form without saving your changes?";

          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Page Exit')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK')
              .cancel('Cancel'),
          ).then(() => {
            self.form.$setPristine();
            $location.path(newPath);
          });
        });

      self.$onInit =
        () => $scope.$watch(
          () => angular.isDefined(self.form),
          () => {
            if (self.form && self.form.$invalid) {
              angular.forEach(self.form.$error, function(field) {
                angular.forEach(field, function(errorField) {
                  errorField.$setTouched();
                });
              });
            }
          }, true);
    },
  ],
});
