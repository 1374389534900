angular.module('CaresApp').controller('AddActivityDialogController', [
  '$scope',
  '$document',
  '$q',
  '$mdDialog',
  '$mdToast',
  'AppointmentActivityService',
  'OcrAuth',
  'PersonaBillingRateService',
  'UserService',
  'OcrLookups',
  'OcrUtils',
  'AppointmentBillingPercentService',
  function($scope, $document, $q, $mdDialog, $mdToast, AppointmentActivityService,
    OcrAuth, PersonaBillingRateService,
    UserService, OcrLookups, OcrUtils, AppointmentBillingPercentService) {
    const self = this;
    let personaBillingRates = null;
    let changeActivityTypeBackToInactive = false;
    let originalActivityType = null;
    let shouldRefreshData = false;
    self.selectedAttachments = [];
    self.today = new Date();
    self.now = OcrUtils.today;
    self.selectedNote = [];
    self.selectedHearingTypes = [];
    self.selectedActivityType = {};
    self.billingPercents = undefined;

    self.activityTypes = self.billableItemTypes = self.activityGroups = self.billableItemTypes =
      self.hearingTypes = self.caseTypeAttorneyRoles = OcrLookups.dummyList;
    OcrLookups.get('BillableItemType').then(
      (billableItemTypes) => self.billableItemTypes = billableItemTypes,
    );
    OcrLookups.get('CaseTypeAttorneyRole').then(
      (caseTypeAttorneyRoles) => self.caseTypeAttorneyRoles = caseTypeAttorneyRoles,
    );

    self.$onInit = function() {
      OcrLookups.get('ActivityType', true).then(
        function(activityTypes) {
          self.activityTypes = activityTypes;
          if (self.activity && self.activity.typeId) {
            self.selectedActivityType = self.activityTypes.lookup(self.activity
              .typeId);
            if (self.selectedActivityType
              && self.selectedActivityType.statusId
              && self.selectedActivityType.statusId !== 1
            ) {
              originalActivityType = self.selectedActivityType;
              changeActivityTypeBackToInactive = true;
              originalActivityType.statusId = 1;
            }
            self.activityTypeChanged();
          }
        },
      );
      OcrLookups.get('ActivityGroup').then(
        function(activityGroups) {
          self.activityGroups = activityGroups;
          if (self.activity && self.activity.groupId) {
            const currentActivityGroup = activityGroups.lookup(self.activity
              .groupId, 'id');
            if (!currentActivityGroup) {
              self.activityGroups = angular.copy(activityGroups);
              self.activityGroups.unshift({
                id: self.activity.groupId,
                group: self.activity.groupName,
              });
            }
          }
        },
      );
      OcrLookups.get('HearingType').then(
        function(hearingTypes) {
          if (self.activity && self.activity.hearingTypeId && !hearingTypes
            .lookup(self.activity.hearingTypeId)) {
            self.hearingTypes = angular.copy(hearingTypes);
            self.hearingTypes.push({
              id: self.activity.hearingTypeId,
              type: self.activity.hearingTypeName,
            });
          } else {
            self.hearingTypes = hearingTypes;
          }
          self.activity.officeId = self.appointment.officeId;
        },
      );

      self.promise = AppointmentBillingPercentService.get({
        limit: 1000,
        appointment_id: self.appointment.id,
      }).$promise.then(
        function(response) {
          self.billingPercents = response.list;
        },
      );
      self.promise = undefined;
      self.variableChildParties = angular.copy(self.appointment.childParties);
      self.variableChildParties = OcrLookups.createLookupList(self.variableChildParties);
      if (self.activity) {
        self.isNew = false;
        self.activity = angular.copy(self.activity);
        self.activity.date = new Date(self.activity.date);
        self.activity.startDate = new Date(self.activity.startDate);
        self.activity.endDate = new Date(self.activity.endDate);
        if (!self.activity.notes) {
          self.activity.notes = [];
        }
        if (!self.activity.attendance) {
          self.activity.attendance = [];
        }
        if (self.activity.childParties) {
          if (!self.appointment.childParties.lookup) {
            self.appointment.childParties = OcrLookups.createLookupList(self.appointment
              .childParties);
          }
          for (const activityChildParty of self.activity.childParties) {
            const childParty = self.appointment.childParties.lookup(
              activityChildParty.childPartyId, 'childPartyId');
            self.activity.attendance.push(childParty);
            if (activityChildParty.variableAttendance) {
              self.variableChildParties.lookup(activityChildParty.childPartyId,
                'childPartyId').activityChildPartyId = activityChildParty.id;
              self.variableChildParties.lookup(activityChildParty.childPartyId,
                'childPartyId').variableAttendance = activityChildParty.variableAttendance;
            }
          }
        } else {
          self.activity.childParties = OcrLookups.createLookupList([]);
        }

        if (self.activity.hearingTypes) {
          for (const activityHearingType of self.activity.hearingTypes) {
            if (self.selectedHearingTypes.includes(activityHearingType.hearingTypeId) === false) {
              self.selectedHearingTypes.push(activityHearingType.hearingTypeId);
            }
          }
        }

        self.activity.attachments = [];
        if (!self.activity.converted) {
          self.activity.converted = true;
          self.activity.duration /= 60.0;
          self.activity.travelTime /= 60.0;
          self.activity.waitTime /= 60.0;
          self.activity.discussionTime /= 60.0;
          self.activity.childMealAmount /= 100.0;
          self.activity.expenseAmount /= 100.0;
          self.activity.postageAmount /= 100.0;
          self.activity.discoveryAmount /= 100.0;
          self.activity.filingFeeAmount /= 100.0;
          self.activity.transcriptAmount /= 100.0;
        }
        if (!self.activity.billableItems) {
          self.activity.billableItems = [];
        }
      } else {
        self.isNew = true;
        self.activity = {
          appointmentId: self.appointment.id,
          userId: OcrAuth.session.user.id,
          user: OcrAuth.session.user,
          notes: [],
          attachments: [],
          billableItems: [],
          hearingTypes: [],
          statusId: 1,
          childParties: [],
          attendance: [],
          childMealAmount: 0,
          copiesQuantity: 0,
          duration: 0,
          expenseAmount: 0,
          postageAmount: 0,
          discoveryAmount: 0,
          filingFeeAmount: 0,
          transcriptAmount: 0,
          mileageQuantity: 0,
          travelTime: 0,
          waitTime: 0,
          discussionTime: 0,
        };

        if (self.upcoming) {
          self.activity.date = new Date(self.upcoming.start);
          self.activity.groupId = parseInt(self.upcoming.groupId);
          self.activity.typeId = parseInt(self.upcoming.typeId);
          self.activity.billable = self.upcoming.billable;

          self.selectedActivityType = {
            id: parseInt(self.upcoming.typeId),
          }
        }
      }

      OcrLookups.get('BillableItemTypeStandardRate').then(
        (rates) => {
          self.activity.standardRates = rates;
        });
      self.activity.attendance = OcrLookups.createLookupList(self.activity.attendance || []);

      if (!self.activity.user) {
        UserService.get({
          id: self.activity.userId,
        }).$promise
          .then(function(response) {
            if (response) {
              self.activity.user = response;
            }
            self.loadPersonaBillingRates();
          })
          .catch((error) => console.error("Unable to get user persona: %O", error));
      } else if (!self.activity.personaBillingRate) {
        self.loadPersonaBillingRates();
      }
      self.originalActivityChildParties = OcrLookups.createLookupList(angular
        .copy(self.activity.childParties));
      self.selectedActivityType = self.getActivityType(self.activity.typeId);
      $scope.$watch(
        () => angular.isDefined(self.addActivityForm),
        function() {
          $scope.$watch(
            () => self.addActivityForm.$invalid === true,
            function() {
              angular.forEach(self.addActivityForm.$error, function(field) {
                angular.forEach(field, function(errorField) {
                  errorField.$setTouched();
                });
              });
            }, true);
        }, true);
    }

    self.checkPermission = OcrAuth.checkPermission;

    function getEffectivePersonaBillingRate(billingRates) {

      for (let c = 0; c < billingRates.length; c++) {

        // billing rates dates have timezone issues and need to be changed to midnight local time.

        let startDateTime = new Date(billingRates[c].startDate).setHours(0,0);
        let endDateTime = billingRates[c].endDate ?
          new Date(billingRates[c].endDate).setHours(0,0) : Number.MAX_VALUE;
        let activityDateTime = self.activity.date.getTime();

        if (startDateTime <= activityDateTime && endDateTime > activityDateTime) {
          return billingRates[c].billingRateCents;
        }

      }
      return undefined;
    }

    function getActivityChildPartiesFromVariable() {
      const childParties = [];
      for (const item of self.variableChildParties) {
        childParties.push({
          id: item.activityChildPartyId,
          activityId: self.activity.id,
          childPartyId: item.childPartyId,
          appointmentChildPartyId: item.id,
          variableAttendance: item.variableAttendance,
          attorneyRoleId: self.caseTypeAttorneyRoles.lookup(item.latestCaseTypeAttorneyRoleId).attorneyRoleId,
        });
      }
      return childParties;
    }

    function getActivityChildPartiesFromAttendance() {
      if (!self.activity.attendance || !self.activity.attendance[0]) {
        return self.activity.attendance;
      }
      const childParties = [];
      for (const item of self.activity.attendance) {
        if (item) {
          const originalChildParty = self.originalActivityChildParties.lookup(
            item.childPartyId, 'childPartyId');
            if (!originalChildParty) {
              childParties.push({
                activityId: self.activity.id,
                childPartyId: item.childPartyId,
                appointmentChildPartyId: item.id,
                attorneyRoleId: self.caseTypeAttorneyRoles.lookup(item.latestCaseTypeAttorneyRoleId).attorneyRoleId,
              });
            }
        }
      }
      for (const item of self.originalActivityChildParties) {
        if (item) {
          const currentChildParty = self.activity.attendance.lookup(item.childPartyId,
            'childPartyId');
            if (!currentChildParty) {
              childParties.push({
                activityId: self.activity.id,
                childPartyId: item.childPartyId,
                appointmentChildPartyId: item.id,
                deleted: true,
              });
            }
        }
      }

      return childParties;
    }

    function getActivityChildParties() {
      if (self.selectedActivityType.variableAttendance) {
        return getActivityChildPartiesFromVariable();
      }
      if (self.selectedActivityType.requiresAttendance) {
        return getActivityChildPartiesFromAttendance();
      }
      const childParties = [];
      if (!self.requiresAttendance() && !self.variableAttendance()) {
        for (const item of self.originalActivityChildParties) {
          childParties.push({
            activityId: self.activity.id,
            childPartyId: item.childPartyId,
            appointmentChildPartyId: item.id,
            deleted: true,
          });
        }
      }
      return childParties;
    }

    function getHearingTypes() {
      const hearingTypes = [];
      for (const hearingTypeId of self.selectedHearingTypes) {
        hearingTypes.push({
          appointmentActivityId: self.activity.id,
          hearingTypeId: hearingTypeId,
        });
      }
      return hearingTypes;
    }

    function copyOverIds(request, response) {
      self.activity.id = response.activity.id;
      for (let i = 0; i < response.billableItems.length; i++) {
        request.billableItems[i].id = response.billableItems[i].id;
      }
      for (let i = 0; i < response.childParties.length; i++) {
        if (request.childParties[i].deleted) {
          continue;
        }
        request.childParties[i].id = response.childParties[i].id;
      }
      for (let i = 0; i < response.notes.length; i++) {
        request.notes[i].isNew = undefined;
        request.notes[i].id = response.notes[i].id;
      }

      self.activity.childParties = self.activity.attendance;
      self.originalActivityChildParties = OcrLookups.createLookupList(
        angular.copy(self.activity.childParties));
    }


    //TODO this gets the promise, not set it
    self.setPromise = () => self.promise;

    self.getUserFullName = OcrUtils.getUserFullName;
    self.age = OcrUtils.age;
    self.filterChildParties = function(value) {
      if (!angular.isUndefined(value.endDate)) {
        for (let i = 0; i < self.activity.attendance.length; i++) {
          if (self.activity.attendance[i].childPartyId == value.childPartyId) {
            return true;
          }
        }
        return false;
      }
      return true;
    };

    self.loadPersonaBillingRates = function() {

      if (!self.activity.date) {
        return $q.when();
      }

      return PersonaBillingRateService.get({
        persona_id: self.activity.user.personaId,
        limit: 1000,
        order: "-start_date",
      }).$promise
        .then(function(resp) {
          personaBillingRates = resp.list;
          if (!self.activity.personaBillingRate) {
            self.activity.personaBillingRate = {};
          }

          self.activity.personaBillingRate.billingRateCents
            = getEffectivePersonaBillingRate(personaBillingRates);
        });
    };

    self.getBillableItemByBillableItemTypeId =
      (id) => OcrUtils.getArrayElementById(self.activity.billableItems, "billableItemTypeId", id);

    self.getBillableItemTypeById = (id) => self.billableItemTypes.lookup(id);
    self.getActivityType = (id) => self.activityTypes.lookup(id);

    self.activityTypeChanged = function() {
      // activity's billable attribute is, by default, the activity type's billable attribute.
      // user can make a billable activity non-billable voluntarily. not visa-versa.
      self.selectedActivityType = self.getActivityType(self.activity
        .typeId);
      if (!self.selectedActivityType) {
        return;
      }

      if (self.isNew) {
        self.activity.billable = self.selectedActivityType.billable;
      }
      self.resetValues();
      $scope.$watch(
        () => self.addActivityForm.$invalid,
        function() {
          if (self.addActivityForm.$invalid === true && self.addActivityForm.$error.length > 0) {
            $scope.$watch(
              () => self.addActivityForm.$error.required.length,
              function() {
                angular.forEach(self.addActivityForm.$error, function(field) {
                  angular.forEach(field, function(errorField) {
                    errorField.$setTouched();
                  });
                });
              }, true);
          }
        }, true);
    };

    self.promptFor = function(fieldName) {
      if (!self.selectedActivityType) {
        return false;
      }
      return self.selectedActivityType[`${fieldName}Prompt`];
    };

    self.promptForDuration = () => self.promptFor('activityDurationTime');
    self.promptForTravelTime = () => self.promptFor('travelTime');
    self.promptForWaitTime = () => self.promptFor('waitTime');
    self.promptForDiscussionTime = () => self.promptFor('discussionTime');
    self.promptForMileageQuantity = () => self.promptFor('mileageAmount');
    self.promptForCopiesQuantity = () => self.promptFor('copyQuantity');
    self.promptForChildMealAmount = () => self.promptFor('mealAmount');
    self.promptForExpenseAmount = () => self.promptFor('expenseAmount');
    self.promptForHearingType = () => self.promptFor('hearingType');
    self.promptForPostageAmount = () => self.promptFor('postageAmount');
    self.promptForDiscoveryAmount = () => self.promptFor('discoveryAmount');
    self.promptForFilingFeeAmount = () => self.promptFor('filingFeeAmount');
    self.promptForTranscriptAmount = () => self.promptFor('transcriptAmount');

    self.timePrompts =
      () => self.promptForDuration()
        || self.promptForTravelTime()
        || self.promptForWaitTime();

    self.quantityPrompts = () => self.promptForMileageQuantity() || self.promptForCopiesQuantity();

    self.amountPrompts =
      () => self.promptForChildMealAmount()
        || self.promptForExpenseAmount()
        || self.promptForPostageAmount()
        || self.promptForDiscoveryAmount()
        || self.promptForFilingFeeAmount()
        || self.promptForTranscriptAmount();

    self.resetValues = function() {
      if (!self.promptForChildMealAmount()) {
        self.activity.childMealAmount = 0;
      }
      if (!self.promptForCopiesQuantity()) {
        self.activity.copiesQuantity = 0;
      }
      if (!self.promptForDuration()) {
        self.activity.duration = 0;
      }
      if (!self.promptForExpenseAmount()) {
        self.activity.expenseAmount = 0;
      }
      if (!self.promptForMileageQuantity()) {
        self.activity.mileageQuantity = 0;
      }
      if (!self.promptForTravelTime()) {
        self.activity.travelTime = 0;
      }
      if (!self.promptForWaitTime()) {
        self.activity.waitTime = 0;
      }
      if (!self.promptForDiscussionTime()) {
        self.activity.discussionTime = 0;
      }
      if (!self.promptForPostageAmount()) {
        self.activity.postageAmount = 0;
      }
      if (!self.promptForDiscoveryAmount()) {
        self.activity.discoveryAmount = 0;
      }
      if (!self.promptForFilingFeeAmount()) {
        self.activity.filingFeeAmount = 0;
      }
      if (!self.promptForTranscriptAmount()) {
        self.activity.transcriptAmount = 0;
      }
    };

    self.isActivityTypeBillable =
      () => (self.selectedActivityType ? self.selectedActivityType.billable : false);

    self.requiresAttendance =
      () => (self.selectedActivityType ? self.selectedActivityType.requiresAttendance : false);

    self.variableAttendance =
      () => (self.selectedActivityType ? self.selectedActivityType.variableAttendance : false);


    self.addNote = function() {
      $mdDialog.show({
        controller: 'AddNoteDialogController',
        templateUrl: 'tmpl/add-note-dialog.html',
        parent: angular.element($document[0].body),
        locals: {
          user: OcrAuth.session.user,
          appointmentActivityId: self.activity.id,
          activityStartDate: self.activity.date,
          note: undefined,
        },
        bindToController: true,
        controllerAs: '$ctrl',
        clickOutsideToClose: false,
        fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
        multiple: true, // to allow nested dialog boxes!
      })
        .then(function(newNote) {
          newNote.isNew = true;
          self.activity.notes.push(newNote);
        }, function() {});
    };

    self.editNote = function() {
      $mdDialog.show({
        controller: 'AddNoteDialogController',
        templateUrl: 'tmpl/add-note-dialog.html',
        parent: angular.element($document[0].body),
        locals: {
          user: OcrAuth.session.user,
          appointmentActivityId: self.activity.id,
          note: self.selectedNote[0],
          readOnly: false,
        },
        bindToController: true,
        controllerAs: '$ctrl',
        clickOutsideToClose: false,
        fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
        multiple: true, // to allow nested dialog boxes!
      })
        .then(function(newNote) {
          newNote.id = self.selectedNote[0].id;
          newNote.userId = self.selectedNote[0].userId;
          for (let i = 0; i < self.activity.notes.length; i++) {
            const listNote = self.activity.notes[i]
            if (newNote.id === listNote.id) {
              self.activity.notes.splice(i, 1, newNote);
              break;
            }
          }
          newNote.isNew = true;
          self.selectedNote.splice(0, self.selectedNote.length);
        }, () => {});
    };

    self.deleteAttachment = function() {
      const message
        = "Are you sure that you want to delete the selected activity attachment?";
      $mdDialog.show(
        $mdDialog.confirm()
          .parent(angular.element($document[0].querySelector(
            '#popupContainer')))
          .clickOutsideToClose(false)
          .title('Confirm Activity Delete')
          .textContent(message)
          .ariaLabel(message)
          .ok('Delete Activity')
          .cancel('Cancel')
          .multiple(true), // to allow nested dialog boxes!
      ).then(function() {
        // ok
        const index = self.activity.attachments.indexOf(self.selectedAttachments[
          0]);
        self.activity.attachments.splice(index, 1);
        self.selectedAttachments.length = 0;
      }, function() {
        // cancel
        // do nothing
      });
    };

    self.updateBillableItem = function(billableItemTypeId) {
      let billableItem = self.getBillableItemByBillableItemTypeId(
        billableItemTypeId);

      if (!billableItem) {
        billableItem = {
          billableItemTypeId: billableItemTypeId,
        };
        self.activity.billableItems.push(billableItem);
      }

      const billingPercent = self.activity.billingPercent / 100;

      const standardRateMillis = self.getEffectiveBillableItemTypeStandardRate(
        billableItemTypeId);

      switch (billableItemTypeId) {
      case 1: // duration (hours)
        billableItem.amountCents = Math.round(self.activity.effectiveBillingRateCents
            * self.activity.duration);
        billableItem.units = Math.round(self.activity.duration * 60); // convert to minutes
        break;
      case 2: // travel time
        billableItem.amountCents = Math.round(self.activity.effectiveBillingRateCents
            * self.activity.travelTime);
        billableItem.units = Math.round(self.activity.travelTime * 60); // convert to minutes
        break;
      case 3: // wait time
        billableItem.amountCents = Math.round(self.activity.effectiveBillingRateCents
            * self.activity.waitTime);
        billableItem.units = Math.round(self.activity.waitTime * 60); // convert to minutes
        break;
      case 4: // mileage quantity
        billableItem.amountCents = Math.round((standardRateMillis
            / 10.0) * self.activity.mileageQuantity * billingPercent);
        billableItem.units = self.activity.mileageQuantity;
        break;
      case 5: // copies quantity
        billableItem.amountCents = Math.round((standardRateMillis
            / 10.0) * self.activity.copiesQuantity * billingPercent);
        billableItem.units = self.activity.copiesQuantity;
        break;
      case 6: // child meal amount
        billableItem.amountCents = Math.round(self.activity.childMealAmount
            * 100 * billingPercent);
        billableItem.units = 1;
        billableItem.unadjustedAmountCents = Math.round(self.activity.childMealAmount
            * 100);
        break;
      case 7: // expense amount
        billableItem.amountCents = Math.round(self.activity.expenseAmount
            * 100 * billingPercent);
        billableItem.unadjustedAmountCents = Math.round(self.activity.expenseAmount
            * 100);
        billableItem.units = 1;
        break;
      case 8: // postage amount
        billableItem.amountCents = Math.round(self.activity.postageAmount
            * 100 * billingPercent);
        billableItem.unadjustedAmountCents = Math.round(self.activity.postageAmount
            * 100);
        billableItem.units = 1;
        break;
      case 9: // discovery amount
        billableItem.amountCents = Math.round(self.activity.discoveryAmount
            * 100 * billingPercent);
        billableItem.unadjustedAmountCents = Math.round(self.activity.discoveryAmount
            * 100);
        billableItem.units = 1;
        break;
      case 10: // filing fee amount
        billableItem.amountCents = Math.round(self.activity.filingFeeAmount
            * 100 * billingPercent);
        billableItem.unadjustedAmountCents = Math.round(self.activity.filingFeeAmount
            * 100);
        billableItem.units = 1;
        break;
      case 11: // transcript amount
        billableItem.amountCents = Math.round(self.activity.transcriptAmount
            * 100 * billingPercent);
        billableItem.unadjustedAmountCents = Math.round(self.activity.transcriptAmount
            * 100);
        billableItem.units = 1;
        break;
      case 12: // discussion time
        billableItem.amountCents = Math.round(self.activity.effectiveBillingRateCents
            * self.activity.discussionTime);
        billableItem.units = Math.round(self.activity.discussionTime * 60); // convert to minutes
        break;
      }
    };

    self.shouldDisableSave =
      () => self.submitting
        || self.addActivityForm.$invalid
        || (!self.activity.billable && !self.confirmNonBillable)
        || (self.requiresAttendance() && self.activity.attendance.length === 0)
        || !self.activity.personaBillingRate
        || self.disableFromChild();

    self.disableFromChild = function() {
      if (self.selectedActivityType.variableAttendance) {
        if (self.variableChildParties.length < 1) {
          return true;
        }
        for (const item of self.variableChildParties) {
          if ([1,2,3].indexOf(item.variableAttendance) >= 0) {
            return false;
          }
        }
        return true;
      }
      return false;
    }

    self.hide = function(response) {
      if (changeActivityTypeBackToInactive) {
        originalActivityType.statusId = 2;
      }
      $mdDialog.hide(response);
    };

    self.cancel = function() {
      if (changeActivityTypeBackToInactive) {
        originalActivityType.statusId = 2;
      }
      $mdDialog.cancel(shouldRefreshData);
    };

    self.getEffectiveBillableItemTypeStandardRate = function( billableItemTypeId) {

      if (!self.activity.standardRates || !self.activity.date) {
        return null;
      }
      for (let c = 0; c < self.activity.standardRates.length; c++) {

        if (self.activity.standardRates[c].billableItemTypeId !== billableItemTypeId) {
          continue;
        }

        let rateStartDate = new Date(self.activity.standardRates[c].startDate).setHours(0, 0);
        let activityDate = self.activity.date.setHours(0,0);
        let rateEndDate = self.activity.standardRates[c].endDate ?
          new Date(self.activity.standardRates[c].endDate).setHours(0, 0): undefined;

        if (rateStartDate > activityDate) {
          continue;
        }

        if (rateEndDate && rateEndDate <= activityDate) {
          continue;
        }

        return self.activity.standardRates[c].standardRateMillis;

      }

      return null;
    };

    self.submit = function(close) {
      if (self.activity.billable && !self.checkValues()) {
        const message
          = "This item is billable but does not have a billable amount. "
          + "Click OK to go back and add at least one amount.";
        $mdDialog.show(
          $mdDialog.alert()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Activity Save')
            .textContent(message)
            .ariaLabel(message)
            .ok('OK')
            .multiple(true),
        );
      } else {
        self.doSave(close);
      }
    };

    self.doSave = function(close) {

      self.submitting = true;
      shouldRefreshData = true;

      self.activity.billingPercent = 100;

      if (self.billingPercents && self.billingPercents.length > 0) {
        self.activity.billingPercent =
          self.billingPercents[self.billingPercents.length - 1].percent;
      }

      self.activity.effectiveBillingRateCents = (self.activity.personaBillingRate
        .billingRateCents
        * self.activity.billingPercent) / 100;

      for (let i = 1; i <= 12; i++) {
        self.updateBillableItem(i);
      }

      let totalAmountCents = 0;
      let totalUnits = 0;

      for (let i = 0; i < 12; i++) {
        const item = self.activity.billableItems[i];
        if (item.billableItemTypeId <= 3) { // only hours-based billable items
          totalAmountCents += isNaN(item.amountCents) ? 0 : item.amountCents;
          totalUnits += isNaN(item.units) ? 0 : item.units;
        }
      }

      self.activity.totalAmountCents = totalAmountCents;
      self.activity.totalUnits = totalUnits;

      const request = {
        activity: OcrUtils.makeVeryShallowCopy(self.activity),
        billableItems: self.activity.billableItems,
        childParties: getActivityChildParties(),
        hearingTypes: getHearingTypes(),
      };
      if (self.isNew) {
        request.notes = self.activity.notes;
        self.promise = AppointmentActivityService.save(request)
          .$promise;
        self.promise.then(function(response) {
          copyOverIds(request, response);
          self.isNew = false;

          $mdToast.show(
            $mdToast.simple()
              .textContent("Appointment activity created.")
              .position("bottom right"),
          );
          if (close) {
            self.hide(response);
          }
        })
          .catch(function(error) {
            if (error && error.data) {
              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            }
          }).finally(() => self.submitting = false);
      } else {
        const newNotes = [];
        for (const note of self.activity.notes) {
          if (note.isNew) {
            newNotes.push(note);
          }
        }
        request.notes = newNotes;
        self.promise = AppointmentActivityService.update({
          id: self.activity.id,
        }, request).$promise;

        self.promise.then(function(response) {
          $mdToast.show(
            $mdToast.simple()
              .textContent("Appointment activity updated.")
              .position("bottom right"),
          );
          copyOverIds(request, response);
          if (close) {
            self.hide(response);
          }
        })
          .catch(function(error) {
            if (error && error.data) {
              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            }
          }).finally(() => self.submitting = false);
      }
    };

    self.checkValues =
      () => self.activity.duration > 0
        || self.activity.travelTime > 0
        || self.activity.waitTime > 0
        || self.activity.mileageQuantity > 0
        || self.activity.copiesQuantity > 0
        || self.activity.childMealAmount > 0
        || self.activity.expenseAmount > 0
        || self.activity.postageAmount > 0
        || self.activity.discoveryAmount > 0
        || self.activity.filingFeeAmount > 0
        || self.activity.transcriptAmount > 0
        || self.activity.discussionTime > 0;

    self.toggleHearingType = function(typeId) {
      const idx = self.selectedHearingTypes.indexOf(typeId);

      if (idx >= 0) {
        self.selectedHearingTypes.splice(idx, 1);
      } else {
        self.selectedHearingTypes.push(typeId);
      }
    };
  },
]);
