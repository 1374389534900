angular.module('CaresApp').component('ocrMyPlacement', {
  templateUrl: 'components/ocr-my-placement/ocr-my-placement.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    '$document',
    '$mdDialog',
    '$mdToast',
    'SavedPlacementService',
    'OcrAuth',
    'OcrUtils',
    'OcrLookups',
    function($scope, $document, $mdDialog, $mdToast, SavedPlacementService, OcrAuth, OcrUtils,
      OcrLookups,
    ) {
      $scope.$emit("SetSearchBox", true);
      const self = this;
      OcrUtils.setPageTitle("My Placements")
      self.selected = [];

      self.placementTypes = OcrLookups.dummyList;
      OcrLookups.get('PlacementType').then(
        (placementTypes) => self.placementTypes = placementTypes,
      );

      self.results = {
        data: [],
        count: 0,
        total: 0,
      };
      self.promise = null;
      $scope.$watch(() => self.promise, () => {
        self.setPromise()(self.promise)
      })

      self.updateData = function() {
        const call = {
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
          order: self.query.order,
          userId: OcrAuth.session.userId,
        }

        if (self.searchQuery) {
          call.query = self.searchQuery;
        }

        self.promise = SavedPlacementService.query(call).$promise.then(
          function(resp) {
            self.results.data = resp.list;
            self.results.count = resp.count;
            self.results.total = resp.total;
          },
        ).finally(() => self.promise = null)
      }

      self.$onInit = function() {
        self.updateData();
      }

      $scope.$on("Searching", function(_, data) {
        self.searchQuery = data;
        self.updateData();
      });

      self.addPlacement = function() {
        $mdDialog.show({
          controller: 'AddSavedPlacementDialogController',
          templateUrl: 'tmpl/add-saved-placement-dialog.html',
          parent: angular.element($document[0].body),
          locals: {
            add: true,
            placement: undefined,
          },
          clickOutsideToClose: false,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
          .then(function(placement) {
            self.updateData();
          }, function() {
          });
      };

      self.editPlacement = function() {
        $mdDialog.show({
          controller: 'AddSavedPlacementDialogController',
          templateUrl: 'tmpl/add-saved-placement-dialog.html',
          parent: angular.element($document[0].body),
          locals: {
            add: false,
            placement: self.selected[0],
          },
          clickOutsideToClose: false,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
          .then(function(placement) {
            Object.assign(self.selected[0], placement); // copy attributes to selected placement
            self.selected.length = 0;
            OcrAuth.session.placements = undefined;
          }, function() {
          });
      };

      self.deletePlacement = function() {
        const message
          = "Are you sure that you want to delete the selected placement?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Placement Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete Placement')
            .cancel('Cancel'),
        ).then(function() {
          // ok
          SavedPlacementService.delete({
            id: self.selected[0].id,
          }, self.selected[0]).$promise.then(function() {
            $mdToast.show(
              $mdToast.simple()
                .textContent('Placement deleted.')
                .position("bottom right"),
            );
            OcrAuth.session.placements = undefined;
            const index = self.results.data.indexOf(self.selected[
              0]);
            if (index >= 0) {
              self.results.data.splice(index, 1);
              self.selected.length = 0;
            }
          })
            .catch(function(error) {
              if (error && error.data) {
                $mdToast.show(
                  $mdToast.simple()
                    .textContent(error.data.reason)
                    .position("bottom right"),
                );
              }
            });
        }, () => {});
      };

      self.getFullAddress = function(placement) {
        if (placement.fullAddress) {
          return placement.fullAddress;
        }
        let address = "";
        if (placement.address1) {
          address += placement.address1;
        }
        if (placement.address1 && placement.address2) {
          address += ", ";
        }
        if (placement.address2) {
          address += placement.address2;
        }
        if (placement.address1 || placement.address2) {
          address += ", ";
        }
        let city = false;
        let state = false;
        if (placement.city) {
          city = true;
          address += placement.city;
        }
        if (placement.state) {
          state = true;
          if (city) {
            address += ", ";
          }
          address += placement.state;
        }
        if (placement.zip) {
          if (state) {
            address += " ";
          }
          address += placement.zip;
        }
        placement.fullAddress = address;
        return address;
      };

      self.reorder = function() {
        self.updateData();
      };
    },
  ],
});
