angular.module('CaresApp').component('ocrDashboard', {
  templateUrl: 'components/ocr-dashboard/ocr-dashboard.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    '$document',
    '$location',
    '$window',
    '$http',
    '$q',
    'AppointmentService',
    'InvoiceService',
    'OcrAuth',
    'OcrUtils',
    'OcrLookups',
    'ExcessFeesRequestService',
    'OtherExpenseRequestService',
    '$mdDialog',
    'UpcomingActivityService',
    'AnnouncementService',
    'RoleChangeAlertService',
    function($scope, $document, $location, $window, $http, $q, AppointmentService,
      InvoiceService, OcrAuth, OcrUtils, OcrLookups,
      ExcessFeesRequestService, OtherExpenseRequestService, $mdDialog,
      UpcomingActivityService, AnnouncementService, RoleChangeAlertService) {
      const self = this;
      OcrUtils.setPageTitle('Home');
      self.today = new Date();

      // Google Charts used in home page
      google.charts.load('current', { 'packages': ['corechart'] });

      self.announcements = [];
      self.announcementPage = 1;
      self.alerts = [];
      self.currentAlerts = [];
      self.userAppointments = [];
      self.currentLoad = 0;
      self.currentChildren = 0;
      self.results = {
        data: [],
        count: 0,
        total: 0,
      };
      self.promise = null;

      const months = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      self.resetSearch = function() {
        OcrLookups.resetSearch();
        $mdDialog.show(
          $mdDialog.alert()
            .title('Alert')
            .textContent('Full-text search reindex request submitted.')
            .ok('Close'),
        );
      };

      self.canResetSearch = OcrAuth.checkPermission("SEARCH_REINDEX");

      self.$onInit = () => $scope.$watch(() => self.promise, () => self.setPromise()(self.promise));

      self.pageAnnouncement = function() {
        self.promise = AnnouncementService.query({
          skip: (self.announcementPage - 1) * 5,
          limit: 5,
        }).$promise.then(
          function(resp) {
            self.announcements.data = resp.list;
            self.announcements.count = resp.count;
            self.announcements.total = resp.total;
          },
        ).finally(() => self.promise = null)
      };

      if (!self.dashboardTotals) {
        $http.get("/api/dashboardTotals")
          .then(function(response) {
            self.dashboardTotals = response.data;
            self.dashboardTotals.appointmentTotals.reverse();
            google.charts.setOnLoadCallback(drawCasesChart);
            google.charts.setOnLoadCallback(drawInvoicesChart);
          })
          .catch((error) => console.error("Unable to get dashboard data: %O", error));
      }
      if (self.announcements.length === 0) {
        self.pageAnnouncement();
      }
      if (self.alerts.length === 0) {
        const warningQuery = {
          inOffice: true,
          nearMax: true,
          showActive: true,
          showInactive: false,
          mineOnly: false,
          bookmark: false
        };

        const tempAlerts = [];
        const promises = [];

        promises.push(RoleChangeAlertService.get()
          .$promise.then(function(response) {
            for (arAlert of response.additionalReminders) {
              arAlert.type = "ar";
              const data = arAlert;
              const description = `Age Reminder for Case: ${arAlert.abbreviatedCaseNumber}`;
              tempAlerts.push({ description, data });
            }
            for (cwAlert of response.roleChangeWarnings) {
              cwAlert.type = "cw";
              const data = cwAlert;
              const description = `Role Change Warning for Case: ${cwAlert.abbreviatedCaseNumber}`;
              tempAlerts.push({ description, data });
            }
            for (rcAlert of response.roleChanges) {
              rcAlert.type = "rc";
              const data = rcAlert;
              const description = `Role Change for Case: ${rcAlert.abbreviatedCaseNumber}`;
              tempAlerts.push({ description, data });
            }
          }));

        if (OcrAuth.checkPermission("ALLOCATION_WARNINGS_READ")) {
          promises.push(AppointmentService.get(
            warningQuery)
            .$promise.then(function(response) {
              for (const appointment of response.list) {
                if (appointment.officeId === OcrAuth.session.user.officeId) {
                  const url = `/#!/appointment/my_appointments/${appointment.id}`;
                  const description = `${appointment.abbreviatedCaseNumber
                  }: Check maximum billing allocation.`;
                  tempAlerts.push({ url, description });
                }
              }
            }));
        }

        if (OcrAuth.checkPermission("INVOICE_WRITE")) {
          promises.push(InvoiceService.query({
            limit: 1000,
            sid: 2,
          }).$promise.then(function(response) {
            for (const invoice of response.list) {
              if (invoice.officeId === OcrAuth.session.user.officeId) {
                const url = `/#!/invoice/invoices/${invoice.invoiceNumber}`;
                const description
                  = `Invoice Rejected by Billing Manager: ${invoice.invoiceNumber}`;
                tempAlerts.push({
                  url, description,
                });
              }
            }
          }));
        }

        promises.push(InvoiceService.query({
          limit: 1000,
          sid: 3,
        }).$promise.then(function(response) {
          for (const invoice of response.list) {
            if (invoice.officeId === OcrAuth.session.user.officeId) {
              const url = `/#!/invoice/invoices/${invoice.invoiceNumber}`;
              const description = `Invoice Rejected by Finance: ${invoice.invoiceNumber}`;
              tempAlerts.push({
                url, description,
              });
            }
          }
        }));

        if (OcrAuth.checkPermission("BILLING_REQUEST_CREATE") || OcrAuth.checkPermission("BILLING_REQUEST_CREATE_OFFICEWIDE")) {
          promises.push(ExcessFeesRequestService.query({
            limit: 1000,
          }).$promise.then(function(response) {
            for (const additionalFunds of response.list) {
              if (additionalFunds.officeId === OcrAuth.session.user.officeId) {
                if (additionalFunds.excessFeesRequestStatusId === 4) {
                  const url = `/#!/additional_funds/additional_funds/${additionalFunds.id}`;
                  const description = `Funds Request Rejected: ${additionalFunds
                    .abbreviatedCaseNumber}`;
                  tempAlerts.push({ url, description });
                } else if (additionalFunds.excessFeesRequestStatusId === 3) {
                  const url = `/#!/additional_funds/additional_funds/${additionalFunds.id}`;
                  const description = `Funds Request Approved: ${additionalFunds
                    .abbreviatedCaseNumber}`;
                  tempAlerts.push({ url, description });
                } else if (additionalFunds.excessFeesRequestStatusId === 2) {
                  const url = `/#!/additional_funds/additional_funds/${additionalFunds.id}`;
                  const description = `Funds Request Requires Update: ${additionalFunds
                    .abbreviatedCaseNumber}`;
                  tempAlerts.push({ url, description });
                }
              }
            }
          }));
        }

        if (OcrAuth.checkPermission("EXPENSE_REQUEST_CREATE")) {
          promises.push(OtherExpenseRequestService.query({
            limit: 1000,
          }).$promise.then(function(response) {
            for (const otherApproval of response.list) {
              if (otherApproval.officeId === OcrAuth.session.user.officeId) {
                if (otherApproval.requestStatusId === 4) {
                  const url = `/#!/other_approvals/other_approvals/${otherApproval.id}`;
                  const description = `Other Approval Request Rejected: ${otherApproval
                    .abbreviatedCaseNumber}`;
                  tempAlerts.push({
                    url, description,
                  });
                } else if (otherApproval.requestStatusId === 3) {
                  const url = `/#!/other_approvals/other_approvals/${otherApproval.id}`;
                  const description = `Other Approval Approved: ${otherApproval
                    .abbreviatedCaseNumber}`;
                  tempAlerts.push({
                    url, description,
                  });
                }
              }
            }
          }));
        }

        if (OcrAuth.checkPermission("UPCOMING_WRITE")
            && OcrAuth.session.user.upcomingNotifications) {
          promises.push(UpcomingActivityService.query({
            limit: 1000,
            notif: true,
          }).$promise.then(function(response) {
            for (const upcomingActivity of response.list) {
              if (moment(new Date(upcomingActivity.start)).diff(moment(self.today), 'hours') < 0) {
                const url = `/#!/appointment/my_appointments/${
                  upcomingActivity.appointmentId
                }?uaid=${upcomingActivity.id
                }&start=${upcomingActivity.start
                }&groupId=${upcomingActivity.groupId
                }&typeId=${upcomingActivity.typeId
                }&billable=${upcomingActivity.billable
                }#activities`;
                const description = `Upcoming Activity Today: ${upcomingActivity.title}`;
                tempAlerts.push({
                  url, description,
                });
              } else {
                const url = "/#!/views/upcoming_activities/";
                const description = `Upcoming Activity: ${upcomingActivity.title}`;
                tempAlerts.push({
                  url, description,
                });
              }
            }
          }));
        }

        promises.push(AppointmentService.get({
          inOffice: true,
          showActive: true,
          showInactive: false,
          mineOnly: false,
          bookmark: false
        }).$promise.then(function(response) {
            for (const appointment of response.list) {
              if (appointment.hasPendingRequest && appointment.officeId == OcrAuth.session.user.officeId) {
                const url = "#!/appointment/my_appointments/" + appointment.id + "#officeAccess";
                const description = `${appointment.abbreviatedCaseNumber}: Pending request for access`
                tempAlerts.push({
                  url, description,
                });
              }
            }
          }));

        promises.push(AppointmentService.query({
          inOffice: true,
          showActive: true,
          mineOnly: true,
          bookmark: false,
          limit: 1000,
          order: 'appointment.appointment_id',
        }).$promise.then((response) => self.userAppointments = response));
        self.promise = $q.all(promises).then(() => {
          if (tempAlerts.length > 0) {
            self.alerts = tempAlerts;
          } else {
            self.alerts = [];
            self.alerts.push({
              url: null,
              description: "No Alerts",
            });
          }

          self.pageAlerts();
        }).finally(() => self.promise = undefined);
      }

      $window.addEventListener('resize', onResize, true);

      function onResize() {
        // refresh dashboard charts
        google.charts.setOnLoadCallback(drawCasesChart);
        google.charts.setOnLoadCallback(drawInvoicesChart);
      }
      self.alertPage = 1;

      self.pageAlerts = function(page) {
        let counter = 5 * (page - 1);
        const countTo = 5 * page;
        self.currentAlerts = [];
        for (counter; counter < countTo; counter++) {
          if (counter < self.alerts.length) {
            self.currentAlerts.push(self.alerts[counter]);
          }
        }
      };

      self.showAlert = function(alert) {
        $mdDialog.show({
          controller: 'HomeAlertDialogController',
          templateUrl: 'components/ocr-dashboard/home-alert-dialog.template.html',
          parent: angular.element($document[0].body),
          locals: {
            alertInfo: alert.data
          },
          bindToController: true,
          controllerAs: '$ctrl',
          multiple: true,
          clickOutsideToClose: true,
        }).then(function(response) {
          if (response){
            for (let i = 0; i < self.currentAlerts.length; i++){
              if (angular.isDefined(self.currentAlerts[i].data)){
                if (self.alerts[i].data.appointmentId == alert.data.appointmentId && self.alerts[i].data.appointmentChildPartyId == alert.data.appointmentChildPartyId
                  && self.alerts[i].data.type == alert.data.type && self.alerts[i].data.alertDate == alert.data.alertDate) {
                  self.alerts.splice(i, 1);
                  self.pageAlerts();
                  return;
                }
              }
            }
          }
        });
      }

      self.showAnnouncement = (announcement) => $mdDialog.show({
        controller: 'AnnouncementDialogController',
        templateUrl: 'components/ocr-dashboard/announcement-dialog.template.html',
        parent: angular.element($document[0].body),
        locals: { announcement: announcement },
        bindToController: true,
        controllerAs: '$ctrl',
        multiple: true,
        clickOutsideToClose: true,
      });

      function drawInvoicesChart() {
        if (!self.dashboardTotals || !self.dashboardTotals.invoiceTotals
          || self.dashboardTotals.invoiceTotals.length === 0) {
          return;
        }

        const element = $document[0].getElementById('chart_div_2');
        if (!element) {
          return;
        }

        const resultsList = self.dashboardTotals.invoiceTotals;

        const a = [['Month', 'Invoiced', 'Paid']];
        for (let i = 0; i < resultsList.length; i++) {
          a.push([
            `${resultsList[i].year} ${months[resultsList[i].month]}`,
            resultsList[i].amountInvoicedCents / 100.0,
            resultsList[i]
              .amountPaidCents
            / 100.0,
          ]);
        }

        const data = google.visualization.arrayToDataTable(a);

        const options = {
          title: 'Invoices',
          chartArea: {
            width: "60%",
            height: "50%",
          },
          hAxis: {
            title: 'Month',
            titleTextStyle: {
              color: '#333',
            },
          },
          vAxis: {
            minValue: 0,
            format: 'currency',
          },
        };

        const chart = new google.visualization.AreaChart(element);
        chart.draw(data, options);
      }

      function drawCasesChart() {
        if (!self.dashboardTotals || !self.dashboardTotals.appointmentTotals
          || self.dashboardTotals.appointmentTotals.length === 0) {
          return;
        }

        const resultsList = self.dashboardTotals.appointmentTotals;

        const element = $document[0].getElementById('chart_div_1');
        if (!element) {
          return;
        }

        const a = [['Month', 'Appointments', 'Children']];
        for (let i = 0; i < resultsList.length; i++) {
          a.push([
            `${resultsList[i].year} ${months[resultsList[i].month]}`,
            resultsList[i].appointments,
            resultsList[i].childParties,
          ]);
        }

        const data = google.visualization.arrayToDataTable(a);

        const options = {
          title: 'Appointments',
          chartArea: {
            width: "60%",
            height: "50%",
          },
          hAxis: {
            title: 'Month',
            titleTextStyle: {
              color: '#333',
            },
          },
          vAxis: {
            minValue: 0,
          },
        };

        const chart = new google.visualization.AreaChart(element);
        chart.draw(data, options);
      }

      self.enterActivities = function() {
        if (self.selectedAppointmentId) {
          $location.hash("activities");
          $location.path(`/appointment/my_appointments/${self.selectedAppointmentId}`);
        } else {
          $location.path('views/my_appointments');
        }
      }
    },
  ],
});
