angular.module('CaresApp').component('ocrUrlLinkTextList', {
  templateUrl: 'components/ocr-url-link-text-list/ocr-url-link-text-list.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    'UrlLinkTextService',
    'OcrUtils',
    'OcrViews',
    'OcrAuth',
    function($scope, UrlLinkTextService, OcrUtils, OcrViews, OcrAuth) {
      const self = this;
      OcrUtils.setPageTitle('External Links');
      self.currentView = OcrViews.currentView;
      self.hasPermission = OcrAuth.checkPermission;

      let currentSearch = undefined;

      self.results = {
        data: [],
        count: 0,
        total: 0,
      };
      self.promise = null;
      $scope.$watch(() => self.promise, () => {
        self.setPromise()(self.promise)
      })

      self.refresh = () => self.updateData();

      self.updateData = function() {
        self.promise = UrlLinkTextService.query({
          query: currentSearch,
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
        }).$promise.then(
          function(resp) {
            self.results.data = resp.list;
            self.results.count = resp.count;
            self.results.total = resp.total;
          },
        ).finally(() => self.promise = null)
      }

      self.$onInit = function() {
        self.updateData()
      }

      $scope.$on("Searching", function(_, data) {
        const call = {
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
        }

        if (data) {
          currentSearch = data;
          call.query = data;
        } else {
          currentSearch = undefined;
        }

        self.promise = UrlLinkText.query(call).$promise.then(
          function(resp) {
            self.results.data = resp.list;
            self.results.count = resp.count;
            self.results.total = resp.total;
          },
        ).finally(() => self.promise = null)
      });
    },
  ],
});
