angular.module('CaresApp').component('ocrHelpArticle', {
  templateUrl: 'components/ocr-help-article/ocr-help-article.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    '$document',
    '$location',
    '$window',
    '$mdMedia',
    '$mdToast',
    '$routeParams',
    '$mdDialog',
    '$timeout',
    'HelpArticleService',
    'OcrAuth',
    'OcrLookups',
    'OcrUtils',
    function($scope, $document, $location, $window, $mdMedia, $mdToast, $routeParams, $mdDialog,
      $timeout, HelpArticleService, OcrAuth, OcrLookups, OcrUtils,
    ) {
      const self = this;

      self.articleId = ($routeParams.articleId !== "create") ? $routeParams.articleId : undefined;
      if (!self.articleId) {
        OcrUtils.setPageTitle('New Help Article')
      }
      self.viewId = $routeParams.view;
      self.today = new Date();
      self.editMode = false;
      self.viewPreview = false;
      self.listStyle = {};
      self.converter = new showdown.Converter();

      self.articleCategories = OcrLookups.dummyList
      OcrLookups.get('HelpArticleCategory').then(
        (articleCategories) => self.articleCategories = articleCategories,
      )

      self.getCategoryById = function(id) {
        if (!self.articleCategories) {
          return undefined
        }
        return self.articleCategories.lookup(id);
      };

      self.promise = null;
      $scope.$watch(() => self.promise, () => {
        self.setPromise()(self.promise)
      });

      if (self.articleId) {
        self.promise = HelpArticleService.get({
          id: self.articleId,
        }).$promise.then(function(article) {
          self.article = article;
          OcrUtils.setPageTitle(article.title)
        }).finally(() => self.promise = null);
        // self.$parent.loading = false;
        // self.article = self.getArticleById(self.articleId);
      } else {
        self.article = {
          createdByUserId: OcrAuth.session.user.id,
          createdOnDate: self.today,
        };
        self.isNew = true;
        self.editMode = true;
      }

      self.edit = () => self.editMode = true;

      self.preview = function() {
        self.viewPreview = true;

        const text = self.article.showdown;
        const target = $document[0].getElementById('preview');
        const html = self.converter.makeHtml(text);
        target.innerHTML = html;
      }

      $window.addEventListener('resize', onResize, true);

      self.checkPermission = OcrAuth.checkPermission

      function onResize() {
        $timeout(() => {
          if (!$mdMedia("xs")) {
            self.listStyle.maxHeight = `${$window.innerHeight - 250
            }px`;
          } else {
            self.listStyle = {};
          }
        });
      }

      self.closeForm = function(noConfirm) {
        // only display confirmation message if form is dirty (has modified fields)
        if (noConfirm || self.articleForm.$pristine) {
          $location.path(`/views/${self.viewId}`);
          return;
        }

        const message
          = "Are you sure that you want to close this form without saving your changes?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Form Close')
            .textContent(message)
            .ariaLabel(message)
            .ok('Close Form')
            .cancel('Cancel'),
        ).then(
          () => $location.path(`/views/${self.viewId}`),
          () => {},
        );
      };

      self.saveForm = function(close) {
        self.article.lastModifiedByUserId = OcrAuth.session.user.id;
        self.article.lastModifiedOnDate = self.today;
        self.article.category = self.getCategoryById(self.article.helpArticleCategoryId);
        self.article.helpArticleCategory = self.article.category.category;
        self.article.articleBody = self.converter.makeHtml(self.article.showdown);

        $mdToast.show(
          $mdToast.simple()
            .textContent('Saving...')
            .position("bottom right"),
        );

        const continueSave = function() {
          self.articleForm.$setPristine();
          $mdToast.show(
            $mdToast.simple()
              .textContent('Help article saved.')
              .position("bottom right"),
          ).then(() => self.isNew = false);
          if (close) {
            $location.path(`/views/${self.viewId}`);
          }
        };

        if (self.isNew) {
          // create
          self.promise = HelpArticleService.save(self.article).$promise
            .then(() => continueSave())
            .catch(function(error) {
              if (error && error.data && error.data.status === 401) {
                $location.path("/");
                return;
              }

              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            }).finally(() => self.promise = null);
        } else {
          // update
          self.promise = HelpArticleService.update({
            id: self.articleId,
          }, self.article).$promise
            .then(() => continueSave())
            .catch(function(error) {
              if (error && error.data && error.data.status === 401) {
                $location.path("/");
                return;
              }

              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            }).finally(() => self.promise = null);
        }
      };

      self.deleteForm = function(close) {
        $mdToast.show(
          $mdToast.simple()
            .textContent('Deleting...')
            .position("bottom right"),
        );

        const continueSave = function() {
          $mdToast.show(
            $mdToast.simple()
              .textContent('Help article deleted.')
              .position("bottom right"),
          ).then(() => self.isNew = false);
          if (close) {
            $location.path(`/views/${self.viewId}`);
          }
        };

        self.promise = HelpArticleService.delete({
          id: self.articleId,
        }, self.article).$promise
          .then(() => continueSave())
          .catch(function(error) {
            if (error && error.data && error.data.status === 401) {
              $location.path("/");
              return;
            }

            $mdToast.show(
              $mdToast.simple()
                .textContent(error.data.reason)
                .position("bottom right"),
            );
          }).finally(() => self.promise = null);
      }

      $scope.$on('$locationChangeStart',
        function(event, newUrl, oldUrl) {
          const removeQueryParams = (url) => {
            if (url) {
              const index = url.indexOf("?");
              if (index >= 0) {
                return url.substring(0, index);
              }
            }
            return url;
          };

          const newPath = $location.path();

          if (
            newPath === "/"
            || self.articleForm.$pristine
            || removeQueryParams(newUrl) === removeQueryParams(oldUrl)
          ) {
            return;
          }

          event.preventDefault();

          const message
            = "Are you sure that you want to close this form without saving your changes?";

          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Page Exit')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK')
              .cancel('Cancel'),
          ).then(() => {
            self.articleForm.$setPristine();
            $location.path(newPath);
          });
        });

      self.$onInit = function() {
        $scope.$watch(
          () => angular.isDefined(self.articleForm),
          () => $scope.$watch(
            () => self.articleForm.$invalid,
            () => {
              if (self.articleForm && self.articleForm.$invalid) {
                angular.forEach(self.articleForm.$error,
                  (field) => angular.forEach(field, (errorField) => errorField.$setTouched()));
              }
            },
          ), true);
      };
    },
  ],
});
