angular.module('CaresApp').controller('EditAppointmentGroupDialogController', [
  '$scope',
  '$mdDialog',
  '$mdToast',
  '$document',
  'AppointmentGroupService',
  'AppointmentService',
  'OcrUtils',
  'AppointmentDataService',
  function($scope, $mdDialog, $mdToast, $document, AppointmentGroupService, AppointmentService, OcrUtils, AppointmentDataService) {
    const self = this;

    self.submitting = false;
    self.selected = [];
    self.appointmentGroups = [];
    self.isDuplicate = false;
    let promises = [];
    self.attorneySelected = 0;

    self.$onInit = function() {
      self.newAppointmentGroupName = self.group.name;
      self.updateData();
      self.madeChanges = false;
    }

    self.updateData = function() {
      self.loading = true;
      self.selected = [];
      promises.push(AppointmentService.get({
        inOffice: true,
        attorneyUserId: self.attorneyId,
        showActive: true,
        inGroup: false,
      }).$promise.then(function(response) {
        self.appointmentsOutGroup = response.list;
        self.appointmentsOutGroup.total = response.total;
        for (const appointment of self.appointmentsOutGroup) {
          const childPartyNames = [];
          if (appointment.childParties) {
            for (const childParty of appointment.childParties) {
              if (childParty.endDate) {
                childParty.endDate = new Date(childParty.endDate);
              }
              if (!childParty.endDate || childParty.endDate > self.today) {
                childPartyNames.push(OcrUtils.getChildPartyFullName(
                  childParty));
              }
            }
          }
          appointment.childPartyNames = childPartyNames.join("; ");
        }
        self.loading = false;
      }));
      promises.push(AppointmentService.get({
        appointmentGroupId: self.group.id
      }).$promise.then(function(response) {
        self.appointmentsInGroup = response.list;
        self.appointmentsInGroup.total = response.total;
        for (const appointment of self.appointmentsInGroup) {
          const childPartyNames = [];
          if (appointment.childParties) {
            for (const childParty of appointment.childParties) {
              if (childParty.endDate) {
                childParty.endDate = new Date(childParty.endDate);
              }
              if (!childParty.endDate || childParty.endDate > self.today) {
                childPartyNames.push(OcrUtils.getChildPartyFullName(
                  childParty));
              }
            }
          }
          appointment.childPartyNames = childPartyNames.join("; ");
        }
        self.loading = false;
      }));
    }

    self.checkGroupName = function(form) {
      if (self.group.name == self.newAppointmentGroupName) {
        form.newAppointmentGroupName.$setValidity('validateGroupName', true);
        return;
      }
      AppointmentGroupService.get().$promise.then(function(resp) {
        for (const group of resp.list) {
          if (group.name == self.newAppointmentGroupName && self.group.name != self.newAppointmentGroupName) {
            form.newAppointmentGroupName.$setValidity('validateGroupName', false);
            form.newAppointmentGroupName.$setTouched();
            return;
          }
        }
        form.newAppointmentGroupName.$setValidity('validateGroupName', true);
        return;
      })
    }

    self.checkAppointment = function() {
      if (self.selected[0].appointmentGroupId == 0) {
        return true;
      }
      return false;
    }

    self.addToGroup = function() {
      self.loading = true;
      promises.push(AppointmentDataService.get({
        id: self.selected[0].id,
      }).$promise.then(function(response) {
        let appointment = response;
        let saveFunction = null;
        let saveArgs = null;
        appointment.appointmentGroupId = self.group.id;
        saveFunction = AppointmentService.update;
        saveArgs = [
          {
            id: appointment.id,
          },
          appointment,
        ];
        saveFunction.apply(this, saveArgs).$promise.then(
          function(resp) {
            self.updateData()
            self.madeChanges = true;
          })
      }));
    }

    self.removeFromGroup = function() {
      self.loading = true;
      promises.push(AppointmentDataService.get({
        id: self.selected[0].id,
      }).$promise.then(function(response) {
        let appointment = response;
        let saveFunction = null;
        let saveArgs = null;
        appointment.appointmentGroupId = undefined;
        saveFunction = AppointmentService.update;
        saveArgs = [
          {
            id: appointment.id,
          },
          appointment,
        ];
        saveFunction.apply(this, saveArgs).$promise.then(
          function(resp) {
            self.updateData();
            self.madeChanges = true;
          })
      }));
    }

    self.deleteGroup = function() {
      const message = "Deleting an Appointment Group only undoes the grouping of these appointments and the deletes this group name for your office. " +
        "This has no impact on the appointments themselves; this does not close or alter appointment data.";
      $mdDialog.show(
        $mdDialog.confirm()
          .parent(angular.element($document[0].querySelector(
            '#popupContainer')))
          .clickOutsideToClose(false)
          .title('Confirm Appointment Group Delete')
          .textContent(message)
          .ariaLabel(message)
          .multiple(true)
          .ok('Delete Appointment Group')
          .cancel('Cancel'),
      ).then(function() {
        // ok
          self.loading = true;
          promises.push(AppointmentGroupService.delete({
            id: self.group.id,
            groupId: self.group.id,
          }, ).$promise.then(function() {
            continueDelete();
            $mdDialog.hide();
          })
            .catch(function(error) {
              console.log(error);
              if (error && error.data && error.data.status === 401) {
                $location.path("/");
                return;
              }
              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            }).finally(() => self.promise = null));
      }, function() {
        // cancel
        // do nothing
      });
        $mdToast.show(
          $mdToast.simple()
            .textContent('Deleting...')
            .position("bottom right"),
        );

        const continueDelete = function() {
          $mdToast.show(
            $mdToast.simple()
              .textContent('Appointment Group deleted.')
              .position("bottom right"),
          ).then(function() {
          });
        };
    };

    self.hide = $mdDialog.hide;
    self.cancel = function() {
      $mdDialog.hide();
    }
    self.submit = () => $mdDialog.hide(self.selected[0]);

    self.editAppointmentGroup = function(newAppointmentGroupName) {
      self.submitting = true;
      self.group.name = newAppointmentGroupName;
      const addAppointmentIds = [];
      for (const appointment_ of self.selected) {
        const request = {
          appointment: appointment_.id,
        }
        addAppointmentIds.push(request);
      }
      self.group.appointmentIds = addAppointmentIds;
      AppointmentGroupService.update({
        id: self.group.id,
        }, self.group).$promise.then(
        (resp) => {
          self.submitting = false;
          $mdDialog.hide(resp);
        },
      );
      self.submitting = false;
      self.madeChanges = true;
    };
  },
]);
