angular.module('CaresApp').component('ocrCoreExport', {
  templateUrl: 'components/ocr-core-export/ocr-core-export.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    '$document',
    '$window',
    '$q',
    '$mdDialog',
    '$mdToast',
    '$location',
    'InvoiceService',
    'OcrLookups',
    'OcrUtils',
    'OcrViews',
    'OcrAuth',
    function($scope, $document, $window, $q, $mdDialog, $mdToast, $location, InvoiceService,
      OcrLookups, OcrUtils, OcrViews, OcrAuth) {
      const self = this;
      OcrUtils.setPageTitle('CORE Export');
      self.currentView = OcrViews.currentView
      self.promise = null;
      $scope.$watch(() => self.promise, () => {
        self.setPromise()(self.promise)
      })

      self.selectedInvoices = []

      self.results = {
        data: [],
        count: 0,
        total: 0,
      };

      self.search = {
        sid: 6,
      };


      self.invoiceStatuses = self.offices = OcrLookups.dummyList;
      self.invoiceStatusesPromise = OcrLookups.get('InvoiceStatus').then(
        (invoiceStatuses) => self.invoiceStatuses = invoiceStatuses,
      ).finally(() => self.invoiceStatusesPromise = null);

      self.officesPromise = OcrLookups.get('Office', true).then(
        (offices) => self.offices = offices,
      ).finally(() => self.officesPromise = null);

      self.disablePaid = function() {
        if (self.selectedInvoices.length === 0) {
          return true;
        }
        for (const invoice of self.selectedInvoices) {
          if (!invoice.exportDate) {
            return true;
          }
        }
        return false;
      }

      self.refresh = () => self.updateData();

      self.updateData = function() {
        self.search.skip = (self.query.page - 1) * self.query.limit;
        self.search.limit = self.query.limit;
        $location.search(self.search);
        self.promise = InvoiceService.query(self.search)
          .$promise.then(
            function(resp) {
              self.results.data = resp.list;
              // FIXME Total and Count are being returned reversed.
              self.results.count = resp.total;
              self.results.total = resp.count;
              self.selectedInvoices = [];
            },
          ).finally(() => self.promise = null);
      };

      self.export = function() {
        const invoiceNumbers = [];
        for (const invoice of self.selectedInvoices) {
          invoiceNumbers.push(invoice.invoiceNumber);
        }
        $mdDialog.show({
          controller: ExportConfirmationController,
          templateUrl:
            'components/ocr-core-export/ocr-create-core-export-confirmation.template.html',
          parent: angular.element($document[0].body),
          clickOutsideToClose: false,
          controllerAs: '$ctrl',
          locals: {
            invoiceNumbers: invoiceNumbers,
          },
          bindToController: true,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        }).then(() => self.updateData(), () => {});
      }

      self.markAsPaid = function() {
        const promises = [];
        for (const invoice of self.selectedInvoices) {
          promises.push(InvoiceService.update({
            id: invoice.invoiceNumber,
          }, {
            newStatus: "Paid",
          }).$promise);
        }

        self.promise = $q.all(promises).then(
          () => $mdToast.show(
            $mdToast.simple()
              .textContent('Invoices marked as paid.')
              .position("bottom right"),
          ),
        ).catch(function(error) {
          console.error("Server error: %O", error);
          $mdToast.show(
            $mdToast.simple()
              .textContent(error.data.reason)
              .position("bottom right"),
          );
        },
        ).finally(() => self.updateData());
      }

      self.$onInit = function() {
        self.search = $location.search();


        if (self.search.dateFrom) {
          self.search.dateFrom = new Date(self.search.dateFrom);
        }
        if (self.search.dateTo) {
          self.search.dateTo = new Date(self.search.dateTo);
        }

        if (!self.search.sid && OcrAuth.session.user.persona.name === 'OCR Accounting') {
          self.search.sid = 6; // approved by OCR Finance
          self.search.order = "invoice.created_date";
        }

        if (self.search.limit) {
          self.query.limit = self.search.limit;
          if (self.search.skip) {
            self.query.page = (self.search.skip / self.search.limit) + 1;
          }
        }
        self.updateData()
      }

      /* eslint-disable no-shadow */
      function ExportConfirmationController($mdDialog, OcrUtils) {
        const self = this;
        /* eslint-enable no-shadow */
        const currentFiscalYear = OcrUtils.getCurrentFiscalYear();
        self.fiscalYear = currentFiscalYear
        self.fiscalPeriod = OcrUtils.getCurrentFiscalPeriod();
        self.fiscalYears = [currentFiscalYear - 1, currentFiscalYear, currentFiscalYear + 1]

        self.hide = $mdDialog.hide;
        self.cancel = $mdDialog.cancel

        self.submit = function() {
          const url = `/api/export/invoice/core?invoiceNumbers=${self.invoiceNumbers.join(',')
          }&fiscalYear=${self.fiscalYear}&fiscalPeriod=${self.fiscalPeriod}`;
          $window.open(url, '_blank');
          $mdDialog.hide();
        }
      }
      ExportConfirmationController.$inject = ['$mdDialog', 'OcrUtils']

      $scope.$on('$locationChangeStart',
        function(_, newUrl, oldUrl) {
          const newArray = newUrl.split("?");
          const oldArray = oldUrl.split("?");
          if (newArray[0] !== oldArray[0]) {
            $location.search("");
          }
        });
    },
  ],
});
