angular.module('CaresApp').component('ocrAppointmentActivitiesStandardView', {
  templateUrl:
    'components/ocr-appointment-activities-standard-view/' +
    'ocr-appointment-activities-standard-view.template.html',
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    selected: '=',
    updateData: '=',
    query: '=',
  },
  controller: [
    '$scope',
    'OcrUtils',
    function($scope, OcrUtils) {
      const self = this;

      self.model = {};

      self.getUserFullName = OcrUtils.getUserFullName;

      self.$onInit =
        () => $scope.$watch(
          () => self.ngModel.$modelValue,
          () => self.model = self.ngModel.$modelValue,
        );
    },
  ],
});
