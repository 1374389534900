angular.module('CaresApp').component('ocrAppointmentChildPartiesPanel', {
  templateUrl: 'components/ocr-appointment-child-parties-panel/' +
    'ocr-appointment-child-parties-panel.template.html',
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    hideEditing: '&hideEditing',
  },
  controller: [
    '$scope',
    '$document',
    '$mdDialog',
    '$mdToast',
    '$window',
    '$location',
    'OcrUtils',
    'OcrLookups',
    'OcrAuth',
    'SavedPlacementService',
    'AppointmentService',
    'RoleChangePromptRecordService',
    'RoleChangeAlertService',
    'AppointmentChildPartyService',
    function($scope, $document, $mdDialog, $mdToast, $window, $location, OcrUtils, OcrLookups, OcrAuth,
      SavedPlacementService, AppointmentService, RoleChangePromptRecordService, RoleChangeAlertService, AppointmentChildPartyService) {
      const self = this;

      self.canEdit = function() {
        return ((OcrAuth.checkPermission('ADMIN_WRITE')
              || OcrAuth.checkPermission('CHILD_PARTY_WRITE'))
              && !self.hideEditing)
      };
      self.model = null;
      self.today = new Date();
      self.placementsLoaded = (OcrAuth.session && OcrAuth.session.placements
        && OcrAuth.session.placements
        && OcrAuth.session.placements.length);
      self.hasPlacements = (OcrAuth.session
        && OcrAuth.session.placements
        && OcrAuth.session.placements.length
        && OcrAuth.session.placements.length > 0);

      self.getUserFullName = OcrUtils.getUserFullName;

      self.hasPermission = OcrAuth.checkPermission;

      self.permanencyGoalOptions = self.attorneyRoles = self.caseTypes = OcrLookups.dummyList;
      OcrLookups.get('PermanencyGoal', true).then(
        (permanencyGoalOptions) => self.permanencyGoalOptions = permanencyGoalOptions,
      );
      OcrLookups.get('AttorneyRole', true).then(
        (attorneyRoles) => self.attorneyRoles = attorneyRoles,
      );
      OcrLookups.get('CaseType', true).then(
        (caseTypes) => self.caseTypes = caseTypes,
      );

      OcrAuth.session.placements = OcrLookups.dummyList;

      self.getAge = OcrUtils.age;
      self.getUserFullName = OcrUtils.getUserFullName;
      self.showedAlert = false;

      self.loadPlacements = function() {
        if (!OcrAuth.session || !OcrAuth.session.userId || self.placementsLoaded) {
          return null;
        } else if (!OcrAuth.session.placements.length) {
          return SavedPlacementService.get({
            limit: 1000,
          }).$promise.then(
            function(response) {
              self.placementsLoaded = true;
              OcrAuth.session.placements = OcrLookups.createLookupList(response.list);
              if (OcrAuth.session.placements.length > 0) {
                self.hasPlacements = true;
              } else {
                self.hasPlacements = false;
              }
            },
          );
        }
        self.placementsLoaded = true;
        if (OcrAuth.session.placements.length > 0) {
          self.hasPlacements = true;
        } else {
          self.hasPlacements = false;
        }
        return null;
      }

      self.checkAlerts = function(warningId, skipAdd) {
        if ($location.search().rcid) {
          self.showRCpopup($location.search().rcid);
          $location.search('rcid', null);
        } else {

          if (!self.model.id) {
            // we are creating an appointment right now; there is no appointment yet, so we cannot have alerts.
            return;
          }

          RoleChangeAlertService.get({ appointmentId: self.model.id })
            .$promise.then(function(response) {
              if (response.roleChanges.length > 0) {
                self.showRCpopup(response.roleChanges[0].childPartyId, response.roleChanges[0].appointmentChildPartyId);
              } else {
                if ($location.search().arid) {
                  skipAdd = true;
                  $location.search('arid', null);
                } else if ($location.search().cwid) {
                  warningId = $location.search().cwid;
                  $location.search('cwid', null);
                }

                if (!self.showedAlert) {
                  self.showedAlert = true;
                  self.showCWpopupLoop(response.roleChangeWarnings.entries(), response.additionalReminders, warningId, skipAdd);
                }
              }
            });
        }
      };

      self.showCWpopupLoop = function(warnings, additional, warningId, skipAdd) {
        const warning = warnings.next();
        if (warning.done) {
          if (additional.length > 0 && !skipAdd) {
            self.showARpopup(additional[0].childPartyId, additional[0].appointmentChildPartyId);
          }
          return;
        }
        const warningValue = warning.value[1];
        if (warningId && warningValue.childPartyId == warningId) {
          self.showCWpopupLoop(warnings, additional, warningId, skipAdd);
          return;
        }
        let cp = undefined;
        for (childParty of self.model.childParties) {
          if (childParty.childPartyId == warningValue.childPartyId) {
            cp = childParty;
          }
        }
        if (!cp) {
          return;
        }
        const ctar = self.model.caseTypeAttorneyRoles.lookup(cp.bestInterestsRoleId);
        cp.age = Math.floor((new Date(cp.roleChangeDate) - new Date(cp.dateOfBirth)) / 31557600000);
        const message
          = `According to the date of birth entered, ${cp.firstName} ${cp.lastName} will be ${cp.age} on ${new Date(cp.roleChangeDate).toDateString()}, meaning your role\nmay transition to Counsel for Youth.  Please plan for this transition if applicable, consulting CJD 04-06 for guidance.`;
        $mdDialog.show({
          controller: 'AlertDialogController',
          templateUrl: 'components/ocr-appointment/dialogs/alert-dialog.html',
          parent: angular.element($document[0].body),
          locals: {
            title: 'Possible Transition to Client-Directed Representation',
            message: message,
            type: "cw",
            appointmentChildPartyId: warningValue.appointmentChildPartyId,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          clickOutsideToClose: false,
        }).then(function() {
          record = {
            appointmentId: self.model.id,
            appointmentChildPartyId: cp.id,
            appointmentChildPartyDateOfBirth: cp.dateOfBirth,
            promptDate: self.today,
            roleChangeDate: cp.roleChangeDate,
            response: "Okay",
            userId: OcrAuth.session.user.id,
          };
          RoleChangePromptRecordService.save(record);
          self.showCWpopupLoop(warnings, additional, warningId, skipAdd);
        });
      }

      self.showARpopup = function(arid, appointmentChildPartyId) {
        $mdDialog.show({
          controller: 'AlertDialogController',
          templateUrl: 'components/ocr-appointment/dialogs/alert-dialog.html',
          parent: angular.element($document[0].body),
          locals: {
            title: 'Age Reminder',
            message: self.caseTypes.lookup(self.model.caseTypeId).reminderText,
            type: "ar",
            appointmentChildPartyId: appointmentChildPartyId,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          clickOutsideToClose: false,
        }).then(function() {
          let cp = undefined;
          for (childParty of self.model.childParties) {
            if (childParty.childPartyId == arid) {
              cp = childParty;
            }
          }
          if (!cp) {
            return;
          }
          const ctar = self.model.caseTypeAttorneyRoles.lookup(cp.bestInterestsRoleId);
          record = {
            appointmentId: self.model.id,
            appointmentChildPartyId: cp.id,
            appointmentChildPartyDateOfBirth: cp.dateOfBirth,
            promptDate: self.today,
            roleChangeDate: cp.roleChangeDate,
            response: "Okay",
            userId: OcrAuth.session.user.id,
          };
          RoleChangePromptRecordService.save(record);
          self.checkAlerts(undefined, true);
        });
      }

      self.showCWpopup = function(cwid) {
        let cp = undefined;
        for (childParty of self.model.childParties) {
          if (childParty.childPartyId == cwid) {
            cp = childParty;
          }
        }
        if (!cp) {
          return;
        }
        const ctar = self.model.caseTypeAttorneyRoles.lookup(cp.bestInterestsRoleId);
        cp.age = Math.floor((new Date(ctar.roleChangeDate) - new Date(cp.dateOfBirth)) / 31557600000);
        const message
          = `According to the date of birth entered, ${cp.firstName} ${cp.lastName} will be ${cp.age} on ${new Date(cp.roleChangeDate).toDateString()}, meaning your role\nmay transition to Counsel for Youth.  Please plan for this transition if applicable, consulting CJD 04-06 for guidance.`;
          $mdDialog.show({
            controller: 'AlertDialogController',
            templateUrl: 'components/ocr-appointment/dialogs/alert-dialog.html',
            parent: angular.element($document[0].body),
            locals: {
              title: 'Possible Transition to Client-Directed Representation',
              message: message,
            },
            bindToController: true,
            controllerAs: '$ctrl',
            clickOutsideToClose: false,
          }).then(function() {
          record = {
            appointmentId: self.model.id,
            appointmentChildPartyId: cp.id,
            appointmentChildPartyDateOfBirth: cp.dateOfBirth,
            promptDate: self.today,
            roleChangeDate: cp.roleChangeDate,
            response: "Okay",
            userId: OcrAuth.session.user.id,
          };
          RoleChangePromptRecordService.save(record);

          self.checkAlerts(cwid, false);
        });
      }

      self.showRCpopup = function(rcid) {
        let cp = undefined;
        for (childParty of self.model.childParties) {
          if (childParty.childPartyId == rcid) {
            cp = childParty;
          }
        }
        if (!cp) {
          return;
        }

        const ctar = self.model.caseTypeAttorneyRoles.lookup(cp.bestInterestsRoleId);
        $mdDialog.show({
          controller: 'RoleChangeDialogController',
          templateUrl: 'components/ocr-appointment/dialogs/role-change-dialog.html',
          parent: angular.element($document[0].body),
          locals: {
            childParty: cp,
            ctar: ctar,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          clickOutsideToClose: false,
        }).then(function(response) {
          console.log("response from RoleChangeDialogController", response);
          if (response.remind) {
            let record = {
              appointmentId: self.model.id,
              appointmentChildPartyId: cp.id,
              appointmentChildPartyDateOfBirth: cp.dateOfBirth,
              promptDate: self.today,
              roleChangeDate: cp.roleChangeDate,
              response: "Remind Me Later",
              userId: OcrAuth.session.user.id,
            };
            RoleChangePromptRecordService.save(record);
            let remindDate = new Date();
            remindDate.setDate(self.today.getDate() + 3);
            remindDate.setHours(3,0,0,0);
            cp.roleChangeRemindDate = remindDate;
            cp.appointmentId = self.model.id;
            console.log(cp);
            const request = {
              appointmentId: self.model.id,
              childParty: cp,
            };
            AppointmentChildPartyService.update({
              id: cp.id,
            }, request).$promise.then(
              function() {
                self.checkAlerts();
              }
            );
          } else {
            let action;

            console.log("response.action", response.action);

            if (response.action === "1") {
              console.log("Setting action to Update Role to Counsel for Youth");
              action = "Update Role to Counsel for Youth";
            } else if (response.action === "2") {
              console.log("Setting action to Correcting Error in Youth Data");
              action = "Correcting Error in Youth Data";
            } else if (response.action === "3") {
              console.log("Setting action to Best Interests Continuation");
              action = "Best Interests Continuation";
            }

            console.log("Selected action", action);

            let record = {
              appointmentId: self.model.id,
              appointmentChildPartyId: cp.id,
              appointmentChildPartyDateOfBirth: cp.dateOfBirth,
              promptDate: self.today,
              roleChangeDate: cp.roleChangeDate,
              response: action,
              userId: OcrAuth.session.user.id,
            };
            RoleChangePromptRecordService.save(record);
            self.editChildParty(cp, response);
          }
        },
        );
      }

      self.getDaysInPlacement = function(placement) {
        if (!placement) {
          return undefined;
        }

        const startDate = new Date(placement.startDate);
        const now = new Date();

        return Math.trunc((now.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24);
      };

      self.getAppointmentStatus = function() {
        if (self.model) {
          if (self.model.endDate && self.model.endDate < self.today) {
            return "Closed";
          }
          return "Open";
        }
        return null;
      }

      self.disableAddChildParty = () => self.getAppointmentStatus() === "Closed";

      self.addChildParty = function() {
        $mdDialog.show({
          controller: 'AddChildPartyDialogController',
          templateUrl:
            'components/ocr-appointment-child-parties-panel/dialogs/add-child-party-dialog.html',
          parent: angular.element($document[0].body),
          locals: {
            appointment: self.model,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          clickOutsideToClose: false,
        })
          .then(() => {
            self.filterChildParties();
            self.reloadChildren();
            self.updateStartDate();
          }, () => {});
      };

      self.editChildParty = function(appointmentChildParty, alertResponse) {
        const count = self.model.childParties
          .map((childParty) => (!childParty.endDate ? 1 : 0))
          .reduce((accumulator, current) => accumulator + current);


        if (!appointmentChildParty.endDate && count === 1) {
          appointmentChildParty.isLastActive = true;
        }

        console.log("before open editAppointmentChildPartyDialog...");

        // open editAppointmentChildPartyDialog
        $mdDialog.show({
          controller: 'EditAppointmentChildPartyDialogController',
          templateUrl:
            'components/ocr-appointment-child-parties-panel/dialogs/'
            + 'edit-appointment-child-party-dialog.html',
          parent: angular.element($document[0].body),
          clickOutsideToClose: false,
          locals: {
            childParty: appointmentChildParty,
            appointment: self.model,
            hideEditing: self.hideEditing(),
            alertResponse: alertResponse,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          multiple: true, // to allow nested dialog boxes!
        }).then(function(response) {

            console.log("response from EditAppointmentChildPartyDialogController", response);

            console.log("appointmentChildParty isLastActive", appointmentChildParty.isLastActive);

            // if (appointmentChildParty.isLastActive && appointmentChildParty.endDate) {

            // if (appointmentChildParty.isLastActive) {
            //   // last appointmentChildParty has been closed (or opened), so we need to close (or reopen) the appointment.
            //   self.model.endDate = appointmentChildParty.endDate;
            // } else if (self.model.endDate && !appointmentChildParty.endDate) {
            //   self.model.endDate = undefined;
            // }
            //
            // self.promise = AppointmentService.update({
            //   id: self.model.id,
            // }, self.model).$promise;
            //
            // self.promise.then(() => $mdToast.show(
            //   $mdToast.simple()
            //     .textContent("Appointment saved.")
            //     .position("bottom right"))
            // )
            // .catch(function(error) {
            //   if (error && error.data) {
            //     $mdToast.show(
            //       $mdToast.simple()
            //         .textContent(error.data.reason)
            //         .position("bottom right"),
            //     );
            //   }
            // }).finally(() => self.promise = null);

            self.filterChildParties();
            self.reloadChildren();
            self.updateStartDate();

            console.log("UpdateData emitted!");

            $scope.$emit("updateData");

            // if (response && !appointmentChildParty.isLastActive && !appointmentChildParty.endDate){
            // $scope.$emit("childPartySaved");
            // }
          }, function() {
            if (alertResponse) {
              self.checkAlerts();
            }
          });
      };

      self.styleButton = () => (self.model.childParties.length > 0 ? "" : "md-primary");

      self.viewPlacement = function(childParty) {
        const placement = self.currentPlacement(childParty);
        $mdDialog.show({
          controller: 'ViewPlacementDialogController',
          templateUrl:
            'components/ocr-appointment-child-parties-panel/dialogs/view-placement-dialog.html',
          parent: angular.element($document[0].body),
          clickOutsideToClose: false,
          locals: {
            placement: placement,
            appointment: self.model,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          //fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
          multiple: true, // to allow nested dialog boxes!
        }).then(function() {}, function() {});
      };

      self.canReadChildParty = OcrAuth.checkPermission('APPOINTMENT_READ')
          || OcrAuth.checkPermission('APPOINTMENT_CHILD_PARTY_READ')
        || OcrAuth.checkPermission('ADMIN_READ');

      self.canWriteChildParty = OcrAuth.checkPermission('APPOINTMENT_WRITE')
          || OcrAuth.checkPermission('APPOINTMENT_CHILD_PARTY_WRITE')
        || OcrAuth.checkPermission('ADMIN_WRITE');

      self.showMap = function(childParty) {
        const placement = self.currentPlacement(childParty);
        const url = `https://maps.google.com/?q=${placement.address1}, ${placement.city}, ${
          placement.state}, ${placement.zip}`;
        $window.open(url, '_blank');
      };

      self.currentPlacement = function(childParty) {
        for (const placement of childParty.placements) {
          placement.startDate = new Date(placement.startDate);
          if (placement.startDate <= self.today) {
            if (angular.isUndefined(placement.endDate) || placement.endDate > self.today) {
              return placement;
            }
          }
        }
        return null;
      };

      self.filterChildParties = function() {
        self.model.activeChildParties = [];
        for (const childParty of self.model.childParties) {
          if (!childParty.endDate || childParty.endDate > self.today) {
            self.model.activeChildParties.push(childParty);
          }
        }
      }

      self.reloadChildren = function() {
        if (self.showInactive) {
          self.model.visibleChildParties = self.model.childParties;
        } else {
          self.model.visibleChildParties = self.model.activeChildParties;
        }
      }

      self.updateStartDate = function() {
        let compareDate;

        for (let i = 0; i < self.model.childParties.length; i++) {
          self.model.childParties[i].startDate = new Date(
            self.model.childParties[i].startDate);
          if (angular.isUndefined(compareDate)) {
            compareDate = new Date(self.model.childParties[i].startDate);
          } else if (compareDate > self.model.childParties[i].startDate) {
            compareDate = new Date(self.model.childParties[i].startDate);
          }
        }

        self.model.startDate = new Date(compareDate);
        self.model.earliestDate = new Date(compareDate);
      }

      $scope.$on('updateChildParties', () => {
        self.filterChildParties();
        self.reloadChildren();
      })

      self.$onInit = function() {
        $scope.$watch(
          () => self.ngModel.$modelValue,
          function() {
            self.model = self.ngModel.$modelValue;
            self.showInactive = false;
            self.filterChildParties();
            self.reloadChildren();
            alerted = false;
            $scope.$watch(
              () => self.model.caseTypeAttorneyRoles,
              function() {
                $scope.$watch(
                  () => self.caseTypes,
                  function() {
                    if (self.model.caseTypeAttorneyRoles && self.caseTypes && !alerted) {
                      alerted = true;
                      self.checkAlerts();
                    }
                  }
                )
              },
            );
          },
        );
        self.loadPlacements();
      };
    },
  ],
});
