angular.module('CaresApp').controller('ReopenCaseAppointmentDialogController', [
  '$mdDialog',
  'OcrUtils',
  'OcrLookups',
  'CaseService',
  'CaseDataService',
  function($mdDialog, OcrUtils, OcrLookups, CaseService, CaseDataService) {
    const self = this;

    console.log("self: %O", self);
    self.today = new Date();

    self.choices = [
      {id: 1, name:'Yes, the court officially reopened the case.'},
      {id: 2, name:'No, but I need this appointment open (to enter activities, etc.).'},
    ]

    self.toggleOpen = function() {
      if (self.choice === 1) {
        self.choiceYes = true;
        return;
      }
      self.choiceYes = false;
    }

    self.reopenCase = function() {

      CaseService.update({ id: self.case.id }, {
        "action": "Reopen",
        "reopenDate": self.choiceYes ? self.reopenDate : null
      }).$promise.then(function(response) {
        console.log("response: %O", response);
        $mdDialog.hide(true); // success
      });

    }
  }
]);
