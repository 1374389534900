angular.module('CaresApp').controller('AppointmentGroupDialogController', [
  '$mdDialog',
  'AppointmentGroupService',
  'OcrAuth',
  'AppointmentService',
  function($mdDialog, AppointmentGroupService, OcrAuth, AppointmentService) {
    const self = this;

    self.submitting = false;
    self.selected = [];
    self.appointmentGroups = [];

    self.$onInit = function() {
      AppointmentGroupService.get({
        limit: 1000,
        attorneyId: self.attorneyUserId,
      }).$promise.then(
        function(resp) {
          self.appointmentGroups = resp.list;
        },
      );
    }

    self.updateData = function() {
      AppointmentGroupService.get({
        limit: 1000,
        attorneyId: self.attorneyUserId,
      }).$promise.then(
        function(resp) {
          self.appointmentGroups = resp.list;
        },
      );
    }

    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;
    self.submit = () => $mdDialog.hide(self.selected[0]);

    self.addToNewAppointmentGroup = function() {
      $mdDialog.show({
        controller: 'AddAppointmentGroupDialogController',
        templateUrl:
          'components/ocr-appointment-associated-appointments/add-appointment-group-dialog.html',
        clickOutsideToClose: false,
        bindToController: true,
        locals: {
          officeId: self.officeId,
          attorneyUserId: self.attorneyUserId,
        },
        multiple: true,
        controllerAs: '$ctrl',
      })
        .then(function(selectedAppointmentGroup) {
          $mdDialog.hide(selectedAppointmentGroup);
          self.updateData();
        }, function() {});
    };
  },
]);
