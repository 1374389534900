angular.module('CaresApp').component('ocrInvoiceList', {
  templateUrl: 'components/ocr-invoice-list/ocr-invoice-list.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope', '$document', '$location', '$mdDialog', '$mdToast',
    'InvoiceService', 'OcrLookups', 'OcrAuth', 'OcrInvoiceFlagsService', 'OcrInvoiceFlagsDialog',
    'OcrUtils', 'OcrViews',
    function($scope, $document, $location, $mdDialog, $mdToast,
      InvoiceService, OcrLookups, OcrAuth, OcrInvoiceFlagsService, OcrInvoiceFlagsDialog, OcrUtils,
      OcrViews) {
      const self = this;
      OcrUtils.setPageTitle('Invoices');
      self.currentView = OcrViews.currentView
      self.flags = OcrInvoiceFlagsService.flags
      self.invoices = []
      self.invoices.total = 0
      self.invoices.count = 0

      self.search = {};

      self.showDeleteColumn = function() {
        return OcrAuth.checkPermission("INVOICE_DELETE");
      }

      self.showGenerateInvoiceButton = function() {
        return OcrAuth.checkPermission("INVOICE_CREATE");
      };

      self.showOfficesFilter = function() {
        return OcrAuth.checkPermission("IGNORE_OFFICE_FILTERS");
      };

      self.invoiceStatuses = self.offices = OcrLookups.dummyList;
      self.invoiceStatusesPromise = OcrLookups.get('InvoiceStatus').then(
        (invoiceStatuses) => self.invoiceStatuses = invoiceStatuses,
      ).finally(() => self.invoiceStatusesPromise = null);

      self.officesPromise = OcrLookups.get('Office', true).then(
        (offices) => self.offices = offices,
      ).finally(() => self.officesPromise = null);

      self.promise = null;


      self.refresh = () => self.updateData();

      self.updateData = function() {
        self.search.skip = (self.query.page - 1) * self.query.limit;
        self.search.limit = self.query.limit;

        $location.url(`${$location.path()}?${self.buildSearch(self.search)}`);

        self.promise = InvoiceService.query(self.search)
          .$promise.then(
            function(resp) {
              self.invoices = resp.list;
              self.invoices.count = resp.count;
              self.invoices.total = resp.total;
            },
          ).finally(() => self.promise = null);
      };

      self.deleteInvoice = function(invoice) {
        const message =
          "Are you sure that you want to delete this invoice?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Invoice Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete Invoice')
            .cancel('Cancel'),
        ).then(function() {
          // ok
          invoice.deleteing = true
          const deletingToast = $mdToast.simple()
            .textContent('Deleting...')
            .position("bottom right")
          $mdToast.show(deletingToast)
          InvoiceService.delete({
            id: invoice.invoiceNumber,
          }).$promise.then(function() {
            $mdToast.hide(deletingToast).then(
              function() {
                return $mdToast.show(
                  $mdToast.simple()
                    .textContent(`Invoice ${invoice.invoiceNumber} deleted.`)
                    .position("bottom right"),
                )
              },
            )
            self.updateData();
          })
            .catch(function(error) {
              if (error && error.data) {
                $mdToast.show(
                  $mdToast.simple()
                    .textContent(error.data.reason)
                    .position("bottom right"),
                );
              }
            });
        }, () => {});
      }

      self.$onInit = function() {
        if (self.setPromise) {
          $scope.$watch(() => self.promise, () => self.setPromise()(self.promise));
        }
        self.search = $location.search();
        if (self.search.dateFrom) {
          self.search.dateFrom = new Date(self.search.dateFrom);
        }
        if (self.search.dateTo) {
          self.search.dateTo = new Date(self.search.dateTo);
        }
        if (self.search.limit) {
          self.query.limit = self.search.limit;
          if (self.search.skip) {
            self.query.page = (self.search.skip / self.search.limit) + 1;
          }
        }
        if (!self.search.sid && OcrAuth.session.user.persona.name === 'OCR Billing Manager') {
          self.search.sid = 4; // submitted
          self.search.order = "-invoice.submitted_date";
        }
        if (!self.search.sid && OcrAuth.session.user.persona.name === 'OCR Accounting') {
          self.search.sid = 5; // approved by OCR Billing Manager
          self.search.order = "-invoice.submitted_date";
        }

        self.updateData();
      };

      self.createInvoice = function() {
        $mdDialog.show({
          controller: 'CreateInvoiceDialogController',
          templateUrl: 'tmpl/create-invoice-dialog.html',
          parent: angular.element($document.body),
          locals: {},
          clickOutsideToClose: false,
          bindToController: true,
          controllerAs: '$ctrl',
        })
          .then(function(resp) {
            const missedCount = resp.orphaned.length;
            if (angular.isDefined(resp.invoiceNumber) && resp.invoiceNumber !== null) {
              $location.path(`/invoice/invoices/${resp.invoiceNumber}`);
            } else if (resp.createdCount === 0) {
              let message = '';

              if (missedCount == 0) {
                message = "No activities were found that occur within the date "
                + "range you requested.  Please enter activities which occurred "
                + "during this date range or try a different date range.";
              } else {
                message = "No activities in the timeframe you "
                + "requested coincide with active payment recipient information "
                + "for your office. Please contact OCR (billing@coloradochildrep.org) "
                + "to make sure your 'Vendor' information covers all the dates "
                + "you are trying to invoice before generating an invoice.";
              }
              $mdDialog.show(
                $mdDialog.alert()
                  .title('No Invoices Created')
                  .htmlContent(message)
                  .ok('Close')
                  .targetEvent(event),
              )
            } else if (missedCount === 0 && resp.createdCount === 1) {
              $location.path(`/invoice/invoices/${resp.created[0].invoiceNumber}`);
            } else {
              let title = 'Multiple Invoices Created';
              let message = '';
              for (let i = 0; i < resp.createdCount; i++) {
                message += `${resp.created[i].invoiceNumber}<br>`;
              }
              if (resp.createdCount > 1) {
                message = `${"There is more than one taxpayer identification number "
                  + "(TIN, i.e. payment recipient) for this office in CARES during "
                  + "the requested date range.  As a result, more than one invoice "
                  + "has been generated (one for each TIN):<br><br>"}${
                  message
                }<br>Please find these invoices in the Invoicing > Invoices table.`;
              } else {
                message = `The following invoice has been created:<br><br>${
                  message
                }<br>Please find these invoices in the Invoicing > Invoices table.`;
              }
              if (missedCount > 0) {
                title = 'Some Activities Excluded'
                const missed = [];
                for (let i = 0; i < missedCount; i++) {
                  const temp = `${resp.orphaned[i].date}: ${resp.orphaned[i].caseNumber}<br>`;
                  if (missed.indexOf(temp) === -1) {
                    missed.push(temp);
                  }
                }
                message += `${"<br><br>"
                  + "<b>Some activities in the timeframe you requested do not "
                  + "coincide with active payment recipient information for "
                  + "your office. Please contact OCR (billing@coloradochildrep.org) "
                  + "to make sure your 'Vendor' information covers all the dates "
                  + "you are trying to invoice before submitting an invoice. "
                  + "Alternatively, if you choose to submit an invoice without "
                  + "addressing this issue, payments for activities from the "
                  + "following dates and cases will be excluded:</b>"
                  + "<br><br>"}${
                  missed.join("<br>")}`;
              }
              $mdDialog.show(
                $mdDialog.alert()
                  .title(title)
                  .htmlContent(message)
                  .ok('Close')
                  .targetEvent(event),
              )
              self.updateData();
            }
          // TODO add error handling
          });
      };

      self.showFlagsDialog = OcrInvoiceFlagsDialog.show

      self.buildSearch = function(object) {
        let search = undefined;
        angular.forEach(object, function(value, key) {
          if (angular.isUndefined(search)) {
            if (typeof value === "boolean" && value === true) {
              search = key;
            } else {
              search = `${key}=${value}`;
            }
          } else if (typeof value === "boolean" && value === true) {
            search = `${search}&${key}`;
          } else {
            search = `${search}&${key}=${value}`;
          }
        });
        return search;
      }

      $scope.$on('$locationChangeStart',
        function(_, newUrl, oldUrl) {
          const newArray = newUrl.split("?");
          const oldArray = oldUrl.split("?");
          if (newArray[0] !== oldArray[0]) {
            $location.search("");
          }
        });
    },
  ],
});
