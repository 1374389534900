angular.module('CaresApp').controller('PhoneValidationDialogController', [
  '$http',
  '$mdDialog',
  function($http, $mdDialog) {
    const self = this;
    self.today = new Date();

    self.submitting = false;

    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;

    self.submit = function() {
      self.submitting = true;
      self.message = "";

      self.promise = $http.put("/api/phoneValidations", {
        id: self.id,
        code: self.code,
      }).then(function(response) {
        self.submitting = false;
        $mdDialog.hide(response.data);
      }).catch(function(error) {
        self.promise = undefined;
        if (error.status === 500) {
          self.message
            = "The application is currently unavailable. Please retry or contact OCR for support.";
        } else {
          self.message
            = "The code you entered is not correct. Please retry.";
        }
        self.submitting = false;
      }).finally(() => self.promise = undefined);
    }
  },
]);
