angular.module('CaresApp').controller('AttachmentsViewController', [
  '$mdDialog',
  function($mdDialog) {
    const self = this;
    self.today = new Date();

    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;
  },
]);
