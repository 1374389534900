angular.module('CaresApp').component('ocrInvoiceStandardView', {
  templateUrl: 'components/ocr-invoice-standard-view/ocr-invoice-standard-view.template.html',
  require: {
    ngModel: 'ngModel',
  },
  controller: [
    '$scope',
    '$q',
    'InvoiceDataService',
    'OcrInvoiceFlagsService',
    'OcrLookups',
    'OcrUtils',
    'OcrViews',
    function($scope, $q, InvoiceDataService, OcrInvoiceFlagsService, OcrLookups,
      OcrUtils, OcrViews,
    ) {
      const self = this;
      self.currentView = OcrViews.currentView;
      self.flags = OcrInvoiceFlagsService.flags;

      self.getUserFullName = OcrUtils.getUserFullName;
      self.getNegativeAmountStyle = OcrUtils.getNegativeAmountStyle

      const setCaseActivityDetails = function(court) {
        for (const caseType of court.caseTypeSummaries) {
          for (const case_ of caseType.caseSummaries) {
            for (const activity of case_.activities) {
              const typeInfo = self.activityTypes.lookup(activity.typeId);
              activity.details = [];
              if (typeInfo.activityDurationTimePrompt && activity.activityDurationMinutes !== 0) {
                activity.details.push({
                  title: "Duration",
                  duration: activity.activityDurationMinutes,
                  durationCents: activity.activityDurationAmountCents,
                  total: activity.activityDurationAmountCents,
                });
              }
              if (typeInfo.travelTimePrompt && activity.travelDurationMinutes !== 0) {
                activity.details.push({
                  title: "Travel Time",
                  duration: activity.travelDurationMinutes,
                  durationCents: activity.travelDurationAmountCents,
                  total: activity.travelDurationAmountCents,
                });
              }
              if (typeInfo.waitTimePrompt && activity.waitTimeMinutes !== 0) {
                activity.details.push({
                  title: "Wait Time",
                  duration: activity.waitTimeMinutes,
                  durationCents: activity.waitTimeAmountCents,
                  total: activity.waitTimeAmountCents,
                });
              }
              if (typeInfo.discussionTimePrompt && activity.discussionTimeMinutes !== 0) {
                activity.details.push({
                  title: "Confer with Involved Persons",
                  duration: activity.discussionTimeMinutes,
                  durationCents: activity.discussionTimeAmountCents,
                  total: activity.discussionTimeAmountCents,
                });
              }
              if (typeInfo.mileageAmountPrompt && activity.mileageAmount !== 0) {
                activity.details.push({
                  title: "Mileage",
                  quantity: activity.mileageAmount,
                  expense: activity.mileageExpenseCents,
                  total: activity.mileageExpenseCents,
                });
              }
              if (typeInfo.copyQuantityPrompt && activity.copiesQuantity !== 0) {
                activity.details.push({
                  title: "Copies",
                  quantity: activity.copiesQuantity,
                  expense: activity.copiesExpenseCents,
                  total: activity.copiesExpenseCents,
                });
              }
              if (typeInfo.mealAmountPrompt && activity.mealExpenseCents !== 0) {
                activity.details.push({
                  title: "Child Meal",
                  expense: activity.mealExpenseCents,
                  total: activity.mealExpenseCents,
                });
              }
              if (typeInfo.expenseAmountPrompt && activity.expenseAmountCents !== 0) {
                activity.details.push({
                  title: "Expenses",
                  expense: activity.expenseAmountCents,
                  total: activity.expenseAmountCents,
                });
              }
              if (typeInfo.postageAmountPrompt && activity.postageAmountCents !== 0) {
                activity.details.push({
                  title: "Postage",
                  expense: activity.postageAmountCents,
                  total: activity.postageAmountCents,
                });
              }
              if (typeInfo.discoveryAmountPrompt && activity.discoveryAmountCents !== 0) {
                activity.details.push({
                  title: "Discovery",
                  expense: activity.discoveryAmountCents,
                  total: activity.discoveryAmountCents,
                });
              }
              if (typeInfo.filingFeeAmountPrompt && activity.filingFeeAmountCents !== 0) {
                activity.details.push({
                  title: "Filing Fee",
                  expense: activity.filingFeeAmountCents,
                  total: activity.filingFeeAmountCents,
                });
              }
              if (typeInfo.transcriptAmountPrompt && activity.transcriptAmountCents !== 0) {
                activity.details.push({
                  title: "Transcript",
                  expense: activity.transcriptAmountCents,
                  total: activity.transcriptAmountCents,
                });
              }
            }
          }
        }
      };

      const activityTypesPromise = OcrLookups.get('ActivityType').then(
        (types) => self.activityTypes = types,
      );

      const getData = function(noCache) {
        const insufficientFundsPromise =
          InvoiceDataService.getStandardViewData(self.invoice.invoiceNumber, true, noCache)
            .then(
              function(data) {
                self.insufficientFunds = data;
              },
            );
        const courtsPromise = InvoiceDataService.getStandardViewData(self.invoice.invoiceNumber, false, noCache)
          .then(
            (data) => {
              self.courts = data;
            },
          );
        $q.all([insufficientFundsPromise, activityTypesPromise]).then(
          function() {
            for (const case_ of self.insufficientFunds) {
              setCaseActivityDetails(case_);
            }
          },
        );
        $q.all([courtsPromise, activityTypesPromise]).then(
          function() {
            for (const case_ of self.courts) {
              setCaseActivityDetails(case_);
            }
          },
        );
        self.promise = $q.all(
          [insufficientFundsPromise, activityTypesPromise, courtsPromise],
        ).finally(() => {
          self.promise = null
        });
      };

      $scope.$watch(
        () => self.ngModel.$modelValue,
        function() {
          self.invoice = self.ngModel.$modelValue;
          if (self.invoice) {
            getData();
          }
        },
      );
      self.getChildPartyNames = function(childParties) {
        if (!childParties || !childParties.length) {
          return null;
        }
        const names = [];
        for (const childParty of childParties) {
          if (names.indexOf(OcrUtils.getChildPartyFullName(childParty)) === -1) {
            names.push(OcrUtils.getChildPartyFullName(childParty));
          }
        }
        return names.join('; ');
      };

      self.collapseAll = function(courts) {
        for (const court of courts) {
          for (const caseType of court.caseTypeSummaries) {
            caseType.isExpanded = false;
            for (const case_ of caseType.caseSummaries) {
              case_.isExpanded = false;
              for (const activity of case_.activities) {
                activity.isExpanded = false;
              }
            }
          }
        }
      };

      self.expandAll = function(courts) {
        for (const court of courts) {
          for (const caseType of court.caseTypeSummaries) {
            caseType.isExpanded = true;
            for (const case_ of caseType.caseSummaries) {
              case_.isExpanded = true;
              for (const activity of case_.activities) {
                activity.isExpanded = true;
              }
            }
          }
        }
      };

      self.toggleCaseType = function(caseType) {
        caseType.isExpanded = !caseType.isExpanded;
        if (caseType.isExpanded === false) {
          for (const case_ of caseType.caseSummaries) {
            case_.isExpanded = false;
            for (const activity of case_.activities) {
              activity.isExpanded = false;
            }
          }
        }
      };

      self.toggleCase = function(case_) {
        case_.isExpanded = !case_.isExpanded;
        if (case_.isExpanded === false) {
          for (const activity of case_.activities) {
            activity.isExpanded = false;
          }
        }
      };

      self.toggleActivity = function(activity) {
        activity.isExpanded = !activity.isExpanded;
      };

      $scope.$on('InvoiceViewCollapseAll',
        function() {
          if (self.insufficientFunds) {
            self.collapseAll(self.insufficientFunds);
          } else {
            const unregister = $scope.$watch(
              !self.insufficientFunds,
              function() {
                if (self.insufficientFunds) {
                  self.collapseAll();
                  unregister();
                }
              },
            );
          }
          if (self.courts) {
            self.collapseAll(self.courts);
          } else {
            const unregister = $scope.$watch(
              !self.courts,
              function() {
                if (self.courts) {
                  self.collapseAll();
                  unregister();
                }
              },
            );
          }
        },
      );

      $scope.$on('InvoiceViewExpandAll',
        function() {
          if (self.insufficientFunds) {
            self.expandAll(self.insufficientFunds);
          } else {
            const unregister = $scope.$watch(
              !self.insufficientFunds,
              function() {
                if (self.insufficientFunds) {
                  self.expandAll();
                  unregister();
                }
              },
            );
          }
          if (self.courts) {
            self.expandAll(self.courts);
          } else {
            const unregister = $scope.$watch(
              !self.courts,
              function() {
                if (self.courts) {
                  self.expandAll();
                  unregister();
                }
              },
            );
          }
        },
      );

      $scope.$on('ClearingCacheAndReloading', function() {
        getData(true);
      });

    },
  ],
});
