angular.module('CaresApp').component('ocrReports', {
  templateUrl: 'components/ocr-reports/ocr-reports.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    'OcrUtils',
    '$http',
    '$timeout',
    function($scope, OcrUtils, $http, $timeout) {
      const self = this;
      OcrUtils.setPageTitle('Reports');

      self.promise = null;
      $scope.$watch(() => self.promise, () => {
        self.setPromise()(self.promise);
      });

      self.updateData = function() {
        self.token = undefined;
        $http.get("/api/jasperTokens")
          .then(
            function(response) {
              self.token = response.data.token;
              $timeout(self.updateData, 60000);
            },
            () => self.token = undefined,
          );
      };

      self.$onInit = function() {
        self.updateData();
      };
    },
  ],
});
