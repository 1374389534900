angular.module('CaresApp').component('ocrCaseTypeEdit', {
  templateUrl: 'components/ocr-case-type-edit/ocr-case-type-edit.template.html',
  controller: [
    '$scope',
    '$document',
    '$q',
    '$location',
    '$routeParams',
    '$mdToast',
    '$mdDialog',
    'CaseTypeService',
    'OcrLookups',
    'CaseTypeBillingAllocationService',
    'CaseTypeAttorneyRoleService',
    'OcrUtils',
    'OcrAuth',
    function($scope, $document, $q, $location, $routeParams, $mdToast, $mdDialog,
      CaseTypeService, OcrLookups, CaseTypeBillingAllocationService,
      CaseTypeAttorneyRoleService, OcrUtils, OcrAuth) {
      $scope.$emit("SetSearchBox", false);
      const self = this;
      self.today = new Date();
      self.today.setHours(0);
      self.today.setMinutes(0);
      self.today.setSeconds(0);
      self.today.setMilliseconds(0);

      self.billingAllocations = [];
      self.caseTypeAttorneyRoles = [];
      self.visibleRoles = [];
      self.currentBillingAllocation = {};
      self.roleTypes = self.attorneyRoles = OcrLookups.dummyList;
      self.hasPermission = OcrAuth.checkPermission;

      OcrLookups.get('RoleType').then(
        (roleTypes) => self.roleTypes = roleTypes,
      )

      OcrLookups.get('AttorneyRole').then(function(attorneyRoles){
        self.attorneyRoles = attorneyRoles;
        self.attorneyRoles.sort(function(a,b){return a.attorneyRoleOrder - b.attorneyRoleOrder});
        if (!self.id) {
          for (role of self.attorneyRoles) {
            self.caseTypeAttorneyRoles.push({
              attorneyRoleId: role.id,
              caseTypeAttorneyRoleVisible: false,
              roleChangeAgePrompt: false,
              roleChangeWarningAge: undefined,
              roleChangeAge: undefined,
              minimumRoleChangeDate: undefined,
            })
          }
        }
      });

      self.query = {
        order: undefined,
        limit: 10,
        page: 1,
      };

      const now = new Date();
      self.today = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
      );

      function getCaseTypeData() {
        if ($routeParams.caseTypeId !== 'create') {
          self.id = $routeParams.caseTypeId;
        } else {
          OcrUtils.setPageTitle('New Case Type');
        }
        self.loading = true;
        if (self.id) {
          self.promise = CaseTypeService.get({
            id: self.id,
          }).$promise
            .then(function(resp) {
              self.promise = undefined;

              self.entity = resp;
              OcrUtils.setPageTitle(`Case Type: ${self.entity.type}`);
              if (self.entity.startDate) {
                self.entity.startDate = new Date(self.entity.startDate);
                self.entity.loadedStart = new Date(self.entity.startDate);
              }
              if (self.entity.endDate) {
                self.entity.endDate = new Date(self.entity.endDate);
              }
              if (self.entity.additionalAgeReminder == false) {
                self.additionalAge = false;
              } else {
                self.additionalAge = true;
              }
              CaseTypeAttorneyRoleService.get({
                caseTypeId: self.entity.id,
              }).$promise
                .then(function(resp) {
                  self.caseTypeAttorneyRoles = resp.list;
                  for (role of self.caseTypeAttorneyRoles) {
                    if (role.caseTypeAttorneyRoleVisible) {
                      self.visibleRoles.push(role);
                    }
                  }
                })
                .catch((error) => {
                  console.error("Unable to load attorney roles: %O", error);
                })

              self.promise = CaseTypeBillingAllocationService.get({
                caseTypeId: self.entity.id,
                skip: (self.query.page - 1) * self.query.limit,
                limit: self.query.limit,
              }).$promise;
              return self.promise;
            })
            .catch((error) => {
              self.promise = undefined;
              console.error("Unable to search case types: %O", error);
            })
            .then(function(response) {
              self.promise = undefined;
              self.billingAllocations.data = response.list;
              self.billingAllocations.count = response.count;
              self.billingAllocations.total = response.total;

              const curDate = new Date();

              for (let i = 0; i < self.billingAllocations.data.length; i++) {
                if (Date.parse(self.billingAllocations.data[i].startDate) <= curDate) {
                  self.currentBillingAllocation = self.billingAllocations.data[i];
                  self.currentBillingAllocation.startDate = new Date(
                    self.currentBillingAllocation.startDate);
                  break;
                }
              }

              self.currentBillingAllocation.apptBillingAlloCents = self.formatZero(
                self.currentBillingAllocation.apptBillingAlloCents);
            })
            .catch((error) => {
              self.promise = undefined;
              console.error("Unable to search case type billing allocations: %O", error);
            })
            .finally(() => self.loading = false);
        } else {
          self.entity = {
            startDate: self.today,
            currentBillingAllocationAmount: 0,
            currentBillingAllocationStartDate: self.today,
            requirePlacement: false,
            visitPrompt: false,
            additionalAgeReminder: false,
          };
          self.currentBillingAllocation.startDate = self.today;
          self.loading = false;
        }
      }

      getCaseTypeData();


      self.save = function(andExit) {
        const message
          = "This is a reminder that changes to this Case Type "
          + "(especially \"Visible\" and \"Age Prompts\" settings) "
          + "will immediately impact any end users using this Case Type. "
          + "Click Cancel to go back and check your entries or click Confirm.";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Please Confirm')
            .textContent(message)
            .ariaLabel(message)
            .ok('Confirm')
            .cancel('Cancel'),
        ).then(function() {
          if (!self.id) {
            self.entity.currentBillingAllocationAmount = parseInt(
              self.currentBillingAllocation.apptBillingAlloCents.replace(/\D/g, ""));
          }
          OcrLookups.remove('CaseType')
          OcrLookups.remove('CaseType', true);
          $mdToast.show(
            $mdToast.simple()
              .textContent('Saving...')
              .position("bottom right"),
          );
          let saveFunction = null;
          let saveArgs = null;
          if (self.id) {
            saveFunction = CaseTypeService.update
            saveArgs = [
              {
                id: self.id,
              },
              self.entity,
            ]
          } else {
            saveFunction = CaseTypeService.save
            saveArgs = [self.entity]
          }
          saveFunction.apply(this, saveArgs).$promise.then(
            function(resp) {
              const promises = [];
              for (role of self.caseTypeAttorneyRoles) {
                let attSaveFunction = null;
                let attSaveArgs = null;
                if (self.id) {
                  attSaveFunction = CaseTypeAttorneyRoleService.update;
                  attSaveArgs = [
                    {
                      id: role.id,
                    },
                    role,
                  ]
                } else {
                  role.caseTypeId = resp.id;
                  attSaveFunction = CaseTypeAttorneyRoleService.save;
                  attSaveArgs = [role];
                }
                promises.push(attSaveFunction.apply(this, attSaveArgs).$promise);
              }
              $q.all(promises).then(
                function(resp) {
                  self.form.$setPristine();
                  $mdToast.show(
                    $mdToast.simple()
                      .textContent('Case type saved.')
                      .position("bottom right"),
                  )
                  if (andExit) {
                    self.close(true)
                  }
                },
              ).catch((error) => {
                if (error && error.data && error.data.status === 401) {
                  self.close(true);
                  return;
                }
                $mdToast.show(
                  $mdToast.simple()
                    .textContent(error.data.reason)
                    .position("bottom right"));
              });
            },
          ).catch((error) => {
            if (error && error.data && error.data.status === 401) {
              self.close(true);
              return;
            }
            $mdToast.show(
              $mdToast.simple()
                .textContent(error.data.reason)
                .position("bottom right"));
          });
        }, () => {});
      }

      self.close = function(noConfirm) {
        if (noConfirm || self.form.$pristine) {
          $location.path(`/views/${$routeParams.view}`);
        } else {
          const message
            = "Are you sure that you want to close this form without saving your changes?";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Form Close')
              .textContent(message)
              .ariaLabel(message)
              .ok('Close Form')
              .cancel('Cancel'),
          ).then(
            () => $location.path(`/views/${$routeParams.view}`),
            () => {});
        }
      }

      self.changeBillingAllocation = function() {
        $mdDialog.show({
          controller: 'ChangeCaseTypeBillingAllocationController',
          templateUrl: 'tmpl/change-case-type-billing-allocation-dialog.html',
          parent: angular.element($document[0].body),
          locals: {
            caseTypeId: self.id,
          },
          clickOutsideToClose: false,
          fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
        })
          .then(
            getCaseTypeData, // refresh list of case types
            getCaseTypeData, // refresh list of case types
          );
      };

      self.updateData = function() {
        self.promise = CaseTypeBillingAllocationService.get({
          caseTypeId: self.entity.id,
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
        }).$promise.then(
          function(response) {
            self.billingAllocations.data = response.list;
            self.billingAllocations.count = response.count;
            self.billingAllocations.total = response.total;
          },
        ).finally(() => self.promise = null)
      };

      self.deleteForm = function() {
        const message
          = "Are you sure that you want to delete this case type?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Case Type Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete Case Type')
            .cancel('Cancel'),
        ).then(function() {
          // ok
          OcrLookups.remove('CaseType')
          OcrLookups.remove('CaseType', true);
          $mdToast.show(
            $mdToast.simple()
              .textContent('Deleting...')
              .position("bottom right"),
          );

          CaseTypeService.delete({
            id: self.id,
          }, self.entity).$promise.then(function() {
            $mdToast.show(
              $mdToast.simple()
                .textContent('Case type deleted.')
                .position("bottom right"),
            );
            $location.path(`/views/${$routeParams.view}`);
          })
            .catch(function(error) {
              console.error("Error: %O", error);
              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            });
        }, () => {});
      };

      self.disableStart =
        () => self.loading || (self.id && self.today > self.entity.loadedStart);

      self.toggleVisible = function(role) {
        if (role.caseTypeAttorneyRoleVisible) {
          self.visibleRoles.push(role);
        } else {
          const objWithId = self.visibleRoles.findIndex((obj) => obj.id === role.id);
          self.visibleRoles.splice(objWithId, 1);
          if (self.disableAgeChange()) {
            for (attRole of self.caseTypeAttorneyRoles) {
              attRole.roleChangeAgePrompt = false;
              self.toggleAgeChange(attRole);
            }
          }
        }
      }

      self.toggleAgeChange = function(role) {
        if (!role.roleChangeAgePrompt) {
          role.roleChangeWarningAge = null;
          role.roleChangeAge = null;
          role.minimumRoleChangeDate = null;
        }
      }

      self.disableSave = function() {
        if (self.visibleRoles.length > 0) {
          return self.submitting || self.form.$invalid || !self.hasPermission('ADMIN_WRITE');
        } else {
          return true;
        }
      }

      self.disableAgeChange = function() {
        if (self.loading) {
          return true;
        }
        for (role of self.visibleRoles) {
          if (self.attorneyRoles.lookup(role.attorneyRoleId).roleTypeId==2 && role.caseTypeAttorneyRoleVisible) {
            return false;
          }
        }
        return true;
      }

      self.updateAge = function() {
        if (!self.additionalAge) {
          self.entity.additionalAgeReminder = false;
          self.entity.reminderAge = null;
          self.entity.reminderText = null;
          return;
        }
        self.entity.additionalAgeReminder = true;
        $scope.$watch(
          () => angular.isDefined(self.form.reminderAge),
          function() {
            self.form.reminderAge.$setTouched();
          }, true);
        $scope.$watch(
          () => angular.isDefined(self.form.reminderMessage),
          function() {
            self.form.reminderMessage.$setTouched();
          }, true);
        return;
      }

      self.checkAge = function(form) {
        if (self.entity.reminderAge == undefined) {
          return;
        }
        if(/^(?: |[0-9]+(\.[0-9])?)?$/.test(self.entity.reminderAge) == false) {
          form.reminderAge.$setValidity('validateDecimal', false);
          form.reminderAge.$setTouched();
        } else {
          form.reminderAge.$setValidity('validateDecimal', true);
        }
        for(const attorney of self.caseTypeAttorneyRoles) {
          if (self.entity.reminderAge == attorney.roleChangeAge || self.entity.reminderAge == attorney.roleChangeWarningAge) {
            form.reminderAge.$setValidity('validateAge', false);
            form.reminderAge.$setTouched();
            return;
          }
        }
        form.reminderAge.$setValidity('validateAge', true);
        return;
      }

      self.$onInit =
        () => $scope.$watch(
          () => angular.isDefined(self.form),
          () => {
            if (self.form && self.form.$invalid) {
              angular.forEach(self.form.$error, function(field) {
                angular.forEach(field, function(errorField) {
                  errorField.$setTouched();
                });
              });
            }
          }, true);

      self.formatZero = (input) => (input === 0 ? "0.00" : input);
    },
  ],
});
