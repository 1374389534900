angular.module('CaresApp').component('ocrAppointmentAdditionalUsers', {
  templateUrl:
    'components/ocr-appointment-additional-users/ocr-appointment-additional-users.template.html',
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    onItemAdded: '&onUserAdded',
  },
  controller: [
    '$scope',
    '$document',
    '$q',
    '$filter',
    '$mdDialog',
    '$mdToast',
    'UserService',
    'OcrAuth',
    'AppointmentAdditionalUserService',
    'OcrUtils',
    'OcrLookups',
    function($scope, $document, $q, $filter, $mdDialog, $mdToast, UserService,
      OcrAuth, AppointmentAdditionalUserService, OcrUtils, OcrLookups) {
      const self = this;

      self.hasAnyPermission = OcrAuth.hasAnyPermission
      self.canEdit = (OcrAuth.checkPermission('ADMIN_WRITE')
        || OcrAuth.checkPermission('APPOINTMENT_ADDITIONAL_USERS_WRITE')
        || OcrAuth.checkPermission('APPOINTMENT_WRITE'));
      self.additionalUsers = []
      self.additionalUsers.count = 0
      self.additionalUsers.total = 0
      self.selected = [];
      self.officeUsers = []
      self.model = null;

      self.personas = OcrLookups.dummyList;

      OcrLookups.get('Persona').then((personas) => self.personas = personas);

      self.getUserFullName = OcrUtils.getUserFullName;

      let getOfficeUsers = function() {
        UserService.get({
          limit: 1000,
          office_id: OcrAuth.session.user.officeId,
        }).$promise.then(
          function(resp) {
            self.officeUsers = resp.list;
          },
        )
      }
      if (OcrAuth.session.user) {
        getOfficeUsers();
        getOfficeUsers = undefined;
      } else {
        const unregister = $scope.$watch(
          () => OcrAuth.session.user,
          function() {
            if (OcrAuth.session.user) {
              getOfficeUsers()
              getOfficeUsers = undefined;
              unregister();
            }
          },
        )
      }

      self.query = {
        page: 1,
        limit: 20,
      }
      self.paginate = function() {
        if (!self.model) {
          return;
        }
        const startIdx = (self.query.page - 1) * self.query.limit;
        let endIdx = startIdx + self.query.limit;
        if (endIdx > self.model.additionalUsers.length) {
          endIdx = self.model.additionalUsers.length;
        }
        self.additionalUsers = self.model.additionalUsers.slice(
          startIdx, endIdx);
        self.additionalUsers.total = self.additionalUsers.length;
        self.additionalUsers.count = self.model.additionalUsers.length;
      }

      self.$onInit =
        () => $scope.$watch(
          () => self.ngModel.$modelValue,
          function() {
            self.model = self.ngModel.$modelValue;
            if (self.model) {
              if (self.model.id) {
                self.isNew = false;
                self.query.page = 1;
                self.additionalUsers = []
                self.additionalUsers.total = 0
                self.paginate();
              } else {
                self.additionalUsers = []
                self.additionalUsers.count = 0
                self.additionalUsers.total = 0
                self.isNew = true;
              }
            }
          },
        );

      self.getUserById = function(id) {
        for (let c = 0; c < self.officeUsers.length; c++) {
          if (id === self.officeUsers[c].id) {
            return self.officeUsers[c];
          }
        }
        return undefined;
      };

      self.addUser = function() {
        $mdDialog.show({
          controller: 'AddAdditionalUserDialogController',
          templateUrl: 'tmpl/add-additional-user-dialog.html',
          parent: angular.element($document[0].body),
          clickOutsideToClose: false,
          locals: {
            add: true,
            officeUsers: self.officeUsers,
            user: undefined,
            existingUsers: self.model.additionalUsers,
            appointmentId: self.model.id,
            attorneyUserId: self.model.attorneyUserId,
            earliestDate: self.model.earliestDate,
          },
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
          .then(function(user) {
            user.persona = self.personas.lookup(user.personaId).name;
            AppointmentAdditionalUserService.save(user).$promise
              .then((response) => {
                user.id = response.id;
                self.model.additionalUsers.push(user)
                $filter('orderBy')(self.model.additionalUsers, (aUser) => aUser.startDate, true);
                self.paginate()
                self.selected.splice(0, self.selected.length);
                $mdToast.show(
                  $mdToast.simple()
                    .textContent("Additional User Added")
                    .position("bottom right"),
                )
              })
          }, () => {});
      }

      self.addOffice = function() {
        $mdDialog.show({
          controller: 'AddOfficeDialogController',
          templateUrl: 'tmpl/add-office-dialog.html',
          parent: angular.element($document[0].body),
          clickOutsideToClose: false,
          locals: {
            earliestDate: self.model.earliestDate,
          },
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        }).then(function(startDate) {
          const promises = [];
          let additionalUser = {};
          for (const user of self.officeUsers) {
            let extant = false;
            for (const au of self.model.additionalUsers) {
              if (user.id === au.userId) {
                extant = true;
                break;
              }
            }
            if (extant !== true && user.id !== self.model.attorneyUserId) {
              additionalUser = {};
              additionalUser.appointmentId = self.model.id;
              additionalUser.userId = user.id;
              additionalUser.user = user;
              additionalUser.personaId = user.personaId;
              additionalUser.userName = self.getUserFullName(user);
              additionalUser.userType = user.personaName;
              additionalUser.startDate = new Date(startDate);
              additionalUser.persona = self.personas.lookup(user.personaId).name;
              additionalUser.firstName = user.firstName;
              additionalUser.middleName = user.middleName;
              additionalUser.lastName = user.lastName;
              additionalUser.suffix = user.suffix;
              self.model.additionalUsers.push(additionalUser);
              $filter('orderBy')(self.model.additionalUsers, (aUser) => aUser.startDate, true);
              self.paginate()
              self.selected.splice(0, self.selected.length);
              promises.push(
                AppointmentAdditionalUserService.save(additionalUser),
              );
            }
          }
          self.promise = $q.all(promises)
            .then((_) => {
              //TODO add toast?
            });
        }, () => {});
      }

      self.editUser = function() {
        $mdDialog.show({
          controller: 'AddAdditionalUserDialogController',
          templateUrl: 'tmpl/add-additional-user-dialog.html',
          parent: angular.element($document[0].body),
          clickOutsideToClose: false,
          locals: {
            add: false,
            officeUsers: self.officeUsers,
            user: self.selected[0],
            existingUsers: self.model.additionalUsers,
            appointmentId: self.model.id,
            attorneyUserId: self.model.attorneyUserId,
            earliestDate: self.model.earliestDate,
          },
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        }).then(function(user) {
          if (user.endDate == null) {
            user.endDate = undefined;
          }
          user.persona = self.personas.lookup(user.personaId).name;
          for (let i = 0; i < self.model.additionalUsers.length; i++) {
            const listUser = self.model.additionalUsers[i]
            if (user.userId === listUser.userId) {
              self.model.additionalUsers.splice(i, 1, user);
              break;
            }
          }
          self.paginate()
          self.selected.splice(0, self.selected.length);
          AppointmentAdditionalUserService.update({
            id: user.id,
          }, user).$promise
            .then((_) => {
              //TODO add toast?
            })
        }, () => {});
      }
    },
  ],
});
