angular.module('CaresApp').component('ocrCreditEdit', {
  templateUrl: 'components/ocr-credit-edit/ocr-credit-edit.template.html',
  controller: [
    '$scope',
    '$document',
    '$location',
    '$window',
    '$mdMedia',
    '$mdToast',
    '$routeParams',
    '$mdDialog',
    '$timeout',
    '$q',
    'CreditService',
    'UserService',
    'CaseService',
    'OcrLookups',
    'OcrUtils',
    function($scope, $document, $location, $window, $mdMedia, $mdToast, $routeParams, $mdDialog,
      $timeout, $q, CreditService, UserService, CaseService, OcrLookups, OcrUtils) {
      const self = this;
      self.officeDropdown = [];

      self.creditId = ($routeParams.creditId !== "create") ? $routeParams.creditId : undefined;
      if (!self.creditId) {
        OcrUtils.setPageTitle('New Credit');
      } else {
        OcrUtils.setPageTitle(`Credit ${self.creditId}`);
      }
      self.viewId = $routeParams.view;

      self.today = new Date();
      self.listStyle = {};

      if (self.creditId) {
        self.promise = CreditService.get({
          id: self.creditId,
        }).$promise.then(function(credit) {
          self.credit = credit;
          self.credit.effectiveDate = new Date(self.credit.effectiveDate);
          self.credit.createdDate = new Date(self.credit.createdDate);
          if (self.credit.applied) {
            self.credit.applied = new Date(self.credit.applied);
          }
          self.creditAmountValue = credit.creditAmountCents / 100;
          self.validateCase();
        }).catch(() => {},
        ).finally(() => self.promise = null);
      } else {
        self.credit = {
          createdDate: new Date(),
        };
        self.isNew = true;
      }

      self.counties = self.judicialDistricts = self.caseTypes = self.offices
        = OcrLookups.dummyList;

      const lookupPromises = [
        OcrLookups.get('County').then((counties) => self.counties = counties),
        OcrLookups.get('JudicialDistrict').then((districts) => self.judicialDistricts = districts),
        OcrLookups.get('CaseType', true).then((types) => self.caseTypes = types),
        OcrLookups.get('Office', true).then((offices) => self.offices = offices),
      ];

      $q.all(lookupPromises).catch(() => {
        console.error("Unable to retrieve lookups.");
        self.permissions = [];
      });

      UserService.get({
        limit: 1000,
      }).$promise.then(function(results) {
        self.users = results.list;
      }).catch(() => {
        console.error("Unable to retrieve users.");
        self.permissions = [];
      });

      $window.addEventListener('resize', onResize, true);

      function onResize() {
        $timeout(() => {
          if (!$mdMedia("xs")) {
            self.listStyle.maxHeight = `${$window.innerHeight - 250
            }px`;
          } else {
            self.listStyle = {};
          }
        });
      }


      self.validateCase = function() {
        self.promise = CaseService.get({
          limit: 1,
          judicialDistrict: self.credit.myCase.judicialDistrictId,
          county: self.credit.myCase.countyId,
          caseYear: self.credit.myCase.caseYear,
          caseType: self.credit.myCase.caseTypeId,
          caseSequence: self.credit.myCase.caseSequence,
        }).$promise.then(function(response) {
          if (response.total > 0) {
            self.caseValid = true;
            /* eslint-disable prefer-destructuring */
            self.credit.myCase = response.list[0];
            /* eslint-enable prefer-destructuring */
            self.credit.caseId = self.credit.myCase.id

            for (let i = 0; i < self.credit.myCase.appointments.length; i++) {
              if (self.officeDropdown.indexOf(self.credit.myCase.appointments[i].officeId) === -1) {
                self.officeDropdown.push(self.credit.myCase.appointments[i].officeId);
              }
            }
          } else {
            self.caseValid = false;
            self.credit.myCase.fullCaseNumber = undefined;
            self.credit.myCase.abbreviatedCaseNumber = undefined;
          }

          self.caseValidated = true;
        }).catch((error) => {
          if (error && error.data && error.data.status === 401) {
            $location.path("/");
          }
        }).finally(() => self.promise = null);
      }

      self.updateCaseNumber = function() {
        self.caseValidated = false;
        self.caseValid = undefined;

        if (
          self.credit.myCase.countyId
          && self.credit.myCase.caseYear
          && self.credit.myCase.caseYear.toString().length === 4
          && self.credit.myCase.caseTypeId
          && self.credit.myCase.caseSequence
          && self.credit.myCase.caseSequence.length === 6
        ) {
          self.credit.myCase.fullCaseNumber =
            `D${self.credit.myCase.countyId.toString().padStart(3, "0")
            }${self.credit.myCase.caseYear
            }${self.caseTypes.lookup(self.credit.myCase.caseTypeId).type
            }${self.credit.myCase.caseSequence}`;

          self.credit.myCase.abbreviatedCaseNumber
            = self.credit.myCase.fullCaseNumber.substring(6, 8)
            + self.caseTypes.lookup(self.credit.myCase.caseTypeId).type
            + (1 * (self.credit.myCase.fullCaseNumber.substring(10)));
        }
      };

      self.closeForm = function(noConfirm) {
        // only display confirmation message if form is dirty (has modified fields)
        if (noConfirm || self.creditForm.$pristine) {
          $location.path(`/views/${self.viewId}`);
          return;
        }

        const message
          = "Are you sure that you want to close this form without saving your changes?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Form Close')
            .textContent(message)
            .ariaLabel(message)
            .ok('Close Form')
            .cancel('Cancel'),
        ).then(
          () => $location.path(`/views/${self.viewId}`),
          () => {},
        );
      };

      self.saveForm = function(close) {
        $mdToast.show(
          $mdToast.simple()
            .textContent('Saving...')
            .position("bottom right"),
        );

        const continueSave = function() {
          self.creditForm.$setPristine();
          $mdToast.show(
            $mdToast.simple()
              .textContent('Credit saved.')
              .position("bottom right"),
          ).then(() => self.isNew = false);
          if (close) {
            $location.path(`/views/${self.viewId}`);
          }
        };

        const credit = OcrUtils.makeVeryShallowCopy(self.credit)
        if (self.isNew) {
          self.promise = CreditService.save(credit)
            .$promise.then( () => continueSave())
            .catch(function(error) {
              if (error && error.data && error.data.status === 401) {
                $location.path("/");
                return;
              }

              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            }).finally(() => self.promise = null);
        } else {
          // update
          self.promise = CreditService.update({
            id: self.creditId,
          }, credit)
            .$promise.then( () => continueSave())
            .catch(function(error) {
              if (error && error.data && error.data.status === 401) {
                $location.path("/");
                return;
              }

              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            }).finally(() => self.promise = null);
        }
      };

      self.$onInit =
        () => $scope.$watch(
          () => angular.isDefined(self.creditForm),
          () => $scope.$watch(
            () => self.creditForm.$invalid,
            () => {
              if (self.creditForm && self.creditForm.$invalid) {
                angular.forEach(self.creditForm.$error, function(field) {
                  angular.forEach(field, function(errorField) {
                    errorField.$setTouched();
                  });
                });
              }
            },
          ), true);

          $scope.$on('$locationChangeStart',
            function(event, newUrl, oldUrl) {
              const removeQueryParams = (url) => {
                if (url) {
                  const index = url.indexOf("?");
                  if (index >= 0) {
                    return url.substring(0, index);
                  }
                }
                return url;
              };

              const newPath = $location.path();

              if (
                newPath === "/"
                || self.creditForm.$pristine
                || removeQueryParams(newUrl) === removeQueryParams(oldUrl)
              ) {
                return;
              }

              event.preventDefault();

              const message
                = "Are you sure that you want to close this form without saving your changes?";

              $mdDialog.show(
                $mdDialog.confirm()
                  .parent(angular.element($document[0].querySelector(
                    '#popupContainer')))
                  .clickOutsideToClose(false)
                  .title('Confirm Page Exit')
                  .textContent(message)
                  .ariaLabel(message)
                  .ok('OK')
                  .cancel('Cancel'),
              ).then(() => {
                self.creditForm.$setPristine();
                $location.path(newPath);
              });
            });

      self.updateCreditAmount = function() {
        if (self.creditAmountValue === null) {
          self.credit.creditAmountCents = null;
        } else {
          let { creditAmountValue } = self
          const creditAmountValueDotIdx = creditAmountValue.indexOf('.')
          if (creditAmountValueDotIdx === -1) {
            creditAmountValue += '00'
          } else {
            const dollars = creditAmountValue.substring(0, creditAmountValueDotIdx)
            let centsString = creditAmountValue.substring(creditAmountValueDotIdx + 1)
            if (centsString.length > 2) {
              centsString = centsString.substr(0, 2)
            } else {
              while (centsString.length < 2) {
                centsString += "0"
              }
            }
            creditAmountValue = dollars + centsString
          }
          self.credit.creditAmountCents = parseInt(creditAmountValue);
        }
      };
    },
  ],
});
