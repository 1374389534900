angular.module('CaresApp').controller('HomeAlertDialogController', [
  '$mdDialog',
  '$location',
  'OcrLookups',
  'RoleChangeAlertService',
  '$filter',
  function($mdDialog, $location, OcrLookups, RoleChangeAlertService, $filter) {
    const self = this;
    self.today = new Date();
    OcrLookups.get('CaseType', true).then(
      (caseTypes) => self.caseTypes = caseTypes,
    );
    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;

    function fixDate(dateStr) {
      return new Date(dateStr).toGMTString().substr(0, 16);
    }

    self.$onInit = function() {
      if(self.alertInfo.type == 'rc') {
        self.age = Math.floor((new Date(self.alertInfo.alertDate) - new Date(self.alertInfo.dateOfBirth)) / 31557600000);
        self.date = new Date(self.alertInfo.alertDate).toUTCString();
        self.date = self.date.substring(0, self.date.length - 13);
        self.message = `According to the date of birth entered, ${self.alertInfo.firstName} ${self.alertInfo.lastName} is ${self.age}
        as of ${fixDate(self.alertInfo.alertDate)}, meaning that your role may now be Counsel for Youth.`;
        self.title = 'Possible Transition to Client-Directed Representation';
        self.url = `/appointment/my_appointments/${self.alertInfo.appointmentId}?rcid=${self.alertInfo.childPartyId}`;
      } else if (self.alertInfo.type == 'ar') {
        self.message = self.alertInfo.reminderText;
        self.url = `/appointment/my_appointments/${self.alertInfo.appointmentId}?arid=${self.alertInfo.childPartyId}`;
        self.title = 'Age Reminder';
      } else {
        self.age = Math.floor((new Date(self.alertInfo.roleChangeDate) - new Date(self.alertInfo.dateOfBirth)) / 31557600000);
        self.message = `According to the date of birth entered, ${self.alertInfo.firstName} ${self.alertInfo.lastName} will be ${self.age} on ${fixDate(self.alertInfo.roleChangeDate)}, meaning your role\nmay transition to Counsel for Youth.  Please plan for this transition if applicable, consulting CJD 04-06 for guidance.`
        self.title = 'Possible Transition to Client-Directed Representation';
        self.url = `/appointment/my_appointments/${self.alertInfo.appointmentId}?cwid=${self.alertInfo.childPartyId}`;
      }
    }

    self.goToAppointment = function() {
      $mdDialog.hide();
      $location.path(self.url);
    }

    self.close = function() {
      if(self.alertInfo.type == 'ar') {
        RoleChangeAlertService.update({
          appointmentChildPartyId: self.alertInfo.appointmentChildPartyId,
          field: "additional",
        })
      } else if(self.alertInfo.type == 'cw') {
        RoleChangeAlertService.update({
          appointmentChildPartyId: self.alertInfo.appointmentChildPartyId,
          field: "warning",
        })
      }
      $mdDialog.hide(true);
    }
  },
]);
