angular.module('CaresApp').controller('AddChildPartyDialogController', [
  '$document',
  '$mdDialog',
  'ChildPartyService',
  'OcrUtils',
  function($document, $mdDialog, ChildPartyService, OcrUtils) {
    const self = this;

    self.childParties = [];
    self.childParties.total = 0;
    self.selected = [];
    self.query = {
      order: undefined,
      limit: 20,
      page: 1,
    };

    self.$onInit = () => self.updateData();

    self.updateData = function() {
      self.promise = ChildPartyService.get({
        exclude_appointment_id: self.appointment.id,
        exclude_party_ids: self.getCurrentChildParties(),
        skip: (self.query.page - 1) * self.query.limit,
        limit: self.query.limit,
        query: self.searchText ? self.searchText : undefined,
        order: "last_name",
      }).$promise.then(
        function(response) {
          self.childParties = response.list;
          self.childParties.total = response.total;
          for (const childParty of self.childParties) {
            childParty.dateOfBirth = new Date(childParty.dateOfBirth);
          }
        }).finally(() => self.promise = undefined);
    };

    self.getCurrentChildParties = function() {
      if (!self.appointment.childParties || self.appointment.childParties.length === 0) {
        return undefined;
      }

      const values = [];

      for (const childParty of self.appointment.childParties) {
        values.push(childParty.childPartyId);
      }
      return values;
    }


    self.createChildParty = function() {
      $mdDialog.show({
        controller: 'CreateChildPartyDialogController',
        templateUrl:
          'components/ocr-appointment-child-parties-panel/dialogs/create-child-party-dialog.html',
        parent: angular.element($document[0].body),
        clickOutsideToClose: false,
        locals: {
          age: OcrUtils.age,
        },
        bindToController: true,
        controllerAs: '$ctrl',
        fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
        multiple: true, // to allow nested dialog boxes!
      })
        .then(function(newChildParty) {
          $mdDialog.show({
            controller: 'EditAppointmentChildPartyDialogController',
            templateUrl:
              'components/ocr-appointment-child-parties-panel/dialogs/' +
              'edit-appointment-child-party-dialog.html',
            parent: angular.element($document[0].body),
            clickOutsideToClose: false,
            locals: {
              childParty: newChildParty,
              appointment: self.appointment,
              addExisting: true,
            },
            multiple: true, // to allow nested dialog boxes!
            bindToController: true,
            controllerAs: '$ctrl',
          }).then(() => {
            self.updateData();
            self.submit();
          }, () => {});
        }, () => {});
    };

    self.getUserFullName = OcrUtils.getUserFullName;
    self.age = self.getAge = OcrUtils.age;

    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;
    self.submit = $mdDialog.hide;

    self.addSelectedParty = function() {
      // open editAppointmentChildPartyDialog
      $mdDialog.show({
        controller: 'EditAppointmentChildPartyDialogController',
        templateUrl: 'components/ocr-appointment-child-parties-panel' +
          '/dialogs/edit-appointment-child-party-dialog.html',
        parent: angular.element($document[0].body),
        clickOutsideToClose: false,
        locals: {
          childParty: self.selected[0],
          appointment: self.appointment,
          addExisting: true,
        },
        multiple: true, // to allow nested dialog boxes!
        bindToController: true,
        controllerAs: '$ctrl',
      })
        .then(() => {
          self.updateData();
          self.submit();
        }, () => {});
    };

    self.checkForDuplicates = function() {
      if (!self.selected || self.selected.length === 0) {
        return true;
      }

      for (const childParty of self.appointment.childParties) {
        if (childParty.childPartyId === self.selected[0].id) {
          return true;
        }
      }
      return false;
    }

    self.checkForDuplicatesStrict = function() {
      if (!self.selected || self.selected.length === 0) {
        return false;
      }

      for (const childParty of self.appointment.childParties) {
        if (childParty.childPartyId === self.selected[0].id) {
          return true;
        }
      }
      return false;
    }

    self.doSearch = OcrUtils.debounce(() => self.updateData(), 500);
  },
]);
