angular.module('CaresApp').component('ocrAppointmentEndReasonList', {
  templateUrl:
    'components/ocr-appointment-end-reason-list/ocr-appointment-end-reason-list.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    'AppointmentEndReasonService',
    'OcrUtils',
    'OcrViews',
    function($scope, AppointmentEndReasonService, OcrUtils, OcrViews) {
      const self = this;
      OcrUtils.setPageTitle('Child Party End Reasons');
      self.currentView = OcrViews.currentView

      let currentSearch = undefined;

      self.results = {
        data: [],
        count: 0,
        total: 0,
      };
      self.promise = null;
      $scope.$watch(() => self.promise, () => self.setPromise()(self.promise));

      self.refresh = () => self.updateData();

      self.updateData = function() {
        self.promise = AppointmentEndReasonService.query({
          query: currentSearch,
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
        }).$promise.then(
          function(resp) {
            self.results.data = resp.list;
            self.results.count = resp.count;
            self.results.total = resp.total;
          },
        ).finally(() => self.promise = null)
      }

      self.$onInit = () => self.updateData();

      $scope.$on("Searching", function(_, data) {
        const call = {
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
        }

        if (data) {
          currentSearch = data;
          call.query = data;
        } else {
          currentSearch = undefined;
        }

        self.promise = AppointmentEndReasonService.query(call).$promise.then(
          function(resp) {
            self.results.data = resp.list;
            self.results.count = resp.count;
            self.results.total = resp.total;
          },
        ).finally(() => self.promise = null)
      });
    },
  ],
});
