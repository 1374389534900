angular.module('CaresApp').controller('AddInvolvedPersonDialogController', [
  '$scope',
  '$filter',
  '$mdDialog',
  '$mdToast',
  'AppointmentInvolvedPersonService',
  'OcrUtils',
  'OcrLookups',
  function($scope, $filter, $mdDialog, $mdToast,
    AppointmentInvolvedPersonService, OcrUtils, OcrLookups) {
    const self = this;
    self.involvedPersonTypes = self.states = OcrLookups.dummyList;
    OcrLookups.get('State').then((states) => self.states = states);
    const originalChildParties = OcrLookups.createLookupList([]);
    self.readOnly = false;
    self.applicableChildParties = OcrLookups.createLookupList([]);
    self.addToContacts = false;

    self.$onInit = function() {
      self.appointment.childParties =
        OcrLookups.createLookupList(self.appointment.childParties || [])

      OcrLookups.get('InvolvedPersonType').then(
        function(involvedPersonTypes) {
          if (self.involvedPerson && self.involvedPerson.typeId && !involvedPersonTypes.lookup(
            self.involvedPerson.typeId)) {
            self.involvedPersonTypes = OcrLookups.createLookupList(
              angular.copy(involvedPersonTypes))
            self.involvedPersonTypes.unshift({
              id: self.involvedPerson.typeId,
              type: self.involvedPerson.typeName,
            })
          } else {
            self.involvedPersonTypes = involvedPersonTypes
          }
        },
      );
      if (self.involvedPerson) {
        if (!self.involvedPerson.childParties.lookup) {
          OcrLookups.createLookupList(self.involvedPerson.childParties)
        }
        self.involvedPerson = angular.copy(self.involvedPerson);
        for (const childParty of self.involvedPerson.childParties) {
          originalChildParties.push(childParty)
          const appointmentChildParty = getChildPartyById(childParty.childPartyId);
          if (appointmentChildParty) {
            self.applicableChildParties.push(appointmentChildParty);
          }
        }
        self.involvedPerson.contact = self.contacts.lookup(self.involvedPerson.contactId);
        if (!self.involvedPerson.contact) {
          self.fromContacts = false;
          self.readOnly = true;
          self.involvedPerson.contact = {
            id: self.involvedPerson.contactId,
            firstName: self.involvedPerson.firstName,
            lastName: self.involvedPerson.lastName,
            organization: self.involvedPerson.organization,
            officePhone: self.involvedPerson.officePhone,
            officeExtension: self.involvedPerson.officeExtension,
            mobilePhone: self.involvedPerson.mobilePhone,
            email: self.involvedPerson.email,
            address1: self.involvedPerson.address1,
            address2: self.involvedPerson.address2,
            city: self.involvedPerson.city,
            state: self.involvedPerson.state,
            zip: self.involvedPerson.zip,
            invisible: true,
            favorite: false,
            userId: self.involvedPerson.userId,
          }
        }
      } else {
        self.involvedPerson = {
          appointmentId: self.appointment.id,
          childParties: OcrLookups.createLookupList([]),
          state: 'CO',
        }
        self.involvedPerson.contact = {
          state: "CO",
          invisible: true,
        }
      }
      $scope.$watch(
        () => self.addInvolvedPersonForm.$invalid && self.addInvolvedPersonForm.$error.length > 0,
        () => $scope.$watch(
          () => angular.isDefined(self.addInvolvedPersonForm.$error.required)
            && self.addInvolvedPersonForm.$error.required.length,
          () => angular.forEach(self.addInvolvedPersonForm.$error, function(field) {
            angular.forEach(field, function(errorField) {
              errorField.$setTouched();
            });
          }), true), true);
    }

    self.age = (dateString) => OcrUtils.age(new Date(dateString));

    self.getUserFullName = OcrUtils.getUserFullName;
    const getChildPartyById = (id) => self.appointment.childParties.lookup(id);
    function copyFromContactToPerson() {
      if (!self.involvedPerson.contact) {
        return
      }
      for (const prop in self.involvedPerson.contact) {
        if (prop === 'id') {
          continue;
        } else {
          self.involvedPerson[prop] = self.involvedPerson.contact[prop];
        }
      }
    }

    function postSave(_, response) {
      self.involvedPerson.id = response.involvedPerson.id;
      if (!self.involvedPerson.childParties.lookup) {
        OcrLookups.createLookupList(self.involvedPerson.childParties)
      }
      if (self.involvedPerson.childParties) {
        for (const addedChildParty of response.newChildParties) {
          const involvedPersonChildParty = self.involvedPerson.childParties.lookup(
            addedChildParty.childPartyId, 'childPartyId');
          if (!involvedPersonChildParty) {
            self.involvedPerson.childParties.push(addedChildParty);
          }
        }
      }
      for (const deletedChildPartyId of response.childPartiesToDelete) {
        for (let c = 0; c < self.involvedPerson.childParties.length; c++) {
          if (self.involvedPerson.childParties[c].id
            === deletedChildPartyId) {
            self.involvedPerson.childParties.splice(c, 1);
            // break;
          }
        }
      }
      $filter('orderBy')(self.appointment.involvedPersons, 'lastName');
    }

    function lookupByAttribute(array, attribute, value) {
      for (const element of array) {
        if (element[attribute] === value) {
          return element;
        }
      }
      return undefined;
    }

    function createRequestRequest() {
      const request = {
        involvedPerson: self.involvedPerson,
        contact: self.involvedPerson.contact,
        childPartiesToAdd: [],
        childPartiesToDelete: [],
      };
      for (const childParty of self.applicableChildParties) {
        if (!lookupByAttribute(originalChildParties, "childPartyId",
          childParty.id)) {
          request.childPartiesToAdd.push(childParty.id);
        }
      }
      for (const childParty of originalChildParties) {
        if (!self.applicableChildParties.lookup(childParty.childPartyId)) {
          request.childPartiesToDelete.push(childParty.id); // this may be incorrect!
        }
      }
      return request;
    }


    self.contactChanged =
        () => self.involvedPerson.contact
            = self.contacts.lookup(self.involvedPerson.contactId) || self.involvedPerson.contact;

    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;

    self.submit = function() {
      if (self.involvedPerson.contact) {
        self.involvedPerson.contactId = self.involvedPerson.contact.id;
      }
      const request = createRequestRequest();

      const type = self.involvedPersonTypes.lookup(self.involvedPerson.typeId);
      if (type) {
        self.involvedPerson.typeName = type.type;
      }

      self.saving = true;
      $mdToast.show(
        $mdToast.simple()
          .textContent('Saving...')
          .position("bottom right"),
      );
      if (!request.involvedPerson.id) {
        // create
        AppointmentInvolvedPersonService.save(request).$promise
          .then(function(response) {
            copyFromContactToPerson();
            self.appointment.involvedPersons.push(self.involvedPerson);
            postSave(request, response);
            $mdDialog.hide(self.involvedPerson);
          }).catch(function(error) {
            $mdToast.show(
              $mdToast.simple()
                .textContent('Failed to add involved person.')
                .position("bottom right"),
            );
            console.error("Unable to add involved person: %O",
              error);
          }).finally(() => self.saving = false);
      } else {
        // update
        AppointmentInvolvedPersonService.update({
          id: request.involvedPerson.id,
        }, request).$promise
          .then(function(response) {
            if (response.id) {
              self.involvedPerson.id = response.id;
            }
            copyFromContactToPerson();
            for (let i = 0; i < self.appointment.involvedPersons.length; i++) {
              const oldInvolvedPerson = self.appointment.involvedPersons[
                i];
              if (oldInvolvedPerson.id === self.involvedPerson.id) {
                self.appointment.involvedPersons.splice(i, 1, self.involvedPerson);
                break;
              }
            }
            postSave(request, response);
            $mdDialog.hide(self.involvedPerson);
          }).catch(function(error) {
            $mdToast.show(
              $mdToast.simple()
                .textContent('Failed to update involved person.')
                .position("bottom right"),
            );
            console.error("Unable to update involved person: %O",
              error);
          }).finally(() => self.saving = false);
      }
    };
  },
]);
