angular.module('CaresApp').component('ocrAppointmentFinancialProfile', {
  templateUrl:
    'components/ocr-appointment-financial-profile/ocr-appointment-financial-profile.template.html',
  require: {
    ngModel: 'ngModel',
  },
  controller: [
    '$scope',
    '$document',
    '$mdDialog',
    '$mdToast',
    'OcrUtils',
    'UserService',
    'OcrAuth',
    'ExcessFeesRequestService',
    'OtherExpenseRequestService',
    function ($scope, $document, $mdDialog, $mdToast, OcrUtils, UserService, OcrAuth,
      ExcessFeesRequestService, OtherExpenseRequestService) {
      const self = this;

      self.model = null;

      self.getUserFullName = OcrUtils.getUserFullName;

      self.hasPermission = OcrAuth.checkPermission;

      self.$onInit = function () {
        $scope.$watch(
          () => self.ngModel.$modelValue,
          function () {
            self.model = self.ngModel.$modelValue;
            if (self.model) {
              if (self.model.id) {
                self.isNew = false;
                UserService.get({
                  limit: 1000,
                  office_id: self.model.officeId,
                }).$promise.then(
                  (resp) => self.usersInOffice = resp.list,
                );
                if (OcrAuth.checkPermission("BILLING_REQUEST_CREATE") ||
                  OcrAuth.checkPermission("BILLING_REQUEST_CREATE_OFFICEWIDE")) {
                  ExcessFeesRequestService.query({ appointment: self.model.id, checkForPendingOnly: true })
                    .$promise.then((resp) => self.pendingRequestExists = resp.pending);
                }
              } else {
                self.isNew = true;
              }
            }
          },
        );
      }

      self.showAbaHistory = () => $mdDialog.show({
        controller: 'AbaHistoryDialogController',
        templateUrl: 'tmpl/aba-history-dialog.html',
        parent: angular.element($document[0].body),
        clickOutsideToClose: false,
        locals: {
          appointment: self.model,
          officeUsers: self.usersInOffice,
        },
      });

      self.requestAdditionalFunding = function () {
        if (self.pendingRequestExists) {
          $mdDialog.show(
            $mdDialog.alert()
              .title('Pending Request')
              .htmlContent(
                'There is already one additional funding request pending for this '
                + 'appointment.<br>' +
                'You will not be able to submit another funding request until the pending '
                + 'request<br>' +
                'is processed. Please go to Requests > Additional Funds to review your '
                + 'pending<br>' +
                'request. If the status is "Submitted", you may delete and thereby<br>' +
                'withdraw it if needed. Email info@coloradochildrep.org with questions.')
              .ok('Close')
              .targetEvent(event),
          );
          return;
        }
        $mdDialog.show({
          controller: 'ExcessFeesRequestDialogController',
          templateUrl: 'tmpl/excess-fees-request-dialog.html',
          parent: angular.element($document[0].body),
          clickOutsideToClose: false,
          locals: {
            appointment: self.model,
          },
        }).then(function (request) {
          self.promise = ExcessFeesRequestService.save(request).$promise
            .then((response) => self.feesRequests.push(response))
            .catch((error) => {
              if (error && error.data) {
                $mdToast.show(
                  $mdToast.simple()
                    .textContent(error.data.reason)
                    .position("bottom right"),
                );
              }
            }).finally(() => self.promise = null);
        }, () => { });
      };

      self.requestAdditionalExpense = function () {
        $mdDialog.show({
          controller: 'OtherApprovalsRequestDialogController',
          templateUrl: 'tmpl/other-approvals-request-dialog.html',
          parent: angular.element($document[0].body),
          clickOutsideToClose: false,
          locals: {
            appointment: self.model,
          },
        })
          .then(function (request) {
            self.promise = OtherExpenseRequestService.save(request)
              .$promise.then().catch(function (error) {
                if (error && error.data) {
                  $mdToast.show(
                    $mdToast.simple()
                      .textContent(error.data.reason)
                      .position("bottom right"),
                  );
                }
              }).finally(() => self.promise = null);
          }, () => { });
      };

      self.isAppointmentAttorney = function () {
        return self.model.attorneyUserId === OcrAuth.session.userId;
      }

      self.canRequestAdditionalFunds = function () {
        if (OcrAuth.checkPermission('BILLING_REQUEST_CREATE_OFFICEWIDE')) {
          return true;
        }
        if (OcrAuth.checkPermission('BILLING_REQUEST_CREATE') && self.model.attorneyUserId === OcrAuth.session.userId) {
          return true;
        }
        return false;
      }
    },
  ],
});
