angular.module('CaresApp').component('ocrMyAppointments', {
  templateUrl: 'components/ocr-my-appointments/ocr-my-appointments.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    '$location',
    'AppointmentService',
    'OcrAuth',
    'OcrUtils',
    'OcrViews',
    'SessionService',
    function($scope, $location, AppointmentService, OcrAuth,
      OcrUtils, OcrViews, SessionService) {
      const self = this;
      self.today = OcrUtils.today;
      OcrUtils.setPageTitle('My Appointments');
      self.currentView = OcrViews.currentView;
      self.getUserFullName = OcrUtils.getUserFullName;

      self.appointments = [];
      self.appointments.count = 0;
      self.appointments.total = 0;

      self.hasPermission = OcrAuth.checkPermission;



      self.promise = null;
      $scope.$watch(() => self.promise, () => self.setPromise()(self.promise));

      self.$onInit = function() {
        if ($location.search('case') != undefined) {
          $location.search('case', null);
        }
      };

      self.requestAccess = function() {
        var url = '' + self.currentView.key + '/' + self.appointments.appointment.id;
        $location.path(url);
      }

      self.resetFilter = function() {
        $scope.$broadcast("resetFilters");
      }

      self.setTab = function(tabName) {
        if ((tabName !== 'inOffice' || $location.hash())) {
          // so that going back in the browser will take us to the correct tab
          $location.hash(tabName);
        }
      };

      self.isTabActive =
        (tabName) => ($location.hash() === tabName) || (!$location.hash() && tabName === "inOffice");

      $scope.$on("LoggingOut",
        () => {
          SessionService.signout();
        },
      );

      $scope.$on('$locationChangeStart',
        function(_, newUrl, oldUrl) {
          const newArray = newUrl.split("?");
          const oldArray = oldUrl.split("?");
          if (newArray[0] !== oldArray[0]) {
            $location.search("");
          }
        });
    },
  ],
});
