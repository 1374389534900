angular.module('CaresApp').controller('activeUserRemindDialogController', [
  '$location',
  '$mdDialog',
  '$mdToast',
  'UserService',
  'user',
  'OcrLookups',
  function($location, $mdDialog, $mdToast, UserService, user, OcrLookups) {
    const self = this;
    self.user = user;
    self.today = new Date();

    const currentSearch = undefined;

    OcrLookups.get('UrlLinkText').then(
      (urlLinkText) => self.deactivateUser = urlLinkText.lookup(4).text,
    )

    self.submitting = false;
    self.results = {
      data: [],
      count: 0,
      total: 0,
    };
    self.promise = null;
    self.query = {
      order: undefined,
      limit: 20,
      page: 1,
    };

    self.openLink = function() {
      window.open(self.deactivateUser, '_blank').focus()
      self.remindLater();
    }

    self.remindLater = function() {
      user.activeUserRemind = new Date();
      // Add 10 days before reminding again
      //TODO reenable for Production
      user.activeUserRemind.setDate(user.activeUserRemind.getDate() + 180);
      // user.tfaRemind = new Date(user.tfaRemind.getTime() + (600000));
      UserService.update(user).$promise.then(
        () => {
          $mdDialog.hide();
        },
      ).catch((error) => {
        if (error && error.data) {
          $mdToast.show(
            $mdToast.simple()
              .textContent(error.data.reason)
              .position("bottom right"),
          );
        }
      })
    };

    self.updateData = function() {
      self.promise = UserService.query({
        office_id: user.officeId,
        active: true,
      }).$promise.then(
        function(resp) {
          self.results.data = resp.list;
          // FIXME the count and total are swapped for some reason
          self.results.count = resp.total;
          self.results.total = resp.count;
        },
      ).finally(() => self.promise = null);
    };

    self.$onInit = function() {
      self.updateData();
    };

    self.hide = $mdDialog.hide;
  },
]);
