angular.module('CaresApp').controller('AddAppointmentGroupWithAppointmentDialogController', [
  '$scope',
  '$mdDialog',
  'AppointmentGroupService',
  'AppointmentService',
  'UserService',
  'OcrAuth',
  'OcrUtils',
  'AppointmentDataService',
  function($scope, $mdDialog, AppointmentGroupService, AppointmentService, UserService, OcrAuth, OcrUtils, AppointmentDataService) {
    const self = this;

    self.submitting = false;
    self.selected = [];
    self.appointmentGroups = [];
    self.isDuplicate = false;
    let promises = [];
    self.getUserFullName = OcrUtils.getUserFullName;
    self.attorneysInOffice = [];

    self.$onInit = function() {
      $scope.$watch(
        () => angular.isDefined(self.form),
        () => {
          if (self.form) {
            self.form.newAppointmentGroupName.$setTouched();
            self.form.attorney.$setTouched();
          }
        }, true);
      AppointmentGroupService.get({
        limit: 1000,
        officeId: self.officeId,
      }).$promise.then(
        function(resp) {
          self.appointmentGroups = resp.list;
        },
      );
      promises.push(UserService.get({
        hasPermission: 'CASE_APPOINT',
      }).$promise.then(function(response) {
        for (const attorney of response.list) {
          if(attorney.statusId != 2) {
            self.attorneysInOffice.push(attorney);
          }
        }
      }));
    }

    self.checkGroupName = function(form) {
      for (const group of self.appointmentGroups) {
        if (group.name.toLowerCase() == self.newAppointmentGroupName.toLowerCase()) {
          form.newAppointmentGroupName.$setValidity('validateGroupName', false);
          form.newAppointmentGroupName.$setTouched();
          self.isChange = true;
          return;
        }
      }
      form.newAppointmentGroupName.$setValidity('validateGroupName', true);
      return;
    }

    self.checkPermissions = function(attorney) {
      return
    }

    self.getAppointments = function() {
      self.attorneySelected = true;
      promises.push(AppointmentService.get({
        inOffice: true,
        attorneyUserId: self.attorneyId,
        showActive: true,
        inGroup: false,
      }).$promise.then(function(response) {
        self.appointments = response.list;
        if (response.list == 0) {
          self.hasAppointments = false;
        } else {
          self.hasAppointments = true;
        }
        for (const appointment of self.appointments) {
          const childPartyNames = [];
          if (appointment.childParties) {
            for (const childParty of appointment.childParties) {
              if (childParty.endDate) {
                childParty.endDate = new Date(childParty.endDate);
              }
              if (!childParty.endDate || childParty.endDate > self.today) {
                childPartyNames.push(OcrUtils.getChildPartyFullName(
                  childParty));
              }
            }
          }
          appointment.childPartyNames = childPartyNames.join("; ");
        }
      }));
    }

    self.hide = $mdDialog.hide;
    self.cancel = function() {
      $mdDialog.hide();
    }
    self.submit = () => $mdDialog.hide(self.selected[0]);

    self.addToNewAppointmentGroup = function(newAppointmentGroupName) {
      self.submitting = true;
      const addAppointmentIds = []
      for (const appointment_ of self.selected) {
        const request = {
          appointment: appointment_.id,
        }
        addAppointmentIds.push(request);
      }
      promises.push(AppointmentGroupService.save({
        officeId: self.officeId,
        attorneyUserId: self.attorneyId,
        name: newAppointmentGroupName,
        appointmentIds: addAppointmentIds,
      }).$promise.then(function(resp) {
        self.submitting = false;
        $mdDialog.hide(resp)
      }
      ));
      self.submitting = false;
    };
  },
]);
