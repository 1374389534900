angular.module('CaresApp').component('ocrPlacementList', {
  templateUrl: 'components/ocr-placement-list/ocr-placement-list.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    '$location',
    'PlacementService',
    'OcrUtils',
    'OcrViews',
    'OcrAuth',
    function($scope, $location, PlacementService, OcrUtils, OcrViews, OcrAuth) {
      const self = this;
      OcrUtils.setPageTitle('Placements');
      self.currentView = OcrViews.currentView;
      self.hasPermission = OcrAuth.checkPermission;

      let currentSearch = undefined;

      self.results = {
        data: [],
        count: 0,
        total: 0,
      };
      self.promise = null;
      $scope.$watch(() => self.promise, () => {
        self.setPromise()(self.promise)
      })

      self.refresh = () => self.updateData();

      self.updateData = function() {
        $location.search({
          search: currentSearch,
        });
        self.promise = PlacementService.query({
          query: currentSearch,
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
        }).$promise.then(
          function(resp) {
            self.results.data = resp.list;
            self.results.count = resp.count;
            self.results.total = resp.total;
          },
        ).finally(() => self.promise = null)
      }

      self.$onInit = function() {
        currentSearch = $location.search().search;
        self.updateData()
      }

      $scope.$on("Searching", function(_, data) {
        const call = {
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
        }

        if (data) {
          currentSearch = data;
          call.query = data;
        } else {
          currentSearch = undefined;
        }

        $location.search({
          search: currentSearch,
        });

        self.promise = PlacementService.query(call).$promise.then(
          function(resp) {
            self.results.data = resp.list;
            self.results.count = resp.count;
            self.results.total = resp.total;
          },
        ).finally(() => self.promise = null)
      });

      $scope.$on('$locationChangeStart',
        function(_, newUrl, oldUrl) {
          const newArray = newUrl.split("?");
          const oldArray = oldUrl.split("?");
          if (newArray[0] !== oldArray[0]) {
            $location.search("");
          }
        });
    },
  ],
});
