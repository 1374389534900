angular.module('CaresApp').component('ocrExceptionLog', {
  templateUrl: 'components/ocr-exception-log/ocr-exception-log.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    '$document',
    '$mdDialog',
    '$location',
    'ExceptionLogService',
    'UserService',
    'OcrLookups',
    'OcrUtils',
    'OcrViews',
    'OcrAuth',
    function($scope, $document, $mdDialog, $location, ExceptionLogService, UserService,
      OcrLookups, OcrUtils, OcrViews, OcrAuth,
    ) {
      const self = this;
      OcrUtils.setPageTitle('Exception Log');
      self.currentView = OcrViews.currentView

      self.checkPermission = OcrAuth.checkPermission;

      self.exceptionStatuses = self.offices = self.ocrUsers = OcrLookups.dummyList;
      OcrLookups.get('ExceptionStatus').then(
        (exceptionStatuses) => self.exceptionStatuses = exceptionStatuses,
      );
      OcrLookups.get('Office', true).then((offices) => self.offices = offices);

      self.results = {
        data: [],
        count: 0,
        total: 0,
      };
      self.search = {};
      self.selected = [];
      self.promise = null;
      $scope.$watch(() => self.promise, () => self.setPromise()(self.promise));

      self.updateData = function() {
        self.search.skip = (self.query.page - 1) * self.query.limit;
        self.search.limit = self.query.limit;
        self.search.order = self.query.order;
        $location.search(self.search);
        self.promise = ExceptionLogService.query(
          self.search,
        ).$promise.then(
          function(resp) {
            self.results.data = resp.list;
            self.results.count = resp.total;
            self.results.total = resp.count;
          },
        ).finally(() => self.promise = null)
      }

      self.loadUsers = function() {
        OcrLookups.getAppSetting('OCROfficeId').then(
          function(response) {
            return UserService.get({
              limit: 1000,
              office_id: response.intValue,
              order: 'last_name',
            }).$promise
          },
        ).then(
          (response) => self.ocrUsers = OcrLookups.createLookupList(response.list))
      };

      self.getUserFullName = function(first, middle, last, suffix) {
        const user = {
          firstName: first,
          middleName: middle,
          lastName: last,
          suffix: suffix,
        }
        return OcrUtils.getUserFullName(user);
      }

      self.$onInit = function() {
        self.search = $location.search();
        if (self.search.limit) {
          self.query.limit = self.search.limit;
          if (self.search.skip) {
            self.query.page = (self.search.skip / self.search.limit) + 1;
          }
        }
        self.updateData();
        self.loadUsers();
      }

      self.canEdit = (exception) => (exception.createdByUserId === OcrAuth.session.userId ?
        self.checkPermission('EXCEPTION_WRITE') : false);

      self.addException = function() {
        $mdDialog.show({
          controller: 'AddExceptionDialogController',
          templateUrl: 'components/ocr-exception-log/add-exception-dialog.template.html',
          parent: angular.element($document[0].body),
          locals: {
            add: true,
            exception: undefined,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          multiple: true,
          clickOutsideToClose: false,
          //fullscreen: self.customFullscreen // Only for -xs, -sm breakpoints.
        }).then(function(newException) {
          self.results.data.push(newException);
        }, function() {});
      }

      self.editException = function(exception) {
        $mdDialog.show({
          controller: 'AddExceptionDialogController',
          templateUrl: 'components/ocr-exception-log/add-exception-dialog.template.html',
          parent: angular.element($document[0].body),
          locals: {
            add: false,
            exception: exception,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          multiple: true,
          clickOutsideToClose: false,
          //fullscreen: self.customFullscreen // Only for -xs, -sm breakpoints.
        }).then(function(newException) {
          if (newException && newException.refresh) {
            self.updateData();
          } else {
            for (let i = 0; i < self.results.data.length; i++) {
              const oldException = self.results.data[i];
              if (newException) {
                if (oldException.id === exception.id) {
                  self.results.data.splice(i, 1, newException);
                  break;
                }
              } else if (oldException.id === exception.id) {
                self.results.data.splice(i, 1);
                break;
              }
            }
          }
        }, () => {});
      }

      self.reorder = self.updateData;

      self.refresh = function() {
        self.search.office = undefined;
        self.search.exceptionStatus = undefined;
        self.search.createdByUser = undefined;
        self.updateData();
      };

      self.openAttachments = (attachments) => $mdDialog.show({
        controller: 'ExceptionAttachmentsViewController',
        templateUrl: 'components/ocr-exception-log/exception-attachments-view.template.html',
        parent: angular.element($document[0].body),
        locals: {
          attachments: attachments,
        },
        bindToController: true,
        controllerAs: '$ctrl',
        clickOutsideToClose: false,
        multiple: true,
        fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
      });

      $scope.$on('$locationChangeStart',
        function(_, newUrl, oldUrl) {
          const newArray = newUrl.split("?");
          const oldArray = oldUrl.split("?");
          if (newArray[0] !== oldArray[0]) {
            $location.search("");
          }
        });
    },
  ],
});
