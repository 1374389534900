angular.module('CaresApp').component('ocrContact', {
  templateUrl: 'components/ocr-contact/ocr-contact.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    '$document',
    '$mdDialog',
    '$mdToast',
    'ContactService',
    'OcrAuth',
    'OcrUtils',
    function($scope, $document, $mdDialog, $mdToast, ContactService, OcrAuth, OcrUtils,
    ) {
      $scope.$emit("SetSearchBox", true);
      const self = this;
      OcrUtils.setPageTitle("My Contacts")
      self.favoriteOnly = false;
      self.selected = [];

      self.results = {
        data: [],
        count: 0,
        total: 0,
      };
      self.promise = null;
      $scope.$watch(() => self.promise, () => self.setPromise()(self.promise));

      self.updateData = function() {
        const call = {
          userId: OcrAuth.session.userId,
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
          order: self.query.order,
          favorite: self.favoriteOnly,
        }

        if (self.searchQuery) {
          call.query = self.searchQuery;
        }

        self.promise = ContactService.query(call).$promise.then(
          function(resp) {
            self.results.data = resp.list;
            self.results.count = resp.count;
            self.results.total = resp.total;
          },
        ).finally(() => self.promise = null)
      }

      self.$onInit = () => self.updateData();

      $scope.$on("Searching", (_, data) => {
        self.searchQuery = data;
        self.updateData();
      });

      self.filterFavorites = () => self.updateData();

      self.addContact = function() {
        $mdDialog.show({
          controller: 'AddContactDialogController',
          templateUrl: 'tmpl/add-contact-dialog.html',
          parent: angular.element($document[0].body),
          locals: {
            add: true,
            contact: undefined,
          },
          clickOutsideToClose: false,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
          .then((contact) => {
            self.updateData();
          }, () => {});
      };

      self.editContact = function() {
        $mdDialog.show({
          controller: 'AddContactDialogController',
          templateUrl: 'tmpl/add-contact-dialog.html',
          parent: angular.element($document[0].body),
          locals: {
            add: false,
            contact: self.selected[0],
          },
          clickOutsideToClose: false,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
          .then((contact) => {
            Object.assign(self.selected[0], contact); // copy attributes to selected contact
            self.selected.length = 0;
            OcrAuth.session.contacts = undefined
          }, () => {});
      };

      self.deleteContact = function() {
        const message
          = "Are you sure that you want to delete the selected contact?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Contact Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete Contact')
            .cancel('Cancel'),
        ).then(function() {
          // ok
          ContactService.delete({
            id: self.selected[0].id,
          }, self.selected[0]).$promise.then(function() {
            $mdToast.show(
              $mdToast.simple()
                .textContent('Contact deleted.')
                .position("bottom right"),
            );
            OcrAuth.session.contacts = undefined
            const index = self.results.data.indexOf(self.selected[
              0]);
            if (index >= 0) {
              self.results.data.splice(index, 1);
              self.selected.length = 0;
            }
          })
            .catch(function(error) {
              if (error && error.data) {
                $mdToast.show(
                  $mdToast.simple()
                    .textContent(error.data.reason)
                    .position("bottom right"),
                );
              }
            });
        }, () => {});
      };

      self.getFullAddress = function(contact) {
        if (contact.fullAddress) {
          return contact.fullAddress;
        }
        let address = "";
        if (contact.address1) {
          address += contact.address1;
        }
        if (contact.address1 && contact.address2) {
          address += ", ";
        }
        if (contact.address2) {
          address += contact.address2;
        }
        if (contact.address1 || contact.address2) {
          address += ", ";
        }
        let city = false;
        let state = false;
        if (contact.city) {
          city = true;
          address += contact.city;
        }
        if (contact.state) {
          state = true;
          if (city) {
            address += ", ";
          }
          address += contact.state;
        }
        if (contact.zip) {
          if (state) {
            address += " ";
          }
          address += contact.zip;
        }
        contact.fullAddress = address;
        return address;
      };

      self.reorder = () => self.updateData();
    },
  ],
});
