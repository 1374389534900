angular.module('CaresApp').controller('CreateChildPartyDialogController', [
  '$scope',
  '$document',
  '$mdDialog',
  'age',
  'ChildPartyService',
  '$mdToast',
  'OcrLookups',
  'OcrUtils',
  function($scope, $document, $mdDialog, age, ChildPartyService, $mdToast, OcrLookups,
    OcrUtils) {
    const self = this;

    self.age = age;

    self.isNew = true;
    self.party = {
      state: "CO",
    };

    self.submitting = false;

    self.today = OcrUtils.today

    const now = new Date();
    self.twentyOneYearsAgo = new Date(
      now.getFullYear() - 21,
      now.getMonth(),
      now.getDate(),
    );

    self.racesEthnicities = self.genders = self.states = OcrLookups.dummyList
    OcrLookups.get('RaceEthnicity').then(
      (racesEthnicities) => self.racesEthnicities = racesEthnicities,
    );
    OcrLookups.get('Gender').then((genders) => self.genders = genders);
    OcrLookups.get('State').then((states) => self.states = states);


    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;

    self.submit = function() {
      self.submitting = true;
      self.promise = ChildPartyService.save(self.party).$promise;
      self.promise.then(function(response) {
        self.promise = undefined;
        self.submitting = false;

        self.party.id = response.id;

        $mdToast.show(
          $mdToast.simple()
            .textContent("Child party created.")
            .position("bottom right"),
        );

        $mdDialog.hide(self.party);
      })
        .catch(function(error) {
          self.promise = undefined;
          self.submitting = false;

          if (error && error.data) {
            $mdToast.show(
              $mdToast.simple()
                .textContent(error.data.reason)
                .position("bottom right"),
            );
          }
        });
    };

    self.createParty = function() {
      self.submitting = true;
      const query = `${self.party.firstName}+${self.party.lastName}`;

      //TODO should be self or self.

      self.promise = ChildPartyService.get({
        skip: 0,
        limit: 1000,
        query: query,
      }).$promise.then(
        function(response) {
          if (response.total > 0) {
            for (const childParty of response.list) {
              if (childParty && childParty.dateOfBirth) {
                childParty.dateOfBirth = new Date(childParty.dateOfBirth);
                self.party.dateOfBirth = new Date(self.party.dateOfBirth);
                if (childParty.firstName === self.party.firstName
                  && childParty.lastName === self.party.lastName
                  && childParty.dateOfBirth.getTime() === self.party.dateOfBirth.getTime()
                ) {
                  let message = undefined;
                  let month = childParty.dateOfBirth.getMonth();
                  month++;
                  const date = `${month}-${childParty.dateOfBirth.getDate()}-${
                    childParty.dateOfBirth.getFullYear()}`;
                  message = `There is already a child party named ${childParty.firstName} ${
                    childParty.lastName}, with a date of birth on ${date
                  }. To avoid creating a duplicate, you can click Cancel, then use `
                    + `"Search child parties" to find the existing child. Or, click Create Child `
                    + `Party to proceed with this new entry.`;

                  $mdDialog.show(
                    $mdDialog.confirm()
                      .parent(angular.element($document[0].querySelector(
                        '#popupContainer')))
                      .clickOutsideToClose(false)
                      .title('Duplicate Child Party')
                      .textContent(message)
                      .ariaLabel(message)
                      .multiple(true)
                      .ok('Create Child Party')
                      .cancel('Cancel'),
                  ).then(
                    () => self.submit(),
                    () => self.submitting = false,
                  );
                  return
                }
              }
            }
          }
          self.submit();
        }).finally(() => self.promise = undefined);
    }

    $scope.$watch(
      () => angular.isDefined(self.partyForm),
      function() {
        $scope.$watch(
          () => self.partyForm.$invalid === true,
          function() {
            angular.forEach(self.partyForm.$error,
              (field) => angular.forEach(field, (errorField) => errorField.$setTouched()));
          }, true);
      }, true);
  },
]);
