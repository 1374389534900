angular.module('CaresApp').component('ocrPrivilegeHolderEdit', {
  templateUrl: 'components/ocr-privilege-holder-edit/ocr-privilege-holder-edit.template.html',
  controller: [
    '$scope',
    '$document',
    '$location',
    '$routeParams',
    '$mdToast',
    '$mdDialog',
    'PrivilegeHolderService',
    'OcrLookups',
    'OcrUtils',
    'OcrAuth',
    function($scope, $document, $location, $routeParams, $mdToast, $mdDialog,
      PrivilegeHolderService, OcrLookups, OcrUtils, OcrAuth,
    ) {
      $scope.$emit("SetSearchBox", false);
      const self = this;
      self.today = new Date();
      self.hasPermission = OcrAuth.checkPermission;

      if ($routeParams.privilegeHolderId !== 'create') {
        self.id = $routeParams.privilegeHolderId;
      } else {
        OcrUtils.setPageTitle('New Privilege Holder');
      }
      self.loading = true;
      if (self.id) {
        self.promise = PrivilegeHolderService.get({ id: self.id }).$promise
          .then(function(resp) {
            self.entity = resp;
            OcrUtils.setPageTitle(`Privilege Holder: ${self.entity.holder}`);
            if (self.entity.startDate) {
              self.entity.startDate = new Date(self.entity.startDate)
              self.entity.loadedStart = new Date(self.entity.startDate);
            }
            if (self.entity.endDate) {
              self.entity.endDate = new Date(self.entity.endDate)
            }
          })
          .finally(() => self.loading = false);
      } else {
        self.entity = {}
        self.loading = false
      }


      self.save = function(andExit) {
        OcrLookups.remove('PrivilegeHolder')
        OcrLookups.remove('PrivilegeHolder', true);
        $mdToast.show(
          $mdToast.simple()
            .textContent('Saving...')
            .position("bottom right"),
        );
        let saveFunction = null;
        let saveArgs = null;
        if (self.id) {
          saveFunction = PrivilegeHolderService.update
          saveArgs = [{ id: self.id }, self.entity]
        } else {
          saveFunction = PrivilegeHolderService.save
          saveArgs = [self.entity]
        }
        saveFunction.apply(this, saveArgs).$promise.then(
          function() {
            self.form.$setPristine();
            $mdToast.show(
              $mdToast.simple()
                .textContent('Privilege holder saved.')
                .position("bottom right"),
            )
            if (andExit) {
              self.close(true)
            }
          },
        ).catch(function(error) {
          if (error && error.data && error.data.status === 401) {
            self.close(true);
            return;
          }
          if (error && error.data) {
            $mdToast.show(
              $mdToast.simple()
                .textContent(error.data.reason)
                .position("bottom right"),
            );
          }
        })
      }

      self.close = function(noConfirm) {
        if (noConfirm || self.form.$pristine) {
          $location.path(`/views/${$routeParams.view}`);
        } else {
          const message
              = "Are you sure that you want to close this form without saving your changes?";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Form Close')
              .textContent(message)
              .ariaLabel(message)
              .ok('Close Form')
              .cancel('Cancel'),
          ).then(
            () => $location.path(`/views/${$routeParams.view}`),
            () => {},
          );
        }
      }

      self.deleteForm = function() {
        const message
          = "Are you sure that you want to delete this privilege holder?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Privilege Holder Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete Privilege Holder')
            .cancel('Cancel'),
        ).then(function() {
          // ok
          OcrLookups.remove('PrivilegeHolder')
          OcrLookups.remove('PrivilegeHolder', true);
          $mdToast.show(
            $mdToast.simple()
              .textContent('Deleting...')
              .position("bottom right"),
          );

          PrivilegeHolderService.delete({
            id: self.id,
          }, self.entity).$promise.then(function() {
            $mdToast.show(
              $mdToast.simple()
                .textContent('Privilege holder deleted.')
                .position("bottom right"),
            );
            $location.path(`/views/${$routeParams.view}`);
          })
            .catch(function(error) {
              if (error && error.data) {
                $mdToast.show(
                  $mdToast.simple()
                    .textContent(error.data.reason)
                    .position("bottom right"),
                );
              }
            });
        }, () => {});
      };

      self.disableStart = function() {
        if (self.loading) {
          return true;
        } else if (self.id) {
          if (self.today > self.entity.loadedStart) {
            return true;
          }
          return false;
        }
        return false;
      }

      self.$onInit =
        () => $scope.$watch(
          () => angular.isDefined(self.form),
          function() {
            if (self.form && self.form.$invalid) {
              angular.forEach(self.form.$error, function(field) {
                angular.forEach(field, function(errorField) {
                  errorField.$setTouched();
                });
              });
            }
          }, true);

    $scope.$on('$locationChangeStart',
      function(event, newUrl, oldUrl) {
        const removeQueryParams = (url) => {
          if (url) {
            const index = url.indexOf("?");
            if (index >= 0) {
              return url.substring(0, index);
            }
          }
          return url;
        };

        const newPath = $location.path();

        if (
          newPath === "/"
          || self.form.$pristine
          || removeQueryParams(newUrl) === removeQueryParams(oldUrl)
        ) {
          return;
        }

        event.preventDefault();

        const message
          = "Are you sure that you want to close this form without saving your changes?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Page Exit')
            .textContent(message)
            .ariaLabel(message)
            .ok('OK')
            .cancel('Cancel'),
        ).then(() => {
          self.form.$setPristine();
          $location.path(newPath);
        });
      });

    },
  ],
});
