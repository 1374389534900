angular.module('CaresApp').component('ocrHelpArticleCategoryEdit', {
  templateUrl:
    'components/ocr-help-article-category-edit/ocr-help-article-category-edit.template.html',
  controller: [
    '$scope',
    '$document',
    '$location',
    '$routeParams',
    '$mdToast',
    '$mdDialog',
    'HelpArticleCategoryService',
    'OcrLookups',
    'OcrUtils',
    'OcrAuth',
    function($scope, $document, $location, $routeParams, $mdToast, $mdDialog,
      HelpArticleCategoryService, OcrLookups, OcrUtils, OcrAuth,
    ) {
      $scope.$emit("SetSearchBox", false);
      const self = this;
      self.hasPermission = OcrAuth.checkPermission;

      if ($routeParams.helpArticleCategoryId !== 'create') {
        self.id = $routeParams.helpArticleCategoryId;
      } else {
        OcrUtils.setPageTitle('New Help Article Category');
      }
      self.loading = true;
      if (self.id) {
        self.promise = HelpArticleCategoryService.get({ id: self.id }).$promise
          .then((resp) => {
            self.entity = resp;
            OcrUtils.setPageTitle(`Help Article Category: ${self.entity.category}`);
          })
          .finally(() => self.loading = false);
      } else {
        self.entity = {}
        self.loading = false
      }

      self.save = function(andExit) {
        OcrLookups.remove('HelpArticleCategory')
        OcrLookups.remove('HelpArticleCategory', true);
        $mdToast.show(
          $mdToast.simple()
            .textContent('Saving...')
            .position("bottom right"),
        );
        let saveFunction = null;
        let saveArgs = null;
        if (self.id) {
          saveFunction = HelpArticleCategoryService.update
          saveArgs = [{ id: self.id }, self.entity]
        } else {
          saveFunction = HelpArticleCategoryService.save
          saveArgs = [self.entity]
        }
        saveFunction.apply(this, saveArgs).$promise.then(
          function() {
            self.form.$setPristine();
            $mdToast.show(
              $mdToast.simple()
                .textContent('Help article category saved.')
                .position("bottom right"),
            )
            if (andExit) {
              self.close(true)
            }
          },
        ).catch(function(error) {
          if (error && error.data && error.data.status === 401) {
            self.close(true);
            return;
          }
          $mdToast.show(
            $mdToast.simple()
              .textContent(error.data.reason)
              .position("bottom right"),
          );
        })
      }

      self.close = function(noConfirm) {
        if (noConfirm || self.form.$pristine) {
          $location.path(`/views/${$routeParams.view}`);
        } else {
          const message
              = "Are you sure that you want to close this form without saving your changes?";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Form Close')
              .textContent(message)
              .ariaLabel(message)
              .ok('Close Form')
              .cancel('Cancel'),
          ).then(
            () => $location.path(`/views/${$routeParams.view}`),
            () => {},
          );
        }
      }

      self.deleteForm = function() {
        const message
          = "Are you sure that you want to delete this help article category?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Help Article Category Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete Help Article Category')
            .cancel('Cancel'),
        ).then(function() {
          // ok
          OcrLookups.remove('HelpArticleCategory')
          OcrLookups.remove('HelpArticleCategory', true);
          $mdToast.show(
            $mdToast.simple()
              .textContent('Deleting...')
              .position("bottom right"),
          );

          HelpArticleCategoryService.delete({
            id: self.id,
          }, self.entity).$promise.then(function() {
            $mdToast.show(
              $mdToast.simple()
                .textContent('Help article category deleted.')
                .position("bottom right"),
            );
            $location.path(`/views/${$routeParams.view}`);
          })
            .catch(function(error) {
              console.error("Error: %O", error);
              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            });
        }, () => {});
      };

      $scope.$on('$locationChangeStart',
        function(event, newUrl, oldUrl) {
          const removeQueryParams = (url) => {
            if (url) {
              const index = url.indexOf("?");
              if (index >= 0) {
                return url.substring(0, index);
              }
            }
            return url;
          };

          const newPath = $location.path();

          if (
            newPath === "/"
            || self.form.$pristine
            || removeQueryParams(newUrl) === removeQueryParams(oldUrl)
          ) {
            return;
          }

          event.preventDefault();

          const message
            = "Are you sure that you want to close this form without saving your changes?";

          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Page Exit')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK')
              .cancel('Cancel'),
          ).then(() => {
            self.form.$setPristine();
            $location.path(newPath);
          });
        });

      self.$onInit =
        () => $scope.$watch(
          () => angular.isDefined(self.form),
          () => {
            if (self.form && self.form.$invalid) {
              angular.forEach(self.form.$error,
                (field) => angular.forEach(field, (errorField) => errorField.$setTouched()));
            }
          }, true);
    },
  ],
});
