angular.module('CaresApp').component('ocrOfficeActivitiesStandardView', {
  templateUrl: 'components/ocr-office-activities/ocr-office-activities-standard-view.template.html',
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    selected: '=',
    updateData: '=',
    query: '=',
  },
  controller: [
    '$scope',
    'OcrUtils',
    function($scope, OcrUtils) {
      const self = this;

      self.results = {};

      self.getUserFullName = OcrUtils.getUserFullName;

      self.$onInit = function() {
        $scope.$watch(
          () => self.ngModel.$modelValue,
          function() {
            self.results = self.ngModel.$modelValue;
          },
        );
      };
    },
  ],
});
