angular.module('CaresApp').controller(
  'EditAppointmentChildPartyDialogController', [
    '$scope',
    '$document',
    '$filter',
    '$q',
    '$mdDialog',
    '$mdToast',
    '$location',
    '$routeParams',
    'AppointmentOperations',
    'OcrUtils',
    'AppointmentChildPartyService',
    'CaseService',
    'CaseDataService',
    'OcrLookups',
    'OcrAuth',
    'AppointmentService',
    'ChildPartyService',
    'CaseTypeAttorneyRoleService',
    'AppointmentDataService',
    '$timeout',
    function($scope, $document, $filter, $q, $mdDialog, $mdToast, $location, $routeParams,
      AppointmentOperations, OcrUtils, AppointmentChildPartyService, CaseService,
      CaseDataService, OcrLookups, OcrAuth, AppointmentService, ChildPartyService,
      CaseTypeAttorneyRoleService, AppointmentDataService, $timeout) {
      const self = this;
      // Lookups
      self.racesEthnicities = self.genders = self.states
        = self.permanencyGoalOptions = self.privilegeHolderOptions
        = self.appointmentEndReasons = self.educationalSettings
        = self.attorneyRoles = self.bestInterestsContinuationReasons = OcrLookups.dummyList;
      OcrLookups.get('RaceEthnicity').then(
        (racesEthnicities) => self.racesEthnicities = racesEthnicities,
      );
      OcrLookups.get('Gender').then((genders) => self.genders = genders);
      OcrLookups.get('State').then((states) => self.states = states);
      OcrLookups.get('ChildPartyCourtAttendanceOption').then(
        /* eslint-disable implicit-arrow-linebreak */
        (childPartyCourtAttendanceOptions, ) =>
          self.childPartyCourtAttendanceOptions = childPartyCourtAttendanceOptions,
        /* eslint-enable implicit-arrow-linebreak */
      );
      OcrLookups.get('AttorneyRole').then((attorneyRoles) => self.attorneyRoles = attorneyRoles);
      OcrLookups.get('BestInterestsContinuationReason', true).then(
        (bestInterestsContinuationReasons) => self.bestInterestsContinuationReasons = bestInterestsContinuationReasons,
      );
      OcrLookups.get('HelpText').then(
        (helpText) => self.bicrHelpText = helpText.lookup(7).text,
      );
      // Locals
      const now = new Date();
      // Attributes
      self.maxDate = self.bestInterestsMaxDate = self.clientDirectedMaxDate = self.today = OcrUtils.today;
      self.clientDirectedMinDate = self.minEndDate = undefined;
      self.activePlacementCount = 0;
      self.canEditCjdComplianceFields = OcrAuth.checkPermission('CJD_COMPLIANCE_EDIT');
      self.twentyOneYearsAgo = new Date(
        now.getFullYear() - 21,
        now.getMonth(),
        now.getDate(),
      );
      self.bestInterestsRoles = [];
      self.clientDirectedRoles = [];
      self.selectedChildPartyPlacements = [];
      self.selectedChildPartyEducationalSettings = [];
      self.childDateChanged = false;
      self.yesOrNo = [
        {
          id: true,
          name: 'Yes',
        },
        {
          id: false,
          name: 'No',
        },
      ];

      self.icwaOptions = [
        {
          id: 'Y',
          name: 'Yes',
        },
        {
          id: 'N',
          name: 'No',
        },
        {
          id: 'I',
          name: 'Under Investigation',
        },
      ];
      const promises = [];
      let childGroup = false;


      // Methods
      self.$onInit = function() {

        OcrLookups.get('PermanencyGoal').then(
          function(permanencyGoalOptions) {
            if (self.childParty
              && self.childParty.permanencyGoalId
              && !permanencyGoalOptions.lookup(self.childParty.permanencyGoalId)
            ) {
              self.permanencyGoalOptions = angular.copy(permanencyGoalOptions);
              self.permanencyGoalOptions.unshift({
                id: self.childParty.permanencyGoalId,
                permanencyGoal: self.childParty.permanencyGoal,
              });
            } else {
              self.permanencyGoalOptions = permanencyGoalOptions;
            }
          },
        );
        OcrLookups.get('PrivilegeHolder').then(
          function(privilegeHolderOptions) {
            if (self.childParty
              && self.childParty.privilegeHolderId
              && !privilegeHolderOptions.lookup(self.childParty.privilegeHolderId)
            ) {
              self.privilegeHolderOptions = angular.copy(privilegeHolderOptions);
              self.privilegeHolderOptions.unshift({
                id: self.childParty.privilegeHolderId,
                holder: self.childParty.privilegeHolder,
              });
            } else {
              self.privilegeHolderOptions = privilegeHolderOptions;
            }
          },
        );
        OcrLookups.get('EducationalSetting', true).then(
          function(educationalSettings) {
            self.educationalSettings = educationalSettings;
            setEducationalSettingDetails();
          },
        );
        self.isNew = self.addExisting;
        self.saveButtonText = self.isNew ? "Add Child Party" : "Save Changes";
        self.savingButtonText = "Saving";

        CaseTypeAttorneyRoleService.get({
          caseTypeId: self.appointment.caseTypeId,
          visible: true,
          bestInterestsRoleId: self.childParty ? self.childParty.bestInterestsRoleId : undefined,
          clientDirectedRoleId: self.childParty ? self.childParty.clientDirectedRoleId : undefined,
        }).$promise.then(
          function(response) {
            $scope.$watch(
              () => self.attorneyRoles.length > 0,
              function() {
                self.caseTypeAttorneyRoles = OcrLookups.createLookupList(response.list);
                for (role of response.list) {
                  if (self.attorneyRoles.lookup(role.attorneyRoleId).roleTypeId == 1) {
                    self.bestInterestsRoles.push(role);
                  } else if (self.attorneyRoles.lookup(role.attorneyRoleId).roleTypeId == 2) {
                    self.clientDirectedRoles.push(role);
                  }
                }
                self.rolesLoaded = true;
                self.setAlertDates();
              }, true);
          }
        );

        if (!self.childParty) {
          self.childParty = {
            appointmentId: self.appointment.id,
            placements: [],
            educationalSettings: [],
            waiveVisit: false,
          };
          $scope.$watch(
            () => self.childParty.dateOfBirth,
            function() {
              self.setAlertDates();
            });
        } else {
          self.childParty = angular.copy(self.childParty);
          if (self.addExisting && self.childParty.id && !self.childParty.childPartyId) {
            self.childParty.childPartyId = self.childParty.id;
            self.childParty.id = undefined;
          }
          if (!self.childParty.appointmentId) {
            self.childParty.appointmentId = self.appointment.id;
          }
          if (self.childParty.bestInterestsStartDate) {
            self.childParty.bestInterestsStartDate = new Date(self.childParty.bestInterestsStartDate);
          }
          if (self.childParty.clientDirectedStartDate) {
            self.childParty.clientDirectedStartDate = new Date(self.childParty.clientDirectedStartDate);
          }
          if (self.childParty.startDate) {
            self.childParty.startDate = new Date(self.childParty.startDate);
          }
          if (self.childParty.endDate) {
            self.childParty.endDate = new Date(self.childParty
              .endDate);
          }
          if (self.childParty.placements) {
            setMostRecentChildPartyPlacement();
          } else {
            self.childParty.placements = [];
          }
          if (!self.childParty.educationalSettings) {
            self.childParty.educationalSettings = [];
          }
          if (!self.childParty.waiveVisit) {
            self.childParty.waiveVisit = false;
          }
          self.activePlacementCount = self.getActivePlacementCount();
          self.initialBICR = self.childParty.bestInterestsContinuationReasonId;
          self.setBestInterestsMaxDate();
          self.setClientDirectedMaxDate();
          self.setClientDirectedMinDate();
          self.setMinEndDate();
          if (self.alertResponse) {

            if (self.alertResponse.action === "1") {

              $scope.$watch(
                () => self.rolesLoaded,
                function() {
                  if (self.rolesLoaded) {

                    for (let role of self.clientDirectedRoles) {

                      if (self.attorneyRoles.lookup(role.attorneyRoleId).attorneyRole === "Counsel for Youth") {
                        self.clientDirectedClicked(role);
                        self.childParty.clientDirectedStartDate = new Date(self.alertResponse.roleChangeDate);
                        self.startDateChanged();
                        break;
                      }
                    }
                  }
                });
            } else if (self.alertResponse.action === "3") {
              self.childParty.bestInterestsContinuationReasonId = self.alertResponse.bicr;
            }
          }
          $scope.$watch(
            () => self.childParty.dateOfBirth,
            function() {
              self.setAlertDates();
            });
        }

        self.childPartyInfo = angular.copy(self.childParty);

        OcrLookups.get('AppointmentEndReason').then(
          function(appointmentEndReasons) {
            self.appointmentEndReasons = appointmentEndReasons;
            if (self.childParty.endingReasonId
              && !appointmentEndReasons.lookup(self.childParty.endingReasonId)
            ) {
              self.appointmentEndReasons = angular.copy(self.appointmentEndReasons)
              self.appointmentEndReasons.push({
                id: self.childParty.endingReasonId,
                reason: self.childParty.endingReason,
              })
            }
          },
        );

        self.childParty.dateOfBirth = new Date(self.childParty.dateOfBirth);

        self.appointment.caseDateOpened = new Date(self.appointment.caseDateOpened);
      }

      self.filterBICR = function(bicr) {
        if (bicr.id == self.initialBICR) {
          return true;
        } else if (new Date(bicr.startDate) < self.today) {
          if (!bicr.endDate || new Date(bicr.endDate) > self.today) {
            return true;
          }
        }
        return false;
      }

      self.showBICR = function() {
        return self.childParty.bestInterestsRoleId &&
              self.childParty.roleChangeDate &&
              self.caseTypeAttorneyRoles &&
              self.caseTypeAttorneyRoles.lookup(self.childParty.bestInterestsRoleId).roleChangeAgePrompt &&
              !self.childParty.endDate;
      }

      self.requireBICR = function() {

        if (self.canEditCjdComplianceFields) {
          // Users that can edit the CJD compliance fields need to be able to update the child party
          // even if the BICR is required. These are OCR staff members.
          return false;
        }

        if (self.childParty.roleChangeDate) {
          const roleChangeDate = new Date(self.childParty.roleChangeDate);
          if (roleChangeDate <= self.today) {
            if (!self.childParty.clientDirectedStartDate && !self.childParty.endDate) {
              return true;
            } else {
              let minDate;
              if (!self.childParty.clientDirectedStartDate) {
                minDate = new Date(self.childParty.endDate);
              } else if (!self.childParty.endDate) {
                minDate = new Date(self.childParty.clientDirectedStartDate);
              } else if (new Date(self.childParty.endDate) < new Date(self.childParty.clientDirectedStartDate)) {
                minDate = new Date(self.childParty.endDate);
              } else {
                minDate = new Date(self.childParty.clientDirectedStartDate);
              }
              if (minDate > roleChangeDate) {
                return true;
              }
            }
          }
        }
        return false;
      }

      function setEducationalSettingDetails() {
        for (const childPartyEducationalSetting of self.childParty.educationalSettings) {
          const educationalSetting = self.educationalSettings.lookup(
            childPartyEducationalSetting.educationalSettingId);
          childPartyEducationalSetting.name = educationalSetting.name;
          childPartyEducationalSetting.email = educationalSetting.email;
          childPartyEducationalSetting.phone = educationalSetting.phone;
          if (self.childPartyEducationalSetting) {
            childPartyEducationalSetting.address1 = self.childPartyEducationalSetting.address1;
            childPartyEducationalSetting.address2 = self.childPartyEducationalSetting.address2;
            childPartyEducationalSetting.city = self.childPartyEducationalSetting.city;
            childPartyEducationalSetting.state = self.childPartyEducationalSetting.state;
            childPartyEducationalSetting.zip = self.childPartyEducationalSetting.zip;
          }
        }
      }

      function setMostRecentChildPartyPlacement() {
        let mostRecent;
        for (let i = 0; i < self.childParty.placements.length; i++) {
          if (self.childParty.placements[i].deleted) {
            continue;
          }
          if (!mostRecent) {
            mostRecent = self.childParty.placements[i];
          }
          if (new Date(mostRecent.startDate).getTime() < new Date(
            self.childParty.placements[i].startDate).getTime()) {
            mostRecent = self.childParty.placements[i];
          }
          self.childParty.placements[i].isMostRecent = false;
        }
        if (mostRecent) {
          mostRecent.isMostRecent = true;
        }
      }

      self.age = OcrUtils.age;

      self.getActivePlacementCount = function() {
        let count = 0;
        if (self.childParty.placements) {
          for (let i = 0; i < self.childParty.placements.length; i++) {
            if (!self.childParty.placements[i].deleted) {
              count++;
            }
          }
        }
        return count;
      };

      self.filterOutDeleted = (value) => value.deleted !== true;

      self.saveFunction = function(closeAppointment, updateAssociated) {
        const request = {
          appointmentId: self.appointment.id,
          childParty: self.childParty,
        };
        const promises = [];
        if (self.childParty.id) {
          promises.push(AppointmentChildPartyService.update({
            id: self.childParty.id,
          }, request).$promise);
        } else {
          promises.push(AppointmentChildPartyService.save(request).$promise);
        }
        if (closeAppointment) {
          let startDate = undefined;
          let endDate = undefined;
          self.saving = true;
          /* eslint-disable prefer-destructuring */
          for (const childParty of self.appointment.childParties) {
            if (angular.isUndefined(startDate)) {
              startDate = childParty.startDate;
            } else if (childParty.startDate && childParty.startDate < startDate) {
              startDate = childParty.startDate;
            }
            if (angular.isUndefined(endDate)) {
              endDate = childParty.endDate;
            } else if (childParty.endDate && childParty.endDate > endDate) {
              endDate = childParty.endDate;
            }
          }

          if (angular.isUndefined(startDate) || startDate > self.childParty.startDate) {
            startDate = self.childParty.startDate;
          }

          if (angular.isUndefined(endDate) || endDate < self.childParty.endDate) {
            endDate = self.childParty.endDate;
          }
          /* eslint-enable prefer-destructuring */

          self.appointment.startDate = startDate;
          self.appointment.earliestDate = startDate;
          self.appointment.endDate = endDate;

          promises.push(AppointmentOperations.close(
            self.appointment.id,
            self.appointment.endDate,
          ));

          // THIS DOES NOT WORK, BECAUSE IT ONLY RETURNS APPOINTMENTS FOR THE CURRENT OFFICE
          // AND A CASE CAN HAVE APPOINTMENTS IN OTHER OFFICES.
          // DO IT IN BACKEND?

          // promises.push(AppointmentService.get({
          //   caseId: self.appointment.caseId,
          // }).$promise.then(function(resp) {
          //   let count = 0;
          //   let maxDate = new Date(self.childParty.endDate);
          //   resp.list.forEach((appointment) => {
          //     if (appointment.hasOwnProperty("endDate")) {
          //       count += 1;
          //       if (maxDate < new Date(appointment.endDate)) {
          //         maxDate = appointment.endDate;
          //       }
          //     }
          //   })
          //   count += 1;
          //   if (count == resp.list.length) {
          //     promises.push(CaseDataService.get({
          //       id: self.appointment.caseId,
          //     }).$promise.then(function(response){
          //
          //       console.log("CaseDataService response: %O", response);
          //       debugger;
          //
          //       response.action = "Close";
          //       response.status = "Closed";
          //       response.statusId = 2;
          //       response.dateClosed = maxDate;
          //       response.statusHistory.push({
          //         date: maxDate,
          //         status: "Closed",
          //       });
          //
          //       let saveFunction = CaseService.update;
          //       let saveArgs = [
          //         {
          //           id: self.id,
          //         },
          //         response,
          //       ];
          //       saveFunction.apply(this, saveArgs);
          //     }))
          //   }
          // }));
        }
        self.promise = $q.all(promises).then(
          function(response) {
            let toastText = null;
            if (self.isNew) {
              toastText = "Child party created.";
            } else {
              toastText = "Child party updated.";
            }
            $mdToast.show(
              $mdToast.simple()
                .textContent(toastText)
                .position("bottom right"),
            );
            Object.assign(self.childParty, response[0]);
            self.childParty.startDate = new Date(self.childParty.startDate);
            if (self.childParty.endDate) {
              self.childParty.endDate = new Date(self.childParty.endDate);
            }
            if (self.isNew) {
              self.childParty.id = response[0].childParties[0].id;
              self.appointment.childParties.push(self.childParty);
            } else {
              for (let i = 0; i < self.appointment.childParties.length; i++) {
                const oldChildParty = self.appointment.childParties[i];
                if (oldChildParty.id === self.childParty.id) {
                  self.appointment.childParties.splice(i, 1, self.childParty);
                  break;
                }
              }
            }
            $filter('orderBy')(self.appointment.childParties, ['-startDate', 'endDate']);
          },
        ).catch(function(error) {
            console.error("Server error: %O", error);
            $mdToast.show(
              $mdToast.simple()
                .textContent(error.data.reason)
                .position("bottom right"),
            );
          },
        ).finally(() => {
          self.saving = true;
          self.promise = null;

          if (self.appointment.appointmentGroupId && childGroup && updateAssociated) {
            self.saving = true;
            self.promise = AppointmentChildPartyService.update({
              id: self.childParty.id,
              push: 1,
            }, request).$promise;

            $mdToast.show(
              $mdToast.simple()
                .textContent('Applying changes to associated appointments...')
                .position("bottom right"),
            );

            self.promise
              .then(function() {
                self.saving = false;
                self.promise = null;
                $mdToast.show(
                  $mdToast.simple()
                    .textContent('Changes applied to associated appointments.')
                    .position("bottom right"),
                );
                if (closeAppointment) {
                  $location.path(`/views/${$routeParams.view}`);
                }
              })
              .catch(function(error) {
                self.saving = false;
                self.promise = null;
                $mdToast.show(
                  $mdToast.simple()
                    .textContent(
                      `Unable to apply changes to associated appointments: ${
                        error.data.reason}`,
                    )
                    .position("bottom right"),
                );
              });
            if (closeAppointment) {
              $location.path(`/views/${$routeParams.view}`);
            }
          }else if (closeAppointment) {
            $location.path(`/views/${$routeParams.view}`);
          }

          $mdDialog.hide(self.childDateChanged)

        });
      }

      self.getFullAddress = function(placement) {
        if (placement.fullAddress) {
          return placement.fullAddress;
        }
        let address = "";
        if (placement.address1) {
          address += placement.address1;
        }
        if (placement.address1 && placement.address2) {
          address += ", ";
        }
        if (placement.address2) {
          address += placement.address2;
        }
        if (placement.address1 || placement.address2) {
          address += ", ";
        }
        let city = false;
        let state = false;
        if (placement.city) {
          city = true;
          address += placement.city;
        }
        if (placement.state) {
          state = true;
          if (city) {
            address += ", ";
          }
          address += placement.state;
        }
        if (placement.zip) {
          if (state) {
            address += " ";
          }
          address += placement.zip;
        }
        placement.fullAddress = address;
        return address;
      };


      self.addPlacement = function() {
        $mdDialog.show({
          controller: 'AddChildPartyPlacementDialogController',
          templateUrl: 'components/ocr-appointment-child-parties-panel/dialogs'
            + '/add-child-party-placement-dialog.html',
          parent: angular.element($document[0].body),
          locals: {
            add: true,
            childPartyPlacement: undefined,
          },
          clickOutsideToClose: false,
          fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
          multiple: true, // to allow nested dialog boxes!
          bindToController: true,
          controllerAs: '$ctrl',
        })
          .then(function(childPartyPlacement) {
            childPartyPlacement.appointmentChildPartyId = self.childParty
              .id;
            self.childParty.placements.push(childPartyPlacement);
            self.childParty.placements.sort(function(a, b) {
              return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
            });
            self.activePlacementCount = self.getActivePlacementCount();
          }, function() {});
      };

      self.addSavedPlacement = function() {
        $mdDialog.show({
          controller: 'AddSavedChildPartyPlacementDialogController',
          templateUrl: 'components/ocr-appointment-child-parties-panel/dialogs/'
            + 'add-saved-child-party-placement-dialog.html',
          parent: angular.element($document[0].body),
          locals: {
            add: true,
            childPartyPlacement: undefined,
          },
          clickOutsideToClose: false,
          fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
          multiple: true, // to allow nested dialog boxes!
          bindToController: true,
          controllerAs: '$ctrl',
        })
          .then(function(childPartyPlacement) {
            childPartyPlacement.appointmentChildPartyId = self.childParty
              .id;
            self.childParty.placements.push(childPartyPlacement);
            self.childParty.placements.sort(function(a, b) {
              return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
            });
            self.activePlacementCount = self.getActivePlacementCount();
          }, function() {});
      };

      self.editPlacement = function() {
        $mdDialog.show({
          controller: 'AddChildPartyPlacementDialogController',
          templateUrl: 'components/ocr-appointment-child-parties-panel/' +
            'dialogs/add-child-party-placement-dialog.html',
          parent: angular.element($document[0].body),
          locals: {
            add: false,
            childPartyPlacement: self.selectedChildPartyPlacements[0],
          },
          clickOutsideToClose: false,
          fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
          multiple: true, // to allow nested dialog boxes!
          bindToController: true,
          controllerAs: '$ctrl',
        })
          .then(function(childPartyPlacement) {
            Object.assign(self.selectedChildPartyPlacements[0],
              childPartyPlacement); // copy attributes to selected contact
            // clear selected array without changing object reference
            self.selectedChildPartyPlacements.length = 0;
            self.activePlacementCount = self.getActivePlacementCount();
          }, function() {});
      };

      self.deletePlacement = function() {
        const message
          = "Are you sure that you want to delete the selected placement?";
        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Placement Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete Placement')
            .cancel('Cancel')
            .multiple(true),
        ).then(function() {
          // ok
          self.selectedChildPartyPlacements[0].deleted = true;
          self.selectedChildPartyPlacements.length = 0;
          setMostRecentChildPartyPlacement();
          self.activePlacementCount = self.getActivePlacementCount();
        }, () => {});
      };

      self.getChildPartyEducationalSettingById =
        (id) => OcrUtils.getArrayElementById(self.childParty.educationalSettings, "id", id);

      self.addEducationalSetting = function() {
        $mdDialog.show({
          controller: 'AddEducationalSettingDialogController',
          templateUrl: 'components/ocr-appointment-child-parties-panel/dialogs/' +
            'add-educational-setting-dialog.template.html',
          parent: angular.element($document[0].body),
          locals: {
            add: true,
            childPartyEducationalSetting: undefined,
            getEducationalSettingById: self.getEducationalSettingById,
          },
          clickOutsideToClose: false,
          //fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
          multiple: true, // to allow nested dialog boxes!
          bindToController: true,
          controllerAs: '$ctrl',
        })
          .then(function(childPartyEducationalSetting) {
            if (!self.childParty.educationalSettings) {
              self.childParty.educationalSettings = [];
            }
            self.childParty.educationalSettings.push(
              childPartyEducationalSetting);
          }, function() {});
      };

      self.editEducationalSetting = function() {
        $mdDialog.show({
          controller: 'AddEducationalSettingDialogController',
          templateUrl: 'components/ocr-appointment-child-parties-panel/dialogs/'
            + 'add-educational-setting-dialog.template.html',
          parent: angular.element($document[0].body),
          locals: {
            add: false,
            childPartyEducationalSetting: self.selectedChildPartyEducationalSettings[
              0],
            getEducationalSettingById: self.getEducationalSettingById,
          },
          clickOutsideToClose: false,
          fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
          multiple: true, // to allow nested dialog boxes!
          bindToController: true,
          controllerAs: '$ctrl',
        })
          .then(function(childPartyEducationalSetting) {
            Object.assign(self.selectedChildPartyEducationalSettings[0],
              childPartyEducationalSetting); // copy attributes to selected setting
            // clear selected array without changing object reference
            self.selectedChildPartyEducationalSettings.length = 0;
          }, function() {});
      };

      self.deleteEducationalSetting = function() {
        const message
          = "Are you sure that you want to delete the selected educational setting?";
        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Educational Setting Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete Educational Setting')
            .cancel('Cancel')
            .multiple(true),
        ).then(function() {
          // ok
          self.selectedChildPartyEducationalSettings[0].deleted
            = true;
          self.selectedChildPartyEducationalSettings.length = 0;
        }, function() {});
      };

      self.disableSavedPlacement =
        () => !(OcrAuth.session
          && OcrAuth.session.placements
          && OcrAuth.session.placements.length
          && OcrAuth.session.placements.length > 0);

      self.hide = () => $mdDialog.hide({
        startDate: self.childParty.startDate,
        endDate: self.childParty.endDate,
        closeAppointment: self.childParty.endDate && self.childParty.isLastActive,
      });

      self.cancel = $mdDialog.cancel;

      self.submit = function() {
        if (self.activityOutside()) {
          const message = "Your changes to this child party would exclude one or more activity dates<br>"
                        + "that were already entered for this appointment. Please use child party dates<br>"
                        + "that include all existing activity dates, or \"Cancel\" your child party<br>"
                        + "updates and edit your activity date(s) first.";
          $mdDialog.show(
            $mdDialog.alert()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Activity Dates')
              .htmlContent(message)
              .ariaLabel(message)
              .ok("OK")
              .multiple(true),
          );
          return;
        }
        self.childParty.edited = true;
        if (self.childParty.endDate == null) {
          self.childParty.endDate = undefined;
        }

        if (!self.appointment.id) {
          if (self.isNew) {
            if (self.appointment.childParties.indexOf(self.childParty) === -1) {
              self.appointment.childParties.push(self.childParty);
            }
          } else {
            for (let i = 0; i < self.appointment.childParties.length; i++) {
              const oldChildParty = self.appointment.childParties[i];
              if (oldChildParty.childPartyId === self.childParty.childPartyId) {
                self.appointment.childParties.splice(i, 1, self.childParty);
                break;
              }
            }
          }

          $filter('orderBy')(self.appointment.childParties, ['-startDate', 'endDate']);
          $mdDialog.hide({
            startDate: self.childParty.startDate,
            endDate: self.childParty.endDate,
            closeAppointment: self.childParty.endDate && self.childParty.isLastActive,
          });
          return;
        }

        self.saving = true;
        self.childParty.appointmentId = self.appointment.id;
        const request = {
          appointmentId: self.appointment.id,
          childParty: self.childParty,
        };
        self.promise = null;
        const saveFunction = function(closeAppointment, updateAssociated) {
          if(angular.isDefined(self.appointment.endDate) && angular.isUndefined(self.childParty.endDate)) {
            self.promise = CaseService.get({
              id: self.appointment.caseId,
            }).$promise;
            self.promise.then(function(response) {
              if (response.statusId === 2) {
                $mdDialog.show({
                  controller: 'ReopenCaseAppointmentDialogController',
                  templateUrl: 'components/ocr-appointment/dialogs/reopen-case-appointment-dialog.html',
                  parent: angular.element($document[0].body),
                  locals: {
                    appointment: self.appointment,
                    case: response,
                  },
                  bindToController: true,
                  controllerAs: '$ctrl',
                  clickOutsideToClose: false,
                  multiple: true,
                }).then(function(response) {
                  // User selected Yes. Response does not really matter. The case is reopened from within the dialog. Why?
                  saveFunction(closeAppointment);
                }, function() {
                  // user selected No.
                  self.appointment.endDate = undefined;
                  const appointmentData = OcrUtils.makeVeryShallowCopy(self.appointment);
                  self.saving = true;
                  self.promise = AppointmentService.update({
                    id: self.appointment.id,
                  }, appointmentData).$promise;

                  $mdToast.show(
                    $mdToast.simple()
                      .textContent('Applying changes to appointment...')
                      .position("bottom right"),
                  );

                  self.promise
                    .then(function() {
                      self.saving = false;
                      self.promise = null;
                      $mdToast.show(
                        $mdToast.simple()
                          .textContent('Changes applied to appointment.')
                          .position("bottom right"),
                      );
                      if (closeAppointment) {
                        $location.path(`/views/${$routeParams.view}`);
                      }
                    })
                    .catch(function(error) {
                      self.saving = false;
                      self.promise = null;
                      $mdToast.show(
                        $mdToast.simple()
                          .textContent(
                            `Unable to apply changes to appointments: ${
                              error.data.reason}`,
                          )
                          .position("bottom right"),
                      );
                    });
                  self.saveFunction(closeAppointment, updateAssociated);
                });
              } else {
                self.appointment.endDate = undefined;
                const appointmentData = OcrUtils.makeVeryShallowCopy(self.appointment);
                self.saving = true;
                self.promise = AppointmentService.update({
                  id: self.appointment.id,
                }, appointmentData).$promise;

                $mdToast.show(
                  $mdToast.simple()
                    .textContent('Applying changes to appointment...')
                    .position("bottom right"),
                );

                self.promise
                  .then(function() {
                    self.saving = false;
                    self.promise = null;
                    $mdToast.show(
                      $mdToast.simple()
                        .textContent('Changes applied to appointment.')
                        .position("bottom right"),
                    );
                    if (closeAppointment) {
                      $location.path(`/views/${$routeParams.view}`);
                    }
                  })
                  .catch(function(error) {
                    self.saving = false;
                    self.promise = null;
                    $mdToast.show(
                      $mdToast.simple()
                        .textContent(
                          `Unable to apply changes to appointments: ${
                            error.data.reason}`,
                        )
                        .position("bottom right"),
                    );
                  });
                self.saveFunction(closeAppointment, updateAssociated);
              }
            });
          } else {
            self.saveFunction(closeAppointment, updateAssociated);
          }
        };


        let reOpenAppointment = false;
        let childPartyChanged = false;
        let shouldNotUpdate = false;

        if (angular.isUndefined(self.childPartyInfo.endDate)) {
          if (angular.isDefined(self.childParty.endDate)) {
            self.childDateChanged = true;
            shouldNotUpdate = true;
          }
        } else {
          if (self.childParty.endDate != self.childPartyInfo.endDate) {
            self.childDateChanged = true;
            shouldNotUpdate = true;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.endingReasonId)) {
          if (angular.isDefined(self.childParty.endingReasonId)) {
            self.childDateChanged = true;
            shouldNotUpdate = true;
          }
        } else {
          if (self.childParty.endingReasonId != self.childPartyInfo.endingReasonId) {
            self.childDateChanged = true;
            shouldNotUpdate = true;
          }
        }
        if (self.childPartyInfo.placements.length != 0) {
          if (self.childParty.placements.length == self.childPartyInfo.placements.length) {
            for (let i = 0; i < self.childParty.placements.length; i++) {
              if (self.childParty.placements[i].deleted == true) {
                childPartyChanged = false;
                shouldNotUpdate = true;
              }
            }
          }
        }
        if (self.childPartyInfo.firstName !== self.childParty.firstName) {
          childPartyChanged = true;
          shouldNotUpdate = false;
        } else if (self.childPartyInfo.lastName !== self.childParty.lastName) {
          childPartyChanged = true;
          shouldNotUpdate = false;
        } else if (new Date(self.childPartyInfo.dateOfBirth).getTime()
          !== new Date(self.childParty.dateOfBirth).getTime()) {
          childPartyChanged = true;
          shouldNotUpdate = false;
        }
        if (angular.isUndefined(self.childPartyInfo.address1)) {
          if(angular.isDefined(self.childParty.address1)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.address1 != self.childParty.address1) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.address2)) {
          if(angular.isDefined(self.childParty.address2)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.address2 != self.childParty.address2) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.middleName)) {
          if(angular.isDefined(self.childParty.middleName)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.middleName != self.childParty.middleName) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.suffix)) {
          if(angular.isDefined(self.childParty.suffix)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.suffix != self.childParty.suffix) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.gender)) {
          if(angular.isDefined(self.childParty.gender)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.gender != self.childParty.gender) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.raceEthnicity)) {
          if(angular.isDefined(self.childParty.raceEthnicity)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.raceEthnicity != self.childParty.raceEthnicity) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.city)) {
          if(angular.isDefined(self.childParty.city)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.city != self.childParty.city) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.state)) {
          if(angular.isDefined(self.childParty.state)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.state != self.childParty.state) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.zip)) {
          if(angular.isDefined(self.childParty.zip)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.zip != self.childParty.zip) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.phone)) {
          if(angular.isDefined(self.childParty.phone)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.phone != self.childParty.phone) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.extension)) {
          if(angular.isDefined(self.childParty.extension)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.extension != self.childParty.extension) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.email)) {
          if(angular.isDefined(self.childParty.email)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.email != self.childParty.email) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (new Date(self.childPartyInfo.startDate).getTime()
          !== new Date(self.childParty.startDate).getTime()
        ) {
          childPartyChanged = true;
          shouldNotUpdate = false;
        }
        if (angular.isUndefined(self.childPartyInfo.permanencyGoalId)) {
          if(angular.isDefined(self.childParty.permanencyGoalId)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.permanencyGoalId != self.childParty.permanencyGoalId) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.icwa)) {
          if(angular.isDefined(self.childParty.icwa)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.icwa != self.childParty.icwa) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.childPartyCourtAttendanceOptionId)) {
          if(angular.isDefined(self.childParty.childPartyCourtAttendanceOptionId)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.childPartyCourtAttendanceOptionId != self.childParty.childPartyCourtAttendanceOptionId) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.iep504Plan)) {
          if(angular.isDefined(self.childParty.iep504Plan)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.iep504Plan != self.childParty.iep504Plan) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.privilegeHolderId)) {
          if(angular.isDefined(self.childParty.privilegeHolderId)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.privilegeHolderId != self.childParty.privilegeHolderId) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.diligentSearchNotes)) {
          if(angular.isDefined(self.childParty.diligentSearchNotes)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.diligentSearchNotes != self.childParty.diligentSearchNotes) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.privilegeHolderNotes)) {
          if(angular.isDefined(self.childParty.privilegeHolderNotes)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.privilegeHolderNotes != self.childParty.privilegeHolderNotes) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.educationalNotes)) {
          if(angular.isDefined(self.childParty.educationalNotes)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.educationalNotes != self.childParty.educationalNotes) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.educationalSurrogate)) {
          if(angular.isDefined(self.childParty.educationalSurrogate)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.educationalSurrogate != self.childParty.educationalSurrogate) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.medication)) {
          if(angular.isDefined(self.childParty.medication)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.medication != self.childParty.medication) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.medicationNotes)) {
          if(angular.isDefined(self.childParty.medicationNotes)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.medicationNotes != self.childParty.medicationNotes) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }
        if (angular.isUndefined(self.childPartyInfo.notes)) {
          if(angular.isDefined(self.childParty.notes)) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        } else {
          if (self.childPartyInfo.notes != self.childParty.notes) {
            childPartyChanged = true;
            shouldNotUpdate = false;
          }
        }

        if (self.childPartyInfo.placements.length == 0 && self.childParty.placements.length != 0) {
          childPartyChanged = true;
          shouldNotUpdate = false;
        } else if (self.childPartyInfo.placements.length != 0) {
          if (self.childParty.placements.length > self.childPartyInfo.placements.length) {
            childPartyChanged = true;
          } else {
            for (let i = 0; i < self.childParty.placements.length; i++) {
              if (angular.isUndefined(self.childPartyInfo.placements[i].address1)) {
                if (angular.isDefined(self.childParty.placements[i].address1)) {
                  childPartyChanged = true;
                }
              } else{
                if (self.childPartyInfo.placements[i].address1 != self.childParty.placements[i].address1) {
                  childPartyChanged = true;
                }
              }
              if (angular.isUndefined(self.childPartyInfo.placements[i].address2)) {
                if (angular.isDefined(self.childParty.placements[i].address2)) {
                  childPartyChanged = true;
                }
              } else {
                  if (self.childPartyInfo.placements[i].address2 != self.childParty.placements[i].address2) {
                    childPartyChanged = true;
                }
              }
              if (angular.isUndefined(self.childPartyInfo.placements[i].appointmentChildPartyId)) {
                if (angular.isDefined(self.childParty.placements[i].appointmentChildPartyId)){
                  childPartyChanged = true;
                }
              } else {
                  if (self.childPartyInfo.placements[i].appointmentChildPartyId != self.childParty.placements[i].appointmentChildPartyId) {
                    childPartyChanged = true;
                  }
              }
              if (angular.isUndefined(self.childPartyInfo.placements[i].city)) {
                if (angular.isDefined(self.childParty.placements[i].city)) {
                  childPartyChanged = true;
                }
              } else {
                  if (self.childPartyInfo.placements[i].city != self.childParty.placements[i].city) {
                    childPartyChanged = true;
                  }
              }
              if (angular.isUndefined(self.childPartyInfo.placements[i].email)) {
                if (angular.isDefined(self.childParty.placements[i].email)) {
                  childPartyChanged = true;
                }
              } else {
                  if (self.childPartyInfo.placements[i].email != self.childParty.placements[i].email) {
                    childPartyChanged = true;
                  }
              }
              if (angular.isUndefined(self.childPartyInfo.placements[i].extension)) {
                if (angular.isDefined(self.childParty.placements[i].extension)) {
                  childPartyChanged = true;
                }
              } else {
                  if (self.childPartyInfo.placements[i].extension != self.childParty.placements[i].extension) {
                    childPartyChanged = true;
                  }
              }
              if (angular.isUndefined(self.childPartyInfo.placements[i].outOfHome)) {
                if (angular.isDefined(self.childParty.placements[i].outOfHome)) {
                  childPartyChanged = true;
                  }
              } else {
                  if (self.childPartyInfo.placements[i].outOfHome != self.childParty.placements[i].outOfHome) {
                    childPartyChanged = true;
                  }
              }
              if (angular.isUndefined(self.childPartyInfo.placements[i].phone)) {
                if (angular.isDefined(self.childParty.placements[i].phone)) {
                  childPartyChanged = true;
                  }
                } else {
                  if (self.childPartyInfo.placements[i].address1 != self.childParty.placements[i].address1) {
                    childPartyChanged = true;
                  }
              }
              if (angular.isUndefined(self.childPartyInfo.placements[i].placement)) {
                if(angular.isDefined(self.childParty.placements[i].placement)) {
                  childPartyChanged = true;
                }
              } else {
                  if (self.childPartyInfo.placements[i].placement != self.childParty.placements[i].placement) {
                    childPartyChanged = true;
                  }
              }
              if (angular.isUndefined(self.childPartyInfo.placements[i].placementId)) {
                if (angular.isDefined(self.childParty.placements[i].placementId)) {
                  childPartyChanged = true;
                }
              } else {
                  if (self.childPartyInfo.placements[i].placementId != self.childParty.placements[i].placementId) {
                    childPartyChanged = true;
                  }
              }
              if (angular.isUndefined(self.childPartyInfo.placements[i].placementName)) {
                if(angular.isDefined(self.childParty.placements[i].placementName)) {
                  childPartyChanged = true;
                }
              } else {
                  if (self.childPartyInfo.placements[i].placementName != self.childParty.placements[i].placementName) {
                    childPartyChanged = true;
                  }
              }
              if (angular.isUndefined(self.childPartyInfo.placements[i].placementType)) {
                if(angular.isDefined(self.childParty.placements[i].placementType)) {
                  childPartyChanged = true;
                }
              } else {
                  if (self.childPartyInfo.placements[i].placementType != self.childParty.placements[i].placementType) {
                    childPartyChanged = true;
                  }
              }
              if (angular.isUndefined(self.childPartyInfo.placements[i].placementTypeId)) {
                if (angular.isDefined(self.childParty.placements[i].placementTypeId)) {
                  childPartyChanged = true;
                }
              } else {
                  if (self.childPartyInfo.placements[i].placementTypeId != self.childParty.placements[i].placementTypeId) {
                    childPartyChanged = true;
                  }
              }
              if (angular.isUndefined(self.childPartyInfo.placements[i].startDate)) {
                if (angular.isDefined(self.childParty.placements[i].startDate)) {
                  childPartyChanged = true;
                }
              } else {
                if (new Date(self.childPartyInfo.placements[i].startDate).getTime()
                  !== new Date(self.childParty.placements[i].startDate).getTime()) {
                  childPartyChanged = true;
                }
              }
              if (angular.isUndefined(self.childPartyInfo.placements[i].state)) {
                if (angular.isDefined(self.childParty.placements[i].state)) {
                  childPartyChanged = true;
                }
              } else {
                  if (self.childPartyInfo.placements[i].state != self.childParty.placements[i].state) {
                    childPartyChanged = true;
                  }
              }
              if (angular.isUndefined(self.childPartyInfo.placements[i].zip)) {
                if (angular.isDefined(self.childParty.placements[i].zip)) {
                  childPartyChanged = true;
                }
              } else {
                  if (self.childPartyInfo.placements[i].zip != self.childParty.placements[i].zip) {
                    childPartyChanged = true;
                  }
              }
              if (angular.isUndefined(self.childPartyInfo.placements[i].endDate)) {
                if (angular.isDefined(self.childParty.placements[i].endDate)) {
                  childPartyChanged = true;
                }
              } else {
                  if (new Date(self.childPartyInfo.placements[i].endDate).getTime()
                    !== new Date(self.childParty.placements[i].endDate).getTime()) {
                    childPartyChanged = true;
                  }
                }
              }
            }
          }

        if (self.childPartyInfo.educationalSettings.length == 0 && self.childParty.educationalSettings.length > 0) {
          childPartyChanged = true;
        } else if (self.childPartyInfo.educationalSettings.length != 0) {
          if (self.childParty.educationalSettings.length > self.childPartyInfo.educationalSettings.length) {
            childPartyChanged = true;
          } else {
            for (let i = 0; i < self.childParty.educationalSettings.length; i++) {
              if (self.childParty.educationalSettings[i].deleted == true) {
                childPartyChanged = true;
              } else if (self.childParty.educationalSettings[i].educationalSettingId != self.childPartyInfo.educationalSettings[i].educationalSettingId) {
                childPartyChanged = true;
              }
              if (angular.isUndefined(self.childPartyInfo.educationalSettings[i].endDate)) {
                if (angular.isDefined(self.childParty.educationalSettings[i].endDate)) {
                  childPartyChanged = true;
                }
              } else {
                if (new Date(self.childPartyInfo.educationalSettings[i].endDate).getTime()
                  !== new Date(self.childParty.educationalSettings[i].endDate).getTime()) {
                  childPartyChanged = true;
                }
              }
            }
          }
        }
        if (angular.isDefined(self.childParty.endDate) && angular.isUndefined(self.childParty.endDate)) {
          reOpenAppointment = true;
        }

        if (childPartyChanged && self.appointment.currentAppointmentGroupId && !shouldNotUpdate) {

          promises.push(AppointmentService.get({
            appointmentGroupId: self.appointment.currentAppointmentGroupId,
          }).$promise.then(function(resp) {

            for (const appointment of resp.list) {
              if (appointment.id != self.appointment.id) {
                if (appointment.childParties) {
                  for (const childParty of appointment.childParties) {
                    if (childParty.childPartyId == self.childParty.childPartyId) {
                      childGroup = true;
                      break;
                    }
                  }
                }
              }
            }
            if (childGroup) {

              const changedMessage
                = "Would you like to apply these changes to the same child party throughout the appointment group? " +
                "If so, this child party's data will be updated in all their open and closed appointments in this group " +
                "(except attorney role, start/end dates, or blank placements)."
              $mdDialog.show(
                $mdDialog.confirm()
                  .parent(angular.element($document[0].querySelector(
                    '#popupContainer')))
                  .clickOutsideToClose(false)
                  .title('This child party belongs to an appointment group')
                  .textContent(changedMessage)
                  .ariaLabel(changedMessage)
                  .ok('Apply changes to associated appointments')
                  .cancel('Do not apply changes to associated appointments')
                  .multiple(true),
              ).then(function() {
                // ok
                if (self.childParty.endDate && self.childParty.isLastActive) {

                  const endMessage
                    = "You have entered an end date for this child party and there are no other active "
                    + "child parties on this appointment. If you continue, the appointment will be "
                    + "closed.";
                  $mdDialog.show(
                    $mdDialog.confirm()
                      .parent(angular.element($document[0].querySelector(
                        '#popupContainer')))
                      .clickOutsideToClose(false)
                      .title('Confirm')
                      .textContent(endMessage)
                      .ariaLabel(endMessage)
                      .ok('Continue')
                      .cancel('Cancel')
                      .multiple(true),
                  ).then(
                    () => {
                      saveFunction(true, true);
                    }
                  );
                } else {
                  saveFunction(false, true);
                }
              }, function(){
                saveFunction(false, false);
              });

            } else if (self.childParty.endDate && self.childParty.isLastActive) {
              const endMessage = "You have entered an end date for this child party and there are no other active " +
                "child parties on this appointment. " +
                "If you continue, the appointment will be closed.";
              $mdDialog.show(
                $mdDialog.confirm()
                  .parent(angular.element($document[0].querySelector(
                    '#popupContainer')))
                  .clickOutsideToClose(false)
                  .title('Confirm')
                  .textContent(endMessage)
                  .ariaLabel(endMessage)
                  .ok('Continue')
                  .cancel('Cancel')
                  .multiple(true),
              ).then(function() {
                  saveFunction(true);
                }
              );
            } else {
              saveFunction();
            }
          }));
        } else {
          if (self.childParty.endDate && self.childParty.isLastActive) {

            // console.log("Inside else, if endDate and isLastActive 123..."); // HERE!

            const endMessage = "You have entered an end date for this child party and there are no other active " +
              "child parties on this appointment. If you continue, the appointment will be " +
              "closed.";

            $mdDialog.show(
              $mdDialog.confirm()
                .parent(angular.element($document[0].querySelector('#popupContainer')))
                .clickOutsideToClose(false)
                .title('Confirm')
                .textContent(endMessage)
                .ariaLabel(endMessage)
                .ok('Continue')
                .cancel('Cancel')
                .multiple(true),
            ).then(function () {
                saveFunction(true);
            }, function() {
              self.saving = false;
            });
          } else {
            saveFunction();
          }
        }
      };

        self.canEdit = function() {
          return ((OcrAuth.checkPermission('ADMIN_WRITE')
              || OcrAuth.checkPermission('CHILD_PARTY_WRITE'))
            && !self.hideEditing);
        };

        self.disableGender = function() {
          if (!self.canEdit()) {
            return true;
          }
          return !self.genders.length;
        }

        self.disableEthnicity = function() {
          if (!self.canEdit()) {
            return true;
          }
          return !self.racesEthnicities.length;
        }

        self.disableState = function() {
          if (!self.canEdit()) {
            return true;
          }
          return !self.states.length;
        }

        self.disablePermanencyGoal = function() {
          if (!self.canEdit()) {
            return true;
          }
          return !self.permanencyGoalOptions.length;
        }

        self.disableEndDate = function() {
          if (!self.canEdit()) {
            return true;
          }
          return self.appointment.isNew;
        }

        self.disablePrivilegeHolder = function() {
          if (!self.canEdit()) {
            return true;
          }
          return !self.privilegeHolderOptions.length;
        }

        self.disableSave = function() {
          return self.partyForm.$invalid
            || (self.appointment.requirePlacement && self.activePlacementCount === 0)
            || (!self.childParty.bestInterestsRoleId && !self.childParty.clientDirectedRoleId)
            || self.saving
        }

        self.bestInterestsClicked = function(role) {
          self.childParty.bestInterestsRoleId = self.childParty.bestInterestsRoleId == role.id ? undefined : role.id;
          self.childParty.bestInterestsStartDate = undefined;
          self.startDateChanged();
          if (self.childParty.bestInterestsRoleId) {
            $scope.$watch(
              () => angular.isDefined(self.partyForm.bestInterestsStartDate),
              function() {
                if(self.partyForm.bestInterestsStartDate) {
                  self.partyForm.bestInterestsStartDate.$setTouched();
                }
              }, true);
            if (!self.caseTypeAttorneyRoles.lookup(self.childParty.bestInterestsRoleId).roleChangeAgePrompt) {
              self.childParty.bestInterestsContinuationReasonId = undefined;
            }
            self.setAlertDates();
          } else {
            self.childParty.bestInterestsContinuationReasonId = undefined;
          }
        }

        self.setAlertDates = function() {
          if (!self.caseTypeAttorneyRoles) {
            return;
          }
          let role = self.caseTypeAttorneyRoles.lookup(self.childParty.bestInterestsRoleId);
          if (!role) {
            return;
          }
          if (role.roleChangeAgePrompt) {
            let roleChangeDate = self.addDecimalDate(role.roleChangeAge, self.childParty.dateOfBirth);
            if (new Date(roleChangeDate) > new Date(role.minimumRoleChangeDate)) {
              self.childParty.roleChangeDate = roleChangeDate;
            } else {
              self.childParty.roleChangeDate = role.minimumRoleChangeDate;
            }

            self.childParty.roleChangeWarningDate = self.addDecimalDate(role.roleChangeWarningAge, self.childParty.dateOfBirth);
          } else {
            self.childParty.roleChangeDate = undefined;
            self.childParty.roleChangeWarningDate = undefined;
          }

          if (self.appointment.parentCase.caseType.additionalAgeReminder) {
            self.childParty.additionalRemindDate = self.addDecimalDate(self.appointment.parentCase.caseType.reminderAge, self.childParty.dateOfBirth);
          } else {
            self.childParty.additionalRemindDate = undefined;
          }
        }

        self.clientDirectedClicked = function(role) {
          self.childParty.clientDirectedRoleId = self.childParty.clientDirectedRoleId == role.id ? undefined : role.id;
          self.childParty.clientDirectedStartDate = undefined;
          self.startDateChanged();
          if (self.childParty.clientDirectedRoleId) {
            $scope.$watch(
              () => angular.isDefined(self.partyForm.clientDirectedStartDate),
              function() {
                if (self.partyForm.clientDirectedStartDate) {
                  self.partyForm.clientDirectedStartDate.$setTouched();
                }
              }, true);
          }
        }

        function delay(time) {
          return new Promise(resolve => setTimeout(resolve, time));
        }


        self.setBestInterestsMaxDate = function() {
          if (self.childParty.clientDirectedStartDate) {
            var dayBefore = new Date(self.childParty.clientDirectedStartDate.getTime());
            dayBefore.setDate(self.childParty.clientDirectedStartDate.getDate() - 1);
            self.bestInterestsMaxDate = dayBefore;
          } else if (self.childParty.endDate) {
            if (self.childParty.endDate < self.today) {
              self.bestInterestsMaxDate = self.childParty.endDate;
            }
          } else {
            self.bestInterestsMaxDate = self.today;
          }
        }

        self.setClientDirectedMaxDate = function() {
          if (self.childParty.endDate) {
            if (self.childParty.endDate < self.today) {
              self.clientDirectedMaxDate = self.childParty.endDate;
            }
          } else {
            self.clientDirectedMaxDate = self.today;
          }
        }

        self.setClientDirectedMinDate = function() {
          if (self.childParty.bestInterestsStartDate) {
            var dayAfter = new Date(self.childParty.bestInterestsStartDate.getTime());
            dayAfter.setDate(self.childParty.bestInterestsStartDate.getDate() + 1);
            self.clientDirectedMinDate = dayAfter;
          } else {
            self.clientDirectedMinDate = undefined;
          }
        }

        self.setMinEndDate = function() {
          if (!self.childParty.bestInterestsStartDate && !self.childParty.clientDirectedStartDate) {
            self.minEndDate = undefined;
          } else if (!self.childParty.bestInterestsStartDate) {
            self.minEndDate = self.childParty.clientDirectedStartDate;
          } else if (!self.childParty.clientDirectedStartDate) {
            self.minEndDate = self.childParty.bestInterestsStartDate;
          } else {
            if (self.childParty.bestInterestsStartDate < self.childParty.clientDirectedStartDate) {
              self.minEndDate = self.childParty.clientDirectedStartDate;
            } else {
              self.minEndDate = self.childParty.bestInterestsStartDate;
            }
          }
        }

        self.endDateChanged = function() {
          self.setBestInterestsMaxDate();
          self.setClientDirectedMaxDate();
          self.setClientDirectedMinDate();
          self.setMinEndDate();

          $timeout(function() {
            if (self.partyForm.endReasonId) {
              self.partyForm.endReasonId.$setTouched();
            }
          }, 250);
        }

        self.startDateChanged = function() {
          self.childDateChanged = true;
          if (!self.childParty.clientDirectedStartDate && self.childParty.bestInterestsStartDate) {
            self.childParty.latestCaseTypeAttorneyRoleId = self.childParty.bestInterestsRoleId;
            self.childParty.startDate = self.childParty.bestInterestsStartDate;
          } else if (!self.childParty.bestInterestsStartDate && self.childParty.clientDirectedStartDate) {
            self.childParty.latestCaseTypeAttorneyRoleId = self.childParty.clientDirectedRoleId;
            self.childParty.startDate = self.childParty.clientDirectedStartDate;
          } else if (self.childParty.clientDirectedStartDate > self.childParty.bestInterestsStartDate) {
            self.childParty.latestCaseTypeAttorneyRoleId = self.childParty.clientDirectedRoleId;
            self.childParty.startDate = self.childParty.bestInterestsStartDate;
          } else {
            self.childParty.latestCaseTypeAttorneyRoleId = self.childParty.bestInterestsRoleId;
            self.childParty.startDate = self.childParty.clientDirectedStartDate;
          }
          self.setBestInterestsMaxDate();
          self.setClientDirectedMaxDate();
          self.setClientDirectedMinDate();
          self.setMinEndDate();
        }

        self.activityOutside = function() {

          // lgr - This does not look right to me. We should validate the current child party's
          // activities against the current child party's start and end dates.
          // Otherwise, we could end up with an activity for a child that is within the range of the
          // dates of the appointment, but not within the range of the dates of the child party.

          if (!self.appointment.activities) {
            return false;
          }
          let earliest = self.childParty.startDate;
          let latest = self.childParty.endDate;

          for (const childParty of self.appointment.childParties) {
            if (childParty.childPartyId != self.childParty.childPartyId && new Date(childParty.startDate) < new Date(earliest)) {
              earliest = childParty.startDate;
            }
            if (childParty.childPartyId != self.childParty.childPartyId && new Date(childParty.endDate) > new Date(latest)) {
              latest = childParty.endDate;
            }
          }

          earliest = new Date(earliest);
          if (latest) {
            latest = new Date(latest);
            latest.setDate(latest.getDate() + 1);
          }

          for (const activity of self.appointment.activities) {
            // This portion would check individual activities against the child parties they contain. It is being removed at Rebecca's request.
            // if (activity.childParties.find(cp => cp.childPartyId === self.childParty.childPartyId)) {
            //   if (self.childParty.endDate && (new Date(activity.date) < new Date(self.childParty.startDate) || new Date(self.childParty.endDate) < new Date(activity.date))) {
            //     return true;
            //   } else if (new Date(activity.date) < new Date(self.childParty.startDate)) {
            //     return true;
            //   }
            // }
            console.log(new Date(activity.date) > latest && self.appointment.endDate != null);

            if (latest && (new Date(activity.date) < earliest || latest < new Date(activity.date)) && self.childParty.isLastActive) {
              return true;
            } else if (new Date(activity.date) < earliest) {
              return true;
            } else if (new Date(activity.date) > latest && self.appointment.endDate != null) {
              return true;
            }
          }

          return false;
        }

        self.addDecimalDate = function(decimalYears, dateToChange) {
          var returnDate = new Date(dateToChange);
          var years = parseInt(decimalYears);
          var additionalFraction = decimalYears - years;
          var totalDays = Math.floor(additionalFraction * 365.25);
          returnDate.setFullYear(returnDate.getFullYear() + years);
          returnDate.setDate(returnDate.getDate() + totalDays);
          return returnDate;
        }

        $scope.$watch(
          () => angular.isDefined(self.partyForm),
          function() {
            if (self.partyForm) {
              $scope.$watch(
                () => self.partyForm.$invalid === true,
                function() {
                  angular.forEach(self.partyForm.$error, function(field) {
                    angular.forEach(field, function(errorField) {
                      errorField.$setTouched();
                    });
                  });
                }, true);
            }
          }, true);
    },
  ]);
