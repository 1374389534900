angular.module('CaresApp').component('ocrAppointmentInvolvedPersons', {
  templateUrl: 'components/ocr-appointment-involved-persons/' +
    'ocr-appointment-involved-persons.template.html',
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    onItemAdded: '&onUserAdded',
    hideEditing: '&hideEditing',
  },
  controller: [
    '$scope',
    '$document',
    '$mdDialog',
    '$mdToast',
    'ContactService',
    'OcrAuth',
    'OcrLookups',
    'OcrUtils',
    'AppointmentInvolvedPersonService',
    function($scope, $document, $mdDialog, $mdToast, ContactService, OcrAuth, OcrLookups, OcrUtils,
      AppointmentInvolvedPersonService) {
      const self = this;

      self.hasAnyPermission = OcrAuth.hasAnyPermission
      self.involvedPersons = [];
      self.involvedPersons.count = 0;
      self.involvedPersons.total = 0;
      self.selected = [];
      self.model = null;
      self.contactsLoaded = OcrAuth.session && OcrAuth.session.user && OcrAuth.session.user.contacts
        && OcrAuth.session.user.contacts.length;
      self.hasContacts = OcrAuth.session && OcrAuth.session.user && OcrAuth.session.user.contacts
        && OcrAuth.session.user.contacts.length && OcrAuth.session.user.contacts.length > 0;

      self.query = {
        page: 1,
        limit: 20,
      };

      function getChildPartyById(id) {
        if (!self.model) {
          return null;
        }
        if (!self.model.childParties || !self.model.childParties.lookup) {
          self.model.childParties = OcrLookups.createLookupList(self.model.childParties || []);
        }
        return self.model.childParties.lookup(id);
      }

      self.paginate = function() {
        if (!self.model) {
          return;
        }
        const startIdx = (self.query.page - 1) * self.query.limit;
        let endIdx = startIdx + self.query.limit;
        if (endIdx > self.model.involvedPersons.length) {
          endIdx = self.model.involvedPersons.length;
        }
        self.involvedPersons = self.model.involvedPersons.slice(
          startIdx, endIdx);
        self.involvedPersons.total = self.involvedPersons.length;
        self.involvedPersons.count = self.model.involvedPersons.length;
      };


      const showInvolvedPersonDialog = function(fromContacts, editInvolvedPerson) {
        $mdDialog.show({
          controller: 'AddInvolvedPersonDialogController',
          templateUrl:
            'components/ocr-appointment-involved-persons/add-involved-person-dialog.html',
          parent: angular.element($document[0].body),
          clickOutsideToClose: false,
          locals: {
            add: !editInvolvedPerson,
            involvedPerson: editInvolvedPerson,
            fromContacts: fromContacts,
            contacts: OcrAuth.session.contacts,
            appointment: self.model,
          },
          bindToController: true,
          controllerAs: '$ctrl',
        })
          .then(function(returnedInvolvedPerson) {
            $mdToast.show(
              $mdToast.simple()
                .textContent(`Involved person ${editInvolvedPerson ? 'saved.' : 'added.'}` )
                .position("bottom right"),
            );
            returnedInvolvedPerson.fullAddress = undefined;
            self.query.page = 1;
            self.involvedPersons = [];
            self.involvedPersons.count = 0;
            self.involvedPersons.total = self.model.involvedPersons.length;
            self.selected.length = 0;
            self.paginate();
          }, () => {});
      };

      OcrAuth.session.contacts = OcrLookups.dummyList

      function loadContacts() {
        if (!OcrAuth.session || !OcrAuth.session.userId || self.contactsLoaded) {
          return null
        } else if (!OcrAuth.session.contacts.length) {
          return ContactService.get({
            userId: OcrAuth.session.userId,
            limit: 1000,
          }).$promise.then(
            function(response) {
              self.contactsLoaded = true;
              OcrAuth.session.contacts = OcrLookups.createLookupList(response.list);
              if (OcrAuth.session.contacts.length > 0) {
                self.hasContacts = true;
              } else {
                self.hasContacts = false;
              }
            },
          );
        }
        self.contactsLoaded = true;
        if (OcrAuth.session.user.contacts.length > 0) {
          self.hasContacts = true;
        } else {
          self.hasContacts = false;
        }
        return null;
      }

      self.addInvolvedPerson = function(fromContacts) {
        if (!OcrAuth.session || !OcrAuth.session.userId
          || angular.isUndefined(OcrAuth.session.contacts)) {
          return;
        }
        if (self.contactsLoaded) {
          showInvolvedPersonDialog(fromContacts);
        } else {
          const promise = loadContacts()
          if (promise) {
            promise.then(
              () => showInvolvedPersonDialog(fromContacts),
            )
          } else {
            showInvolvedPersonDialog(fromContacts)
          }
        }
      }

      self.editInvolvedPerson = function() {
        if (!OcrAuth.session || !OcrAuth.session.userId) {
          return;
        }
        if (self.contactsLoaded) {
          showInvolvedPersonDialog(self.selected[0].contactId, self.selected[
            0]);
        } else {
          const promise = loadContacts();
          if (promise) {
            promise.then(
              () => showInvolvedPersonDialog(self.selected[0].contactId,
                self.selected[0]),
            );
          } else {
            showInvolvedPersonDialog(self.selected[0].contactId, self.selected[
              0]);
          }
        }
      };

      self.deleteInvolvedPerson = function() {
        const message
          = "Are you sure that you want to delete this involved person?\n"
          + "If you added this person from My Contacts, s/he will still be saved there.";
        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Involved Person Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete the involved person')
            .cancel('Cancel'),
        ).then(function() {
          // ok
          $mdToast.show(
            $mdToast.simple()
              .textContent('Deleting...')
              .position("bottom right"),
          );
          AppointmentInvolvedPersonService.delete({
            id: self.selected[0].id,
          }).$promise
            .then(function() {
              for (let i = 0; i < self.model.involvedPersons.length; i++) {
                if (self.model.involvedPersons[i].id === self.selected[
                  0].id) {
                  self.model.involvedPersons.splice(i, 1);
                  break;
                }
              }
              self.paginate();
              self.selected.length = 0;
              $mdToast.show(
                $mdToast.simple()
                  .textContent('Involved person deleted.')
                  .position("bottom right"),
              );
            })
            .catch(function(error) {
              $mdToast.show(
                $mdToast.simple()
                  .textContent('Deletion failed.')
                  .position("bottom right"),
              );
              console.error("Unable to delete involved person: %O",
                error);
            });
        }, () => {});
      };

      self.getFormattedApplicableChildParties = function(involvedPerson) {
        if (!involvedPerson) {
          return null;
        }

        let temp = "";
        for (let c = 0; c < involvedPerson.childParties.length; c++) {
          temp += OcrUtils.getUserFullName(getChildPartyById(
            involvedPerson.childParties[c].childPartyId));
          if (c < involvedPerson.childParties.length - 1) {
            temp += "; ";
          }
        }
        return temp;
      };

      self.getFullAddress = function(involvedPerson) {
        if (involvedPerson.fullAddress) {
          return involvedPerson.fullAddress;
        }
        let address = "";
        if (involvedPerson.address1) {
          address += involvedPerson.address1;
        }
        if (involvedPerson.address1 && involvedPerson.address2) {
          address += ", ";
        }
        if (involvedPerson.address2) {
          address += involvedPerson.address2;
        }
        if (involvedPerson.address1 || involvedPerson.address2) {
          address += ", ";
        }
        let city = false;
        let state = false;
        if (involvedPerson.city) {
          city = true;
          address += involvedPerson.city;
        }
        if (involvedPerson.state) {
          state = true;
          if (city) {
            address += ", ";
          }
          address += involvedPerson.state;
        }
        if (involvedPerson.zip) {
          if (state) {
            address += " ";
          }
          address += involvedPerson.zip;
        }
        involvedPerson.fullAddress = address;
        return address;
      };

      self.$onInit =
        () => $scope.$watch(
          () => self.ngModel.$modelValue,
          function() {
            self.model = self.ngModel.$modelValue;
            if (self.model) {
              if (self.model.id) {
                self.isNew = false;
                self.query.page = 1;
                self.involvedPersons = [];
                self.involvedPersons.total = 0;
                self.paginate();
              } else {
                self.involvedPersons = [];
                self.involvedPersons.count = 0;
                self.involvedPersons.total = 0;
                self.isNew = true;
              }
            }
          },
        );
      loadContacts();

      $scope.$on('SessionChanged', function(_, oldSession, newSession) {
        if (oldSession && oldSession.contacts && newSession) {
          newSession.contacts = oldSession.contacts
        }
      })
    },
  ],
});
