angular.module('CaresApp').controller('AddChildPartyPlacementDialogController', [
  '$scope',
  '$mdDialog',
  'OcrAuth',
  'OcrLookups',
  'OcrUtils',
  'PlacementService',
  'SavedPlacementService',
  function($scope, $mdDialog, OcrAuth, OcrLookups, OcrUtils, PlacementService,
    SavedPlacementService) {
    const self = this;

    self.placementTypes = self.placementOptions = self.states = OcrLookups.dummyList;
    OcrLookups.get('State').then((states) => self.states = states);

    self.placements = [];
    self.placements.total = 0;
    self.selected = [];
    self.query = {
      order: undefined,
      limit: 20,
      page: 1,
    };
    self.saveToMyPlacements = false;

    self.today = OcrUtils.today;
    self.yesOrNo = [
      {
        id: 'Y',
        name: 'Yes',
      },
      {
        id: 'N',
        name: 'No',
      },
    ];

    self.$onInit = function() {
      OcrLookups.get('PlacementType').then(
        function(placementTypes) {
          self.placementTypes = placementTypes;
          if (self.childPartyPlacement && self.childPartyPlacement.placementTypeId) {
            self.selectedPlacementType = self.placementTypes.lookup(self.childPartyPlacement
              .placementTypeId);
          }
        },
      );
      OcrLookups.get('Placement').then(
        function(placementOptions) {
          if (self.childPartyPlacement
          && self.childPartyPlacement.placementId
          && !placementOptions.lookup(self.childPartyPlacement.placementId)
          ) {
            self.placementOptions = OcrLookups.createLookupList(angular.copy(placementOptions));
            const newPlacement = angular.copy(self.childPartyPlacement);
            newPlacement.id = newPlacement.placementId;
            delete newPlacement.placementId;
            self.placementOptions.unshift(newPlacement);
          } else {
            self.placementOptions = placementOptions;
          }
        },
      );
      if (self.childPartyPlacement) {
        // editing
        self.childPartyPlacement = angular.copy(self.childPartyPlacement);

        const unregister = $scope.$watch(
          () => self.selectedPlacementType,
          function() {
            if (self.selectedPlacementType) {
              if (!self.selectedPlacementType.privateHome) {
                self.updateData();
                const currentPlacement = {
                  id: self.childPartyPlacement.placementId,
                  placement: self.childPartyPlacement.placement,
                  placementTypeId: self.childPartyPlacement.placementTypeId,
                  startDate: new Date(self.childPartyPlacement.startDate),
                  address1: self.childPartyPlacement.address1,
                  address2: self.childPartyPlacement.address2,
                  city: self.childPartyPlacement.city,
                  state: self.childPartyPlacement.state,
                  zip: self.childPartyPlacement.zip,
                  phone: self.childPartyPlacement.phone,
                  extension: self.childPartyPlacement.extension,
                  email: self.childPartyPlacement.email,
                  outOfHome: self.childPartyPlacement.outOfHome,
                };
                self.selected.push(currentPlacement);
              }
              unregister();
            }
          },
        );

        if (self.childPartyPlacement.startDate) {
          self.childPartyPlacement.startDate = new Date(self.childPartyPlacement
            .startDate);
        } else {
          self.childPartyPlacement.startDate = undefined;
        }
        if (self.childPartyPlacement.endDate) {
          self.childPartyPlacement.endDate = new Date(self.childPartyPlacement
            .endDate);
        } else {
          self.childPartyPlacement.endDate = undefined;
        }
      } else {
        // adding
        self.childPartyPlacement = {
          deleted: false,
        };
      }
      $scope.$watch(
        () => angular.isDefined(self.addPlacementForm),
        function() {
          $scope.$watch(
            () => self.addPlacementForm.$invalid === true,
            function() {
              angular.forEach(self.addPlacementForm.$error, function(field) {
                angular.forEach(field, function(errorField) {
                  errorField.$setTouched();
                });
              });
            }, true);
        }, true);
    }

    self.updateData = function() {
      self.promise = PlacementService.get({
        skip: (self.query.page - 1) * self.query.limit,
        limit: self.query.limit,
        query: self.searchText ? self.searchText : undefined,
        activeOnly: true,
        placementTypeId: self.selectedPlacementType.id,
      }).$promise.then(
        function(response) {
          self.placements = response.list;
          self.placements.total = response.total;
        }).finally(() => self.promise = undefined);
    };

    self.placementTypeChanged = function() {
      if (self.selectedPlacementType) {
        if (self.selectedPlacementType.privateHome) {
          const placement = self.placementOptions.lookup(self.selectedPlacementType.type,
            'placement');
          self.childPartyPlacement = {
            placementTypeId: self.selectedPlacementType.id,
            placementType: self.placementTypes.lookup(self.selectedPlacementType.id).type,
            placementId: placement.id,
            placement: placement.placement,
            state: "CO",
            startDate: self.childPartyPlacement.startDate,
          };
          self.placements = [];
          self.placements.total = 0;

          $scope.$watch(
            () => self.addPlacementForm.$invalid,
            function() {
              $scope.$watch(
                () => (
                  self.addPlacementForm.$error.required
                    ? self.addPlacementForm.$error.required.length : null
                ),
                function() {
                  angular.forEach(self.addPlacementForm.$error, function(field) {
                    angular.forEach(field, function(errorField) {
                      errorField.$setTouched();
                    });
                  });
                }, true);
            }, true);
        } else {
          self.saveToMyPlacements = false;
          self.childPartyPlacement.placementTypeId = self.selectedPlacementType
            .id;
          self.childPartyPlacement.placementType = self.placementTypes.lookup(
            self.selectedPlacementType.id).type;
          self.updateData();
        }
      }
    };

    self.getPlacementById = function(id) {
      if (!self.placementOptions) {
        return undefined;
      }
      return self.placementOptions.lookup(id);
    };

    self.savePlacement = function() {
      self.childPartyPlacement.userId = OcrAuth.session.user.id;
      SavedPlacementService.save(self.childPartyPlacement);
    }

    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;

    self.submit = function() {
      if (self.selectedPlacementType.privateHome && self.saveToMyPlacements) {
        self.savePlacement();
      }
      if (!self.selectedPlacementType.privateHome) {
        self.childPartyPlacement.placementId = self.selected[0].id;
        self.childPartyPlacement.placement = self.selected[0].placement
        self.childPartyPlacement.placementName = self.selected[0].placement;
        self.childPartyPlacement.address1 = self.selected[0].address1;
        self.childPartyPlacement.address2 = self.selected[0].address2;
        self.childPartyPlacement.city = self.selected[0].city;
        self.childPartyPlacement.state = self.selected[0].state;
        self.childPartyPlacement.zip = self.selected[0].zip;
        self.childPartyPlacement.phone = self.selected[0].phone;
        self.childPartyPlacement.extension = self.selected[0].extension;
        self.childPartyPlacement.email = self.selected[0].email;
        self.childPartyPlacement.outOfHome = self.selected[0].outOfHome;
        self.childPartyPlacement.fullAddress = undefined;
      }
      $mdDialog.hide(self.childPartyPlacement);
    };

    self.doSearch = OcrUtils.debounce(() => self.updateData(), 500);
  },
]);
