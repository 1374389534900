angular.module('CaresApp').component('ocrAnnouncement', {
  templateUrl: 'components/ocr-announcement/ocr-announcement.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    '$document',
    '$location',
    '$window',
    '$mdMedia',
    '$mdToast',
    '$routeParams',
    '$mdDialog',
    '$timeout',
    'AnnouncementService',
    'OcrAuth',
    'OcrUtils',
    function($scope, $document, $location, $window, $mdMedia, $mdToast, $routeParams, $mdDialog,
      $timeout, AnnouncementService, OcrAuth, OcrUtils) {
      const self = this;

      self.announcementId =
        ($routeParams.announcementId !== "create") ? $routeParams.announcementId : undefined;
      if (!self.announcementId) {
        OcrUtils.setPageTitle('New Announcement')
      }
      self.viewId = $routeParams.view;
      self.today = new Date();
      self.editMode = false;
      self.viewPreview = false;
      self.listStyle = {};
      self.converter = new showdown.Converter();

      self.promise = null;
      $scope.$watch(() => self.promise, () => self.setPromise()(self.promise));

      if (self.announcementId) {
        self.promise = AnnouncementService.get({
          id: self.announcementId,
        }).$promise.then(function(announcement) {
          self.announcement = announcement;
          OcrUtils.setPageTitle(announcement.title)
        }).finally(() => self.promise = null);
      } else {
        self.announcement = {
          createdByUserId: OcrAuth.session.user.id,
          createdOnDate: self.today,
        };
        self.isNew = true;
        self.editMode = true;
      }

      self.edit = () => self.editMode = true;

      self.preview = function() {
        self.viewPreview = true;

        const text = self.announcement.showdown;
        const target = $document[0].getElementById('preview');
        const html = self.converter.makeHtml(text);
        target.innerHTML = html;
      }

      $window.addEventListener('resize', onResize, true);

      self.checkPermission = OcrAuth.checkPermission

      function onResize() {
        $timeout(function() {
          if (!$mdMedia("xs")) {
            self.listStyle.maxHeight = `${$window.innerHeight - 250
            }px`;
          } else {
            self.listStyle = {};
          }
        });
      }

      self.closeForm = function(noConfirm) {
        // only display confirmation message if form is dirty (has modified fields)
        if (noConfirm || self.announcementForm.$pristine) {
          $location.path(`/views/${self.viewId}`);
          return;
        }

        const message
          = "Are you sure that you want to close this form without saving your changes?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Form Close')
            .textContent(message)
            .ariaLabel(message)
            .ok('Close Form')
            .cancel('Cancel'),
        ).then(function() {
          // ok
          $location.path(`/views/${self.viewId}`);
        }, function() {
          // cancel
          // do nothing
        });
      };

      self.saveForm = function(close) {
        self.announcement.lastModifiedByUserId = OcrAuth.session.user.id;
        self.announcement.lastModifiedOnDate = self.today;
        self.announcement.announcementBody = self.converter.makeHtml(self.announcement.showdown);

        $mdToast.show(
          $mdToast.simple()
            .textContent('Saving...')
            .position("bottom right"),
        );

        const continueSave = function() {
          self.announcementForm.$setPristine();
          $mdToast.show(
            $mdToast.simple()
              .textContent('Announcement saved.')
              .position("bottom right"),
          ).then(function() {
            self.isNew = false;
          });
          if (close) {
            $location.path(`/views/${self.viewId}`);
          }
        };

        if (self.isNew) {
          // create
          self.promise = AnnouncementService.save(self.announcement)
            .$promise.then(function() {
              continueSave();
            })
            .catch(function(error) {
              if (error && error.data && error.data.status === 401) {
                $location.path("/");
                return;
              }

              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            }).finally(() => self.promise = null);
        } else {
          // update
          self.promise = AnnouncementService.update({
            id: self.announcementId,
          }, self.announcement).$promise.then(function() {
            continueSave();
          })
            .catch(function(error) {
              if (error && error.data && error.data.status === 401) {
                $location.path("/");
                return;
              }

              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            }).finally(() => self.promise = null);
        }
      };

      self.deleteForm = function(close) {
        const message
          = "Are you sure that you want to delete this announcement?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Announcement Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete Announcement')
            .cancel('Cancel'),
        ).then(function() {
          // ok
          $mdToast.show(
            $mdToast.simple()
              .textContent('Deleting...')
              .position("bottom right"),
          );

          const continueSave = function() {
            $mdToast.show(
              $mdToast.simple()
                .textContent('Announcement deleted.')
                .position("bottom right"),
            ).then(function() {
              self.isNew = false;
            });
            if (close) {
              $location.path(`/views/${self.viewId}`);
            }
          };

          self.promise = AnnouncementService.delete({
            id: self.announcementId,
          }, self.announcement).$promise.then(function() {
            continueSave();
          })
            .catch(function(error) {
              if (error && error.data && error.data.status === 401) {
                $location.path("/");
                return;
              }

              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            }).finally(() => self.promise = null);
        }, function() {
          // cancel
          // do nothing
        });
      }

      $scope.$on('$locationChangeStart',
        function(event, newUrl, oldUrl) {
          const removeQueryParams = (url) => {
            if (url) {
              const index = url.indexOf("?");
              if (index >= 0) {
                return url.substring(0, index);
              }
            }
            return url;
          };

          const newPath = $location.path();

          if (
            newPath === "/"
            || self.announcementForm.$pristine
            || removeQueryParams(newUrl) === removeQueryParams(oldUrl)
          ) {
            return;
          }

          event.preventDefault();

          const message
            = "Are you sure that you want to close this form without saving your changes?";

          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Page Exit')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK')
              .cancel('Cancel'),
          ).then(() => {
            self.announcementForm.$setPristine();
            $location.path(newPath);
          });
        });

      self.$onInit =
        () => $scope.$watch(
          () => angular.isDefined(self.announcementForm),
          function() {
            $scope.$watch(
              () => self.announcementForm.$invalid,
              function() {
                if (self.announcementForm && self.announcementForm.$invalid) {
                  angular.forEach(self.announcementForm.$error, function(field) {
                    angular.forEach(field, function(errorField) {
                      errorField.$setTouched();
                    });
                  });
                }
              },
            )
          }, true);
    },
  ],
});
