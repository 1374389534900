angular.module('CaresApp').controller('AddExceptionDialogController', [
  '$scope',
  '$document',
  '$mdDialog',
  '$mdToast',
  'ExceptionLogService',
  'OcrAuth',
  'UserService',
  'OcrLookups',
  'OcrUtils',
  function($scope, $document, $mdDialog, $mdToast, ExceptionLogService, OcrAuth, UserService,
    OcrLookups, OcrUtils,
  ) {
    const self = this;
    self.today = new Date();

    self.exceptionStatuses = self.offices = OcrLookups.dummyList;
    OcrLookups.get('ExceptionStatus').then(
      (exceptionStatuses) => self.exceptionStatuses = exceptionStatuses,
    );
    OcrLookups.get('Office').then((offices) => self.offices = offices);

    self.$onInit = function() {
      if (self.exception) {
        self.isNew = false;
        self.exception = angular.copy(self.exception);
        self.exception.startDate = new Date(self.exception.startDate);
        self.exception.endDate = new Date(self.exception.endDate);
        self.exception.createdOnDate = new Date(self.exception.createdOnDate);
        self.exception.modifiedDate = new Date(self.exception.modifiedDate);

        self.loadUsers();
      } else {
        self.isNew = true;
        self.exception = {
          exceptionStatusId: 3,
          createdByUserId: OcrAuth.session.userId,
        };

        if (self.officeId) {
          self.exception.officeId = self.officeId;
          self.loadUsers();
        }
      }
      $scope.$watch(
        () => angular.isDefined(self.addExceptionForm),
        () => {
          if (self.add) {
            $scope.$watch(
              () => self.addExceptionForm.$invalid === true,
              () => angular.forEach(self.addExceptionForm.$error,
                (field) => angular.forEach(field, (errorField) => errorField.$setTouched())), true);
          }
        }, true);
    }

    self.checkPermission = OcrAuth.checkPermission;
    self.getUserFullName = OcrUtils.getUserFullName;

    //TODO this gets the promise, not set it
    self.setPromise = () => self.promise;

    self.attachmentAdded = () => self.exception.refresh = true;

    self.loadUsers =
      () => UserService.get({
        limit: 1000,
        office_id: self.exception.officeId,
      }).$promise.then(
        (response) => self.users = OcrLookups.createLookupList(response.list));


    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;

    self.shouldDisableSave =
      () => self.submitting
        || self.addExceptionForm.$invalid;

    self.doSave = function(close) {
      console.log("Saving");
      self.submitting = true;
      $mdToast.show(
        $mdToast.simple()
          .textContent('Saving...')
          .position("bottom right"),
      );
      let saveFunction = null;
      let saveArgs = null;
      if (self.exception.id) {
        saveFunction = ExceptionLogService.update;
        self.exception.modifiedDate = new Date();
        saveArgs = [self.exception];
      } else {
        saveFunction = ExceptionLogService.save;
        self.exception.modifiedDate = new Date();
        self.exception.createdOnDate = new Date();
        saveArgs = [self.exception];
      }
      saveFunction.apply(this, saveArgs).$promise.then(
        function(resp) {
          $mdToast.show(
            $mdToast.simple()
              .textContent('Exception saved.')
              .position("bottom right"),
          )
          self.submitting = false;
          self.add = false;
          self.exception.id = resp.id
          if (close) {
            resp.user = self.users.lookup(resp.userId);
            if (resp.user) {
              resp.userFirstName = resp.user.firstName;
              resp.userMiddleName = resp.user.middleName;
              resp.userLastName = resp.user.lastName;
              resp.userSuffix = resp.user.suffix;
            }
            resp.refresh = self.exception.refresh;
            resp.createdByUser = OcrAuth.session.user;
            resp.createdByUserFirstName = OcrAuth.session.user.firstName;
            resp.createdByUserMiddleName = OcrAuth.session.user.middleName;
            resp.createdByUserLastName = OcrAuth.session.user.lastName;
            resp.createdByUserSuffix = OcrAuth.session.user.suffix;
            $mdDialog.hide(resp);
          }
        },
      ).catch(function(error) {
        if (error && error.data) {
          $mdToast.show(
            $mdToast.simple()
              .textContent(error.data.reason)
              .position("bottom right"),
          );
        }
      })
    };

    self.submit = self.doSave;

    self.delete = function() {
      const message
        = "Are you sure that you want to delete this exception?";
      $mdDialog.show(
        $mdDialog.confirm()
          .parent(angular.element($document[0].querySelector(
            '#popupContainer')))
          .clickOutsideToClose(false)
          .title('Confirm Exception Delete')
          .multiple(true)
          .textContent(message)
          .ariaLabel(message)
          .ok('Delete the exception')
          .cancel('Cancel'),
      ).then(function() {
        $mdToast.show(
          $mdToast.simple()
            .textContent('Deleting...')
            .position("bottom right"),
        );
        ExceptionLogService.delete({
          id: self.exception.id,
        }).$promise
          .then(function() {
            $mdToast.show(
              $mdToast.simple()
                .textContent('Exception deleted.')
                .position("bottom right"),
            );
            $mdDialog.hide();
          }, function(error) {
            let errorMsg = error.data.reason;
            if (error.data.status === 400) {
              errorMsg = 'Unable to delete exception. Please retry after deleting any ' +
                'attachments in the exception.';
            }
            $mdToast.show(
              $mdToast.simple()
                .textContent(errorMsg)
                .position("bottom right")
                .action('OK')
                .highlightAction(true)
                .highlightClass('md-accent')
                .hideDelay(30000),
            );
          });
      }, () => {});
    };
  },
]);
