angular.module('CaresApp').component('ocrInvoiceFlags', {
  templateUrl: 'components/ocr-invoice-flags/ocr-invoice-flags.template.html',
  require: {
    ngModel: 'ngModel',
  },
  controller: [
    '$scope',
    'OcrInvoiceFlagsService',
    function($scope, OcrInvoiceFlagsService) {
      const self = this;
      self.flags = OcrInvoiceFlagsService.flags;

      self.$onInit = function() {
        $scope.$watch(
          () => self.ngModel.$modelValue,
          function() {
            self.model = self.ngModel.$modelValue;
          },
        );
      };
    },
  ],
});
