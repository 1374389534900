angular.module('CaresApp').controller('AddAppointmentGroupDialogController', [
  '$scope',
  '$mdDialog',
  'AppointmentGroupService',
  'OcrAuth',
  function($scope, $mdDialog, AppointmentGroupService, OcrAuth) {
    const self = this;

    self.submitting = false;
    self.selected = [];
    self.appointmentGroups = [];
    self.isDuplicate = false;

    self.$onInit = function() {
      AppointmentGroupService.get({
        limit: 1000,
        officeId: OcrAuth.session.user.officeId,
      }).$promise.then(
        function(resp) {
          self.appointmentGroups = resp.list;
        },
      );
    }
    self.isChange = true;
    self.checkGroupName = function(form) {
      for (const group of self.appointmentGroups) {
        if (group.name == self.newAppointmentGroupName) {
          form.newAppointmentGroupName.$setValidity('validateGroupName', false);
          form.newAppointmentGroupName.$setTouched();
          self.isChange = true;
          return;
        }
      }
      form.newAppointmentGroupName.$setValidity('validateGroupName', true);
      self.isChange = false;
      return;
    }

    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;

    self.submit = () => $mdDialog.hide(self.selected[0]);

    self.addToNewAppointmentGroup = function(newAppointmentGroupName) {
      self.submitting = true;
      console.log("hello");
      AppointmentGroupService.save({
        officeId: self.officeId,
        attorneyUserId: self.attorneyUserId,
        name: newAppointmentGroupName,
      }).$promise.then(
        (resp) => {
          self.submitting = false;
          $mdDialog.hide(resp);
        },
      );
      self.submitting = false;
    };
  },
]);
