angular.module('CaresApp').component('ocrUserSettings', {
  templateUrl: 'components/ocr-user-settings/ocr-user-settings.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    '$document',
    '$location',
    '$mdToast',
    '$mdDialog',
    'UserService',
    'InvoiceAdditionalNotificationService',
    'PhoneValidationService',
    'OcrLookups',
    'OcrUtils',
    'OcrViews',
    'OcrAuth',
    'SessionService',
    '$timeout',
    function($scope, $document, $location, $mdToast, $mdDialog, UserService,
      InvoiceAdditionalNotificationService, PhoneValidationService, OcrLookups, OcrUtils,
      OcrViews, OcrAuth, SessionService, $timeout
    ) {
      $scope.$emit("SetSearchBox", false);
      const self = this;
      OcrUtils.setPageTitle('General Settings');
      self.currentView = OcrViews.currentView;
      self.hasPermission = OcrAuth.checkPermission;

      self.judicialDistricts = OcrLookups.dummyList;

      OcrLookups.get('JudicialDistrict').then(
        (judicialDistricts) => self.judicialDistricts
        = judicialDistricts,
      );

      self.additionalUsers = [];
      self.usersInOffice = [];
      self.loadedUsers = [];
      self.getUserFullName = OcrUtils.getUserFullName;
      self.tfaPhone = undefined;
      self.failed = false;

      self.loading = true;
      self.promise = UserService.get({ id: OcrAuth.session.userId }).$promise
        .then(function(resp) {
          self.entity = resp;
          self.tfaPhone = self.entity.tfaPhone;

          self.promise = InvoiceAdditionalNotificationService.get({
            limit: 1000,
            userId: OcrAuth.session.userId,
          }).$promise;
          return self.promise;
        }).then(function(resp) {
          self.loadedUsers = resp.list;

          self.promise = UserService.get({
            limit: 1000,
            office_id: OcrAuth.session.user.officeId,
          }).$promise;
          return self.promise;
        }).then(function(resp) {
          self.usersInOffice = resp.list;
          for (const addUser of self.loadedUsers) {
            for (const officeUser of self.usersInOffice) {
              if (addUser.additionalUserId === officeUser.id) {
                self.additionalUsers.push(officeUser);
              }
            }
          }
        }).finally(() => {
          let urlParams = $location.search().quickSetup;
          if(urlParams == 1){
            self.entity.useTFA = true;
            $timeout(function() {
              $document[0].getElementById('tfaPhone').focus();
            }, 250);
          }
          self.loading = false
        });

      $scope.$on('$locationChangeStart',
        function(event, newUrl, oldUrl) {
          const newList = newUrl.split("#");
          const oldList = oldUrl.split("#");

          if (angular.isUndefined(newList)
            && angular.isUndefined(oldList)
            && newList[1] === oldList[1]
          ) {
            $location.search("");
            return;
          }

          const removeQueryParams = function(url) {
            if (url) {
              const index = url.indexOf("?");
              if (index >= 0) {
                return url.substring(0, index);
              }
            }
            return url;
          };

          const newPath = $location.path();

          if (
            newPath === "/"
            || removeQueryParams(newUrl) === removeQueryParams(oldUrl)
          ) {
            $location.search("");
            return;
          }

          if (self.forms.userSettingsForm.$pristine) {
            $location.search("");
            return;
          }

          event.preventDefault();

          const message
            = "Are you sure that you want to leave this page without saving your changes?";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Page Exit')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK')
              .cancel('Cancel'),
          ).then(function() {
            self.forms.userSettingsForm.$setPristine();
            $location.search("");
            $location.path(newPath);
          }, () => {});
        });

      $scope.$on("LoggingOut",
        () => {
          if (self.forms.userSettingsForm.$pristine) {
            SessionService.signout();
            return;
          }

          const message
            = "Are you sure that you want to leave this page without saving your changes?";

          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Page Exit')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK')
              .cancel('Cancel'),
          ).then(function() {
            SessionService.signout();
          });
        },
      );

      self.save = function() {
        self.entity.additionalNotifications = []
        for (const user of self.additionalUsers) {
          self.entity.additionalNotifications.push({
            parentUserId: self.entity.id,
            additionalUserId: user.id,
          })
        }
        self.saving = true;
        $mdToast.show(
          $mdToast.simple()
            .textContent('Saving...')
            .position("bottom right"),
        );

        UserService.update(self.entity).$promise.then(
          function() {
            OcrAuth.session.user.upcomingNotifications = self.entity.upcomingNotifications;
            OcrAuth.session.user.favoriteJudicialDistrictId =
              self.entity.favoriteJudicialDistrictId;
            self.forms.userSettingsForm.$setPristine();
            self.saving = false;
            $mdToast.show(
              $mdToast.simple()
                .textContent('Saved.')
                .position("bottom right"),
            );
          },
        ).catch(function(error) {
          self.saving = false;
          if (error && error.data) {
            $mdToast.show(
              $mdToast.simple()
                .textContent(error.data.reason)
                .position("bottom right"),
            );
          }
        })
      };

      self.validateLength = function() {
        if (self.entity.tfaPhone === null) {
          if (self.entity.tfaPhone.toString().length === 10) {
            self.forms.userSettingsForm.tfaPhone.$setValidity("length", false);
          } else {
            self.forms.userSettingsForm.tfaPhone.$setValidity("length", true);
          }
        } else {
          self.forms.userSettingsForm.tfaPhone.$setValidity("length", true);
        }
      };

      self.submit = function() {
        self.saving = true;
        self.failed = false;
        if (!self.entity.useTFA || self.tfaPhone === self.entity.tfaPhone) {
          self.save();
        } else {
          const pv = {
            userId: self.entity.userId,
            recipientPhone: self.entity.tfaPhone,
          };
          PhoneValidationService.save(pv)
            .$promise.then(function (response) {
            $mdDialog.show({
              controller: 'PhoneValidationDialogController',
              templateUrl: 'components/ocr-user-settings/phone-validation.dialog.template.html',
              parent: angular.element($document[0].body),
              locals: {
                id: response.id,
              },
              bindToController: true,
              controllerAs: '$ctrl',
              clickOutsideToClose: false,
              fullscreen: self.customFullscreen,
              multiple: true,
            })
              .then(function () {
                self.tfaPhone = self.entity.tfaPhone;
                self.save();
              }, function () {
                self.saving = false;
                self.failed = true;
              });
          });
        }
      }
    },
  ],
});
