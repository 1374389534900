angular.module('CaresApp').component('ocrUserList', {
  templateUrl: 'components/ocr-user-list/ocr-user-list.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    '$location',
    'UserService',
    'OcrLookups',
    'OcrUtils',
    'OcrViews',
    'OcrAuth',
    function($scope, $location, UserService, OcrLookups, OcrUtils, OcrViews,
      OcrAuth,
    ) {

      const self = this;
      OcrUtils.setPageTitle('Users');
      self.currentView = OcrViews.currentView;
      self.personas = self.statuses = self.offices = OcrLookups.dummyList;
      self.hasPermission = OcrAuth.checkPermission;

      OcrLookups.get('Persona').then(
        (personas) => self.personas = personas,
      );

      OcrLookups.get('UserStatus').then(
        (statuses) => self.statuses = statuses,
      );

      OcrLookups.get('Office', true).then(
        (offices) => self.offices = offices,
      );

      self.search = undefined;

      self.results = {
        data: [],
        count: 0,
        total: 0,
      };
      self.promise = null;
      $scope.$watch(() => self.promise, () => {
        self.setPromise()(self.promise);
      });

      self.updateData = function() {

        if (angular.isDefined(self.search) && angular.isDefined(self.query.office) ) {
          self.query.search = `officeId = ${self.query.office} AND ${self.search}`;
        } else if (angular.isDefined(self.search)) {
          self.query.search = self.search;
        } else {
          self.query.search = undefined;
        }
        $location.search({
          search: self.search,
          oid: self.query.office,
          order: self.query.order,
        });
        self.promise = UserService.query({
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
          order: self.query.order,
          query: self.query.search,
          office_id: self.query.office,
        }).$promise.then(
          function(resp) {
            self.results.data = resp.list;
            self.results.count = resp.count;
            self.results.total = resp.total;
          },
        ).finally(() => self.promise = null);
      };

      self.$onInit = function() {
        self.search = $location.search().search;
        self.query.office = $location.search().oid;
        self.query.order = $location.search().order;
        self.updateData()
      };

      $scope.$on("Searching", function(_, data) {

        console.log("Searching... data: %O", data);

        if (data) {
          self.search = data;
        } else {
          self.search = undefined
        }

        self.updateData();
      });

      self.reorder = function() {
        self.updateData();
      };

      self.refresh = function() {
        self.query.office = undefined;
        self.updateData();
      }

      $scope.$on('$locationChangeStart',
        function(_, newUrl, oldUrl) {
          const newArray = newUrl.split("?");
          const oldArray = oldUrl.split("?");
          if (newArray[0] !== oldArray[0]) {
            $location.search("");
          }
        });
    },
  ],
});
