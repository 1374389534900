angular.module('CaresApp').component('ocrSearchCases', {
  templateUrl: 'components/ocr-search-cases/ocr-search-cases.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    query: '<',
  },
  controller: [
    '$scope',
    '$filter',
    '$location',
    'CaseService',
    'OcrLookups',
    'OcrUtils',
    'OcrViews',
    'OcrAuth',
    'SessionService',
    function($scope, $filter, $location, CaseService,
      OcrLookups, OcrUtils, OcrViews, OcrAuth, SessionService) {
      const self = this;
      self.today = OcrUtils.today;
      OcrUtils.setPageTitle('Search Cases');
      self.currentView = OcrViews.currentView

      self.caseTypes = self.judicialDistricts = self.counties = self.offices
        = OcrLookups.dummyList;

      function getUniqueCaseTypes(source) {
        const result = [];
        for (let i = 0; i < source.length; i++) {
          if (result.indexOf(source[i].type) === -1) {
            result.push(source[i].type);
          }
        }
        return result;
      }
      OcrLookups.get('CaseType', true).then(
        (caseTypes) => {
          self.caseTypes = caseTypes;
          self.uniqueCaseTypes = getUniqueCaseTypes(self.caseTypes);
        },
      );
      OcrLookups.get('JudicialDistrict').then(
        (judicialDistricts) => self.judicialDistricts
        = judicialDistricts,
      );
      OcrLookups.get('County').then(
        (counties) => self.counties = counties,
      );
      OcrLookups.get('Office').then(
        (offices) => self.offices = offices,
      );

      self.hasPermission = OcrAuth.hasAnyPermission;

      self.cases = [];

      self.search = {};
      self.search.showActive = true;
      self.search.showInactive = false;

      self.promise = null;
      $scope.$watch(() => self.promise, () => self.setPromise()(self.promise));

      self.refresh = () => self.updateData();

      self.updateData = function() {
        self.cases.length = 0
        if (self.search.caseYear) {
          self.search.caseYear = Number(self.search.caseYear);
        }
        self.search.skip = (self.query.page - 1) * self.query.limit;
        self.search.limit = self.query.limit;
        $location.search(self.search);
        self.promise = CaseService.get(self.search)
          .$promise.then(
            function(resp) {
              if (
                self.search.judicialDistrict
              && self.search.county
              && self.search.caseYear
              && self.search.caseType
              && self.search.caseSequence
              && resp.list.length > 0
              ) {
                self.caseExists = true;
              } else {
                self.caseExists = false;
              }

              self.cases = resp.list;
              self.cases.count = resp.count;
              self.cases.total = resp.total;

              for (const case_ of self.cases) {
                case_.attorneys = [];
                const attorneyNames = [];
                case_.offices = [];
                const officeNames = [];
                if (case_.appointments) {
                  for (const appointment of case_.appointments) {
                    if (!appointment.endDate) {
                      const attorney = {
                        id: appointment.attorneyId,
                        firstName: appointment.attorneyFirstName,
                        middleName: appointment.attorneyMiddleName,
                        lastName: appointment.attorneyLastName,
                        suffix: appointment.attorneySuffix,
                      };
                      const attorneyName = OcrUtils.getUserFullName(
                        attorney);
                      if (attorneyNames.indexOf(attorneyName) < 0) {
                        attorneyNames.push(attorneyName);
                      }
                      case_.attorneys.push(attorney);
                      const office = {
                        id: appointment.officeId,
                        name: appointment.officeName,
                      };
                      case_.offices.push(office);
                      if (officeNames.indexOf(office.name) < 0) {
                        officeNames.push(office.name);
                      }
                    }
                  }
                }
                case_.attorneyNames = $filter('orderBy')(attorneyNames)
                  .join("; ");
                case_.officeNames = $filter('orderBy')(officeNames).join(
                  "; ");
                const childPartyNames = [];
                if (case_.childParties) {
                  for (const childParty of case_.childParties) {
                    if (childParty.endDate) {
                      childParty.endDate = new Date(childParty.endDate);
                    }
                    if (!childParty.endDate || childParty.endDate > self.today) {
                      childPartyNames.push(OcrUtils.getChildPartyFullName(
                        childParty));
                    }
                  }
                }
                const uniqueArray = childPartyNames.filter(function(item, pos) {
                  return childPartyNames.indexOf(item) === pos;
                });
                case_.childPartyNames = uniqueArray.join("; ");
              }
            },
          ).finally(() => self.promise = null);
      };

      self.searchCases = function() {
        // eslint-disable-next-line
        if (self.search.caseSequence != null) {
          self.search.caseSequence = self.search.caseSequence.toString().padStart(6, "0");
        }
        self.query.page = 1;
        self.updateData();
      }

      self.countyChanged = function() {
        if (self.search.county) {
          const county = self.counties.lookup(self.search.county);
          if (county) {
            self.search.judicialDistrict = county.judicialDistrictId;
          }
        }
      };

      self.createCaseUrl = function() {
        let url = `/case/${self.currentView.key}/create`;
        const parameters = [];
        if (self.search.caseSequence) {
          parameters.push(`caseSequence=${self.search.caseSequence}`);
        }
        if (self.search.caseType) {
          parameters.push(`caseType=${self.search.caseType}`);
        }
        if (self.search.caseYear) {
          parameters.push(`caseYear=${self.search.caseYear}`);
        }
        if (self.search.county) {
          parameters.push(`county=${self.search.county}`);
        }
        if (self.search.judicialDistrict) {
          parameters.push(`judicialDistrict=${self.search.judicialDistrict}`);
        }
        if (parameters.length > 0) {
          url = url.concat('?', parameters.join('&'));
        }
        $location.url(url);
      }

      self.$onInit = function() {
        if (Object.keys($location.search()).length !== 0) {
          self.search = $location.search();
          if (self.search.limit) {
            self.query.limit = self.search.limit;
            if (self.search.skip) {
              self.query.page = (self.search.skip / self.search.limit) + 1;
            }
          }
          self.updateData();
        }
      }

      self.resetSearch = function() {
        self.cases = [];
        self.search = {};
        self.caseExists = false;
      }

      self.reorder = function() {
        self.updateData();
      }

      $scope.$on("LoggingOut",
        () => {
          SessionService.signout();
        },
      );

      $scope.$on('$locationChangeStart',
        function(_, newUrl, oldUrl) {
          const newArray = newUrl.split("?");
          const oldArray = oldUrl.split("?");
          if (newArray[0] !== oldArray[0]) {
            if (newArray[0].indexOf('case/cases/create') === -1) {
              $location.search("");
            }
          }
        });
    },
  ],
});
