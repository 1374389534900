angular.module('CaresApp').component('ocrAttachmentsPanel', {
  templateUrl: 'components/ocr-attachments-panel/ocr-attachments-panel.template.html',
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    setPromise: '&onPromiseChanged',
    notifyOfItemAdded: '&onItemAdded',
    formType: '@ocrFormType',
    isNew: '<ocrIsNew',
    readOnly: '<ocrReadOnly',
  },
  controller: [
    '$scope',
    '$document',
    '$mdDialog',
    'AppointmentAttachmentService',
    'ActivityAttachmentService',
    'AllAttachmentsService',
    'ApprovalAttachmentService',
    'ExceptionAttachmentService',
    'OcrAuth',
    function($scope, $document, $mdDialog,
      AppointmentAttachmentService, ActivityAttachmentService,
      AllAttachmentsService, ApprovalAttachmentService,
      ExceptionAttachmentService, OcrAuth) {
      const self = this;

      let attachmentService = null;
      let searchService = null;
      self.promise = true;
      self.selection = [];
      self.model = null;
      self.searchParams = {
        thisAppointmentOnly: false,
        text: null,
        order: null,
      }

      self.query = {
        order: undefined,
        limit: 10,
        page: 1,
      };

      self.results = {
        data: [],
        count: 0,
        total: null,
      };

      self.promise = null;
      $scope.$watch(() => self.promise, () => {
        self.setPromise()(self.promise)
      })

      self.executeSearch = function(reset) {
        if (reset) {
          self.query.page = 1;
        }
        const request = {
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
        }
        if (this.searchParams.thisAppointmentOnly) {
          request.appointmentId = self.model.id
        }
        if (self.searchParams.text) {
          request.query = self.searchParams.text;
        }
        if (self.searchParams.order) {
          request.order = self.searchParams.order;
        }
        self.promise = searchService.query(request).$promise.then(
          function(resp) {
            self.results.data = resp.list;
            self.results.count = resp.count;
            self.results.total = resp.total;
          },
        ).finally(() => self.promise = null)
      }

      self.showAddAttachmentDialog = function() {
        $mdDialog.show({
          controller: 'AddAttachmentDialogController',
          templateUrl: 'tmpl/add-attachment-dialog.html',
          parent: angular.element($document[0].body),
          clickOutsideToClose: false,
          controllerAs: '$ctrl',
          locals: {
            formId: self.model.id,
            formType: self.formType,
            appointmentId: self.model.appointmentId,
            officeId: self.model.officeId,
          },
          bindToController: true,
          multiple: true,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        }).then(function() {
          self.updateData();
          if (self.notifyOfItemAdded) {
            self.notifyOfItemAdded();
          }
        }, () => {});
      }

      self.showDeletionConfirmation = function(event, entry) {
        const dialogText = `Are you sure you want to delete ${entry.name
        }?`
        const confirmDialog = $mdDialog.confirm()
          .title('Confirm Deletion')
          .textContent(dialogText)
          .targetEvent(event)
          .ok('Delete')
          .cancel('Cancel')
          .multiple(true)
        $mdDialog.show(confirmDialog).then(
          function() {
            self.promise = attachmentService.delete({
              id: entry.id,
            }).$promise
              .then(
                function() {
                  self.selection = []
                  //TODO show toast
                  self.updateData()
                },
              ).finally(() => self.promise = null);
          }, () => {},
        )
      }

      self.updateData = function() {
        const request = {
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
          officeId: self.model.officeId,
        }
        if (self.formType === 'appointment') {
          request.appointmentId = self.model.id
        } else if (self.formType === 'activity') {
          request.activityId = self.model.id
        } else if (self.formType === 'approval') {
          request.otherExpenseRequestId = self.model.id
        } else if (self.formType === 'exception') {
          request.exceptionLogId = self.model.id
        }
        self.promise = searchService.query(request).$promise.then(
          function(resp) {
            self.results.data = resp.list;
            self.results.count = resp.count;
            self.results.total = resp.total;
          },
        ).finally(() => self.promise = null)
      }

      self.pageAttachments = function() {
        if (self.formType === 'appointment') {
          self.executeSearch();
        } else if (self.formType === 'activity') {
          self.updateData();
        } else if (self.formType === 'approval') {
          self.updateData();
        } else if (self.formType === 'exception') {
          self.updateData();
        }
      }

      self.$onInit = function() {
        if (self.formType === 'appointment') {
          self.formTypeUpper = 'Appointment';
          self.formTypeLower = 'appointment';
          attachmentService = AppointmentAttachmentService;
          searchService = AllAttachmentsService;
          self.canWriteAttachments = () => OcrAuth.hasAnyPermission([
            'APPOINTMENT_WRITE',
            'APPOINTMENT_ATTACHMENT_WRITE',
            'ADMIN_WRITE',
          ]);
          self.canReadAttachments = () => OcrAuth.hasAnyPermission([
            'APPOINTMENT_READ',
            'APPOINTMENT_ATTACHMENT_READ',
            'ADMIN_READ',
          ]);
        } else if (self.formType === 'activity') {
          self.formTypeUpper = 'Activity';
          self.formTypeLower = 'activity';
          attachmentService = ActivityAttachmentService;
          searchService = ActivityAttachmentService;
          self.canWriteAttachments = () => OcrAuth.hasAnyPermission([
            'ACTIVITY_WRITE',
            'ADMIN_WRITE',
          ]);
          self.canReadAttachments = () => OcrAuth.hasAnyPermission(['ACTIVITY_READ', 'ADMIN_READ']);
        } else if (self.formType === 'approval') {
          self.formTypeUpper = 'Request';
          self.formTypeLower = 'request';
          attachmentService = ApprovalAttachmentService;
          searchService = ApprovalAttachmentService;
          self.canWriteAttachments = () => OcrAuth.hasAnyPermission([
            'EXPENSE_REQUEST_CREATE',
            'EXPENSE_REQUEST_REVIEW',
            'ADMIN_WRITE',
          ]);
          self.canReadAttachments = () => OcrAuth.hasAnyPermission([
            'EXPENSE_REQUEST_CREATE',
            'EXPENSE_REQUEST_REVIEW',
            'ADMIN_READ',
          ]);
        } else if (self.formType === 'exception') {
          self.formTypeUpper = 'Exception';
          self.formTypeLower = 'exception';
          attachmentService = ExceptionAttachmentService;
          searchService = ExceptionAttachmentService;
          self.canWriteAttachments = () => OcrAuth.hasAnyPermission([
            'EXCEPTION_WRITE',
            'ADMIN_WRITE',
          ]);
          self.canReadAttachments = () => OcrAuth.hasAnyPermission([
            'EXCEPTION_READ',
            'ADMIN_READ',
          ]);
        } else {
          console.error(`Unknown form type: ${self.formType}`);
          return;
        }
        $scope.$watch(
          () => self.ngModel.$modelValue,
          function() {
            self.model = self.ngModel.$modelValue;
            if (self.model) {
              if (self.model.id) {
                self.updateData();
                self.isNew = false;
              } else {
                self.isNew = true;
              }
            }
          },
        );
        $scope.$watch(
          () => self.isNew, () => {
            self.searchParams.thisAppointmentOnly = !self.isNew;
            self.updateData();
          },
        );
      };

      self.reorder = function() {
        if (self.promise === null) {
          self.executeSearch(true);
        }
      };

      self.showDelete = function(entry) {
        if (self.formType === 'appointment') {
          if (entry.activityId === 0) {
            return self.canWriteAttachments();
          }
          return false;
        }
        return self.canWriteAttachments();
      };

      self.getLowerDynamic = function(entry) {
        if (self.formType === 'appointment') {
          if (entry.activityId === 0) {
            return self.formTypeLower;
          }
          return 'activity';
        }
        return self.formTypeLower;
      }

      $scope.$on("ReloadAttachmentsDown", () => self.updateData());
    },
  ],
});
