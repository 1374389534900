angular.module('CaresApp').component('ocrInvolvedPersonTypeEdit', {
  templateUrl:
    'components/ocr-involved-person-type-edit/ocr-involved-person-type-edit.template.html',
  controller: [
    '$scope',
    '$document',
    '$location',
    '$routeParams',
    '$mdToast',
    '$mdDialog',
    'InvolvedPersonTypeService',
    'OcrLookups',
    'OcrUtils',
    'OcrAuth',
    function($scope, $document, $location, $routeParams, $mdToast, $mdDialog,
      InvolvedPersonTypeService, OcrLookups, OcrUtils, OcrAuth,
    ) {
      $scope.$emit("SetSearchBox", false);
      const self = this;
      self.today = new Date();
      self.hasPermission = OcrAuth.checkPermission;

      if ($routeParams.involvedPersonTypeId !== 'create') {
        self.id = $routeParams.involvedPersonTypeId;
      } else {
        OcrUtils.setPageTitle('New Involved Person Type');
      }
      self.loading = true;
      if (self.id) {
        self.promise = InvolvedPersonTypeService.get({ id: self.id }).$promise
          .then(function(resp) {
            self.entity = resp;
            OcrUtils.setPageTitle(`Involved Person Type: ${self.entity.type}`);
            if (self.entity.startDate) {
              self.entity.startDate = new Date(self.entity.startDate)
              self.entity.loadedStart = new Date(self.entity.startDate);
            }
            if (self.entity.endDate) {
              self.entity.endDate = new Date(self.entity.endDate)
            }
          })
          .finally(() => self.loading = false);
      } else {
        self.entity = {}
        self.loading = false
      }


      self.save = function(andExit) {
        OcrLookups.remove('InvolvedPersonType')
        OcrLookups.remove('InvolvedPersonType', true);
        $mdToast.show(
          $mdToast.simple()
            .textContent('Saving...')
            .position("bottom right"),
        );
        let saveFunction = null;
        let saveArgs = null;
        if (self.id) {
          saveFunction = InvolvedPersonTypeService.update
          saveArgs = [{ id: self.id }, self.entity]
        } else {
          saveFunction = InvolvedPersonTypeService.save
          saveArgs = [self.entity]
        }
        saveFunction.apply(this, saveArgs).$promise.then(
          function() {
            self.form.$setPristine();
            $mdToast.show(
              $mdToast.simple()
                .textContent('Involved person type saved.')
                .position("bottom right"),
            )
            if (andExit) {
              self.close(true)
            }
          },
        ).catch(function(error) {
          if (error && error.data && error.data.status === 401) {
            self.close(true);
            return;
          }
          $mdToast.show(
            $mdToast.simple()
              .textContent(error.data.reason)
              .position("bottom right"),
          );
        })
      }

      self.close = function(noConfirm) {
        if (noConfirm || self.form.$pristine) {
          $location.path(`/views/${$routeParams.view}`);
        } else {
          const message
              = "Are you sure that you want to close this form without saving your changes?";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Form Close')
              .textContent(message)
              .ariaLabel(message)
              .ok('Close Form')
              .cancel('Cancel'),
          ).then(
            () => $location.path(`/views/${$routeParams.view}`),
            () => {},
          );
        }
      }

      self.deleteForm = function() {
        const message
          = "Are you sure that you want to delete this involved person type?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Involved Person Type Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete Involved Person Type')
            .cancel('Cancel'),
        ).then(function() {
          // ok
          OcrLookups.remove('InvolvedPersonType')
          OcrLookups.remove('InvolvedPersonType', true);
          $mdToast.show(
            $mdToast.simple()
              .textContent('Deleting...')
              .position("bottom right"),
          );

          InvolvedPersonTypeService.delete({
            id: self.id,
          }, self.entity).$promise.then(function() {
            $mdToast.show(
              $mdToast.simple()
                .textContent('Involved person type deleted.')
                .position("bottom right"),
            );
            $location.path(`/views/${$routeParams.view}`);
          })
            .catch(function(error) {
              console.error("Error: %O", error);
              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            });
        }, () => {});
      };

      self.disableStart = function() {
        if (self.loading) {
          return true;
        } else if (self.id) {
          if (self.today > self.entity.loadedStart) {
            return true;
          }
          return false;
        }
        return false;
      }

      $scope.$on('$locationChangeStart',
        function(event, newUrl, oldUrl) {
          const removeQueryParams = (url) => {
            if (url) {
              const index = url.indexOf("?");
              if (index >= 0) {
                return url.substring(0, index);
              }
            }
            return url;
          };

          const newPath = $location.path();

          if (
            newPath === "/"
            || self.form.$pristine
            || removeQueryParams(newUrl) === removeQueryParams(oldUrl)
          ) {
            return;
          }

          event.preventDefault();

          const message
            = "Are you sure that you want to close this form without saving your changes?";

          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Page Exit')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK')
              .cancel('Cancel'),
          ).then(() => {
            self.form.$setPristine();
            $location.path(newPath);
          });
        });

      self.$onInit =
        () => $scope.$watch(
          () => angular.isDefined(self.form),
          function() {
            if (self.form && self.form.$invalid) {
              angular.forEach(self.form.$error, function(field) {
                angular.forEach(field, function(errorField) {
                  errorField.$setTouched();
                });
              });
            }
          }, true);
    },
  ],
});
