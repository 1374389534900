angular.module('CaresApp').component('ocrHelpArticleList', {
  templateUrl: 'components/ocr-help-article-list/ocr-help-article-list.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    '$location',
    'HelpArticleService',
    'OcrLookups',
    'OcrUtils',
    'OcrViews',
    'OcrAuth',
    function($scope, $location, HelpArticleService, OcrLookups, OcrUtils, OcrViews, OcrAuth) {
      const self = this;
      OcrUtils.setPageTitle('Help Articles');
      self.currentView = OcrViews.currentView

      self.articleCategories = OcrLookups.dummyList
      OcrLookups.get('HelpArticleCategory').then(
        (articleCategories) => self.articleCategories = articleCategories,
      );
      self.results = {
        data: [],
        count: 0,
        total: 0,
      };
      self.promise = null;
      $scope.$watch(() => self.promise, () => self.setPromise()(self.promise));
      self.$onInit = () => self.updateData();

      self.refresh = () => self.updateData();

      self.updateData = function() {
        self.promise = HelpArticleService.query({
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
        }).$promise.then(
          function(resp) {
            self.results.data = resp.list;
            self.results.count = resp.count;
            self.results.total = resp.total;
          },
        ).finally(() => self.promise = null)
      }

      self.checkPermission = OcrAuth.checkPermission;

      self.searchHelp = function(categoryId) {
        if (self.query.query === "") {
          self.query.query = undefined;
        }

        let input = self.query.query;
        if (categoryId) {
          if (input) {
            input += ` AND category = ${self.articleCategories.lookup(categoryId).category}`;
          } else {
            input = `category = ${self.articleCategories.lookup(categoryId).category}`;
          }
        }

        self.promise = HelpArticleService.get({
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
          query: input,
        }).$promise;

        self.promise.then(function(response) {
          self.promise = null;
          // improve error handling here.
          self.results.data = response.list;
          self.results.total = response.total;
          self.results.count = self.results.data.length;
        })
          .catch(function(error) {
            if (error && error.data && error.data.status === 401) {
              $location.path("/");
            }
          });
      };
    },
  ],
});
