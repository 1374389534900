angular.module('CaresApp').component('ocrAdditionalFundsList', {
  templateUrl: 'components/ocr-additional-funds-list/ocr-additional-funds-list.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    '$location',
    'ExcessFeesRequestService',
    'OcrLookups',
    'OcrUtils',
    'OcrViews',
    'OcrAuth',
    function($scope, $location, ExcessFeesRequestService, OcrLookups, OcrUtils,
      OcrViews, OcrAuth,
    ) {
      $scope.$emit("SetSearchBox", false);
      const self = this;
      OcrUtils.setPageTitle('Additional Funds');
      self.currentView = OcrViews.currentView

      self.excessFeesRequestStatuses =
        self.caseTypes =
        self.judicialDistricts =
        self.offices =
        OcrLookups.dummyList;

      OcrLookups.get('ExcessFeesRequestStatus').then(
        (excessFeesRequestStatuses) => self.excessFeesRequestStatuses = excessFeesRequestStatuses,
      );
      OcrLookups.get('CaseType', true).then((caseTypes) => self.caseTypes = caseTypes);
      OcrLookups.get('JudicialDistrict').then(
        (judicialDistricts) => self.judicialDistricts = judicialDistricts);
        OcrLookups.get('Office', true).then(
          (offices) => self.offices = offices,
        ).then(() => {
          self.offices = self.offices.filter(self.filterOffices);
        });

      self.results = {
        data: [],
        count: 0,
        total: 0,
      };
      self.search = {
        order: "-timestamp",
      };
      self.promise = null;
      $scope.$watch(() => self.promise, () => self.setPromise()(self.promise))

      self.filterOffices = function(office) {
        const allowed = ["ADMIN_READ", "BILLING_CHANGE", "BILLING_REQUEST_REVIEW"];
        if (allowed.some(i => OcrAuth.session.user.permissions.includes(i))) {
          return true;
        } else if (office.id === OcrAuth.session.user.officeId) {
          return true;
        } else {
          return false;
        }
      }

      self.getUserFullName = (first, middle, last, suffix) => OcrUtils.getUserFullName({
        firstName: first,
        middleName: middle,
        lastName: last,
        suffix: suffix,
      });

      self.refresh = () => self.updateData();

      self.updateData = function() {
        self.search.skip = (self.query.page - 1) * self.query.limit;
        self.search.limit = self.query.limit;
        if (self.fundsFrom) {
          self.search.fundsFrom = self.fundsFrom * 100;
        }
        if (self.fundsTo) {
          self.search.fundsTo = self.fundsTo * 100;
        }

        $location.url(`${$location.path()}?${self.buildSearch(self.search)}`);

        self.promise = ExcessFeesRequestService.query(self.search).$promise.then(
          function(resp) {
            self.results.data = resp.list;
            self.results.count = resp.total;
            self.results.total = resp.count;
            // TODO fix swapping of total and count
          },
        ).finally(() => self.promise = null)
      }

      self.$onInit = function() {
        self.search = $location.search();

        if (self.search.dateFrom) {
          self.search.dateFrom = new Date(self.search.dateFrom);
        }
        if (self.search.dateTo) {
          self.search.dateTo = new Date(self.search.dateTo);
        }
        if (self.search.limit) {
          self.query.limit = self.search.limit;
          if (self.search.skip) {
            self.query.page = (self.search.skip / self.search.limit) + 1;
          }
        }
        if (self.search.fundsFrom) {
          self.fundsFrom = self.search.fundsFrom / 100;
        }
        if (self.search.fundsTo) {
          self.fundsTo = self.search.fundsTo / 100;
        }

        if (!self.search.excessFeesRequestStatus
          && OcrAuth.session && OcrAuth.session.user
          && OcrAuth.session.user.permissions
        ) {
          if (OcrAuth.session.user.permissions.indexOf("BILLING_CHANGE") !== -1) {
            self.search.excessFeesRequestStatus = 1;
          } else if (OcrAuth.session.user.permissions.indexOf("BILLING_REQUEST_REVIEW") !== -1) {
            self.search.excessFeesRequestStatus = 2;
          }
        } else {
        }

        self.updateData()
      }

      self.buildSearch = function(object) {
        let search = undefined;
        angular.forEach(object, function(value, key) {
          if (angular.isUndefined(search)) {
            if (typeof value === "boolean" && value === true) {
              search = key;
            } else {
              search = `${key}=${value}`;
            }
          } else if (typeof value === "boolean" && value === true) {
            search = `${search}&${key}`;
          } else {
            search = `${search}&${key}=${value}`;
          }
        });
        return search;
      }

      $scope.$on('$locationChangeStart',
        function(_, newUrl, oldUrl) {
          const newArray = newUrl.split("?");
          const oldArray = oldUrl.split("?");
          if (newArray[0] !== oldArray[0]) {
            $location.search("");
          }
        });
    },
  ],
});
