angular.module('CaresApp').component('ocrAppointmentActivitiesDetailView', {
  templateUrl:
    'components/ocr-appointment-activities-detail-view/' +
    'ocr-appointment-activities-detail-view.template.html',
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    selected: '=',
    updateData: '=',
    query: '=',
  },
  controller: [
    '$scope',
    '$document',
    '$mdDialog',
    'OcrAuth',
    'OcrUtils',
    function($scope, $document, $mdDialog, OcrAuth, OcrUtils) {
      const self = this;

      self.model = {};

      self.getUserFullName = OcrUtils.getUserFullName;
      self.hasAnyPermission = OcrAuth.hasAnyPermission;
      self.showAttachmentsRead = () => OcrAuth.hasAnyPermission([
        'APPOINTMENT_READ',
        'APPOINTMENT_ATTACHMENT_READ',
        'ADMIN_READ',
      ])

      self.$onInit =
        () => $scope.$watch(
          () => self.ngModel.$modelValue,
          function() {
            self.model = self.ngModel.$modelValue;
          },
        );

      self.viewNote = (note) => $mdDialog.show({
        controller: 'AddNoteDialogController',
        templateUrl: 'tmpl/add-note-dialog.html',
        parent: angular.element($document[0].body),
        locals: {
          user: OcrAuth.session.user,
          appointmentActivityId: undefined,
          note: note,
          readOnly: true,
        },
        bindToController: true,
        controllerAs: '$ctrl',
        clickOutsideToClose: false,
        multiple: true,
        fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
      });

      self.openNotes = (notes) => $mdDialog.show({
        controller: 'NotesViewController',
        templateUrl: 'components/ocr-appointment-activities-detail-view/notes-view.template.html',
        parent: angular.element($document[0].body),
        locals: {
          notes: notes,
        },
        bindToController: true,
        controllerAs: '$ctrl',
        clickOutsideToClose: false,
        multiple: true,
        fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
      })

      self.openAttachments = (attachments) => $mdDialog.show({
        controller: 'AttachmentsViewController',
        templateUrl:
          'components/ocr-appointment-activities-detail-view/attachments-view.template.html',
        parent: angular.element($document[0].body),
        locals: {
          attachments: attachments,
        },
        bindToController: true,
        controllerAs: '$ctrl',
        clickOutsideToClose: false,
        multiple: true,
        fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
      });
    },
  ],
});
