angular.module('CaresApp').component('ocrInvoiceView', {
  templateUrl: 'components/ocr-invoice-view/ocr-invoice-view.template.html',
  controller: [
    '$scope',
    '$document',
    '$window',
    '$location',
    '$routeParams',
    '$mdToast',
    '$mdDialog',
    'InvoiceService',
    'InvoiceDataService',
    'OcrAuth',
    'OcrInvoiceFlagsService',
    'OcrInvoiceFlagsDialog',
    'OcrUtils',
    'ExceptionLogService',
    'OcrViews',
    '$interval',
    function($scope, $document, $window, $location, $routeParams, $mdToast, $mdDialog,
      InvoiceService, InvoiceDataService, OcrAuth, OcrInvoiceFlagsService, OcrInvoiceFlagsDialog,
      OcrUtils, ExceptionLogService, OcrViews, $interval,
    ) {
      const self = this;
      OcrUtils.setPageTitle(`Invoice ${$routeParams.invoiceNumber}`);
      self.currentView = OcrViews.currentView;
      self.flags = OcrInvoiceFlagsService.flags;
      self.saving = false;
      self.invoiceNumber = $routeParams.invoiceNumber;
      self.invoice = null;
      self.showInsufficientFunds = false;
      self.office = {};
      self.activities = [];
      self.exceptions = [];
      self.invoiceLoading = true;
      self.casesLoading = false;
      self.insufficientFundsLoading = false;
      self.dateView = $location.hash() === 'date';
      self.currentView.key = $routeParams.view;

      self.hasPermission = OcrAuth.checkPermission;

      const canRejectOrApprove = ['System Admin', 'OCR Billing Manager', 'OCR Accounting'];

      const unregisterWatcher = $scope.$watch(
        () => OcrAuth.session.user && self.invoice.status,
        function() {
          if (!(OcrAuth.session.user && self.invoice.status)) {
            return;
          }
          unregisterWatcher();

          self.showRecreateButton = function() {
            if (!OcrAuth.session) {
              return false;
            }
            if (self.invoice.status.status !== 'Draft' && self.invoice.status.status !== 'Error') {
              return false;
            }
            if (OcrAuth.session.user.permissions.indexOf("INVOICE_RECREATE") !== -1) {
              return true;
            }
            return false;
          };

          self.showPaidButton = function() {
            if (!self.invoice.status.status.endsWith('OCR Finance')) {
              return false;
            }
            if (OcrAuth.session.user.permissions.indexOf("INVOICE_RECREATE") !== -1) {
              return true;
            }
            return false;
          };

          self.disableSubmitButton = function() {
            if (!self.invoice.sufficientFundsTotals || self.invoice.sufficientFundsTotals.total == 0) {
              return true;
            }
            if (!self.invoice.status.status.endsWith('Draft')) {
              return true;
            }
            if (OcrAuth.session.user.permissions.indexOf("INVOICE_SUBMIT") !== -1) {
              return false;
            }
            return true;
          }

          self.showClearCacheButton = function() {
            return self.hasPermission("INVOICE_CLEAR_CACHE");
          }

          self.showSubmitButton = function() {
            if (self.invoice.status.status !== 'Draft') {
              return false;
            }
            return OcrAuth.session.user.persona.name === 'Managing Attorney'
              || OcrAuth.session.user.persona.name === 'System Admin';
          };

          self.showDelete = function() {
            if (self.saving || !OcrAuth.session) {
              return false;
            }
            return self.invoice
                && OcrAuth.session.user
                && self.invoice.userId === OcrAuth.session.user.id
                && (self.invoice.statusId === 1 || self.invoice.statusId === 11);
          };

          self.showApproveOrRejectButton = function() {
            if (!OcrAuth.session
              || canRejectOrApprove.indexOf(OcrAuth.session.user.persona.name) === -1
            ) {
              return false;
            } else if (self.invoice.status.status === 'Paid'
              || self.invoice.status.status === 'Draft'
              || self.invoice.status.status.startsWith('Rejected')
              || self.invoice.status.status.endsWith('OCR Finance')) {
              return false;
            } else if (self.invoice.status.status === 'Submitted') {
              return true;
            } else if (self.invoice.status.status.endsWith('OCR Billing Manager')
              && (OcrAuth.session.user.persona.name === 'OCR Accounting'
                || OcrAuth.session.user.persona.name === 'System Admin')
            ) {
              return true;
            }
            return false;
          };

          self.showClose = function() {
            if (self.invoice.status.status.startsWith('Rejected')
              && !self.invoice.status.status.endsWith('Alert Dismissed')
              && OcrAuth.session.userId === self.invoice.userId
            ) {
              return true;
            }
            return false;
          };

          self.buttonSuffix = function() {
            if (self.invoice.status.status.endsWith('OCR Billing Manager')
              || OcrAuth.session.user.persona.name === "OCR Accounting"
            ) {
              return ": OCR Finance";
            } else if (self.invoice.status.status === 'Submitted') {
              return ": OCR Billing Manager";
            }
            return "";
          };
        },
      );

      self.totalsLoading = true;
      self.invoiceLoading = true;
      self.invoice = {};
      self.invoice.insufficientFunds = {};

      self.showAuditLog = function() {
        $mdDialog.show({
          controller: 'InvoiceAuditLogController',
          templateUrl: 'tmpl/invoice-audit-log-dialog.html',
          parent: angular.element($document[0].body),
          clickOutsideToClose: false,
          locals: {
            invoiceNumber: self.invoiceNumber,
            invoice: self.invoice,
          },
          fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
        })
          .then(function() {}, function() {});
      };

      self.showExceptionLog = function() {
        $mdDialog.show({
          controller: 'InvoiceExceptionLogController',
          templateUrl: 'tmpl/invoice-exception-log-dialog.html',
          parent: angular.element($document[0].body),
          clickOutsideToClose: false,
          locals: {
            exceptions: self.exceptions,
          },
          fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
        })
          .then(function() {}, function() {});
      }

      self.getUserFullName = function(first, middle, last, suffix) {
        const user = {
          firstName: first,
          middleName: middle,
          lastName: last,
          suffix: suffix,
        };
        return OcrUtils.getUserFullName(user);
      };

      self.getInvoice = function() {
        InvoiceService.get({
          id: self.invoiceNumber
        }).$promise.then(
          function(resp) {
            self.invoice = {};
            Object.assign(self.invoice, resp);
            return ExceptionLogService.query({
              limit: 1000,
              office: resp.officeId,
              invoiceRangeStart: resp.rangeFrom,
              invoiceRangeEnd: resp.rangeTo,
            })
          },
        ).then(
          function(resp) {
            self.exceptions = resp;
          },
        ).finally(() => self.invoiceLoading = false);
      }

      self.getInvoice();

      self.getTotals = function(noCache) {
        InvoiceDataService.getTotals(self.invoiceNumber, noCache).then(
          function(totals) {
            if (totals) {
              self.invoice.sufficientFundsTotals = totals.sufficientFundsTotals;
              self.invoice.insufficientFundsTotals = totals.insufficientFundsTotals;
            }
            self.totalsLoading = false;
          }, function (error) {
            console.error("Error: %O", error);
            const message
              = "There was an error loading this invoice. Please close the invoice and try to load it again. "
              + "If the issue persists, please contact OCR (info@coloradochildrep.org) to report the issue.";
            $mdDialog.show(
              $mdDialog.alert()
                .parent(angular.element($document[0].querySelector(
                  '#popupContainer')))
                .clickOutsideToClose(false)
                .title('Error Loading Invoice')
                .textContent(message)
                .ariaLabel(message)
                .ok('OK')
                .multiple(true),
            );
            self.totalsLoading = false;
          });
      }

      self.getTotals(false);

      self.print = function() {
        $window.print();
      };

      self.showFlagsDialog = OcrInvoiceFlagsDialog.show;

      self.toggleDateView = function() {
        self.dateView = !self.dateView;
        if (self.dateView) {
          $location.hash('date');
        } else {
          $location.hash('standard');
        }
      };

      const showSubmitDialog = function(hasChanged, creditsExceedInvoiceTotals) {
        console.log(creditsExceedInvoiceTotals);
        $mdDialog.show({
          controller: 'SubmitInvoiceController',
          templateUrl: 'components/ocr-invoice-view/ocr-submit-invoice-confirmation.template.html',
          parent: angular.element($document[0].body),
          clickOutsideToClose: false,
          controllerAs: '$ctrl',
          locals: {
            hasChanged: hasChanged,
            invoiceNumber: self.invoiceNumber,
            view: $routeParams.view,
            creditsExceedInvoiceTotals: creditsExceedInvoiceTotals,
          },
          bindToController: true,
          multiple: true,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        }).then(function() {}, () => {});
      };

      self.submitInvoice = function() {
        self.saving = true;

        if (self.invoice.sufficientFundsTotals && self.invoice.sufficientFundsTotals.total < 0) {
          showSubmitDialog(false, true);
          self.saving = false;
          return;
        }

        InvoiceDataService.hasChanged(self.invoiceNumber).then(function(hasChanged) {
          showSubmitDialog(hasChanged, false);
          self.saving = false;
        });
      };

      self.rejectInvoice = function() {
        $mdDialog.show({
          controller: RejectInvoiceController,
          templateUrl: 'components/ocr-invoice-view/ocr-reject-invoice-confirmation.template.html',
          parent: angular.element($document[0].body),
          clickOutsideToClose: false,
          controllerAs: '$ctrl',
          locals: {
            invoiceNumber: self.invoiceNumber,
            view: $routeParams.view,
          },
          bindToController: true,
          multiple: true,
          //fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        }).then(function() {}, () => {});
      };

      self.approveInvoice = function() {
        $mdDialog.show($mdDialog.confirm()
          .title("Confirm Approval")
          .textContent(
            `Are you sure you want to approve invoice number ${self.invoiceNumber}?`)
          .ok("Approve")
          .cancel("Cancel"),
        ).then(
          function() {
            self.saving = true;
            //TODO change approval type based on who's approving.
            InvoiceService.update({
              id: self.invoiceNumber,
            }, {
              newStatus: "Approved",
            }).$promise.then(
              function() {
                self.saving = false;
                $mdToast.show(
                  $mdToast.simple()
                    .textContent('Invoice approved.')
                    .position("bottom right"),
                );
                $location.path(`/views/${$routeParams.view}`);
              },
            );
          }, () => {},
        );
      };

      self.recreateInvoice = function() {
        $mdDialog.show($mdDialog.confirm()
          .title("Confirm Approval")
          .textContent(
            "Are you sure you want to recreate this invoice?")
          .ok("Recreate")
          .cancel("Cancel"),
        ).then(
          function() {
            const invoice = {
              rangeFrom: self.invoice.rangeFrom,
              rangeTo: self.invoice.rangeTo,
              recreate: self.invoice.invoiceNumber,
            };
            $mdToast.show(
              $mdToast.simple()
                .textContent('Recreating invoice...')
                .position("bottom right"),
            );
            InvoiceService.save(invoice).$promise.then(
              function(resp) {
                Object.assign(self.invoice, resp.created[0]);

                let deregister = null;
                const ip = $interval(function() {
                  InvoiceService.get({
                    id: self.invoice.invoiceNumber,
                  }).$promise.then(
                    function(response) {
                      if (response.statusId === 11 || response.statusId === 1) {
                        $interval.cancel(ip);
                        if (deregister !== null) {
                          deregister();
                        }
                        Object.assign(self.invoice, response);
                        $mdToast.show(
                          $mdToast.simple()
                            .textContent(`Invoice #${self.invoice.invoiceNumber} created.`)
                            .position("bottom right"),
                        );
                        $location.path(`/views/${$routeParams.view}`);
                      }
                    },
                  );
                }, 3000);
                deregister = $scope.$on('$locationChangeStart', () => {
                  deregister();
                  deregister = null;
                  $interval.cancel(ip);
                });
              },
            );
          }, () => {},
        );
      };

      self.markAsPaid = function() {
        $mdDialog.show($mdDialog.confirm()
          .title("Confirm Mark as Paid")
          .textContent(
            `Are you sure you want to mark invoice number ${self.invoiceNumber} as paid?`)
          .ok("Mark as Paid")
          .cancel("Cancel"),
        ).then(
          function() {
            self.saving = true;
            InvoiceService.update({
              id: self.invoiceNumber,
            }, {
              newStatus: "Paid",
            }).$promise.then(
              function() {
                self.saving = false;
                $mdToast.show(
                  $mdToast.simple()
                    .textContent('Invoice marked as paid.')
                    .position("bottom right"),
                );
                $location.path(`/views/${$routeParams.view}`);
              },
            );
          }, () => {},
        );
      };

      self.markAsDismissed = function() {
        let status = null;

        if (self.invoice.status.status === 'Rejected by OCR Billing Manager') {
          status = 'Rejected by Billing Manager - Alert Dismissed';
        } else if (self.invoice.status.status === 'Rejected by OCR Finance') {
          status = 'Rejected by Finance - Alert Dismissed';
        } else {
          console.error(`Invalid Status: ${self.invoice.status.status}`);
          return;
        }

        $mdDialog.show($mdDialog.confirm()
          .title("Dismiss Alert?")
          .textContent(
            `Are you sure you remove invoice number ${self.invoiceNumber
            } from your alerts? You can still find this invoice in the future by going to `
            + `Invoicing/Invoices.`)
          .ok("Dismiss Alert")
          .cancel("Cancel"),
        ).then(
          function() {
            self.saving = true;
            InvoiceService.update({
              id: self.invoiceNumber,
            }, {
              newStatus: status,
              rejectionReason: self.invoice.rejectionReason,
            }).$promise.then(
              function() {
                self.saving = false;
                $mdToast.show(
                  $mdToast.simple()
                    .textContent('Invoice dismissed.')
                    .position("bottom right"),
                );
                $location.path(`/views/${$routeParams.view}`);
              },
            );
          }, () => {},
        );
      }

      self.closeForm = function() {
        $location.path(`/views/${$routeParams.view}`);
      };

      self.delete = function() {
        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Invoice Delete')
            .textContent(
              "Are you sure you want to delete this invoice? This can not be undone.",
            )
            .ariaLabel(
              "Are you sure you want to delete this invoice? This can not be undone.",
            )
            .ok('Delete')
            .cancel('Cancel'),
        ).then(function() {
          $mdToast.show(
            $mdToast.simple()
              .textContent('Deleting...')
              .position("bottom right"),
          );

          InvoiceService.delete({
            id: self.invoiceNumber,
          }, self.invoice).$promise
            .then(function() {
              $mdToast.show(
                $mdToast.simple()
                  .textContent('Invoice deleted.')
                  .position("bottom right"),
              );
              self.closeForm();
            })
            .catch(function(error) {
              console.error("Error: %O", error);
              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            });
        }, () => {});
      }

      self.collapseAll = function() {
        $scope.$broadcast('InvoiceViewCollapseAll')
      }

      self.expandAll = function() {
        $scope.$broadcast('InvoiceViewExpandAll')
      }

      self.exceptionsExist = function() {
        if (self.exceptions && self.exceptions.total && self.exceptions.total > 0) {
          return true;
        }
        return false;
      }

      self.addException = function() {
        $mdDialog.show({
          controller: 'AddExceptionDialogController',
          templateUrl: 'components/ocr-exception-log/add-exception-dialog.template.html',
          parent: angular.element($document[0].body),
          locals: {
            add: true,
            exception: undefined,
            officeId: self.invoice.officeId,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          multiple: true,
          clickOutsideToClose: false,
          //fullscreen: self.customFullscreen // Only for -xs, -sm breakpoints.
        }).then(function(newException) {
          self.exceptions.list.unshift(newException);
        }, function() {});
      }

      self.clearCacheAndReload = function() {

        let message = "Are you sure you want to clear the cache and reload this invoice?" +
          " This should only be necessary if the invoice appears incomplete.";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Clear Cache and Reload')
            .textContent(message)
            .ariaLabel(message)
            .ok('Clear Cache and Reload')
            .cancel('Cancel'),
        ).then(function() {
          $mdToast.show(
            $mdToast.simple()
              .textContent('Clearing cache and reloading...')
              .position("bottom right"),
          );
          $scope.$broadcast("ClearingCacheAndReloading");
        });
      }

      $scope.$on('ClearingCacheAndReloading', function() {
        self.getTotals(true);
      });

      $scope.$on('$routeChangeStart', () => $location.hash(''));

      /* eslint-disable no-shadow */
      function RejectInvoiceController($mdDialog) {
        const self = this;
        /* eslint-enable no-shadow */

        self.hide = function() {
          $mdDialog.hide();
        };

        self.cancel = function() {
          $mdDialog.cancel();
        };


        self.submit = function() {
          self.submitting = true;
          InvoiceService.update({
            id: self.invoiceNumber,
          }, {
            newStatus: "Rejected",
            rejectionReason: self.rejectionReason,
          }).$promise.then(
            function() {
              self.hide();
              $mdToast.show(
                $mdToast.simple()
                  .textContent('Invoice rejected.')
                  .position("bottom right"),
              );
              $location.path(`/views/${self.view}`);
            },
          ).finally(() => self.submitting = false);
        };
      }
      RejectInvoiceController.$inject = ['$mdDialog'];
    },
  ],
});
