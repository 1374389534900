angular.module('CaresApp').controller('ReopenAppointmentDialogController', [
  '$mdDialog',
  'OcrUtils',
  'OcrLookups',
  '$document',
  '$scope',
  function($mdDialog, OcrUtils, OcrLookups, $document, $scope) {
    const self = this;

    self.today = new Date();

    self.selected = [];

    self.getUserFullName = OcrUtils.getUserFullName;
    self.getAge = OcrUtils.age;

    self.permanencyGoalOptions = OcrLookups.dummyList
    OcrLookups.get('PermanencyGoal', true).then(
      (permanencyGoalOptions) => self.permanencyGoalOptions = permanencyGoalOptions,
    );

    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;

    self.submit = function() {
      if (self.case.statusId == 2) {
        $mdDialog.show({
          controller: 'ReopenCaseAppointmentDialogController',
          templateUrl: 'components/ocr-appointment/dialogs/reopen-case-appointment-dialog.html',
          parent: angular.element($document[0].body),
          locals: {
            appointment: self.appointment,
            case: self.case,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          clickOutsideToClose: false,
          multiple: true,
        })
          .then(function(response) {
            $scope.$emit("updateData");
            $mdDialog.hide(self.selected);
          })
      } else {
        $scope.$emit("updateData");
        $mdDialog.hide(self.selected);
      }
    }

    self.getDaysInPlacement = function(placement) {
      if (!placement) {
        return undefined;
      }

      const startDate = new Date(placement.startDate);
      const now = new Date();

      return Math.trunc((now.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24);
    };

    self.currentPlacement = function(childParty) {
      for (const placement of childParty.placements) {
        placement.startDate = new Date(placement.startDate);
        if (placement.startDate <= self.today) {
          if (angular.isUndefined(placement.endDate) || placement.endDate > self.today) {
            return placement;
          }
        }
      }
      return null;
    }
  },
]);
