angular.module('CaresApp').component('ocrActivityTypeEdit', {
  templateUrl: 'components/ocr-activity-type-edit/ocr-activity-type-edit.template.html',
  controller: [
    '$scope',
    '$document',
    '$location',
    '$routeParams',
    '$mdToast',
    '$mdDialog',
    'ActivityTypeService',
    'ActivityTypeHistoryService',
    'OcrLookups',
    'OcrUtils',
    'OcrAuth',
    function($scope, $document, $location, $routeParams, $mdToast, $mdDialog, ActivityTypeService,
      ActivityTypeHistoryService, OcrLookups, OcrUtils, OcrAuth) {
      $scope.$emit("SetSearchBox", false);
      const self = this;

      self.groups = null;
      self.statuses = null;
      self.statusHistory = null;

      self.selection = [];
      self.hasPermission = OcrAuth.checkPermission;

      self.groups = self.statuses = OcrLookups.dummyList;
      OcrLookups.get('ActivityGroup').then((groups) => self.groups = groups);
      OcrLookups.get('ActivityTypeStatus').then((statuses) => self.statuses = statuses);

      if ($routeParams.activityTypeId !== 'create') {
        self.id = $routeParams.activityTypeId;
      } else {
        OcrUtils.setPageTitle('New Activity Type');
      }
      self.loading = true;
      if (self.id) {
        self.promise = ActivityTypeService.get({ id: self.id }).$promise
          .then(function(resp) {
            self.entity = resp;
            OcrUtils.setPageTitle(`Activity Type: ${self.entity.type}`);
            if (self.entity.billable) {
              self.selection.push(1);
            }
            if (self.entity.requiresAttendance) {
              self.selection.push(2);
            }
            if (self.entity.mealAmountPrompt) {
              self.selection.push(3);
            }
            if (self.entity.copyQuantityPrompt) {
              self.selection.push(4);
            }
            if (self.entity.activityDurationTimePrompt) {
              self.selection.push(5);
            }
            if (self.entity.expenseAmountPrompt) {
              self.selection.push(6);
            }
            if (self.entity.postageAmountPrompt) {
              self.selection.push(7);
            }
            if (self.entity.discoveryAmountPrompt) {
              self.selection.push(8);
            }
            if (self.entity.filingFeeAmountPrompt) {
              self.selection.push(9);
            }
            if (self.entity.transcriptAmountPrompt) {
              self.selection.push(10);
            }
            if (self.entity.hearingTypePrompt) {
              self.selection.push(11);
            }
            if (self.entity.mileageAmountPrompt) {
              self.selection.push(12);
            }
            if (self.entity.travelTimePrompt) {
              self.selection.push(13);
            }
            if (self.entity.waitTimePrompt) {
              self.selection.push(14);
            }
            if (self.entity.variableAttendance) {
              self.selection.push(15);
            }
            if (self.entity.discussionTimePrompt) {
              self.selection.push(16);
            }
          })
          .finally(() => self.loading = false);

        ActivityTypeHistoryService.get({
          limit: 1000,
          activityTypeId: self.id,
        }).$promise
          .then(function(response) {
            self.statusHistory = response.list;
          });
      } else {
        self.entity = {
          initialVisit: false,
          childVisit: false,
        };
        self.loading = false;
      }

      self.getUserFullName = OcrUtils.getUserFullName;

      self.toggleSelection = function toggleSelection(id) {
        const idx = self.selection.indexOf(id);

        if (idx >= 0) {
          self.selection.splice(idx, 1);
        } else {
          self.selection.push(id);
        }
      };

      self.checkSelection = () => self.selection.length <= 0;

      self.save = function(andExit) {
        OcrLookups.remove('ActivityType');
        OcrLookups.remove('ActivityType', true);
        $mdToast.show(
          $mdToast.simple()
            .textContent('Saving...')
            .position("bottom right"),
        );
        let saveFunction = null;
        let saveArgs = null;
        if (self.id) {
          saveFunction = ActivityTypeService.update;
          saveArgs = [{ id: self.id }, self.entity];
        } else {
          saveFunction = ActivityTypeService.save;
          saveArgs = [self.entity];
        }
        saveFunction.apply(this, saveArgs).$promise.then(
          function() {
            self.form.$setPristine();
            $mdToast.show(
              $mdToast.simple()
                .textContent('Activity type saved.')
                .position("bottom right"),
            );
            if (andExit) {
              self.close(true);
            }
          },
        ).catch(function(error) {
          if (error && error.data && error.data.status === 401) {
            self.close(true);
            return;
          }
          if (error && error.data) {
            $mdToast.show(
              $mdToast.simple()
                .textContent(error.data.reason)
                .position("bottom right"),
            );
          }
        });
      };

      self.close = function(noConfirm) {
        if (noConfirm || self.form.$pristine) {
          $location.path(`/views/${$routeParams.view}`);
        } else {
          const message
              = "Are you sure that you want to close this form without saving your changes?";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Form Close')
              .textContent(message)
              .ariaLabel(message)
              .ok('Close Form')
              .cancel('Cancel'),
          ).then(
            () => $location.path(`/views/${$routeParams.view}`),
            () => {},
          );
        }
      };

      self.deleteForm = function() {
        const message
          = "Are you sure that you want to delete this activity type?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Activity Type Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete Activity Type')
            .cancel('Cancel'),
        ).then(function() {
          // ok
          OcrLookups.remove('ActivityType');
          OcrLookups.remove('ActivityType', true);
          $mdToast.show(
            $mdToast.simple()
              .textContent('Deleting...')
              .position("bottom right"),
          );

          ActivityTypeService.delete({
            id: self.id,
          }, self.entity).$promise.then(function() {
            $mdToast.show(
              $mdToast.simple()
                .textContent('Activity type deleted.')
                .position("bottom right"),
            );
            $location.path(`/views/${$routeParams.view}`);
          })
            .catch(function(error) {
              if (error && error.data) {
                $mdToast.show(
                  $mdToast.simple()
                    .textContent(error.data.reason)
                    .position("bottom right"),
                );
              }
            });
        },
        () => {});
      };

      $scope.$on('$locationChangeStart',
        function(event, newUrl, oldUrl) {
          const removeQueryParams = (url) => {
            if (url) {
              const index = url.indexOf("?");
              if (index >= 0) {
                return url.substring(0, index);
              }
            }
            return url;
          };

          const newPath = $location.path();

          if (
            newPath === "/"
            || self.form.$pristine
            || removeQueryParams(newUrl) === removeQueryParams(oldUrl)
          ) {
            return;
          }

          event.preventDefault();

          const message
            = "Are you sure that you want to close this form without saving your changes?";

          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Page Exit')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK')
              .cancel('Cancel'),
          ).then(() => {
            self.form.$setPristine();
            $location.path(newPath);
          });
        });

      self.$onInit =
        () => $scope.$watch(
          () => angular.isDefined(self.form),
          function() {
            if (self.form && self.form.$invalid) {
              angular.forEach(self.form.$error, function(field) {
                angular.forEach(field, function(errorField) {
                  errorField.$setTouched();
                });
              });
            }
          }, true);
    },
  ],
});
