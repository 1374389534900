angular.module('CaresApp').component('ocrMyInOfficeAppointments', {
  templateUrl: 'components/ocr-my-in-office-appointments/ocr-my-in-office-appointments.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    '$location',
    'AppointmentService',
    'OcrAuth',
    'OcrUtils',
    'OcrViews',
    'SessionService',
    'AppointmentBookmarkService',
    'OcrLookups',
    function($scope, $location, AppointmentService, OcrAuth,
             OcrUtils, OcrViews, SessionService, AppointmentBookmarkService, OcrLookups) {
      const self = this;
      self.selected = [];
      self.today = OcrUtils.today;
      self.currentView = OcrViews.currentView;
      self.getUserFullName = OcrUtils.getUserFullName;
      self.appointments = [];
      self.appointments.count = 0;
      self.appointments.total = 0;
      self.search = {
        showActive: true,
        inOffice: true,
        bookmark: false
      };
      self.hasPermission = OcrAuth.checkPermission;
      OcrLookups.get('HelpText').then(
        (helpText) => self.requestHelpText = helpText.lookup(2).text,
      );
      const promises = [];
      self.promise = null;

      self.saveSearch = function() {
        if (self.search.showInactive == undefined) {
          self.search.showInactive = false;
        }
        if (self.search.nearMax == undefined) {
          self.search.nearMax = false;
        }
        if(self.search.showActive == undefined) {
          self.search.showActive = false;
        }
        if(self.search.mineOnly == undefined) {
          self.search.mineOnly = false;
        }
        if(self.search.bookmark == undefined) {
          self.search.bookmark = false;
        }
        localStorage.setItem('showInactive', self.search.showInactive);
        localStorage.setItem('nearMax', self.search.nearMax);
        localStorage.setItem('showActive', self.search.showActive);
        localStorage.setItem('mineOnly', self.search.mineOnly);
        localStorage.setItem('dateFrom', self.search.dateFrom);
        localStorage.setItem('dateTo', self.search.dateTo);
        localStorage.setItem('abbreviatedCaseNumber', self.search.abbreviatedCaseNumber);
        localStorage.setItem('childPartyFirstName', self.search.childPartyFirstName);
        localStorage.setItem('childPartyLastName', self.search.childPartyLastName);
        localStorage.setItem('bookmark', self.search.bookmark);
      }

      self.resetFilter = function() {
        self.search.showActive = true;
        self.search.showInactive = false;
        self.search.mineOnly = false;
        self.search.nearMax = false;
        self.search.bookmark = false;
        self.search.dateFrom = undefined;
        self.search.dateTo = undefined;
        self.search.abbreviatedCaseNumber = '';
        self.search.childPartyLastName = '';
        self.search.childPartyFirstName = '';
        localStorage.setItem('showInactive', '');
        localStorage.setItem('nearMax', '');
        localStorage.setItem('showActive', self.search.showActive);
        localStorage.setItem('mineOnly', '');
        localStorage.setItem('dateFrom', undefined);
        localStorage.setItem('dateTo', undefined);
        localStorage.setItem('abbreviatedCaseNumber', '');
        localStorage.setItem('childPartyFirstName', '');
        localStorage.setItem('childPartyLastName', '');
        localStorage.setItem('bookmark', '');
        self.updateData();
      }

      self.updateData = function() {
        self.search.skip = (self.query.page - 1) * self.query.limit;
        self.search.limit = self.query.limit;
        self.search.inOffice = true;
        promises.push(AppointmentService.get(self.search)
          .$promise.then(function(resp) {
            self.appointments = resp.list;
            self.appointments.count = resp.count;
            self.appointments.total = resp.total;
            for (const appointment_ of self.appointments) {
              const childPartyNames = [];
              promises.push(AppointmentBookmarkService.get({
                appointmentId: appointment_.id,
                userId: OcrAuth.session.user.id
              }).$promise.then(function (response) {
                if (response.list.length == 0) {
                  appointment_.isBookmark = false;
                } else {
                  appointment_.isBookmark = true;
                }
            }));
            if (appointment_.childParties) {
              for (const childParty of appointment_.childParties) {
                if (childParty.endDate) {
                  childParty.endDate = new Date(childParty.endDate);
                }
                if (!childParty.endDate || childParty.endDate > self.today) {
                  childPartyNames.push(OcrUtils.getChildPartyFullName(
                    childParty));
                }
              }
            }
              appointment_.childPartyNames = childPartyNames.join("; ");
            }
          }));
        }


      self.$onInit = function() {
        self.search = $location.search();
        if (Object.keys(self.search).length !== 0) {
          self.query.limit = self.search.limit;
          self.query.page = (self.search.skip / self.query.limit) + 1;
          self.search.showInactive = (localStorage.getItem('showInactive') == 'true');
          self.search.nearMax = (localStorage.getItem('nearMax') == 'true');
          self.search.bookmark = (localStorage.getItem('bookmark') == 'true');
          if (localStorage.getItem('dateFrom') != null && localStorage.getItem('dateFrom') != 'undefined') {
            self.search.dateFrom = localStorage.getItem('dateFrom');
          }
          if (localStorage.getItem('dateTo') != null && localStorage.getItem('dateTo') != 'undefined') {
            self.search.dateTo = localStorage.getItem('dateTo');
          }
          if (localStorage.getItem('abbreviatedCaseNumber') != null && localStorage.getItem('abbreviatedCaseNumber') != 'undefined') {
            self.search.abbreviatedCaseNumber = localStorage.getItem('abbreviatedCaseNumber');
          }
          if (localStorage.getItem('childPartyFirstName') != null && localStorage.getItem('childPartyFirstName') != 'undefined') {
            self.search.childPartyFirstName = localStorage.getItem('childPartyFirstName');
          }
          if (localStorage.getItem('childPartyLastName') != null && localStorage.getItem('childPartyLastName') != 'undefined') {
            self.search.childPartyLastName = localStorage.getItem('childPartyLastName');
          }
          if (localStorage.getItem('showActive') == null) {
            self.search.showActive = true;
          } else {
            self.search.showActive = (localStorage.getItem('showActive') == 'true');
          }
        } else {
          self.query.limit = 50;
          self.search.inOffice = true;
          self.search.mineOnly = (localStorage.getItem('mineOnly') == 'true');
          self.search.showInactive = (localStorage.getItem('showInactive') == 'true');
          self.search.nearMax = (localStorage.getItem('nearMax') == 'true');
          self.search.bookmark = (localStorage.getItem('bookmark') == 'true');
          if (localStorage.getItem('dateFrom') != null && localStorage.getItem('dateFrom') != 'undefined') {
            self.search.dateFrom = localStorage.getItem('dateFrom');
          }
          if (localStorage.getItem('dateTo') != null && localStorage.getItem('dateTo') != 'undefined') {
            self.search.dateTo = localStorage.getItem('dateTo');
          }
          if (localStorage.getItem('abbreviatedCaseNumber') != null && localStorage.getItem('abbreviatedCaseNumber') != 'undefined') {
            self.search.abbreviatedCaseNumber = localStorage.getItem('abbreviatedCaseNumber');
          }
          if (localStorage.getItem('childPartyFirstName') != null && localStorage.getItem('childPartyFirstName') != 'undefined') {
            self.search.childPartyFirstName = localStorage.getItem('childPartyFirstName');
          }
          if (localStorage.getItem('childPartyLastName') != null && localStorage.getItem('childPartyLastName') != 'undefined') {
            self.search.childPartyLastName = localStorage.getItem('childPartyLastName');
          }
          if (localStorage.getItem('showActive') == null) {
            self.search.showActive = true;
          } else {
            self.search.showActive = (localStorage.getItem('showActive') == 'true');
          }
        }
        self.updateData();
      };

      self.requestAccess = function() {
        var url = '' + self.currentView.key + '/' + self.appointments.appointment.id;
        $location.path(url);
      }

      self.addBookmark = function() {
        for (const appointment of self.selected) {
          if (appointment.isBookmark == false) {
            promises.push(AppointmentBookmarkService.save({
              appointmentId: appointment.id,
              userId: OcrAuth.session.user.id
            }).$promise,);
            appointment.isBookmark = true;
          }
        }
        self.selected = [];
      }

      self.deleteBookmark = function() {
        for (const appointment of self.selected) {
          if (appointment.isBookmark == true) {
            promises.push(AppointmentBookmarkService.get({
                appointmentId: appointment.id,
                userId: OcrAuth.session.user.id
              }).$promise.then(function(response) {
                for (const bookmark of response.list) {
                  promises.push(AppointmentBookmarkService.delete({
                    id: bookmark.id
                  }).$promise,);
                }
              })
            )
            appointment.isBookmark = false;
          }
        }
        self.selected = [];
      }

      self.disableAddBookmark = function() {
        if (self.selected[0].isBookmark == true) {
          return true;
        } else {
          return false;
        }
      }

      self.disableRemoveBookmark = function() {
        if (self.selected[0].isBookmark == false) {
          return true;
        } else {
          return false;
        }
      }

      $scope.$on('resetFilters', () => {
          self.resetFilter();
        },
      );

      $scope.$on("LoggingOut",
        () => {
          SessionService.signout();
        },
      );

      $scope.$on('$locationChangeStart',
        function(_, newUrl, oldUrl) {
          const newArray = newUrl.split("?");
          const oldArray = oldUrl.split("?");
          if (newArray[0] !== oldArray[0]) {
            $location.search("");
          }
        });
    },
  ],
});
