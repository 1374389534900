angular.module('CaresApp').component('ocrOfficeActivities', {
  templateUrl: 'components/ocr-office-activities/ocr-office-activities.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    '$document',
    '$mdDialog',
    'AppointmentActivityService',
    'UserService',
    'OcrUtils',
    'OcrViews',
    'OcrAuth',
    'OcrLookups',
    'AppointmentGroupService',
    'AppointmentActivityTotalsService',
    function($scope, $document, $mdDialog, AppointmentActivityService,
      UserService, OcrUtils, OcrViews, OcrAuth, OcrLookups,
      AppointmentGroupService, AppointmentActivityTotalsService,
    ) {
      const self = this;

      self.selected = [];
      self.attorneysInOffice = [];
      self.loaded = false;
      self.hasAnyPermission = OcrAuth.hasAnyPermission;
      self.detailView = false;
      self.paidFilter = true;
      self.unpaidFilter = true;
      self.appointmentGroups = [];
      self.search = {
        billable: true,
        notBillable: true,
      };
      OcrUtils.setPageTitle('Office Activities');
      self.currentView = OcrViews.currentView;

      self.getUserFullName = OcrUtils.getUserFullName;

      self.activityTypes = self.activityGroups = OcrLookups.dummyList;
      OcrLookups.get('ActivityType').then((activityTypes) => self.activityTypes = activityTypes);

      OcrLookups.get('ActivityGroup').then(
        (activityGroups) => self.activityGroups = activityGroups,
      );

      function fixBillable() {
        if (self.search.billable && self.search.notBillable) {
          return undefined;
        }
        if (self.search.billable) {
          return true;
        }
        return false;
      }

      function fixPaid() {
        if (self.paidFilter && !self.unpaidFilter) {
          return true;
        } else if (!self.paidFilter && self.unpaidFilter) {
          return false;
        }
        return undefined;
      }

      function undefinedIfBlank(value) {
        if (value === "" || value === null) {
          return undefined;
        }
        return value;
      }

      AppointmentGroupService.get({
        officeId: OcrAuth.session.user.officeId,
      }).$promise.then(function(response) {
        self.appointmentGroups = response.list;
      }).catch(() => self.appointmentGroups = []);
      self.query = {
        order: undefined,
        page: 1,
        limit: 20,
      };

      self.results = {
        data: [],
        count: 0,
        total: 0,
      };
      self.promise = null;
      $scope.$watch(() => self.promise, () => {
        self.setPromise()(self.promise);
      });

      self.updateData = function() {
        self.promise = AppointmentGroupService.get({
          officeId: OcrAuth.session.user.officeId,
        })
          .$promise.then(function(response) {
          self.appointmentGroups = response.list;
        }).finally(() => self.promise = null);
        AppointmentActivityTotalsService.get({
          officeId: OcrAuth.session.user.officeId,
          dateFrom: self.search.dateFrom,
          dateTo: self.search.dateTo,
          userId: self.search.userId,
          appointmentGroupId: undefinedIfBlank(self.search.appointmentGroupId),
          activityTypeId: undefinedIfBlank(self.search.activityTypeId),
          activityGroupId: undefinedIfBlank(self.search.activityGroupId),
          note: undefinedIfBlank(self.search.note),
          billable: fixBillable(),
          paid: fixPaid(),
        }).$promise
          .then(
            (resp) => self.activityTotals = resp,
          );
        self.promise = AppointmentActivityService.get({
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
          order: self.query.order,
          officeId: OcrAuth.session.user.officeId,
          dateFrom: self.search.dateFrom,
          dateTo: self.search.dateTo,
          userId: self.search.userId,
          appointmentGroupId: undefinedIfBlank(self.search.appointmentGroupId),
          activityTypeId: undefinedIfBlank(self.search.activityTypeId),
          activityGroupId: undefinedIfBlank(self.search.activityGroupId),
          note: undefinedIfBlank(self.search.note),
          billable: fixBillable(),
          paid: fixPaid(),
        }).$promise.then(function(response) {
          self.results.data = response.list;
          // FIXME This should be total, not count. Results are flipped.
          self.results.total = response.count;
          for (const activity_ of self.results.data) {
            const childPartyNames = [];
            if (activity_.childParties) {
              for (const childParty of activity_.childParties) {
                childPartyNames.push(OcrUtils.getChildPartyFullName(
                  childParty));
              }
            }
            activity_.childPartyNames = childPartyNames.join("; ");
          }
          self.loaded = true;
        }).finally(() => self.promise = null);
      };

      self.resetSearch = function() {
        self.search = {
          billable: true,
          notBillable: true,
        };
        self.updateData();
      }

      self.viewActivity = function() {
        $mdDialog.show({
          controller: 'AddActivityDialogController',
          templateUrl: 'components/ocr-appointment-activities/add-activity-dialog.template.html',
          parent: angular.element($document[0].body),
          locals: {
            add: false,
            appointment: {
              id: self.selected[0].appointmentId,
              childParties: self.selected[0].childParties,
            },
            activity: self.selected[0],
            readOnly: true,
            disableAdd: true,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          multiple: true,
          clickOutsideToClose: false,
          //fullscreen: self.customFullscreen // Only for -xs, -sm breakpoints.
        });
      };

      self.$onInit = function() {
        self.updateData();

        self.promise = UserService.get({
          limit: 1000,
          office_id: OcrAuth.session.user.officeId,
        }).$promise.then(
          function(resp) {
            self.attorneysInOffice = resp.list;
          },
        ).finally(() => self.promise = null);
      };

      self.toggleView = function() {
        self.detailView = !self.detailView;
        if (self.detailView === false) {
          self.search.note = undefined;
        }
      };
    },
  ],
});
