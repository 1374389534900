angular.module('CaresApp').component('ocrBestInterestsContinuationReasonList', {
  templateUrl: 'components/ocr-best-interests-continuation-reason-list/ocr-best-interests-continuation-reason-list.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    'OcrAuth',
    'OcrUtils',
    'OcrViews',
    'BestInterestsContinuationReasonService',
    function($scope, OcrAuth, OcrUtils, OcrViews, BestInterestsContinuationReasonService) {

      const self = this;
      OcrUtils.setPageTitle('Best Interests Continuation Reason');
      self.currentView = OcrViews.currentView;
      self.hasPermission = OcrAuth.checkPermission;
      let currentSearch = undefined;
      self.results = {
        data: [],
        count: 0,
        total: 0,
      };
      self.promise = null;
      $scope.$watch(() => self.promise, () => self.setPromise()(self.promise));
      const promises =[]

      self.updateData = function() {
        promises.push(BestInterestsContinuationReasonService.get({
          query: currentSearch,
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
        }).$promise.then(function(response) {
          self.results.data = response.list;
          self.results.count = response.count;
          self.results.total = response.total;
        }));
      }
      self.$onInit = () => self.updateData();

      $scope.$on("Searching", function(_, data) {
        const call = {
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
        }

        if (data) {
          currentSearch = data;
          call.query = data;
        } else {
          currentSearch = undefined;
        }

        self.promise = BestInterestsContinuationReasonService.query(call).$promise.then(
          function(resp) {
            self.results.data = resp.list;
            self.results.count = resp.count;
            self.results.total = resp.total;
          },
        ).finally(() => self.promise = null)
      });
    },
  ],
});
