angular.module('CaresApp').component('ocrDirectory', {
  templateUrl: 'components/ocr-directory/ocr-directory.template.html',
  bindings: {
    setPromise: '&onPromiseChanged',
    paginate: '<onPaginate',
    query: '<',
  },
  controller: [
    '$scope',
    'UserService',
    'OcrLookups',
    'OcrAuth',
    'OcrUtils',
    'VendorService',
    function($scope, UserService, OcrLookups, OcrAuth, OcrUtils, VendorService) {

      OcrLookups.get('HelpText').then(
        (helpText) => self.requestHelpText = helpText.lookup(4).text,
      );
      OcrLookups.get('UrlLinkText').then(
        (urlLinkText) => self.changeInfoUrl = urlLinkText.lookup(1).text,
      )
      OcrLookups.get('UrlLinkText').then(
        (urlLinkText) => self.addStaffUrl = urlLinkText.lookup(2).text,
      )
      OcrLookups.get('UrlLinkText').then(
        (urlLinkText) => self.removeStaffUrl = urlLinkText.lookup(3).text,
      )
      const self = this;
      OcrUtils.setPageTitle('My Office');
      self.personas = self.statuses = OcrLookups.dummyList;

      OcrLookups.get('Persona').then((personas) => self.personas = personas);
      OcrLookups.get('UserStatus').then((statuses) => self.statuses = statuses);
      self.selectedOptions = {id: 1, option: 'Active'};
      let currentSearch = undefined;
      self.options = [
        {id: 1, option: 'Active'},
        {id: 2, option: 'Inactive'},
        {id: 3, option: 'Active and Inactive'}
      ]
      self.hasPermission = OcrAuth.checkPermission;
      self.search = {
        active: false,
        inActive: false
      }

      self.results = {
        data: [],
        count: 0,
        total: 0,
      };
      self.promise = null;
      $scope.$watch(() => self.promise, () => self.setPromise()(self.promise));

      self.updateData = function() {
        self.search.skip = (self.query.page - 1) * self.query.limit;
        self.search.limit = self.query.limit;
        self.search.order = self.query.order;
        self.search.office_id = OcrAuth.session.user.officeId;
        self.promise = VendorService.query({
          officeId: OcrAuth.session.user.officeId,
        }).$promise.then(function(response) {
            for (const vendor of response.list) {
              self.vendorName = vendor.name;
              self.vendorAddress = vendor.address1;
              self.vendorCity = vendor.city;
              self.vendorState = vendor.state;
              self.vendorZip = vendor.zip;
            }
          }
        ).finally(() => self.promise = null);
        if (self.selectedOptions.id == 1) {
          self.search.active = true;
          self.search.inActive = false;
        }
        if (self.selectedOptions.id == 2) {
          self.search.active = false;
          self.search.inActive = true;
        }
        if (self.selectedOptions.id == 3) {
          self.search.active = false;
          self.search.inActive = false;
        }
        self.promise = UserService.query(self.search)
          .$promise.then(
          function(resp) {
            self.results.data = resp.list;
            // FIXME the count and total are swapped for some reason
            self.results.count = resp.total;
            self.results.total = resp.count;
          },
        ).finally(() => self.promise = null);
      };

      self.$onInit = function() {
        self.updateData();
      }

      $scope.$on("Searching", function(_, data) {
        const call = {
          skip: (self.query.page - 1) * self.query.limit,
          limit: self.query.limit,
        };

        if (data) {
          currentSearch = data;
          call.query = data;
        } else {
          currentSearch = undefined;
        }

        self.promise = UserService.query(call).$promise.then(
          function(resp) {
            self.results.data = resp.list;
            self.results.count = resp.count;
            self.results.total = resp.total;
          },
        ).finally(() => self.promise = null);
      });

      self.reorder = self.updateData;
    },
  ],
});
